import { Logger } from "./Logger";
import { v4 as uuidv4 } from "uuid";

export class ApiResponse {
    constructor(response, hasSuccess, messages, statusCode) {
        this.response = response;
        this.hasSuccess = hasSuccess;
        this.messages = messages;
        this.statusCode = statusCode;
    }
}

export default class OrderPlacementApi {
    constructor(baseUrl) {
        this.props = {
            baseUrl: baseUrl,
            cacheName: "default-cache",
            settings: OrderPlacementApi.settings,
        };
    }

    static settings = {
        mode: "cors", // no-cors, cors, *same-origin
        cache: "default", // *default, no-cache, reload, force-cache, only-if-cached
        credentials: "include", // include, same-origin, *omit
        redirect: "follow", // manual, *follow, error
        referrer: "no-referrer", // no-referrer, *client
        headers: {
            "Content-Type": "application/json; charset=utf-8",
            "Accept-Language": "*",
        },
    };

    getAbsoluteUrl(url) {
        return this.props.baseUrl + url;
    }

    getOrder = async (globalOfferId, culture) =>
        await this.getAsJson(`api/order/${globalOfferId}/translation`, culture);

    getLocalization = async (culture) => await this.getAsJson(`api/localization?culture=${culture}`);

    getOrderContact = async (accountId) => {
        return await this.getAsJson(`api/order/account/${accountId}/owner`);
    };

    getShippingAddress = async (salesForceAccountId) =>
        await this.getAsJson(`api/address/${salesForceAccountId}/shipping`);

    getBillingAddress = async (salesForceAccountId) =>
        await this.getAsJson(`api/address/${salesForceAccountId}/billing`);

    getAdditionalShippingAddresses = async (salesForceAccountId) =>
        await this.getAsJson(`api/address/${salesForceAccountId}/additionalShipping`);

    getCountries = async () => await this.getAsJson(`api/location/countries`);

    getStatesByCountry = async (countryId) => await this.getAsJson(`api/location/countries/${countryId}/states`);

    getUnwindDirections = async (plantLocationWithId) =>
        await this.getAsJson(`api/order/${plantLocationWithId}/unwinddirections`);

    getArticle = async (plantLocationWithId, article) =>
        await this.getAsJson(`api/article/${plantLocationWithId}/${article}`);

    getArticleByName = async (plantLocationWithId, article) =>
        await this.getAsJson(`api/article/${plantLocationWithId}/name/${article}`);

    getDielineInstructions = async (plantLocationWithId) =>
        await this.getAsJson(`api/article/${plantLocationWithId}/dieline/instructions/article`);

    submitOrderForCheckout = async (key, orderPayload) => {
        var response = await this.postAsJson(`api/order/${key}/create`, orderPayload);
        console.log("response from submit for checkout", response);
        return response;
    };

    submitReorderArtwork = async (orderIntentId, payload) => {
        var response = await this.postAsJson(`api/reorder/orderintent/${orderIntentId}/artwork`, payload);
        console.log("response from submit artwork checkout", response);
        return response;
    };

    isReorderArtworkSubmitted = async (orderIntentId) => await this.getAsJson(`api/reorder/orderintent/${orderIntentId}/artwork`);

    getEstimatedAmountDue = async (key, payload) => {
        var response = await this.postAsJson(`api/order/${key}/due`, payload);
        console.log("response from getEstimatedAmountDue", response);
        return response;
    };

    submitForOrderPlacement = async (key) => await this.postAsJson(`api/order/${key}/order`);

    getArtworkSkus = async (globalOfferId) => await this.getAsJson(`api/order/${globalOfferId}/artworkskus`);

    getEpacConnectUser = async (salesforceAccountId) =>
        await this.getAsJson(`api/epacconnect/account/search/?accountId=${salesforceAccountId}`);

    getIsDuplicateEmailAddress = async (emailAddress) =>
        await this.getAsJson(`api/epacconnect/account/search/?emailAddress=${encodeURIComponent(emailAddress)}`);

    getPaymentOptions = async (globalOfferId, plantId) =>
        await this.getAsJson(`api/order/${globalOfferId}/plant/${plantId}/payment/configuration`);

    getArtworkContacts = async (salesforceAccountId) =>
        await this.getAsJson(`api/contacts/artwork/${salesforceAccountId}`);

    getRoutedPlant = async (key, shippingAddressPayload) =>
        await this.postAsJson(`api/order/${key}/route/plant`, shippingAddressPayload);

    postPurchaseOrder = async (globalOfferId, file, fileName) => {
        var settings = { ...this.props.settings };

        settings.method = "POST";
        settings.headers = {};

        const formData = new FormData();
        formData.append("formFile", file);
        formData.append("fileName", file.name);

        settings.body = formData;

        var uri = this.getAbsoluteUrl(`api/order/${globalOfferId}/attachment/upload`);

        try {
            const response = await fetch(uri, settings);

            if (response.ok) {
                return response.json();
            }
        } catch (err) {
            throw new Error(`Response was not successful - ${err}`);
        }
    };

    async postAsJson(url, payload) {
        var settings = { ...this.props.settings };

        settings.method = "POST";
        settings.headers["Accept"] = "application/json";
        settings.headers["Content-Type"] = "application/json";
        settings.body = JSON.stringify(payload);

        var uri = this.getAbsoluteUrl(url);

        try {
            const response = await fetch(uri, settings);

            if (response.ok) {
                const data = await response.json();
                return new ApiResponse(data, response.ok, [], response.status);
            }

            const body = await response.json();
            return new ApiResponse(body, false, [], response.status);

        } catch (err) {
            throw new Error(`Response was not successful - ${err}`);
        }
    }

    async putAsJson(url, payload) {
        var settings = { ...this.props.settings };

        settings.method = "PUT";
        settings.headers["Accept"] = "application/json";
        settings.headers["Content-Type"] = "application/json";
        settings.body = JSON.stringify(payload);

        var uri = this.getAbsoluteUrl(url);

        try {
            const response = await fetch(uri, settings);

            if (!response.ok) {
                const message = `Response was not successful - ${response.status}`;
                const contentType = response.headers.get("content-type");

                if (response.status === 400) {
                    //Try and parse validation details
                    if (contentType && contentType.indexOf("application/problem+json") !== -1) {
                        var data = await response.json();

                        console.log("bad request body", data);


                        return new ApiResponse(data, false, [], response.status);
                    } else {
                        var text = await response.text();

                        return new ApiResponse(text, false, [], response.status);
                    }
                }
                return new ApiResponse(null, false, [message], response.status);
            }

            const contentType = response.headers.get("content-type");

            if (contentType && contentType.indexOf("application/json") !== -1) {
                var data = await response.json();

                return new ApiResponse(data, true, [], response.status);
            } else {
                var text = await response.text();

                return new ApiResponse(text, true, [], response.status);
            }
        } catch (err) {
            throw new Error(`Response was not successful - ${err}`);
        }
    }

    async getAsJson(url, culture) {
        var settings = { ...this.props.settings };

        settings.method = "GET";
        settings.headers["Accept-Language"] = culture ? culture : "*";
        settings.headers["epac-global-trace-id"] = uuidv4();

        var uri = this.getAbsoluteUrl(url);

        const response = await fetch(uri, settings);

        if (!response.ok) {
            const message = `Response was not successful - ${response.status}`;
            return new ApiResponse(null, false, [message], response.status);
        }

        const contentType = response.headers.get("content-type");
        if (contentType && contentType.indexOf("application/json") !== -1) {
            var data = await response.json();

            return new ApiResponse(data, true, [], response.status);
        } else {
            var text = await response.text();

            return new ApiResponse(text, true, [], response.status);
        }
    }

    getOfferWithSalesContact = async (globalOfferId) => {
        var offerWithContact = {
            offer: null,
            salesContact: {},
            culture: null,
            formattedSizeString: null,
            applicationFeatures: 0,
            orderIntent: {},
            canNotSellToCustomer: false,
            invalidForPurchase: false,
            customerSubmitDate: null,
        };
        var offer = await this.getOrder(globalOfferId);

        if (offer.hasSuccess) {
            offerWithContact.offer = offer.response.offerState;
            offerWithContact.culture = offer.response.culture;
            offerWithContact.canNotSellToCustomer = offer.response.canNotSellToCustomer;
            offerWithContact.invalidForPurchase = offer.response.invalidForPurchase;
            offerWithContact.formattedSizeString = offer.response.formattedSizeString;
            offerWithContact.applicationFeatures = offer.response.applicationFeatures;
            offerWithContact.isQuoteExpired = offer.response.isQuoteExpired;
            offerWithContact.orderIntent = {
                id: offer.response.orderIntentId,
                state: offer.response.orderIntentState,
                intentRequest: offer.response.orderIntentRequest,
                paymentStateId: offer.response.paymentStateId,
            };
            offerWithContact.analyticsMetadata = offer.response.analyticsMetadata;
            offerWithContact.checkoutIntentStateId = offer.response.checkoutIntentStateId;
            offerWithContact.salesForceQuoteStatus = offer.response.salesForceQuoteStatus;
            offerWithContact.customerSubmitDate = offer.response.customerSubmitDate;
            offerWithContact.orderConfiguration = offer.response.orderConfiguration;
            if (offer.response && offer.response.offerState?.navSalesPersonCode) {
                var contact = await this.getOrderContact(offer.response.offerState.salesForceAccountId);

                if (contact.hasSuccess) {
                    offerWithContact.salesContact = contact.response;
                }
            }
            return new ApiResponse(offerWithContact, true, [], offer.response);
        } else {
            return new ApiResponse(offer, false, [], offer.statusCode);
        }
    };

    getOfferWithSalesContactAndArtwork = async (globalOfferId) => {
        var result = {
            offer: null,
            salesContact: {},
            culture: null,
            formattedSizeString: null,
            applicationFeatures: 0,
            artwork: [],
            orderIntent: {},
            customerSubmitDate: null,
        };

        var r = await this.getOfferWithSalesContact(globalOfferId);
        if (r.hasSuccess) {
            result.offer = r.response.offer;
            result.salesContact = r.response.salesContact;
            result.culture = r.response.culture;
            result.formattedSizeString = r.response.formattedSizeString;
            result.canNotSellToCustomer = r.response.canNotSellToCustomer;
            result.invalidForPurchase = r.response.invalidForPurchase;           
            result.applicationFeatures = r.response.applicationFeatures;
            result.isQuoteExpired = r.response.isQuoteExpired;
            result.analyticsMetadata = r.response.analyticsMetadata;
            result.checkoutIntentStateId = r.response.checkoutIntentStateId;
            result.salesForceQuoteStatus = r.response.salesForceQuoteStatus;
            result.customerSubmitDate = r.response.customerSubmitDate;
            result.orderConfiguration = r.response.orderConfiguration;
            if (r.response.orderIntent) {
                result.orderIntent = {
                    id: r.response.orderIntent.id,
                    state: r.response.orderIntent.state,
                    intentRequest: r.response.orderIntent.intentRequest,
                    paymentStateId: r.response.orderIntent.paymentStateId,
                };
            }
            /*
            var r2 = await this.getArtworkSkus(globalOfferId);
            if (r2.hasSuccess) {
                result.artwork = r2.response;
            }
            */
        }
        return new ApiResponse(result, r.hasSuccess, [], r.statusCode);
    };

    getOfferContext = async (globalOfferId) => {
        var result = {
            offerContext: null,
        };

        var response = await this.getAsJson(`api/order/${globalOfferId}/context`);

        if (response.hasSuccess) {
            result.offerContext = response.response.offerContext;
        }
        return new ApiResponse(result, response.hasSuccess, [], response.statusCode);
    }

    sendPaymentRequestEmail = async (key, sender, recipient) => {
        var request = {
            sender: sender,
            recipient: recipient,
        };

        await this.postAsJson(`api/email/${key}/pay`, request);
    };

    sendArtworkRequestEmail = async (key, sender, recipient) => {
        var request = {
            sender: sender,
            recipient: recipient,
        };

        await this.postAsJson(`api/email/${key}/artwork`, request);
    };

    applyPaymentToOrder = async (key, request) => {
        await this.postAsJson(`api/order/${key}/pay`, request);
    };

    updateOrderContext = async (key, request, page) => {
        var url = `api/order/${key}/update`;

        if (page)
        {
            if (page.toLowerCase() === 'skus') {
                url += "/skus";
            }
            else {
                throw new Error("Unknown page type requested to save offer context: " + page);
            }
        }

        var response = await this.putAsJson(url, request);

        return response;
    }


    submitArtworkComplete = async (globalOfferId) => {
        var response = await this.postAsJson(`api/order/${globalOfferId}/artwork/finish`, {});
        return response;
    }
}
