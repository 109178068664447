
export class LengthMeasurement {

    constructor(value, uomId) {
        this.value = value;
        this.uomId = uomId;
    }

    convertToInches() {
        switch (this.uomId) {
            case 13:
                return this.value;
            case 15:
                return this.value / 25.4;
            default:
                throw new Error(`UOM ${this.uomId} Not supported`);
        }   
    }

    convertToMillimeters() {
        switch (this.uomId) {
            case 13:
                return this.value * 25.4;
            case 15:
                return this.value;
            default:
                throw new Error(`UOM ${this.uomId} Not supported`);
        }
    }

    getUom() {
        switch (this.uomId) {
            case 13:
                return 'in';
            case 15:            
                return 'mm';
            default:
                throw new Error(`UOM ${this.uomId} Not supported`);
        }
    }





}