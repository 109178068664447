import { useStateStorage } from './ServerStateStorage';

export function useCheckoutContext() {

    const update = (globalState, payload) => {
        return {
            ...globalState,
            checkout: {
                ...globalState.checkout,
                ...payload,
            },
        };
    };

    const setAll = (globalState, payload) => {
        return {
            ...globalState,
            checkout: payload,
        };
    };


    const { state, actions } = useStateStorage({
        update,
        setAll
    });

    /*
    const { state, actions } = useStateMachine({
        update,
        setAll
    });
    */

    const updateProduct = (product) => actions.update({ product: product });

    const updateMaterial = (material) => actions.update({ material: material });

    const updateDimensions = (dimensions) => actions.update({ dimensions: dimensions });

    const updateConvertingMethod = (convertingMethod) => actions.update({ convertingMethod: convertingMethod });

    const updateFeature = (feature) => actions.update({ feature: feature });
    
    const updateSelectedSkuQuantityBreakdown = (selectedSkuQuantityBreakdown) => actions.update({ selectedSkuQuantityBreakdown: selectedSkuQuantityBreakdown });

    const updatePrices = (value) => actions.update({ prices: value });

    const updateSkuCount = (count) => actions.update({ skuCount: count });

    const updateCheckoutFlag = (isCheckoutClicked) => actions.update({ isCheckoutClicked: isCheckoutClicked });

    const updateSelectedSkuCountAndQuantity = (quantity, skuCount) => actions.update({selectedSkuCount: skuCount, selectedQuantity: quantity});

    const createUrlFromContext = (region) => {
        if (!region) {
            return "/";
        }

        console.log('createUrlFromContext');

        let url = "/" + region;
    
        if (state.checkout.selectedSkuCount && state.checkout.convertingMethod && state.checkout.product && state.checkout.material) {                       
            return url + `/group/${state.checkout.product.productGroupId}/convertingMethod/${state.checkout.convertingMethod.convertingMethodId}/material/${state.checkout.material.id}/skus`;                        
        }
        if (state.checkout.material && state.checkout.convertingMethod && state.checkout.product && state.checkout.material) {            
            return url + `/group/${state.checkout.product.productGroupId}/convertingMethod/${state.checkout.convertingMethod.convertingMethodId}/material/${state.checkout.material.id}/features`;
        }
        if (state.checkout.convertingMethod && state.checkout.product) {            
            return url + `/group/${state.checkout.product.productGroupId}/convertingMethod/${state.checkout.convertingMethod.convertingMethodId}/dimensions`;
        }
        if (state.checkout.product) {            
            return url + `/group/${state.checkout.product.productGroupId}/convertingMethod`;
        }

        return url + "/search";
    }

    const getCart = () => {
        var cart = {
            product: state.checkout.product,
            material: state.checkout.material,
            dimensions: state.checkout.dimensions,
            convertingMethod: state.checkout.convertingMethod,
            feature: state.checkout.feature,
            skuCount: state.checkout.skuCount,
            selectedSkuQuantityBreakdown: state.checkout.selectedSkuQuantityBreakdown,
            prices: state.checkout.prices,
            isCheckoutClicked: false,
            selectedQuantity: state.checkout.selectedQuantity,
            selectedSkuCount: state.checkout.selectedSkuCount
        }
        return cart;
    }

    const setCart = (cart) => {
        actions.setAll(cart);
    }

    const reset = () => {
        updateProduct('');
        updateMaterial('');
        updateDimensions('');
        updateConvertingMethod('');
        updateFeature('');
        updateSelectedSkuQuantityBreakdown('');
        updateSkuCount('');
        updatePrices([]);
        updateCheckoutFlag(false);
    }

    return {
        getCart,
        setCart,
        updateProduct,        
        updateConvertingMethod,
        updateDimensions,        
        updateMaterial,
        updateFeature,
        updatePrices,
        updateCheckoutFlag,
        reset,
        createUrlFromContext,        
        updateSelectedSkuQuantityBreakdown,
        updateSkuCount,
        updateSelectedSkuCountAndQuantity,
        product: state.checkout.product,
        material: state.checkout.material,
        dimensions: state.checkout.dimensions,
        convertingMethod: state.checkout.convertingMethod,
        feature: state.checkout.feature,
        skuCount: state.checkout.skuCount,
        prices: state.checkout.prices,
        selectedSkuQuantityBreakdown: state.checkout.selectedSkuQuantityBreakdown,
        isCheckoutClicked: state.checkout.isCheckoutClicked,
        selectedQuantity: state.checkout.selectedQuantity,
        selectedSkuCount: state.checkout.selectedSkuCount
    };
}
