
import React, { useState, useEffect } from "react";
import { useOrderPlacementRouter } from "../../infrastructure/GlobalOfferId";
import { RenderModel } from "../ThreeDModel";
import { Row } from "../../core/Row";
import { Column } from "../../core/Column";
import { Card } from "../../core/Card";
import { ColumnTitle } from "../../core/ColumnTitle";
import { Grid } from "../../core/Grid";
import { SectionTitle } from "../../core/SectionTitle";
import { SectionLabel } from "../../core/SectionLabel";
//testing samples: test/pouchpage/6-fcb156db-a8b0-4aca-9b24-0c4b7738645c
// test/pouchpage/6-b718dd2f-80fd-481a-ab45-33f6962b3168
// test/pouchpage/6-b09fbe93-73fc-4ad4-bf99-3603400b333f

export const PouchPreviewPage = () => {

    const { initialized, offer, orderIntent, backwardsCompatibilityOffer, globalOfferId, status, refresh, analyticsMetadata, formattedSizeString,
        salesContact, salesforceAccount, orderReference, offerLockStatus, checkoutIntentStateId } = useOrderPlacementRouter();

    const standardizeFeatureElements = (name) => {
        //if the specified feature was selected...
        if (offer.packagingFeature[name].id != 1) {

            //get the selection ID
            var retID, retOffset;
            retID = offer.packagingFeature[name].id;

            //make sure the offset is in mm
            switch (offer.packagingFeature[name].location.uomId) {
                case 13: //inches
                    retOffset = offer.packagingFeature[name].location.value * 25.4;
                    break;
                case 15: //mm
                    retOffset = offer.packagingFeature[name].location.value;
                    break;
                default:
                    console.log("Unrecognized uomID for " + name + " location: ", offer.packagingFeature[name].location.uomId);
            }

            return {
                rID: retID,
                rOffset: retOffset
            }

        } else { //if the feature was not selected...
            return {
                rID: 1, 
                rOffset: null
            }
        }
    }

    const buildPouchPreviewRequestPayload = (stuff) => { 

        /* get height, width, and gusset */{
            var h, w, g;
            switch (stuff.dimensions.measureUnitId) {
                case 13: //inches
                    h = stuff.dimensions.length * 25.4;
                    w = stuff.dimensions.width * 25.4;
                    g = stuff.dimensions.gusset * 25.4;
                    break;
                case 15: //mm
                    h = stuff.dimensions.length;
                    w = stuff.dimensions.width;
                    g = stuff.dimensions.gusset;
                    break;
                default:
                    console.log("Unrecognized uomID in request dimensions: ", stuff.dimensions.measureUnitId);
            }
        }

        /* ensure zipper offset is in mm */ { 
            var zRet = standardizeFeatureElements("zipper");
            var zOff = zRet.rOffset;
            var zId = zRet.rID;
        }

        /* ensure notches offset is in mm */{
            var nRet = standardizeFeatureElements("tearNotch");
            var nOff = nRet.rOffset;
            var nId = nRet.rID;
        }

        /* ensure valve offset is in mm */{
            var vRet = standardizeFeatureElements("vent");
            var vOff = vRet.rOffset;
            var vId = vRet.rID;
        }

        /* ensure hang hole offset is in mm */ {
            var hRet = standardizeFeatureElements("hangHole");
            var hOff = hRet.rOffset;
            var hId = hRet.rID;
        }

        var req = {
            ProductGroupType: RenderModel.MapProductGroupType( stuff.convertingMethod.id),
            //ProductGroupType: 1,
            LengthInMillimeters: h,
            WidthInMillimeters: w,
            GussetInMillimeters: g,
            ZipperType: RenderModel.MapZipper(zId), 
            ZipperOffsetInMillimeters: zOff,
            TearNotchType: RenderModel.MapTearNotch(nId),
            TearNotchOffsetInMillimeters: nOff,
            ValveType: RenderModel.MapVent( vId),
            ValveOffsetInMillimeters:  vOff,
            HangHoleType: RenderModel.MapHangHole( hId),
            HangHoleOffsetInMillimeters: hOff,
            RoundedCornerType: RenderModel.MapRoundedCorners( stuff.packagingFeature.roundedCorner.id),
            FinishType: RenderModel.MapFinishType( stuff.packagingMaterial.finishType.id),
            SealWidthType: RenderModel.MapSealWidth(stuff.packagingFeature.sealWidth.id),
        };
        //console.log("xxxxxxx given offer: ", offer);
        //console.log('xxxxxxx returning object: ', req);
        return req;
    }

    const holeName = (idNo) => {
        switch (idNo) {
            case 1: return "None";
            case 2: return "Sombrero 30mm";
            case 3: return "Round 6.4mm";
            case 4: return "Round 5mm";
            case 5: return "Round 6mm";
            case 6: return "Round 8mm";
            case 7: return "Round 10mm";
            case 8: return "Sombrero 25mm";
            case 9: return "Sombrero 20mm";
            default: return "*** unrecognized hole value (in PouchPreviewPage) *** ";
        }
    }

    const zipperName = (idNo) => {
        switch (idNo) {
            case 1: return "None";
            case 2: return "PTC";
            case 3: return "Powder Resistant";
            case 4: return "Child Resistant";
            case 5: return "Clean Tear";
            default: return "*** unrecognized zipper value (in PouchPreviewPage) *** "
        }
    }

    const pouchName = (idNo) => {
        switch (idNo) {
            case 1: return "Two Side Seal";
            case 2: return "Three Side Seal";
            case 3: return "Plow Bottom";
            case 4: return "Skirt Seal";
            case 5: return "Doyen";
            case 6: return "Fin Seal";
            case 7: return "Quad Seal";
            default: return "*** unrecognized pouchType value (in PouchPreviewPage) ***";
        }
    }

    const sealName = (idNo) => {
        switch (idNo) {
            case 1: return "5 mm";
            case 2: return "8 mm";
            case 3: return "10 mm";
            default: return "*** unrecognized sealWidth value (in PouchPreviewPage) ***";
        }
    }

    useEffect(() => { //called when component loads
        
    }, []);


    return (
        status !== 'success' ? <div>Offer was not found</div> :
        <div>
            <h1>Here's a preview of your selections: </h1>
                {//<RenderModel request={buildPouchPreviewRequestPayload(offer)} viewHeight={500} viewWidth={500} />
                    <Row className="contains-all">
                        <Column>
                            <Card title="3D Model">
                                <RenderModel request={buildPouchPreviewRequestPayload(offer)} viewHeight={window.innerWidth > window.innerHeight ? window.innerWidth * 0.36 : window.innerWidth * 0.86} viewWidth={window.innerWidth > window.innerHeight ? window.innerWidth * 0.36 : window.innerWidth * 0.86 /*can also try CSS3 media query*/} />
                            </Card>
                        </Column>

                        <Column >
                            <Card title="Packaging Features">
                                <Row>
                                    {/*<Card style='width: 20' title={`Pouch Type: ${buildPouchPreviewRequestPayload(offer).ProductGroupType}`} subtitle={`Height: ${Math.round(buildPouchPreviewRequestPayload(offer).LengthInMillimeters)}mm\nWidth: ${Math.round(buildPouchPreviewRequestPayload(offer).WidthInMillimeters)}mm \nGusset: ${Math.round(buildPouchPreviewRequestPayload(offer).GussetInMillimeters)}mm`} />*/}
                                    {/*<Card title={`Hang Hole: ${buildPouchPreviewRequestPayload(offer).HangHoleType}`} subtitle={`Offset from top: ${buildPouchPreviewRequestPayload(offer).HangHoleOffsetInMillimeters ? buildPouchPreviewRequestPayload(offer).HangHoleOffsetInMillimeters + "mm" : "N/A"}`} />*/}
                                    <Column>
                                        <SectionTitle> Pouch Type: </SectionTitle>
                                        <SectionLabel> {pouchName(buildPouchPreviewRequestPayload(offer).ProductGroupType)} </SectionLabel>                            
                                    </Column>
                                    <Column>
                                        <SectionTitle> Tear Notches: </SectionTitle>
                                        <SectionLabel> {buildPouchPreviewRequestPayload(offer).TearNotchType == 1 ? "None" : "Standard"} </SectionLabel>
                                        <SectionLabel> Offset from top: {buildPouchPreviewRequestPayload(offer).TearNotchOffsetInMillimeters ? Math.round(buildPouchPreviewRequestPayload(offer).TearNotchOffsetInMillimeters) + "mm" : "N/A"} </SectionLabel>
                                    </Column>
                                </Row>
                                <Row>
                                    {/*<Card title={`Tear Notches: ${buildPouchPreviewRequestPayload(offer).TearNotchType == 1 ? "No" : "Yes"}`} subtitle={`Offset from top: ${buildPouchPreviewRequestPayload(offer).HangHoleOffsetInMillimeters ? buildPouchPreviewRequestPayload(offer).HangHoleOffsetInMillimeters + "mm" : "N/A"}`} />*/}
                                    {/*<Card title={`Zipper: ${buildPouchPreviewRequestPayload(offer).TearNotchType == 1 ? "No" : "Yes"}`} subtitle={`Offset from top: ${buildPouchPreviewRequestPayload(offer).ZipperOffsetInMillimeters ? buildPouchPreviewRequestPayload(offer).ZipperOffsetInMillimeters + "mm" : "N/A"}`} />*/}
                                    <Column>
                                        <SectionTitle> Zipper: </SectionTitle>
                                        <SectionLabel> {zipperName(buildPouchPreviewRequestPayload(offer).ZipperType)} </SectionLabel>
                                        <SectionLabel> Offset from top: {buildPouchPreviewRequestPayload(offer).ZipperOffsetInMillimeters ? Math.round(buildPouchPreviewRequestPayload(offer).ZipperOffsetInMillimeters) + "mm" : "N/A"}  </SectionLabel>
                                    </Column>
                                    <Column>
                                        <SectionTitle> Valve: </SectionTitle>
                                        <SectionLabel> {buildPouchPreviewRequestPayload(offer).ValveType == 1 ? "None" : "Standard"} </SectionLabel>
                                        <SectionLabel> Offset from top: {buildPouchPreviewRequestPayload(offer).ValveOffsetInMillimeters ? Math.round(buildPouchPreviewRequestPayload(offer).ValveOffsetInMillimeters) + "mm" : "N/A"} </SectionLabel>
                                    </Column>
                                </Row>
                                <Row>
                                    {/*<Card title={`Valve: ${buildPouchPreviewRequestPayload(offer).ValveType == 1 ? "No" : "Yes"}`} subtitle={`Offset from top: ${buildPouchPreviewRequestPayload(offer).ValveOffsetInMillimeters ? Math.round(buildPouchPreviewRequestPayload(offer).ValveOffsetInMillimeters) + "mm" : "N/A"}`} />*/}
                                    {/*<Card title={`Rounded Corners: ${buildPouchPreviewRequestPayload(offer).RoundedCornerType == 1 ? "No" : "Yes"}`} />*/}
                                    <Column>
                                        <SectionTitle> Rounded Corners: </SectionTitle>
                                        <SectionLabel> {buildPouchPreviewRequestPayload(offer).RoundedCornerType == 1 ? "No" : "Yes"} </SectionLabel>
                                    </Column>
                                    <Column>
                                        <SectionTitle> Seal Width: </SectionTitle>
                                        <SectionLabel> {sealName(buildPouchPreviewRequestPayload(offer).SealWidthType)} </SectionLabel>
                                    </Column>
                                </Row>
                                <Row>
                                    {/*<Card title={`Finish Type: ${buildPouchPreviewRequestPayload(offer).FinishType == 1 ? "Gloss" : buildPouchPreviewRequestPayload(offer) == 2 ? "Matte" : "Soft Touch Matte"}`} />*/}
                                    {/*<Card title={`Side Seal Width: ${buildPouchPreviewRequestPayload(offer).SealWidthType == 1 ? "5mm" : buildPouchPreviewRequestPayload(offer).SealWidthType == 2 ? "8mm" : "10mm"}`}/>*/}
                                    <Column>
                                    </Column>
                                    <Column>
                                        {/* a new feature can be added here */}
                                    </Column>
                                </Row>
                            </Card>
                            <Card title="Specifications">
                                <Row>
                                    <Column>
                                        <SectionTitle> Dimensions: </SectionTitle>
                                        <SectionLabel> Width:  {Math.round(buildPouchPreviewRequestPayload(offer).WidthInMillimeters)} mm </SectionLabel>
                                        <SectionLabel> Length:  {Math.round(buildPouchPreviewRequestPayload(offer).LengthInMillimeters)} mm </SectionLabel>
                                        {
                                            buildPouchPreviewRequestPayload(offer).GussetInMillimeters ? <SectionLabel> Gusset:  {Math.round(buildPouchPreviewRequestPayload(offer).GussetInMillimeters)} mm </SectionLabel> : <></>
                                        }

                                    </Column>
                                    <Column>
                                        <SectionTitle> Finish Type: </SectionTitle>
                                        <SectionLabel> {buildPouchPreviewRequestPayload(offer).FinishType == 1 ? "Gloss" : buildPouchPreviewRequestPayload(offer) == 2 ? "Matte" : "Soft Touch Matte"} </SectionLabel>
                                    </Column>
                                </Row>
                                <Row>
                                    <Column>
                                        {/*<SectionTitle> Title </SectionTitle>*/}
                                        {/*<SectionLabel> text </SectionLabel>*/}
                                    </Column>
                                    <Column>
                                        <SectionTitle> Material </SectionTitle>
                                        <SectionLabel> {offer.packagingMaterial.name} </SectionLabel>
                                    </Column>
                                </Row>
                            </Card>
                        </Column>
                    </Row>
                }
                
        </div>
    );
}