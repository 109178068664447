import React, { useEffect, useContext, useState } from "react";
import { AddressItem } from "./AddressItem";
import { SectionTitle } from "../../core/SectionTitle";
import { Translation } from "../../core/Translation";
import { Language } from "../../infrastructure/Languages";

export const EditEpacConnectContact = ({
  orderPlacementApi,
  register,
  getErrorMessage,
  errors,
  isValidating,
  trigger,
  setValue,
  setError,
  getValues,
  clearErrors
  // epacConnect: { firstName, lastName, emailAddress, accountToken },
}) => {
  const emailRegex = /^\S+@\S+\.\S+$/;
  // const tokenRequired = !accountToken;
  const language = useContext(Language);

  // useEffect(() => {
  //   console.log("Epac connect", accountToken, tokenRequired, !tokenRequired);
  // }, []);
  // const emailValidationCallback = useDebouncedCallback(
  //   async (value) => {
  //     var valid = await connectAddressExistsAlready(value);
  //     console.log("valid result?", valid);
  //     if (!valid.isValid) {
  //       setError("epacConnectData.emailAddress", {
  //         type: 'custom',
  //         message: valid.message,
  //       },
  //       {
  //         shouldFocus: true
  //       });
  //     }
  //     else {
  //       clearErrors("epacConnectData.emailAddress");
  //     }
  //   },
  //   1000
  // );

  const connectAddressExistsAlready = async (emailAddress) => {

    if (emailAddress.length > 1) {
      try {
        var call = await orderPlacementApi.getIsDuplicateEmailAddress(emailAddress);
        console.log("call response!", call);
        if (call.hasSuccess && call.response && call.response !== "") {
          console.log("ePac Connect account exists already:", emailAddress);
          //return true;
          return 'ePac Connect account already exists for this e-mail.'
        }
        else {
          return '';
        }
      }
      catch (e) {
        //return false;
        return 'Server communication error. Please try again later.';
      }
    }
    return '';
  };

  const connectAddressExistsAlreadySetError = async (emailAddress) => {

    if (emailAddress.length > 1) {
      try {
        var call = await orderPlacementApi.getIsDuplicateEmailAddress(emailAddress);
        console.log("call response!", call);
        if (call.hasSuccess && call.response && call.response !== "") {
          console.log("ePac Connect account exists already:", emailAddress);
          setError("epacConnectData.emailAddress", {
            type: 'validate',
            message: 'ePac Connect account already exists for this e-mail.'
          });
        }
        else {
          clearErrors("epacConnectData.emailAddress");
        }
      }
      catch (e) {
        setError("epacConnectData.emailAddress", {
          type: 'validate',
          message: 'Server communication error. Please try again later.'
        });
      }
    }
  };

  return (
    <div>
      <div className="input-validation">
        <SectionTitle required>
          <Translation id="8eb7936c-b737-4f03-b8d8-e282d9f43c6a" defaultMessage="First Name" category='Label' />
        </SectionTitle>
        <input
          className={"control textbox" + (errors && errors.epacConnect ? " error" : "")}
          // disabled={!tokenRequired}
          placeholder="First Name"
          {...register('epacConnectData.firstName', {
            required: {
              value: true,
              message: "First name is required"
            }
          })
          }
        />
        {getErrorMessage(errors, "epacConnectData.firstName") && <span className="error-message">{getErrorMessage(errors, "epacConnectData.firstName")?.message}</span>}
      </div>
      <div className="input-validation">
        <SectionTitle required>
          <Translation id="cedc02f5-9290-4fa0-9107-c052919a958c" defaultMessage="Last Name" category='Label' />
        </SectionTitle>
        <input
          className={"control textbox" + (errors && errors.epacConnect ? " error" : "")}
          // disabled={!tokenRequired}
          placeholder="Last Name"
          {...register('epacConnectData.lastName', {
            required: {
              value: true,
              message: "Last name is required"
            }
          })
          }
        />
        {getErrorMessage(errors, "epacConnectData.lastName") && <span className="error-message">{getErrorMessage(errors, "epacConnectData.lastName")?.message}</span>}
      </div>
      <div className="input-validation">
        <SectionTitle required>
          <Translation id="15b53b85-df5e-46db-a112-10950c5dfe37" defaultMessage={"Email"} category='Label' />
        </SectionTitle>
        <input
          className={"control textbox" + (errors && errors.epacConnect ? " error" : "")}
          // disabled={!tokenRequired}
          placeholder={language.translateLabel("15b53b85-df5e-46db-a112-10950c5dfe37", "Email", 'Label')}
          {
          ...register("epacConnectData.emailAddress",
            {
              required: { value: true, message: "E-mail address is required" },
              //onBlur: e => connectAddressExistsAlready(e.target.value),
              pattern: {
                value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                message: "E-mail address is not correctly formatted"
              },
              // onChange: async e => {
              //   var result = await connectAddressExistsAlready(e.target.value);
              //   if (result !== '') {
              //     setError("epacConnectData.emailAddress", {
              //       type: "validate",
              //       message: result,
              //     });
              //   }
              //   else {
              //     clearErrors("epacConnectData.emailAddress");
              //   }
              //   console.log("triggered, Karen")
              // }
              // validate: {
              //   email: async e => { return await connectAddressExistsAlready(e) }
              // }
              // validate: {
              //   emailAvailable: async (email) => {
              //     var data = await connectAddressExistsAlready(email);
              //     console.log("validate result", data);
              //     if (data === '') {
              //       return true;
              //     }
              //     return 'Bad';
              //   }
              // }
              // onChange:async (e) => {
              //     var result = await emailValidationCallback(e.target.value);
              //     console.log("result", result);
              // }
            })
          }
        />
        {getErrorMessage(errors, "epacConnectData.emailAddress") && <span className="error-message">{getErrorMessage(errors, "epacConnectData.emailAddress")?.message}</span>}
      </div>
    </div>
  );
};
