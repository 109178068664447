import React, { useEffect, useState, useContext } from 'react';
import { SecureApiDataAccess } from "../../infrastructure/SecureApiDataAccess";
import './MyAddresses.css';
import { useMsal, useAccount } from "@azure/msal-react";
import { loginRequest, protectedResources } from "../../authConfig";
import { Translation } from "../../core/Translation";
import { MyAccountPageHeaderOptions } from '../../core/MyAccountPageHeaderOptions'
import { useForm, get } from "react-hook-form";
import { useLocation } from 'react-router-dom';
import OrderPlacementApi from '../../infrastructure/OrderPlacementApi';
import { HookEditMyAccountAddress } from './HookEditMyAccountAddress';
import { CountriesWithSubSelection } from "../../infrastructure/Constants";
import { Language } from '../../infrastructure/Languages';
import { CapabilitiesContext } from "../../infrastructure/Contexts";
import { CapabilityName } from "../../infrastructure/Constants";
import { useNavigate } from "react-router-dom";

import './MyAddresses.css';

export const EditBillingAddress = () => {
    const {
        register,
        handleSubmit,
        trigger,
        unregister,
        watch,
        reset,
        setValue,
        setError,
        getValues,
        formState: { errors, isSubmitting, isValid },
    } = useForm({
        mode: "onBlur",
        shouldFocusError: true,
        defaultValues: {
        }
    });

    const location = useLocation();
    const language = useContext(Language);
    const navigate = useNavigate();
    const orderPlacementApi = new OrderPlacementApi("/");
    const userDataAccess = new SecureApiDataAccess("/api/user");
    const { instance, accounts } = useMsal();
    const account = useAccount(accounts[0] || {});
    const capabilitiesContext = useContext(CapabilitiesContext);
    const loader = capabilitiesContext.getCapability(CapabilityName.Loader);
    const [address, setAddress] = useState(location.state.address);
    const [salesforceAccountId, setSalesforceAccountId] = useState(location.state.salesforceAccountId);
    const [states, setStates] = useState(null);
    const [countries, setCountries] = useState(null);
    const billingAddressDataAccess = new SecureApiDataAccess("/api/billingaddress");


    const [loaded, setLoaded] = useState(false);

    console.log("salesforceAccountId", salesforceAccountId)
    console.log("address", address)

    useEffect(() => {

        const initUser = async () => {
            await getUser();
        }
        initUser();

        var countryName = getValues(`billingAddress.country`);
        console.log("COUNTRY NAME: ", countryName)
    }, []);

    useEffect(() => {

        const init = async () => {
            var response = await orderPlacementApi.getCountries();
            setCountries(response.response);
            setLoaded(true);
            loadAddress();
        }
        init();


    }, []);

    const loadAddress = async () => {
        setValue('billingAddress.country', address.country);
        setValue('billingAddress.city', address.city);
        setValue('billingAddress.name', address.name1);
        setValue('billingAddress.postalCode', address.postalCode);
        setValue('billingAddress.state', address.state);
        setValue('billingAddress.street', address.street);
        setValue('billingAddress', address);

    }

    const getUser = async () => {
        const response = await instance.acquireTokenSilent({
            scopes: [...protectedResources.shopApp.scopes],
            ...loginRequest,
            account: account,
        });


        if (!response.accessToken) {
            throw new Error("Could not retrieve access token.");
        }
        var user = await userDataAccess.get(
            `/search?emailAddress=${encodeURIComponent(
                account.username
            )}&identityProviderAccountId=${encodeURIComponent(account.idTokenClaims.oid)}`,
            response.accessToken,
            {
                throwOnNonSuccess: false
            }
        );

        if (user.hasSuccess) {
            console.log("A user was found", user);
            setSalesforceAccountId(user.data.salesforceAccountId);
            return user;

        } else {

            console.log("Didn't find a user.", user.statusCode);
            return null;
        }
    }

    const getStatesByCountry = async (countryId) => {
        var result = await orderPlacementApi.getStatesByCountry(countryId);
        return result?.response;
    }



    const onSubmit = async () => {
        var streetLine1 = getValues("billingAddress.streetAddress1");
        var streetLine2 = getValues("billingAddress.streetAddress2");
        var street = !streetLine2 ? streetLine1 : streetLine1 + ", " + streetLine2;

        var request = {
            salesforceAccountId: salesforceAccountId,
            mailingStreet: street,
            mailingCity: getValues("billingAddress.city"),
            mailingPostalCode: getValues("billingAddress.postalCode"),
            mailingState: getValues("billingAddress.state"),
            mailingCountry: getValues("billingAddress.country"),
            mailToName: getValues("billingAddress.name1"),
        };

        try {
            loader.show();
            var response = await billingAddressDataAccess.postAsJson('/edit', request);
            if (response.hasSuccess)
                navigate('/myaddresses');
        }
        catch (ex) {
            console.log(ex.message);
        }
        finally {
            loader.hide();
        }
    }



    return loaded && (
        <div>
            <MyAccountPageHeaderOptions selectedHeaderNumber={2} />
            <div className="edit-address-title">
                <h2>{language.translateLabel('021a08bf-3248-4cb0-a47e-a014532a82d8', 'Edit Billing Address')}</h2>
            </div>
            <div className="address-form">
                <HookEditMyAccountAddress
                    unregister={unregister}
                    getErrorMessage={get}
                    setValue={setValue}
                    getValues={getValues}
                    register={register}
                    countriesForStateValidation={CountriesWithSubSelection}
                    errors={errors}
                    countries={countries}
                    id="billingAddress"
                    addressType="billingAddress"
                    orderPlacementApi={orderPlacementApi}
                    reset={reset}
                    requireEmail={false}
                    requireCompanyName={true}
                />
            </div>
            <button className='btn btn-secondary pull-right' disabled={!isValid} onClick={() => { onSubmit() }}><Translation id="b5e8af97-25c9-413c-89a6-eb090846613b" defaultMessage="Save" category='Button' /></button>
        </div>
    );
}



