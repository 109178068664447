import React from "react"
import { useState } from "react";

const OfferLanguageContext = React.createContext({
    offerId: null,
    sourceLanguage: null,
    targetLanguage: null
});

const OfferLanguageProvider = ({ children }) => {
    const [offerLanguage, setOfferLanguage] = useState();

    return (
        <OfferLanguageContext.Provider value={{ offerLanguage, setOfferLanguage }}>
            {children}
        </OfferLanguageContext.Provider>
    );
}

export { OfferLanguageContext, OfferLanguageProvider };