import { ApiDataAccess } from './ApiDataAccess';
import { useState, createContext, useContext, useEffect } from "react";
import { useCookies } from 'react-cookie';
import { SalesChannel } from './Constants'

export class StateStorageContextManager {

    static GlobalState = {
        user: {
            userRegion: "en-US",
            userLanguage: "en-US",
            userPreferredRenderSize: 2,
            defaultPouchColor: 'C1C1C1',
            previousCart: ""
        },
        checkout: {
            product: "",
            material: "",
            dimensions: "",
            convertingMethod: "",
            feature: "",
            skuCount: "",
            selectedSkuQuantityBreakdown: "",
            prices:[],
            isCheckoutClicked: false
        }
    };

    static StateData = StateStorageContextManager.GlobalState;

    constructor() {
        this.apiDataAccess = new ApiDataAccess('api/userContext');
        StateStorageContextManager.StateData = StateStorageContextManager.GlobalState;
    }

    async createVisitorId() {
        var visitorId = await this.apiDataAccess.post(`/user`);
        return visitorId;
    }

    async loadAsync(visitorId) {
        try {
            if (visitorId) {
                console.log(`Loading Visitor Context: ${visitorId}`);
                var payload = await this.apiDataAccess.get(`/user/${visitorId}`);
                if (payload && payload.text) {
                    var ctxt = await this.validateCheckoutData(JSON.parse(payload.text));
                    StateStorageContextManager.StateData = ctxt;
                    StateStorageContextManager.GlobalState = ctxt;
                    console.log(`Loaded Visitor Context: ${visitorId}`, ctxt);
                }
            }
        }
        catch {
            console.error(`Could not load Visitor Context: ${visitorId}`);
        }
    }

    async validateCheckoutData(ctxt) {

        try {
            if (ctxt && ctxt.checkout && ctxt.checkout.product && ctxt.checkout.convertingMethod) {
                var checkout = ctxt.checkout;

                var request = {
                    salesChannelId: SalesChannel.Website,
                    productId: checkout.product.productId,
                    productGroupId: checkout.product.productGroupId,
                    convertingMethodId: checkout.convertingMethod.convertingMethodId,
                    materialId: checkout.material ? checkout.material.id : null,
                    lengthUomId: checkout.dimensions && checkout.dimensions.selectedDims ? checkout.dimensions.selectedDims.measureUnitId : null,
                    pouchWidth: checkout.dimensions && checkout.dimensions.selectedDims ? checkout.dimensions.selectedDims.width : null,
                    pouchLength: checkout.dimensions && checkout.dimensions.selectedDims ? checkout.dimensions.selectedDims.length : null,
                    pouchGusset: checkout.dimensions && checkout.dimensions.selectedDims ? checkout.dimensions.selectedDims.gusset : null,
                    zipperId: checkout.feature && checkout.feature.zippers ? checkout.feature.zippers.id : null,
                    hangHoleId: checkout.feature && checkout.feature.hangHoles ? checkout.feature.hangHoles.id : null,
                    tearNotchId: checkout.feature && checkout.feature.tearNotches ? checkout.feature.tearNotches.id : null,
                    roundedCornersId: checkout.feature && checkout.feature.roundedCorners ? checkout.feature.roundedCorners.id : null,
                    doubleCutId: checkout.feature && checkout.feature.doubleCuts ? checkout.feature.doubleCuts.id : null,
                    valveId: checkout.feature && checkout.feature.vents ? checkout.feature.vents.id : null,
                    sealWidthId: checkout.feature && checkout.feature.sealWidths ? checkout.feature.sealWidths.id : null,
                    connectId: null,
                    skuCount: checkout.skuCount ? checkout.skuCount : null,
                    eyemarkId: checkout.convertingMethod && checkout.convertingMethod.eyemarkDefault ? checkout.convertingMethod.eyemarkDefault.id : null,
                    pouchEyemarkLength: checkout.convertingMethod && checkout.convertingMethod.eyemarkMeasurement ? checkout.convertingMethod.eyemarkMeasurement.value : null,
                    pouchBleedLength: checkout.convertingMethod && checkout.convertingMethod.bleedMeasurement ? checkout.convertingMethod.bleedMeasurement.value : null,
                    zipperOffsetValue: checkout.feature && checkout.feature.zippers ? checkout.feature.zippers.defaultLocation : null,
                    hangHoleOffsetValue: checkout.feature && checkout.feature.hangHoles ? checkout.feature.hangHoles.defaultLocation : null,
                    tearNotchOffsetValue: checkout.feature && checkout.feature.tearNotches ? checkout.feature.tearNotches.defaultLocation : null,
                    valveOffsetValue: checkout.feature && checkout.feature.vents ? checkout.feature.vents.defaultLocation : null,
                    dielineInstructions: checkout.feature ? checkout.feature.dielineInstructions : null
                };

                var valid = await this.apiDataAccess.post(`/checkout/validate`, request);
                if (!valid) {
                    console.log('Checkout context has been reset since cart items are no longer valid with latest product management release');
                    ctxt.checkout = {
                        product: checkout.product,
                        material: "",
                        dimensions: "",
                        convertingMethod: "",
                        feature: "",
                        skuCount: "",
                        selectedSkuQuantityBreakdown: "",
                        isCheckoutClicked: false
                    };
                }
            }
        }
        catch (ex) { 
            console.log(`Could not validate checkout context: ${ex}`);
        }

        return ctxt;
    }

    async saveAsync(visitorId) {
        try {
            if (visitorId) {
                console.log(`Saving Visitor Context: ${visitorId}`, StateStorageContextManager.StateData);
                await this.apiDataAccess.post(`/user/${visitorId}`, { text: JSON.stringify(StateStorageContextManager.StateData) });
            }
        }
        catch (ex) {
            console.error(`Could not save Visitor Context: ${visitorId}`, ex);
        }
    }
}

export class Visitor
{
    static Id = null;

    constructor(id) {
        Visitor.Id = id;
    }   
}

export const StateStorageContext = createContext();
export const StateStorageProvider = ({ children }) => {

    const storageManager = new StateStorageContextManager();
    const [visitorId, setVisitorId] = useState(null);
    const [ready, setReady] = useState(false);
    const [cookies, setCookie] = useCookies(['visitorId']);
    const cookieSettings = { path: '/', maxAge: 31536000, secure: true, sameSite: 'none' };
    var persistenceTimeOut = null;

    useEffect(() => {
        const init = async () => {

            var id = cookies.visitorId;

            if (id) {                
                setVisitorId(id);
                console.log(`Welcome back visitor ${id}!`);
            }
            else {
                id = await storageManager.createVisitorId();
                setCookie('visitorId', id, cookieSettings);
                setVisitorId(id);
                console.log(`Welcome new visitor ${id}!`);
            }

            new Visitor(id);
            await storageManager.loadAsync(id);
            setReady(true);
        }
        init();
    }, []);


    const setState = (state) => {
        StateStorageContextManager.StateData = state;

        clearTimeout(persistenceTimeOut);
        persistenceTimeOut = setTimeout(async () => {
            await storageManager.saveAsync(visitorId);
        }, 10);
    }

    const getState = () => {
        return StateStorageContextManager.StateData;
    }


    return (
        <>
            {ready &&
                <StateStorageContext.Provider value={{ setState, getState }}>
                    {children}
                </StateStorageContext.Provider>
            }
        </>
    )
}



export function useStateStorage(methods) {

    const actions = {};
    const ctxt = useContext(StateStorageContext);
    const state = ctxt.getState();

    Object.keys(methods).forEach(key => {
        actions[key] = (...args) => {
            var result = methods[key](StateStorageContextManager.StateData, ...args);
            ctxt.setState(result);
            return result;
        };
    })


    return {
        state,
        actions
    }
}
