import React, { useState, useContext, useEffect, forwardRef, useImperativeHandle, useRef } from 'react';
import { useCookies } from 'react-cookie';
import { UploadCloud, Check, X } from 'react-feather';
import { useNavigate } from 'react-router-dom';
import { useTracking } from 'react-tracking';
import { ShoppingBreadcrumbs, OrderReference } from '../../core/Breadcrumbs';
import { Translation } from '../../core/Translation';
import { Card } from '../../core/Card';
import { Row } from '../../core/Row';
import { Popup } from '../../core/Popup';
import { ArtworkInstructions } from '../../components/offer/ArtworkInstructions';
import { CapabilityName, OrderType } from '../../infrastructure/Constants';
import { Language } from '../../infrastructure/Languages';
import { CapabilitiesContext, SettingsContext } from '../../infrastructure/Contexts';
import { useGlobalOfferId } from '../../infrastructure/GlobalOfferId';
import OrderPlacementApi from '../../infrastructure/OrderPlacementApi';

import "./ArtworkUpload.css";

export const ArtworkUpload = ({ offer, orderReference, status, orderIntent, orderConfiguration, analyticsMetadata }) => {

    const globalOfferId = useGlobalOfferId();
    const orderPlacementApi = new OrderPlacementApi("/");

    const url = `/api/order/artwork/`;
    const capabilitiesContext = useContext(CapabilitiesContext);
    const loader = capabilitiesContext.getCapability(CapabilityName.Loader);
    const [artworkSkus, setArtwork] = useState([]);
    const [initialized, setInitialized] = useState(false);
    const [checklistArticle, setChecklistArticle] = useState(false);
    const [submitEnabled, setSubmitEnabled] = useState(false);
    const { trackEvent } = useTracking({ page: "Artwork Upload Page" });
    const popup = useRef(null);
    const navigate = useNavigate();
    const [cookies, setCookie] = useCookies(['visitorId']);
    const [artworkNotRequired, setArtworkNotRequired] = useState(false);
    const settings = useContext(SettingsContext);
    const paymentsDisabledOnRegion = orderConfiguration ? orderConfiguration.paymentsDisabled : false;

    useEffect(() => {
        if (status !== 'success') {
            return;
        }

        if (!globalOfferId.globalOfferId) {
            return;
        }

        const get = async () => {
            if (!initialized) {

                var apiResponse = await orderPlacementApi.getArtworkSkus(globalOfferId.globalOfferId);
                var skus = apiResponse.response;
                setArtwork(skus);


                /*
                if (offer?.supportedCountry?.cultureString) {
                    await context.setLanguage(offer.supportedCountry.cultureString);
                }
                */

                setInitialized(true);
                setSubmitEnabled(!isSubmitDisabled(skus));
                setArtworkNotRequired(orderReference.orderType === OrderType.TestRoll);
                return skus;
            }
        }
        get();
        getArtworkChecklist(globalOfferId.globalOfferId);

        // Breadcrumbs.instance.setActiveStep(4);
    }, [offer, globalOfferId, status]);

    const setStatusOfArtworkResource = (artworkResourceId, status) => {
        var skus = artworkSkus;
        var sku = skus.find(x => x.artworkResourceId === artworkResourceId);
        if (sku) {
            sku.hasArtworkData = status;
        }
        setArtwork(skus);
        setSubmitEnabled(!isSubmitDisabled(skus));
    }

    const onArtworkResourceUploadFailed = (artworkResourceId) => setStatusOfArtworkResource(artworkResourceId, false);

    const onUploadComplete = (artworkResourceId) => {
        trackEvent({
            event: 'ordering_tool_interactions',
            eventProps: {
                'step': 'placement_5',
                'product_category': analyticsMetadata.industryName,
                'product_sub_category': analyticsMetadata.industryCategoryName,
                'selection': "upload_artwork",
                'element': artworkResourceId
            },
            userId: analyticsMetadata?.visitorId
        });
        setStatusOfArtworkResource(artworkResourceId, true);
    }

    const isSubmitDisabled = (skus) => {
        if (skus && skus.length > 0) {
            var sku = skus ? skus.find(x => x.hasArtworkData == false) : null;
            return sku ? true : false;
        }
        return true;
    }

    const handleSubmit = async () => {
        if (loader)
            loader.show();

        var response = await orderPlacementApi.submitArtworkComplete(globalOfferId.globalOfferId);

        console.log("artwork finished", response);

        trackEvent({ ecommerce: null });  // Clear the previous ecommerce object.

        if (orderIntent.paymentStateId === 'COMPL' || orderIntent.paymentStateId === 'AWCHK') {
            trackEvent({
                event: 'purchase',
                ecommerce: {
                    items: [
                        {
                            item_id: offer.product.id,
                            item_name: offer.product.value,
                            currency: offer.plant.currency,
                            //  index: quoteCount,            //the nth quote added, starting at 1
                            unit_price: offer.calculationBreakdown[0].price.unitPrice.amount,
                            item_category: offer.productGroup.value,  //what packaging category
                            item_category2: analyticsMetadata.industryCategoryName,      //what packaging sub-category
                            item_category3: offer.convertingMethod.componentType.value,     //pouch type
                            item_category4: offer.dimensions.width + " x " + offer.dimensions.length,      // width x length
                            price: offer.calculationBreakdown[0].price.totalPrice.amount,
                            quantity: offer.calculationBreakdown[0].quantityBreak,
                            skuCount: offer.skuCount
                        }
                    ],
                    transaction_id: orderReference.orderNumber,
                    value: orderIntent.intentRequest.orderIntentTotals.grandTotalAmount,     //value is the price from items array minus discount amount
                    //  tax: orderIntent.orderIntentTotals.
                    shipping: orderIntent.intentRequest.orderIntentTotals.shippingAmount,
                    currency: offer.plant.currency
                },
                userId: cookies.visitorId
            });
        }
        document.location = `/offer/${globalOfferId.globalOfferId}`;
    }

    const getArtworkChecklist = async (globalOfferId) => {
        await orderPlacementApi.getArticleByName(globalOfferId, "dieline-checklist-pdf")
            .then(r => {
                if (r.hasSuccess) {
                    setChecklistArticle(r.response);
                }
            })
    }

    const render = () => {
        if (status === 'success' && offer && checklistArticle) {
            if (artworkNotRequired) {
                return (<div>
                    <Card title={<Translation id='fd8a733c-931c-4d8a-a5cd-50616a67c309' defaultMessage='Artwork not required' category='Card Title' />}>
                        <p><Translation id='3dcc4f85-da59-47ed-9917-4b29d693d2e3' defaultMessage='No artwork is required for your order.' category='Card Sub Title' /></p>
                    </Card>
                </div>);
            }
            else {
                return (
                    <div>
                        <ShoppingBreadcrumbs active={8} ignore={offer.salesChannel == 'Estimator' ? 4 : 0} paymentsDisabled={paymentsDisabledOnRegion} />
                        <OrderReference orderReference={orderReference} />


                        <div className='artworkLayout'>
                            {/* <Layout offerData={props.offerContext} title={<Translation id="1ec5fff1-2c7d-4ea7-a658-11863be481c1" defaultMessage="ePac Sales Contact" category='Button' />} steps={getSteps()} stepOffset={props.offerContext.offer.offer.salesChannel == SalesChannel.WebSite ? 6 : 0}> */}

                            {checklistArticle &&

                                <Card title={<Translation id='eb29c55f-ef65-4c0e-be15-a0ca1080a891' defaultMessage='Checklist for Print-ready Artwork' category='Card Title' />} >
                                    <div className='content' dangerouslySetInnerHTML={{ __html: checklistArticle.html }} />
                                </Card>
                            }
                            <Card title={<Translation id='971411f0-087b-4784-abff-2f771f46c0be' defaultMessage='Prepare Your Artwork for Upload' category='Card Title' />}>
                                <p><Translation id='101fdcf0-fc54-4fe2-9850-6bd606cc1cd6' defaultMessage='Please follow the steps below to ensure production and no delays in the process:' category='Card Sub Title' /></p>
                                <ol>
                                    <li>
                                        <Translation id='46a92899-2dbd-4877-b9a6-ab1c95413fd9' defaultMessage='Press the button below to download the Dielines:' category='Label' />
                                        <br />
                                        <a onClick={() => {
                                            /*</li>gaContext.sendClick(CategoryType.Artwork, 'Download Dieline')*/

                                            trackEvent({
                                                event: 'ordering_tool_interactions',
                                                eventProps: {
                                                    'step': 'placement_5',
                                                    'product_category': analyticsMetadata.industryName,
                                                    'product_sub_category': analyticsMetadata.industryCategoryName,
                                                    'selection': "download_dielines",
                                                },
                                                userId: analyticsMetadata?.visitorId
                                            });
                                        }} href={`api/order/${globalOfferId.globalOfferId}/dieline/pdf`} download="filename"><button className='btn btn-primary-outline'><Translation id='8344101e-1add-4d46-a816-ec06799a48b2' defaultMessage='Download Dielines (Artwork Templates)' category='Label' /></button></a>
                                    </li>
                                    <li>
                                        <Translation id='fdb50601-42fa-4cce-9a7f-4874edea53a1' defaultMessage='Press the button below to review the Artwork Instructions:' category='Label' />
                                        <br />
                                        <button className='btn btn-primary-outline' onClick={() => {
                                            popup.current.show();
                                            // gaContext.sendClick(CategoryType.Artwork, 'View Instructions')*/
                                            trackEvent({
                                                event: 'ordering_tool_interactions',
                                                eventProps: {
                                                    'step': 'placement_5',
                                                    'product_category': analyticsMetadata.industryName,
                                                    'product_sub_category': analyticsMetadata.industryCategoryName,
                                                    'selection': "download_artwork_instructions",
                                                },
                                                userId: analyticsMetadata?.visitorId
                                            });
                                        }}><Translation id='a992cfa0-a484-4acc-af1a-14212912d0c3' defaultMessage='Artwork Instructions' category='Label' /></button>
                                    </li>
                                    <li>
                                        <Translation id='9a9fe42f-3bb9-42c6-af86-c9abac66c7ae' defaultMessage='Save the completed artwork files on your computer.' category='Label' />
                                    </li>
                                    <li>
                                        <Translation id='94fbab9a-7236-47e8-948a-ea67b1df71de' defaultMessage='Upload the artwork file for each SKU below.' category='Label' />
                                    </li>
                                </ol>
                            </Card>




                            <Card title={<Translation id='c4f6d647-2dcb-4c9c-a896-3a42d7c4294c' defaultMessage='Upload Your Artwork' category='Card Title' />} subtitle={<Translation id='73c66dbe-92ce-40eb-a2b6-8b57187ae296' defaultMessage='Maximum file size is 100MB' category='Card Sub Title' />} >

                                {settings && settings.settings["Application:ArtworkUploadPageHelpUrl"] &&
                                    <Row><a href={settings.settings["Application:ArtworkUploadPageHelpUrl"]} target="_blank" rel="noreferrer noopener"><Translation id='38c183c8-2a17-4729-999d-96d44f55d01a' defaultMessage='Click here to watch how to get your artwork print ready' category='Label' /></a></Row>}

                                <div className='flexContainer'>
                                    {
                                        artworkSkus && artworkSkus.length > 0 ?
                                            artworkSkus.map((sku, idx) => {
                                                return (
                                                    <UploadLink2 testIndex={idx} key={`tile${idx}`} sku={sku.skuDescription} onUploadComplete={onUploadComplete} defaultLabel={<Translation id='d9fb0130-cd7a-4ce1-8bbb-904eec8187b7' defaultMessage='Upload' category='Label' />} id={sku.artworkResourceId} url={url + sku.artworkResourceId} existingFileName={sku.fileName} onArtworkResourceUploadFailed={onArtworkResourceUploadFailed} />
                                                )
                                            })
                                            : ''
                                    }
                                </div>
                            </Card>


                            {offer.source == 'website' &&
                                <button className='btn btn-tertiary-outline back' onClick={() => { document.location = `/offer/${globalOfferId.globalOfferId}` }}>Back</button>
                            }
                            <button
                                className="btn btn-tertiary-outline back"
                                onClick={() => {
                                    navigate(`/offer/${globalOfferId.globalOfferId}`);
                                }}
                            ><span className='btn-text'><Translation id='03473ff0-be47-4bd3-9ed8-190fedf344c5' defaultMessage='Back' category='Button' /></span>
                            </button>
                            <button
                                data-testid="order-placement-upload-artwork-finish-button"
                                type="submit" className='btn btn-primary pull-right' disabled={!submitEnabled} onClick={async () => await handleSubmit()}>Finish</button>

                            <Popup closeOnClickAway={true} ref={popup}>
                                <ArtworkInstructions offerKey={globalOfferId.globalOfferId} orderPlacementApi={orderPlacementApi} />

                            </Popup>


                            {/* </Layout> */}
                        </div>
                    </div>
                );
            }
        }
        return <div></div>
    }

    return render();
}




export const UploadLink2 = props => {

    var progressIndicator = useRef(null);

    const language = useContext(Language);
    const [fileName, setFileName] = useState(props.existingFileName);
    const [progress, setProgress] = useState(0);
    const [errorOnUpload, setErrorOnUpload] = useState(false);

    const [label, setLabel] = useState(props.existingFileName ? props.existingFileName : props.defaultLabel);
    const [uploading, setUploading] = useState(false);
    const [uploadErrorText, setUploadErrorText] = useState(null);
    const MAX_UPLOAD_SIZE = 314572800;

    useEffect(() => {
        if (props.existingFileName) {
            progressIndicator.current.setValue(100);
        }
        else {
            progressIndicator.current.setValue(0);
        }

    }, []);

    const onClickUpload = () => {
        let element = document.getElementById("upload_" + props.id);
        element.click();

        if (props.onClick) {
            props.onClick();
        }
    }

    const onHandleUpload = (e) => {
        e.preventDefault();

        var fileToUpload = e.target.files[0];
        let file = fileToUpload;
        let fileName = fileToUpload ? fileToUpload.name : '';
        let ext = fileName.toLowerCase().split(".").slice(-1)[0];

        if ((fileToUpload.size > MAX_UPLOAD_SIZE) || (ext !== 'pdf' && ext !== 'ai')) {
            setErrorOnUpload(true);
            notifyUserOfUploadError(language.translateLabel('358defa3-d58c-4bd0-91a2-e424daf1c4b1', 'File cannot exceed 300MB and must be in PDF or AI format to upload.', 'Label'));
            notifyArtworkResourceUploadFailed(props.Id);
            return;
        }

        setFileName(fileName);

        let formData = new FormData();

        formData.append("formFile", file);
        formData.append("fileName", fileName)

        try {
            setErrorOnUpload(false);
            setUploading(true);

            let xhr = new XMLHttpRequest();

            xhr.upload.onprogress = (e) => {
                if (e.lengthComputable) {
                    var percentComplete = (e.loaded / e.total) * 99;
                    progressIndicator.current.setValue(percentComplete);
                }
            }

            xhr.upload.onerror = (e) => {
                console.log('Upload Error', e);
                notifyUserOfUploadError(language.translateLabel('03e2c5d7-1fa3-48ca-b871-47a1a30746f2', 'Unknown error uploading file. Please try again later', 'Label'));
                notifyArtworkResourceUploadFailed(props.Id);
                setFileName(props.existingFileName);
            }

            //https://stackoverflow.com/questions/15418608/xmlhttprequest-level-2-determinate-if-upload-finished
            xhr.upload.onload = (e) => {
                console.log("upload finished", xhr);
                setLabel(fileToUpload.name);
                setFileName(fileToUpload.name);

                if (props.onUploadComplete) {
                    props.onUploadComplete(props.id, fileToUpload);
                }
                progressIndicator.current.setWait(true);
                setUploading(false);
            };

            xhr.onreadystatechange = (e) => {

                if (progressIndicator.current) {
                    progressIndicator.current.setValue(99);
                }

                if (xhr.readyState === XMLHttpRequest.DONE) {
                    const status = xhr.status;
                    console.log('Status', status);

                    if (status === 0 || (status >= 200 && status < 400)) {
                        if (progressIndicator.current) {
                            progressIndicator.current.setWait(false);
                            progressIndicator.current.setValue(100);
                        }
                    } else {
                        setErrorOnUpload(true);
                        if (progressIndicator.current) {
                            progressIndicator.current.setWait(false);
                        }
                        notifyArtworkResourceUploadFailed(props.Id);
                    }
                }
            }

            console.log(`uploading to ${props.url}...`);

            xhr.open("POST", props.url);
            xhr.send(formData);
        }
        catch (ex) {
            console.log('Upload Error', ex);
            notifyUserOfUploadError(language.translateLabel('03e2c5d7-1fa3-48ca-b871-47a1a30746f2', 'Unknown error uploading file. Please try again later', 'Label'));
            setUploading(false);
            setErrorOnUpload(true);
            notifyArtworkResourceUploadFailed(props.Id);
        }
    }

    const onDropUpload = (file) => {
        if (file.size > MAX_UPLOAD_SIZE) {
            setErrorOnUpload(true);
            notifyUserOfUploadError(language.translateLabel('358defa3-d58c-4bd0-91a2-e424daf1c4b1', 'File cannot exceed 300MB.', 'Label'));
            notifyArtworkResourceUploadFailed(props.Id);
            return;
        }

        let formData = new FormData();

        formData.append("formFile", file);
        formData.append("fileName", file.name)

        try {
            setErrorOnUpload(false);
            setUploading(true);

            let xhr = new XMLHttpRequest();

            xhr.upload.onprogress = (e) => {
                if (e.lengthComputable) {
                    var percentComplete = (e.loaded / e.total) * 99;
                    progressIndicator.current.setValue(percentComplete);
                }
            }

            xhr.upload.onerror = (e) => {
                console.log('Upload Error', e);
                notifyUserOfUploadError(language.translateLabel('03e2c5d7-1fa3-48ca-b871-47a1a30746f2', 'Unknown error uploading file. Please try again later', 'Label'));
                notifyArtworkResourceUploadFailed(props.Id);
                setFileName(props.existingFileName);
            }

            //https://stackoverflow.com/questions/15418608/xmlhttprequest-level-2-determinate-if-upload-finished
            xhr.upload.onload = (e) => {
                console.log("upload finished", xhr);
                setLabel(file.name);
                setFileName(file.name);

                if (props.onUploadComplete) {
                    props.onUploadComplete(props.id, file);
                }
                progressIndicator.current.setWait(true);
                setUploading(false);
            };

            xhr.onreadystatechange = (e) => {

                if (progressIndicator.current) {
                    progressIndicator.current.setValue(99);
                }

                if (xhr.readyState === XMLHttpRequest.DONE) {
                    const status = xhr.status;
                    console.log('Status', status);

                    if (status === 0 || (status >= 200 && status < 400)) {
                        if (progressIndicator.current) {
                            progressIndicator.current.setWait(false);
                            progressIndicator.current.setValue(100);
                        }
                    } else {
                        setErrorOnUpload(true);
                        if (progressIndicator.current) {
                            progressIndicator.current.setWait(false);
                        }
                        notifyArtworkResourceUploadFailed(props.Id);
                    }
                }
            }

            console.log(`uploading to ${props.url}...`);

            xhr.open("POST", props.url);
            xhr.send(formData);
        }
        catch (ex) {
            console.log('Upload Error', ex);
            notifyUserOfUploadError(language.translateLabel('03e2c5d7-1fa3-48ca-b871-47a1a30746f2', 'Unknown error uploading file. Please try again later', 'Label'));
            setUploading(false);
            setErrorOnUpload(true);
            notifyArtworkResourceUploadFailed(props.Id);
        }
    }

    const notifyArtworkResourceUploadFailed = (artworkResourceId) => {
        if (props.onArtworkResourceUploadFailed) {
            props.onArtworkResourceUploadFailed(artworkResourceId);
        }
    }

    const notifyUserOfUploadError = (message) => {

        if (!message) {
            message = language.translateLabel('03e2c5d7-1fa3-48ca-b871-47a1a30746f2', 'Unknown error uploading file. Please try again later', 'Label');
        }

        setUploadErrorText(message);
        setLabel(props.defaultLabel);
        setErrorOnUpload(true);
    }

    const checkSuffixPDFAI = (filename) => {
        let ext = filename.toLowerCase().split(".").slice(-1)[0];
        if (ext === 'ai' || ext === 'pdf') {
            return true;
        } else {
            return false;
        }
    }

    const dropHandler = (ev) => {
        //ev.stopPropogation(); //this normally helps but not for this case
        ev.preventDefault();
        console.log("handling drop...", ev);
        if (ev.dataTransfer.items.length === 1) {
            [...ev.dataTransfer.items].forEach((item, i) => {
                if (item.kind === "file") {
                    const file = item.getAsFile();
                    if (checkSuffixPDFAI(file.name)) {
                        setErrorOnUpload(false);
                        onDropUpload(file);
                    } else {
                        notifyUserOfUploadError("Unregocnized file type. Please upload Illustrator (myfile.ai) or PDF (myfile.pdf) files only.", 'Label');
                    }
                }
            });
        } else if (ev.dataTransfer.items.length > 1) {
            notifyUserOfUploadError("Please upload one file at a time. Several uploads were just received.");
        } else {
            notifyUserOfUploadError("No valid file uploads detected.");
        }
    }

    const dragOverHandler = (ev) => {
        ev.preventDefault();
        console.log("entered drop zone");
    }


    return (
        <div className="dragAndDropArea" onDrop={ev => { dropHandler(ev) }} onDragOver={ev => { dragOverHandler(ev) }}>
            <div className="uploadTile uploadLink buttonTile">
                <div className="header"></div>
                <div className="content">
                    <h4>{props.sku}</h4>
                    {errorOnUpload
                        ? <div className='progress indicator failure'>
                            <div className='circle'>
                                <X className='icon' />
                            </div>
                        </div>
                        : <ProgressIndicator ref={progressIndicator} size={80} total={100} />
                    }

                    {fileName &&
                        <h4 className='fileName'>{fileName}</h4>
                    }

                    <input className='upload' type="file" id={"upload_" + props.id} accept=".pdf,.ai" onChange={onHandleUpload} disabled={props.disabled} />

                </div>
                <div className="footer">
                    <button
                        data-testid={`order-placement-upload-artwork-file-button-${props.testIndex}`}
                        className="btn btn-secondary" onClick={() => { onClickUpload() }}>
                        {fileName
                            ? <span>Change</span>
                            : <span>Upload</span>
                        }
                    </button>
                    {errorOnUpload && <p className='tile-error'>{uploadErrorText}</p>}
                </div>

            </div>
        </div>
    );
}


export const ProgressIndicator = forwardRef((props, ref) => {

    const total = props.total;
    const [circumference, setCircumference] = useState(0);
    const [progressOffset, setProgressOffset] = useState(0);
    const [progress, setProgress] = useState(0);
    const [waiting, setWaiting] = useState(false);

    const size = props.size;
    const strokeWidth = 8;
    const center = size / 2;
    const radius = size / 2 - strokeWidth / 2;
    const circleOneStroke = '#EEEEEE';
    const circleTwoStroke = '#7CBD42';


    const style = {
        width: size,
        height: size,
        lineHeight: '80px'
    }

    const containerStyle = {
        height: size,
        lineHeight: '80px'
    }

    const setValue = (step) => {
        var p = (100 / total) * step;
        var c = 2 * Math.PI * radius;
        var po = ((100 - p) / 100) * c;

        setProgress(p);
        setCircumference(c)
        setProgressOffset(po);
    }

    const setWait = (value) => {
        setWaiting(value);
    }

    useImperativeHandle(ref, () => ({
        setValue: (value) => setValue(value),
        setWait: (value) => setWait(value)
    }));

    useEffect(() => {
        setValue(props.value ? props.value : 0);
    }, []);

    const getProgressIcon = (progress) => {

        if (waiting) {
            return <span className="spinner"></span>
        }
        else {
            var value = progress.toFixed();
            if (value == 0) {
                return <span><UploadCloud className='icon thin' /></span>
            }
            else if (value < 100) {
                return <span>{progress.toFixed(0)}%</span>
            }
            else {
                return <span><Check className='icon' /></span>
            }
        }
    }

    return (
        <div className='progress indicator' style={containerStyle}>
            <div className="progress-ring" style={style}>
                <svg width={size} height={size}>
                    <circle
                        className="svg-circle-bg"
                        stroke={circleOneStroke}
                        cx={center}
                        cy={center}
                        r={radius}
                        strokeWidth={strokeWidth}
                    />
                    <circle
                        className="svg-circle"
                        stroke={circleTwoStroke}
                        cx={center}
                        cy={center}
                        r={radius}
                        strokeWidth={strokeWidth}
                        strokeDasharray={circumference}
                        strokeDashoffset={progressOffset}
                        fill='#FFF'
                    />
                </svg>

                <div className='value'>
                    {getProgressIcon(progress)}
                </div>
            </div>

        </div>
    )
});