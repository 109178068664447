import React, { useState, useContext, useEffect, useRef } from 'react';
import { User, Phone, AtSign } from 'react-feather';
import { DebugMenu } from './DebugMenu';
import { NotificationCenter } from '../core/NotificationCenter';
import { Popup } from '../core/Popup';
import { Language } from '../infrastructure/Languages';
import { useUserContext } from '../infrastructure/UserContext';
import { useCheckoutContext } from '../infrastructure/CheckoutContext';
import { SettingsContext } from '../infrastructure/Contexts';
import { OfferLanguageContext } from "../infrastructure/OfferLanguageContext";
import { SignoutButton } from './SignoutButton';
import { MyAccountHeaderButton } from './MyAccountHeaderButton';
import { AuthenticatedTemplate } from "@azure/msal-react";

export const Layout = props => {

    return (
        <div id='layout'>
            <Header settings={props.settings} />
            <NotificationCenter />
            <div className='container'>
                {props.children}
            </div>
        </div>
    );
}


export const Header = props => {

    const language = useContext(Language);
    const popup = useRef(null);
    const { product } = useCheckoutContext();

    const onLogoClick = () => {
        if (props.settings && props.settings["Application:HomePageUrl"]) {
            popup.current.show();
        }
    }


    return (
        <div className='header background-white'>
            <Colorbar />

            <img className='logo clickable' src='api/scripts/css/images/png/epac_logo' onClick={() => { onLogoClick() }} />

            {process.env.NODE_ENV !== 'production' && <DebugMenu />}
            {/*<AuthenticatedTemplate>*/}

            {/*</AuthenticatedTemplate>*/}
            {props.settings && props.settings["Application:MyAccountHeaderEnabled"] === true ?
                <MyAccountHeaderButton />
                :
                <AuthenticatedTemplate>
                    <div className="contact-pop-over">
                        <SignoutButton />
                    </div>
                </AuthenticatedTemplate>
            }

            <ContactUsDropdownMenu />
            <CountryDropdownMenu />

            <Popup closeOnClickAway={true} ref={popup}>
                <div className='contentSection'>
                    <span>{language.translateLabel('6995b100-5c7a-45ff-a521-d7e1a6e0eee6', 'Do you want to leave the shopping experience?', 'Label')}</span>
                </div>
                <div className='footerSection'>
                    <button className='btn btn-secondary btn-modal' onClick={() => { document.location = props.settings["Application:HomePageUrl"] }}>{language.translateLabel('dbaf68ea-89df-4a1e-8b55-8599f212a319', 'Yes', 'Button')}</button>
                    <button className='btn btn-primary btn-modal pull-right' onClick={() => { popup.current.close() }}>{language.translateLabel('ffa0b5ab-5199-4771-8950-69bb9f078aef', 'No', 'Button')}</button>
                </div>
            </Popup>
        </div>
    );
}


export const Colorbar = props => {
    return (
        <div className='colorbar'>
            <div className='rectangle background-navy' />
            <div className='rectangle background-orange' />
            <div className='rectangle background-green' />
            <div className='rectangle background-blue' />
            <div className='rectangle background-purple' />
            <div className='rectangle background-yellow' />
        </div>
    );
}

export const CountryDropdownMenu = props => {
    const [visible, setVisible] = useState(false);
    const language = useContext(Language);
    const settings = useContext(SettingsContext);
    const offerLanguageContext = useContext(OfferLanguageContext);
    const [regions, setRegions] = useState(null);
    const [sourceCulture, setSourceCulture] = useState(language ? language.getSourceCulture() : 'en-US');
    const [selectedCulture, setSelectedCulture] = useState(null);
    const { userLanguage, updateUserLanguage, userRegion } = useUserContext();
    const { reset } = useCheckoutContext();

    // source culture could change on offer loading and must be applied
    if (offerLanguageContext && offerLanguageContext.offerLanguage && offerLanguageContext.offerLanguage.sourceLanguage != sourceCulture)
        setSourceCulture(offerLanguageContext.offerLanguage.sourceLanguage);

    useEffect(() => {
        var regions = settings.regions;
        var plantRegion = regions.find(e => e.cultureString == sourceCulture);
        if (plantRegion && plantRegion.supportedCountries && plantRegion.supportedCountries.length > 1) {
            setRegions(plantRegion.supportedCountries);
            var culture = plantRegion.supportedCountries.find(e => e.cultureString == userLanguage);
            if (!culture)
                culture = plantRegion.regionCulture;
            setSelectedCulture(culture ? culture : null);
        }
        else {
            setRegions(null);
        }
    }, [sourceCulture]);

    const onRegionSelected = item => {
        setVisible(false);
        reset();
        updateUserLanguage(item.cultureString);
        setTimeout(() => {

            if (!document.location.pathname.includes("offer"))  // shop case
                document.location = `/${userRegion}/search`;
            else {                                              // order placement case
                offerLanguageContext.setOfferLanguage({
                    offerId: offerLanguageContext.offerLanguage.offerId,
                    sourceLanguage: offerLanguageContext.offerLanguage.sourceLanguage,
                    targetLanguage: item.cultureString
                });
            }
        }, 500);
    }

    return (
        <div className='country-pop-over'>
            {regions && selectedCulture &&
                <div>
                    <button className='btn btn-primary-outline' onClick={() => { setVisible(!visible) }}><span className={`fi fi-${selectedCulture.country}`}></span> <span className='caption'>{selectedCulture.name}</span></button>
                    {visible &&
                        <div>
                            <div className='backSplash clear' onClick={() => { setVisible(false); }} />
                            <div className='pop-over'>
                                <div className='triangle'></div>
                                <div className='menu'>
                                    {regions.map((item, idx) => {
                                        return (
                                            <div key={`menuItem${idx}`} className='menu-item clickable' onClick={() => { onRegionSelected(item) }}><span className={`fi fi-${item.country}`}></span> <span>{item.name}</span></div>
                                        )
                                    })}
                                </div>
                            </div>
                        </div>
                    }
                </div>
            }
        </div>
    );
}


export const ContactUsDropdownMenu = props => {
    const [visible, setVisible] = useState(false);
    //const settings = useContext(SettingsContext);
    const language = useContext(Language);
    //const [contact, setContact] = useState(null);
    //const cultureStr = language ? language.getSourceCulture() : 'en-US';
    const labelText = language ? language.translateLabel('7b4d701b-0375-45c2-a6b0-f6cd8c892374', 'Contact Us', 'Label') : 'Contact Us';
    const { salesforceAccount } = useUserContext();

    /*
    useEffect(() => {
        var regions = settings.regions;
        var region = regions.find(e => e.cultureString == cultureStr);
        var contact = {
            email: region && region.defaultAddress ? region.defaultAddress.emailAddress : 'contact@epacflexibles.com',
            phone: region && region.defaultAddress ? region.defaultAddress.phoneNumber : '+1 (844) 623-8603'
        }
        setContact(contact);

    }, []);
    */

    return (
        <div data-testid="contact-us" className='contact-pop-over'>
            {salesforceAccount &&
                <div>
                    <button className='btn btn-primary' onClick={() => { setVisible(!visible) }}><User className='icon' /><span className='caption'>{labelText}</span></button>
                    {visible &&
                        <div>
                            <div className='backSplash clear' onClick={() => { setVisible(false); }} />
                            <div className='pop-over'>
                                <div className='triangle'></div>
                                <div className='menu'>
                                    <div className='menu-item'><Phone className='icon' /><a href={`tel:${salesforceAccount.phone}`}>{salesforceAccount.phone}</a></div>
                                    <div className='menu-item'><AtSign className='icon' /><a href={`mailto:${salesforceAccount.email}`}>{salesforceAccount.email}</a></div>
                                </div>
                            </div>
                        </div>
                    }
                </div>
            }
        </div>
    );
}


