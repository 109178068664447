import React from "react";

export const Money = ({ currency, amount }) => {
  const displayName = Money.name;

  return (
    <div className="money">
      <span className="currency color-light-grey">{currency}</span>
      <span className="amount color-grey">{amount}</span>
    </div>
  );
};
