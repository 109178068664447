import React, { useState, useEffect } from "react";


export const Textbox = (props) => {
        
    const [value, setValue] = useState(props.value ? props.value : '');
    const [valid, setValid] = useState(true);
    const [focused, setFocused] = useState(false);

    const onChange = (evt) => {
        let changedValue = evt.target.value;
        let valid = true;
        if (props.requireValidInput === true) {
            if (props.type === 'number') {
                if (changedValue < 0) {
                    setValid(false);
                    return;
                }
            }
            valid = isValueValid(changedValue);
            
            if (valid || changedValue.length === 0) {
                setValid(true);
                setValue(changedValue);  
                notifyListeners(changedValue);
            }
            else {
                setValue(changedValue);  
                notifyListeners(changedValue);
            }

        }
        else {
            let valid = isValueValid(changedValue);            
            setValid(valid);
            setValue(changedValue); 
            notifyListeners(changedValue);
        }
    }

    const notifyListeners = (value) => {
        let valid = isValueValid(value);
        
        if (props.onChange) {
            props.onChange(value, valid, props.id)
        }
    }

    const isValueValid = (value) => {
        var result = true;

        if (props.required === true) {
            if (!value || value.length === 0) {                
                result = false;
            }
        }

        if (props.regex) {
            let valid = Regex.Matches(props.regex, value);  
            if (!valid) {                
                result = false;
            }
        }    

        if (props.onValidating) {
            if (props.onValidating(value) === false) {
                result = false;
            }
        }

        if (props.id && props.onValidated) {
            props.onValidated(props.id, result);
        }

        return result;
    }

    const getStyle = () => {
        let result = 'control textbox';

        if (!isValueValid(value)) {
            result += ' error';
        }
        else {
            if (value) {
                result += ' valid';
            }            
        }
        return result;
    }

    const onBlur = () => {
        setFocused(false);
        if (props.onBlur) {
            props.onBlur(value);
        }
    }


    return (
        <input 
         type={props.type ? props.type : 'text'} 
         autoComplete='none'
         value={value}
         className={focused ? 'control textbox' : getStyle()}
         onChange={onChange}
         maxLength={parseInt(props.maxLength) > 0 ? parseInt(props.maxLength) : 255}
         placeholder={props.placeholder ? props.placeholder : 'Input text here'}
         required={props.required === true ? true : false}
            onFocus={() => setFocused(true)}
            onBlur={() => onBlur()}
        />            
    );
}


export const Textarea = (props) => {

    const [value, setValue] = useState(props.value ? props.value.toString() : '');
    const [valid, setValid] = useState(value);
    const [focused, setFocused] = useState(false);

    const onChange = (evt) => {
        let changedValue = evt.target.value;
        //this.value = evt.target.value;

        if (props.requireValidInput === true) {

            let valid = isValueValid(changedValue);

            if (valid || changedValue.length === 0) {
                setValid(true);

                notifyListeners(changedValue);
            }
        }
        else {
            let valid = isValueValid(changedValue);
            //setValue(changedValue);
            setValid(valid);

            notifyListeners(changedValue);
        }
        setValue(changedValue);
    }

    const notifyListeners = (value) => {
        let valid = isValueValid(value);

        if (props.onChange) {
            props.onChange(value, valid)
        }
    }

    const isValueValid = (value) => {
        var result = true;

        if (props.required === true) {
            if (!value || value.length === 0) {
                result = false;
            }
        }

        if (props.regex) {
            let valid = Regex.Matches(props.regex, value);
            if (!valid) {
                result = false;
            }
        }

        if (props.onValidating) {
            if (props.onValidating(value) === false) {
                result = false;
            }
        }

        if (props.id && props.onValidated) {
            props.onValidated(props.id, result);
        }

        return result;

    }

    const getStyle = () => {
        let result = 'control textbox';
        if (!isValueValid(value)) {
            result += ' error';
        }
        else {
            if (value) {
                result += ' valid';
            }
        }
        return result;
    }

    const onBlur = () => {
        setFocused(false);
        if (props.onBlur) {
            props.onBlur(value);
        }
    }

    return (
        <textarea className={focused ? 'control textbox' : getStyle()
        }
            type={props.type ? props.type : 'text'}
            autoComplete='none'
            rows={props.rows ? props.rows : 5}
            value={props.value}
            onChange={onChange}
            maxLength={parseInt(props.maxLength) > 0 ? parseInt(props.maxLength) : 255}
            placeholder={props.placeholder ? props.placeholder : 'Input text here'}
            required={props.required === true ? true : false}
            onFocus={() => setFocused(true)}
            onBlur={() => onBlur()}
        />
    );
}




export class Regex {

    static Matches(exp, value) {
        var result = true;
        var regex = exp ? exp : null;
        if (regex) {
            var r = new RegExp(regex, 'g');
            var match = r.exec(value);
            if (match == null || match[0] != value) {
                result = false;
            }
        }
        return result;
    }

}