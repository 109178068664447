import React, { useState } from 'react';
import { useCheckoutContext } from '../infrastructure/CheckoutContext';
import { useUserContext } from '../infrastructure/UserContext';
import { useNavigate } from 'react-router-dom';
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from '@azure/msal-react';
import { loginRequest } from "../authConfig";
import { useContext } from 'react';
import { NotificationContext } from '../capabilities/NotificationContext';




export const MyAccountHeaderButton = () => {
    const [visible, setVisible] = useState(false);
    const { reset } = useCheckoutContext();
    const { clearSettings } = useUserContext();
    const navigate = useNavigate();
    const { instance } = useMsal();
    const { setMessage, setCanLogout } = useContext(NotificationContext);

    const onClearContext = () => {
        setVisible(false);

        reset();

        window.setTimeout(() => {
            window.location = '/';
        }, 1000);
    }

    const clearCulture = () => {
        clearSettings();

        window.setTimeout(() => {
            window.location = '/';
        }, 1000);
    }

    


    const handleLogin = () => {
        instance.loginRedirect({
            scopes: loginRequest.scopes,
            redirectUri: "/myorders",
        })
            .catch((error) => console.log(error));
    };

    return (
        <div className='contact-pop-over'>
            <button className='btn btn-primary' onClick={() => setVisible(!visible)}>My Account</button>
            {visible &&
                <div className='pop-over'>
                    <div className='triangle'></div>
                    <div className='menu'>
              
                        <UnauthenticatedTemplate>
                            <div className='menu-item' onClick={() => { handleLogin(); }}><span className="debug-menu-item">Login</span></div>
                        </UnauthenticatedTemplate>
                        <AuthenticatedTemplate>
                            <div className='menu-item' onClick={() => { window.location='myorders'; }}><span className="debug-menu-item">My Orders</span></div>
                            <div className='menu-item' onClick={() => { window.location='myaddresses'; }}><span className="debug-menu-item">My Addresses</span></div>
                            <div className='menu-separator' />
                            <div className='menu-item' onClick={() => {
                                instance.logoutRedirect({
                                    postLogoutRedirectUri: "/",
                                    mainWindowRedirectUri: "/",
                                })
                            }}><span className="debug-menu-item">Logout</span></div>
                        </AuthenticatedTemplate>
                    </div>
                </div>
            }
        </div>
    );
}