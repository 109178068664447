
export const CategoryType = {
    Product: 'Product',
    Features: 'Features',
    Info: 'Info',
    Order: 'Order',
    Shipping: 'Shipping',
    Billing: 'Billing',
    Connect: 'Connect',
    Artwork: 'Artwork'
}

export const ActionType = {
    Load: 'Load',
    Change: 'Change',
    Click: 'Click'    
}


export const FeatureLocationType = {
    Zipper: 1,
    HangHole: 2,
    TearNotch: 3,
    Valve: 4
}


export class PaymentMethod  {
    static CreditCard = 'CC';
    static eCheck = 'eCheck';
    static ACH = 'ACH';
    static Check = 'Check';


    static toString(method) {
        switch (method) {
            case PaymentMethod.CreditCard:
                return 'Credit Card';                
            case PaymentMethod.eCheck:
                return 'Electronic Check';
            case PaymentMethod.ACH:
                return 'ACH';
            case PaymentMethod.Check:
                return 'Check';
            default:
                throw new Error(`Payment Method ${method} is not supported!`);
        }
    }
}

export const ReasonCode = {
    None: 'NONE',
    NotFound: 'NOT_FOUND',
    InvalidCardNumber: 'INVALID_CARD_NUMBER',
    InvalidCvv: 'INVALID_CVV',
    InvalidExpirationDate: 'INVALID_EXPIRATION_DATE',
    InvalidPostalCode: 'INVALID_POSTAL_CODE',
    PaymentResubmitted: 'PAYMENT_RESUBMITTED',
    InvalidAccountNumber: 'INVALID_ACCOUNT_NUMBER',
    InvalidRoutingNumber: 'INVALID_ROUTING_NUMBER',
    PaymentProviderError: 'PAYMENT_PROVIDER_ERROR'
}

export const ResponseStatus = {
    Rejected: 'REJECTED',
    Declined: 'DECLINED',
    Approved: 'APPROVED',
    ApprovedPending: 'APPROVED_PENDING'
}

export const PaymentTerms = {
    Prepaid: 'Prepaid',
    Prepaid_50_50: 'Prepaid 50/50',
    Net30: 'Net 30 Days',
    Net30_EOM: 'Net 30 Days EOM',
    Net30_50_50: 'Net 30 Days 50/50',
    Net45: 'Net 45 Days',
    Net60: 'Net 60 Days',
    Net90: 'Net 90 Days',
    Net90_EOM: 'Net 90 Days EOM'
}