import React, { useState, useEffect, forwardRef, useContext, useImperativeHandle  } from 'react';
import { ChevronsLeft, ChevronsRight, ChevronLeft, ChevronRight, ChevronUp, ChevronDown } from 'react-feather';
import { ApiDataAccess } from '../infrastructure/ApiDataAccess';
import { CapabilitiesContext } from "../infrastructure/Contexts";
import { CapabilityName } from "../infrastructure/Constants";
import { Alert } from './Alert'
import { Textbox } from './Textbox'
import { ContextHelp } from './ContextHelp'
import { Timer } from './Utils'


import './controls.css'

export const List = forwardRef(
    ({
        controller,
        action,
        headers,
        items = null,
        pageSize = 20,
        pageIndex = 0,
        sortColumn = null,
        sortDesc = false,
        onSelect,
        onRenderRow,
        showHeader = true,
        className = null
    }, ref) => {

        const capabilitiesContext = useContext(CapabilitiesContext);
        const loader = capabilitiesContext.getCapability(CapabilityName.Loader);

        const timer = new Timer();
        const [loaded, setLoaded] = useState(false);
        const [filter, setFilter] = useState({});
        const [list, setList] = useState(null);
        const [sort, setSort] = useState(null);
        const [isSortable, setIsSortable] = useState(true);
        const [currentSort, setCurrentSort] = useState({
            column: sortColumn,
            desc: sortDesc
        });

        useImperativeHandle(ref, () => ({

            setItems(items) {
                var listData = new ListData(items, headers);
                var sort = listData.getSort();

                setSort(sort);
                setIsSortable(listData.isSortable());
                setList(listData.getList());
            },

            refresh() {
                refreshListUnsorted();
            }

        }));

        const getColumnWidth = (header) => {

            if (header.width) {
                return `col-${header.width}`
            }
            
            if (!header.className) {
                return `w${list.headers.length}`;
            }

            return '';
        }

        const onRowSelected = (data) => {
            if (isSelectable()) {
                onSelect(data);
            }
        }

        const isSelectable = () => {
            return onSelect
                ? true
                : false;
        }


        const refreshListUnsorted = async () => {
            loader.show();
            try {
                setList(null);

                var firstSortableHeader = headers.find(e => e.sortable === true);
                var sortColumn = currentSort.column ? currentSort.column : firstSortableHeader ? firstSortableHeader.column : null;
                var sortDesc = currentSort.desc ? currentSort.desc : false;
                console.log("####filter UNSORTED ", filter)
                var listData = await ListData.GetAsync(controller, action, headers, pageIndex, pageSize, sortColumn, sortDesc, filter, items);
                setIsSortable(listData.isSortable());
                setList(listData.getList());
                setLoaded(true);
            }
            catch (ex) {
                setList(null);
                setLoaded(true);
            }
            loader.hide();
        }


        const refreshList = async () => {
            loader.show();
            try {

                if (isSortable) {
                    setList(null);

                    var firstSortableHeader = headers.find(e => e.sortable === true);
                    var sortColumn = currentSort.column ? currentSort.column : firstSortableHeader ? firstSortableHeader.column : null;
                    var sortDesc = currentSort.desc ? currentSort.desc : false;
                    var listData = await ListData.GetAsync(controller, action, headers, pageIndex, pageSize, sortColumn, sortDesc, filter, items);
                    var sort = listData.getSort();   

                    setSort(sort);
                    setIsSortable(listData.isSortable());
                    setList(listData.getList());
                }
                else {
                    sortListClientSide();
                }
                setLoaded(true);
            }
            catch (ex) {
                setList(null);
                setLoaded(true);
            }
            loader.hide();
        }

        const sortListClientSide = () => {
            setSort({
                columnName: currentSort.column,
                desc: currentSort.desc
            });

            if (currentSort.desc) {
                list.items.sort((a, b) => (a[currentSort.column] > b[currentSort.column]) ? -1 : 1);
            }
            else {
                list.items.sort((a, b) => (a[currentSort.column] > b[currentSort.column]) ? 1 : -1);
            }

            setList(list);
        }

        const setPage = async (index) => {
            pageIndex = index;
            await refreshList();
        }

        const setSortColumn = async (column, desc) => {
            currentSort.column = column;
            currentSort.desc = desc;
            setCurrentSort(currentSort);
            await refreshList();
        }

        const setColumnFilter = async (column, txt) => {          
            timer
                .waitAsync(1000)
                .then(async () => {
          
                    filter[column] = txt && txt.length > 0 ? txt : null;
                    setFilter(filter);
                    await refreshListUnsorted();
                })
        }

        const getFilterValue = (column) => {
            var value = list && list.paging && list.paging.filter ? list.paging.filter[column] : '';
            return value ? value : '';
        }

        const hasFilters = () => {
            return list.headers.findIndex(e => e.filterable === true) >= 0;
        }

        const init = async () => {
            pageIndex = 0;
            setIsSortable(true);
            await refreshList();
        }


        useEffect(() => {
            init();
        }, []);


        return (
            <div className={className ? 'list ' + className : 'list'}>
                {loaded &&
                    <div>
                        {list && list.items &&
                            <div>
                                {list.paging && list.paging.totalPages > 1 &&
                                    <ListPager paging={list.paging} onChange={(idx) => { setPage(idx) }} />
                                }

                                {showHeader !== false &&
                                    <div className='headerrow background-green color-white'>
                                        {list.headers.map((header, idx) => {
                                            return (
                                                <div className={header.className ? 'column ' + getColumnWidth(header) + ' ' + header.className : 'column ' + getColumnWidth(header)} key={'idx_' + idx} >
                                                    <HeaderItem header={header} sortColumn={sort ? sort.columnName : null} sortDesc={sort ? sort.desc : null} onClick={(sortColumn, desc) => { setSortColumn(sortColumn, desc) }} />
                                                </div>
                                            );
                                        })}
                                    </div>
                                }

                                {hasFilters() !== false &&
                                    <div className='headerrow'>
                                        {list.headers.map((header, idx) => {
                                            return (
                                                <div className={header.className ? 'column ' + getColumnWidth(header) + ' ' + header.className : 'column ' + getColumnWidth(header)} key={'idx_' + idx} >
                                                    {header.filterable == true
                                                        ? <Textbox placeholder='filter by...' value={getFilterValue(header.column)} onChange={txt => { setColumnFilter(header.column, txt) }} />
                                                        : <span>&nbsp;</span>
                                                    }
                                                </div>
                                            );
                                        })}
                                    </div>
                                }


                                {list.items.length > 0 &&
                                    list.items.map(function (item, index) {
                                    return (
                                        <div className={isSelectable() ? 'row datarow selectable' : 'row datarow'} onClick={() => onRowSelected(item)} key={'idx_' + index}>
                                            {list.headers.map((header, idx) => {
                                                return (
                                                    <div className={header.className ? 'column ' + getColumnWidth(header) + ' ' + header.className : 'column ' + getColumnWidth(header)} key={'idx_' + idx}>
                                                        <div>{onRenderRow ? onRenderRow(header.column, item, header) : item[header.column]}</div>
                                                    </div>
                                                );
                                            })}
                                        </div>)
                                })}
                            </div>
                        }

                        {list && list.items && list.items.length == 0 &&
                            <Alert type={'info'}>The list does not contain any items. Please refresh the page or try again later.</Alert>
                        }

                    </div>
                }
            </div>
        )
    });


export const HeaderItem = ({ header, sortColumn, sortDesc, onClick}) => {

    const hasSort = header.column === sortColumn;
    const isSortable = header.sortable === true;
    const hasInfoIcon = header.info ? true : false;

    const onSelected = () => {
        if (isSortable && onClick) {
            var desc = sortDesc === true;
            if (header.column === sortColumn) {
                desc = !desc;
            }
            onClick(header.column, desc);
        }
    }

    return (
        <div className={isSortable === true ? 'clickable' : 'notClickable'} onClick={() => { onSelected() }}>
            {header.title
                ? <span>{header.title ? header.title : '-'}</span>
                : <span>&nbsp;</span>
            }
            
            {hasSort && !sortDesc &&
                <ChevronUp className='icon' />
            }

            {hasSort && sortDesc &&
                <ChevronDown className='icon' />
            }
            {hasInfoIcon &&
                <span style={{ "display": "inline-block" }}><ContextHelp context={header.helpContext} /></span>
            }
        </div>
        )
}


export const ListPager = ({ paging, onChange }) => {

    const isFirstPage = paging.pageIndex <= 0;
    const isLastPage = paging.pageIndex >= paging.totalPages - 1;

    const onFirstClick = () => {
        if (!isFirstPage) {
            let newIndex = 0;
            if (onChange) {
                onChange(newIndex);
            }
        }
    }

    const onPreviousClick = () => {        
        if (!isFirstPage) {
            let newIndex = paging.pageIndex > 0 ? paging.pageIndex - 1 : 0;
            if (onChange) {
                onChange(newIndex);
            }
        }
    }

    const onNextClick = () => {
        if (!isLastPage) {
            let newIndex = paging.pageIndex < paging.totalPages - 1 ? paging.pageIndex + 1 : paging.totalPages - 1;
            if (onChange) {
                onChange(newIndex);
            }
        }
    }

    const onLastClick = () => {
        if (!isLastPage) {
            let newIndex = paging.totalPages - 1;
            if (onChange) {
                onChange(newIndex)
            }
        }
    }

    return (
        <div className='listPager row'>

            <div className='column w3 text-align-left'>
                <ChevronsLeft className={!isFirstPage ? 'icon clickable' : 'icon color-light-grey'} onClick={() => { onFirstClick()} } />
                <ChevronLeft className={!isFirstPage > 0 ? 'icon clickable' : 'icon color-light-grey'} onClick={() => { onPreviousClick() }} />
            </div>

            <div className='column w3 text-align-center'>Page {paging.pageIndex + 1} of { paging.totalPages}</div>

            <div className='column w3 text-align-right'>
                <ChevronRight className={!isLastPage ? 'icon clickable' : 'icon color-light-grey'} onClick={() => { onNextClick() }} />
                <ChevronsRight className={!isLastPage ? 'icon clickable' : 'icon color-light-grey'} onClick={() => { onLastClick() }} />
            </div>
        </div>
        )
}





export class ListData {
    constructor(data, headers) {

        this.items = !Array.isArray(data) && data.items
            ? data.items
            : data;

        this.paging = !Array.isArray(data) && data.paging
            ? data.paging
            : null;

        this.headers = headers;
    }

    getItems() {
        return this.items;
    }

    getPaging() {
        return this.paging;
    }

    getSort() {
        return this.paging && this.paging.sort
            ? this.paging.sort
            : null;
    }

    getHeaders() {
        return this.headers;
    }

    getList() {
        return {
            paging: this.paging,
            items: this.items,
            headers: this.headers
        }
    }

    isPageable() {
        return this.getPaging()
            ? true
            : false;
    }

    isSortable() {
        return this.getSort()
            ? true
            : false;
    }



    static GetAsync(controller, action, headers, pageIndex=0, pageSize=15, sortColumn=null, sortDesc=false, filter=null, items=null) {
        return new Promise((resolve, reject) => {

            var url = sortColumn
                ? `${action}?pageSize=${pageSize}&pageIndex=${pageIndex}&sort.columnName=${sortColumn}&sort.desc=${sortDesc}`
                : `${action}?pageSize=${pageSize}&pageIndex=${pageIndex}`;

            if (filter) {
                let filterStr = '';
                Object.keys(filter).forEach(key => {
                    let val = filter[key] ? encodeURIComponent(filter[key]): null;
                    if (val && val.length > 0) {
                        filterStr += filterStr == ''
                            ? `filter.${key}=${val}`
                            : `&filter.${key}=${val}`
                    }
                })

                url += `&${filterStr}`;
            }

            if (items) {
                var listData = new ListData(items, headers);
                resolve(listData);
            }
            else {
                var dataAccess = new ApiDataAccess(controller);
                dataAccess.get(url)
                    .then(data => {
                        var listData = new ListData(data, headers);
                        resolve(listData);
                    })
                    .catch(ex => {
                        reject(ex);
                    });
            }

        });
    }
}
