import React, { useEffect, useRef, useContext, useState } from 'react';
import { User, Phone, AtSign } from 'react-feather';
import { SecureApiDataAccess } from "../../infrastructure/SecureApiDataAccess";
import './MyAccountEpacContacts.css';


export const MyAccountEpacContacts = ({ salesforceAccountId }) => {



    const userDataAccess = new SecureApiDataAccess("/api/user");
    const [salesforceAccount, setSalesforceAccount] = useState(null);
    const [accountOwner, setAccountOwner] = useState(null);
    const [salesSupportSpecialist, setSalesSupportSpecialist] = useState(null);
    const [visibleOwnerContact, setVisibleOwnerContact] = useState(false);
    const [visibleSSSContact, setVisibleSSSContact] = useState(false);

    useEffect(() => {

        const init = async () => {
            var account = await getSfAccount(salesforceAccountId);
            console.log(account)

            if (account.hasSuccess === true) {
                setAccountOwner(account.data.accountOwner);
                setSalesSupportSpecialist(account.data.salesSupportSpecialist);

            }
            setSalesforceAccount(account)
        }
        init();
    }, []);

    const getSfAccount = async (salesforceAccountId) => {
        if (salesforceAccountId != null) {
            var sfAccnt = await userDataAccess.get(
                `/account/${encodeURIComponent(
                    salesforceAccountId
                )}`
            );
            return sfAccnt;
        }

    }



    return (
        <div className="topHeader">
            {salesSupportSpecialist &&
                <div data-testid="contact-us" className="contact-pop-over">
                    <h4>Sales Support Specialist</h4>
                    <div className='menu'>
                        {salesSupportSpecialist.firstName &&
                            <a onClick={() => { setVisibleSSSContact(!visibleSSSContact) }}>{salesSupportSpecialist.firstName} {salesSupportSpecialist.lastName}</a>
                        }
                        {visibleSSSContact &&
                            <div className="contact-info">
                                <div className='backSplash clear' onClick={() => { setVisibleSSSContact(false); }} />
                                <div className='pop-over'>
                                    <div className='menu'>
                                        {salesSupportSpecialist.phone &&
                                            <div className='menu-item'><Phone className='icon' /><a href={`tel:${salesSupportSpecialist.phone}`}>{salesSupportSpecialist.phone}</a></div>
                                        }
                                        {salesSupportSpecialist.email &&
                                            <div className='menu-item'><AtSign className='icon' /><a href={`mailto:${salesSupportSpecialist.email}`}>{salesSupportSpecialist.email}</a></div>
                                        }
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            }
            {accountOwner &&
                <div data-testid="contact-us" className="contact-pop-over">
                    <h4>Your Sales Rep</h4>
                    <div className='menu'>
                        {accountOwner.firstName &&
                            <a onClick={() => { setVisibleOwnerContact(!visibleOwnerContact) }}>{accountOwner.firstName} {accountOwner.lastName}</a>
                        }
                        {visibleOwnerContact &&
                            <div className="contact-info">
                                <div className='backSplash clear' onClick={() => { setVisibleOwnerContact(false); }} />
                                <div className='pop-over'>
                                    <div className='menu'>
                                        {accountOwner.phone &&
                                            <div className='menu-item'><Phone className='icon' /><a href={`tel:${accountOwner.phone}`}>{accountOwner.phone}</a></div>
                                        }
                                        {accountOwner.email &&
                                            <div className='menu-item'><AtSign className='icon' /><a href={`mailto:${accountOwner.email}`}>{accountOwner.email}</a></div>
                                        }
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            }
            


        </div>
    )
}