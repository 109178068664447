import { Card } from "../../core/Card"
import { Translation } from "../../core/Translation"
import { Column } from "../../core/Column"
import { Row } from "../../core/Row"
import { FileDownloadLink } from "./FileDownloadLink"

export const ShippingAddressDisplay = ({ globalOfferId, address, additionalShippingAddresses, purchaseOrderUploadAttachmentId, poNumber }) => {
    return (
        address ?
            <Card title={<Translation id='7469dfd8-be15-4ece-a8fc-2a42f3bd20bf' defaultMessage='Shipping Address' category='Card Title' />} >
                <Row>
                    <Column width={3}>
                        <p>{address.name}</p>
                        <p>{address.addressLine1} {address.addressLine2}</p>
                        <p>{address.postalCode}</p>
                    </Column>
                    <Column width={3}>
                        <p>{address.city}</p>
                        <p>{address.state}</p>
                        <p>{address.country}</p>
                    </Column>
                    <Column width={3}>
                        {poNumber &&
                            <p>PO# {poNumber}</p>
                        }
                        {(purchaseOrderUploadAttachmentId && parseInt(purchaseOrderUploadAttachmentId)) > 0 &&
                            <a aria-label="download pdf" href={`/api/order/${globalOfferId}/attachment/${purchaseOrderUploadAttachmentId}/download`}>{poNumber}</a>
                        }
                    </Column>
                </Row>
            </Card>
            : ''
    )
}

export const ShippingAddressesDisplay = ({ globalOfferId, address, additionalShippingAddresses, purchaseOrderUploadAttachmentId, poNumber }) => {
    console.log("additionalShippingAddresses: ", additionalShippingAddresses)
    console.log("address: ", address)
    return (
        additionalShippingAddresses ?
            <Card title={<Translation id='7469dfd8-be15-4ece-a8fc-2a42f3bd20bf' defaultMessage='Shipping Address' category='Card Title' />} >
                <Row>
                    <span className="additionalShippingInfo"><Translation id='a43e0ba6-6768-4db4-9d28-b14e527a3ea0' defaultMessage='To edit or remove a shipping address from this order, return to previous step' category='Label' /></span>
                </Row>
                <Row>
                    <Row className="selectedShippingRow primary" key={"shipaddr-row-primary"}>
                        <span className="address"> 1. {address.name}, {address.addressLine1} {address.addressLine2}, {address.city}, {address.state}, {address.postalCode}, {address.country} </span><span className="primaryLabel">Primary</span>
                    </Row>
                    {additionalShippingAddresses.map((field, index) => {
                        return (
                            < Row className="selectedShippingRow" key={"shipaddr-row" + index}>
                                <span className="address"> {index + 2}. {field.name}, {field.addressLine1} {address.addressLine2}, {field.city}, {field.state}, {field.postalCode}, {field.country} </span>
                            </Row>
                        )
                    })}
                </Row>
            </Card>
            :
            <Card title={<Translation id='7469dfd8-be15-4ece-a8fc-2a42f3bd20bf' defaultMessage='Shipping Address' category='Card Title' />} >
                <Row>
                    <Column width={3}>
                        <p>{address.name}</p>
                        <p>{address.addressLine1} {address.addressLine2}</p>
                        <p>{address.postalCode}</p>
                    </Column>
                    <Column width={3}>
                        <p>{address.city}</p>
                        <p>{address.state}</p>
                        <p>{address.country}</p>
                    </Column>
                    <Column width={3}>
                        {poNumber &&
                            <p>PO# {poNumber}</p>
                        }
                        {(purchaseOrderUploadAttachmentId && parseInt(purchaseOrderUploadAttachmentId)) > 0 &&
                            <a aria-label="download pdf" href={`/api/order/${globalOfferId}/attachment/${purchaseOrderUploadAttachmentId}/download`}>{poNumber}</a>
                        }
                    </Column>
                    <Column width={3}>
                        <p>{address.specialInstructions}</p>
                    </Column>
                </Row>
              
            </Card>
    )
}