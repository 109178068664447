import React, { Component } from 'react';
import { ApiDataAccess } from '../infrastructure/ApiDataAccess';

export class ExistingOfferThreeDModel extends Component {

    constructor(props) {
        super(props);

        console.log('XXXXXXXXXXXXXXX', this.props.width);

        this.width = this.props.width && this.props.width < 750 ? this.props.width : 750;
        this.height = this.props.height && this.props.height < 750 ? this.props.height : 750;

        this.state = {
            loaded: false,
            url: null
        }

        this.dataAccess = new ApiDataAccess('/api/Estimates');
    }

    componentDidMount() {
        this.setState({ url: null, loaded: false });
        this.dataAccess.get(`/offer/${this.props.offerKey}/3dmodel?viewWidth=${this.width}&viewHeight=${this.height}`)
            .then(data => {
                if (data && data.toLowerCase().startsWith('http')) {
                    this.setState({ url: data, loaded: true });
                }
                else {
                    this.setState({ url: null, loaded: true });
                    if (this.props.onError) {
                        this.props.onError();
                    }
                }
            })
            .catch(ex => {
                this.setState({ url: null, loaded: true });
                if (this.props.onError) {
                    this.props.onError();
                }
            });
    }



    render() {

        return (
            <div className='preview3d'>
                {/*this.props.settings.threeDModelEnabled === true &&*/
                    <div>
                        {this.state.loaded &&
                            <div>
                                {this.state.url
                                    ? <iframe scrolling="no" frameBorder="0" style={{ overflow: 'hidden', border: 0 }} src={this.state.url} width={this.width + 20} height={this.height + 20} >LOADED</iframe>
                                    : <p>3D Model is not availble for this offer.</p>
                                }
                            </div>
                        }
                        {!this.state.loaded &&
                            <img width={20} src={'/images/ajax-loader.gif'} />
                        }
                    </div>
                }
            </div>
        )
    }

}