import { useEffect, useContext } from "react";
import { React, useState } from "react";
import { useForm } from "react-hook-form";
import { ApiDataAccess } from "../../infrastructure/ApiDataAccess";
import { RegistrationSelectionDropdown } from "./RegistrationSelectionDropdown";
import { RequiredRegistrationTextbox } from "./RequiredRegistrationTextbox";
import { CapabilityName } from '../../infrastructure/Constants';
import { CapabilitiesContext } from '../../infrastructure/Contexts';
import { Language } from '../../infrastructure/Languages';
//import { useCheckoutContext } from "../../infrastructure/CheckoutContext";

export const RegistrationForm = ({ onSubmit, onError, submitError }) => {
    const language = useContext(Language);
    const registerDataAccess = new ApiDataAccess("/api/register");
    const capabilitiesContext = useContext(CapabilitiesContext);
    const loader = capabilitiesContext.getCapability(CapabilityName.Loader);

    //const { product } = useCheckoutContext();
    //const industryName = product && product.industryName ? product.industryName : "";
    //const industryCategoryName = product && product.industryCategoryName ? product.industryCategoryName : "";

    const {
        handleSubmit,
        control,
        setValue,
        formState: { errors },
    } = useForm({
        mode: "onChange",
        defaultValues: {
            firstName: "",
            lastName: "",
            phoneNumber: "",
            companyName: "",
            //industryCategory: industryName,
            //industryCategoryItem: industryCategoryName,
            taxArea: "",
            taxLocale: "",
        },
    });

    /*
    const [industryCategory, setIndustryCategory] = useState("");
    const [industryCategories, setIndustryCategories] = useState({});

    const [industryCategoryItem, setIndustryCategoryItem] = useState("");
    const [industryCategoryItems, setIndustryCategoryItems] = useState({});
    */

    const [taxArea, setTaxArea] = useState("");
    const [taxAreas, setTaxAreas] = useState({});

    const [taxLocale, setTaxLocale] = useState("");
    const [taxLocales, setTaxLocales] = useState({});

    const [loaded, setLoaded] = useState(false);

    /*
    const onIndustryCategoryChanged = (e) => {
        setIndustryCategory(e.target.value);
        setValue("industryCategory", e.target.value, {
            shouldValidate: true
        });
        setIndustryCategoryItem("");
        setValue("industryCategoryItem", "");
    };

    const onIndustryCategoryItemChanged = (e) => {
        setIndustryCategoryItem(e.target.value);
        setValue("industryCategoryItem", e.target.value, {
            shouldValidate: true,
        });
    };
    */

    const onTaxAreaChanged = (e) => {
        setTaxArea(e.target.value);
        setValue("taxArea", e.target.value, {
            shouldValidate: true,
        });

        if (taxLocales[e.target.value] && taxLocales[e.target.value].length === 1) {
            setTaxLocale(taxLocales[e.target.value][0]);
            setValue("taxLocale", taxLocales[e.target.value][0], {
                shouldValidate: true,
            });
        } else {
            setTaxLocale("");
            setValue("taxLocale", "", {
                shouldValidate: true,
            });
        }
    };

    const onTaxLocaleChanged = (e) => {
        setTaxLocale(e.target.value);
        setValue("taxLocale", e.target.value, {
            shouldValidate: true,
        });
    };

    useEffect(() => {
        const init = async () => {
            loader.show();
            /*
            var categoriesResponse = await registerDataAccess.get("/categories");
            let categories = categoriesResponse.values.map((item, x) => {
                return item.value;
            });

            setIndustryCategories(categories);
            setIndustryCategory(industryName);


            let categoryItems = {};
            categoriesResponse.values.forEach((item) => {
                categoryItems[item.value] = item.dependentListValues;
            });

            setIndustryCategoryItems(categoryItems);
            setIndustryCategoryItem(industryCategoryName);
            */

            var taxAreas = await registerDataAccess.get("/taxareas");
            let taxLocales = {};
            taxAreas.values.forEach((item) => {
                taxLocales[item.value] = item.dependentListValues;
            });

            setTaxAreas(Object.keys(taxLocales));
            setTaxLocales(taxLocales);

            setLoaded(true);
            loader.hide();
        };
        init();
    }, []);

    const onSubmitForm = (data, e) => {
        onSubmit(data, e);
    };

    const onFormError = (errors, e) => {
        onError(errors, e);
    };

    return (
        loaded && (
            <div>
                <h3>User Account Registration</h3>
                <form className="registration-container" onSubmit={handleSubmit(onSubmitForm, onFormError)}>
                    <div className="registration-grid-item">
                        <RequiredRegistrationTextbox
                            control={control}
                            placeHolder={language.translateLabel('ce014de7-4ecf-4960-b753-d9e253234b3c', 'First Name', 'Label')}
                            label={language.translateLabel('ce014de7-4ecf-4960-b753-d9e253234b3c', 'First Name', 'Label')}
                            rules={{ required: language.translateLabel('6a5500e5-d62a-40e0-9efa-0a10e5377643', 'A first name is required', 'Label') }}
                            name="firstName"
                        />
                    </div>
                    <div className="registration-grid-item">
                        <RequiredRegistrationTextbox
                            control={control}
                            rules={{ required: language.translateLabel('ef36e4e4-557d-4f8f-bd99-b4d2588bcc28', 'A last name is required', 'Label') }}
                            placeHolder={language.translateLabel('7c4ad56e-ce11-47eb-97de-2758d479cebf', 'Last Name', 'Label')}
                            label={language.translateLabel('7c4ad56e-ce11-47eb-97de-2758d479cebf', 'Last Name', 'Label')}
                            name="lastName"
                        />
                    </div>

                    <div className="registration-grid-item">
                        <RequiredRegistrationTextbox
                            control={control}
                            rules={{ required: language.translateLabel('8706a12f-da9f-40bd-a755-2875df16ce00', 'A legal company name is required', 'Label') }}
                            placeHolder={language.translateLabel('415f8121-836c-428d-97d0-dcf423f249af', 'Legal Company Name', 'Label')}
                            label={language.translateLabel('415f8121-836c-428d-97d0-dcf423f249af', 'Legal Company Name', 'Label')}
                            name="companyName"
                        />
                    </div>

                    <div className="registration-grid-item">
                        <RequiredRegistrationTextbox
                            control={control}
                            placeHolder={language.translateLabel('8c741df5-4799-4cd7-9bae-9226f4f1bcfa', 'Contact phone number', 'Label')}
                            label={language.translateLabel('8c741df5-4799-4cd7-9bae-9226f4f1bcfa', 'Contact phone number', 'Label')}
                            rules={{ required: language.translateLabel('b7e01269-47ef-4aa8-82f0-21c54e0467d0', 'A contact phone number is required', 'Label') }}
                            name="phoneNumber"
                        />
                    </div>
                    {/*
                    <div className="registration-grid-item">
                        <RegistrationSelectionDropdown
                            data={industryCategories}
                            value={industryCategory}
                            control={control}
                            label={"Product Name"}
                            rules={{ required: "Industry category is required" }}
                            name="industryCategory"
                            onValueChanged={(e) => onIndustryCategoryChanged(e)}
                        />
                    </div>
                    <div className="registration-grid-item">
                        <RegistrationSelectionDropdown
                            value={industryCategoryItem}
                            label={"Industry Category"}
                            data={industryCategoryItems[industryCategory]}
                            control={control}
                            rules={{ required: "Industry sub-category is required" }}
                            name="industryCategoryItem"
                            onValueChanged={(e) => onIndustryCategoryItemChanged(e)}
                        />
                    </div>
                    */}
                    <div className="registration-grid-item">
                        <RegistrationSelectionDropdown
                            value={taxArea}
                            data={taxAreas}
                            control={control}
                            label={language.translateLabel('429fa5e8-451b-47d7-969e-2c61bb087264', 'Tax Country', 'Label')}
                            rules={{ required: language.translateLabel('c3bd073f-375b-49b9-ad1b-bb8805048500', 'A Tax Country is required', 'Label') }}
                            name="taxArea"
                            onValueChanged={(e) => onTaxAreaChanged(e)}
                        />
                    </div>
                    <div className="registration-grid-item">
                        <RegistrationSelectionDropdown
                            value={taxLocale}
                            label={language.translateLabel('ed4f56df-095b-4ad3-9533-c8580150a56e', 'Tax Area', 'Label')}
                            data={taxLocales[taxArea]}
                            control={control}
                            rules={{ required: language.translateLabel('e0ebd147-dd1a-483c-8829-eb1f952be64e', 'A Tax Area is required', 'Label') }}
                            name="taxLocale"
                            onValueChanged={(e) => onTaxLocaleChanged(e)}
                        />
                    </div>
                    {submitError && submitError.length > 0 && (
                        <div className="registration-notifications">
                            <ul className="registration-validation-error">
                            {submitError.map((item, idx) => <li key={idx}>{item}</li>)}
                            </ul>
                        </div>
                    )}
                    <div className="registration-submit">
                        <button type="submit" className="btn btn-primary" disabled={!loaded }>
                            Register
                        </button>
                    </div>
                </form>
            </div>
        )
    );
};
