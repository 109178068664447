import React, { Component } from 'react';
import { DownloadLink } from '../../core/DownloadLink';
import { Translation } from '../../core/Translation';

import './ArtworkInstructions.css';

export class ArtworkInstructions extends Component {
    static displayName = ArtworkInstructions.name;

    constructor(props) {
        super(props);

        this.state = {
            article: null
        }

        this.props.orderPlacementApi.getDielineInstructions(this.props.offerKey)
            .then(r => {
                if (r.hasSuccess) {                    
                    this.setState({
                        article: r.response
                    });                    
                }
            });
    }


    render() {
        return (
            <div>
                {this.state.article &&
                    <div>
                        <div className='article-header'>
                            <DownloadLink className='pull-right' href={`api/article/${this.props.offerKey}/dieline/instructions/pdf`}><Translation id='fa84beb7-b8c4-436e-802b-ef7adc6be11d' defaultMessage='Download' category='Label' /></DownloadLink>
                            <h3>{this.state.article.name}</h3>
                        </div>
                        <div className='content' dangerouslySetInnerHTML={{ __html: this.state.article.html }} />
                    </div>
                }
            </div>
        );
    }
}

