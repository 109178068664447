import React from "react";
import { ArrowDownCircle } from "react-feather";

export const DownloadLink = (props) => {
    const displayName = DownloadLink.name;

    const onClick = () => {
        if (props.onClick) {
            props.onClick();
        }
    };

    return (
        <div className={props.className ? "iconLink " + props.className : "iconLink"}>
            <a
                href={props.href}
                download
                onClick={() => {
                    onClick();
                }}
            >
                <ArrowDownCircle className="icon" />
                {props.children}
            </a>
        </div>
    );
};
