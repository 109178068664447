import React, { useState, useContext, useEffect } from "react";

import { Textbox } from "../../core/Textbox";
import { RadioButton } from "../../core/RadioButton";
import { SectionTitle } from "../../core/SectionTitle";
import { Translation } from "../../core/Translation";
import { Language } from "../../infrastructure/Languages";

export const RollRestrictionEditor = ({
    offer,
    onChange,
    register,
    errors,
    getValues,
    offerKey,
    orderPlacementApi,
    measureUnitId
}) => {
    const language = useContext(Language);
    
    const isMetric = offer.dimensions && offer.dimensions.measureUnitId != 13 ? true : false;


    const onRestrictionSelected = (evt) => {
        let value = evt.target.value;
        //setRestrictionId(value);
        // this.setState({ restrictionId: value });

        if (onChange) {
            onChange(1, null);
            onChange(2, null);
        }
    };

    const onMaxDiameterChanged = (value) => {
        if (onChange) {
            onChange(1, value ? value : null);
        }
    };

    const onMaxImpressionsChanged = (value) => {
        if (onChange) {
            onChange(2, value ? value : null);
        }
    };

    return (
        <div>
            <div onChange={onRestrictionSelected}>
                <label className="controlContainer radiobutton">
                    <input
                        className="control radiobutton"
                        type="radio"
                        {...register("rollstockTypeSelection")}
                        value="1"
                        required
                    />
                    <div className="payload">
                        <Translation
                            id="98e025f0-d03f-4bc1-a4b6-8a7ab2d95773"
                            defaultMessage="Maximum Overall Diameter (OD)"
                            category="Label"
                        />
                    </div>
                </label>
                <label className="controlContainer radiobutton">
                    <input
                        className="control radiobutton"
                        type="radio"
                        {...register("rollstockTypeSelection")}
                        value="2"
                    />
                    <div className="payload">
                        <Translation
                            id="0eae5347-368c-4caa-b641-972670b2d643"
                            defaultMessage="Maximum Impressions per Roll"
                            category="Label"
                        />
                    </div>
                </label>
            </div>

            {getValues("rollstockTypeSelection") === "1" && (
                <div>
                    <SectionTitle
                        required
                        offerKey={offerKey}
                        cms="rollstock-max-diameter"
                        orderPlacementApi={orderPlacementApi}
                        imageUrl="https://epacflexibles.com/wp-content/uploads/2020/02/Closures.png"
                    >
                        {language.translateLabel("d4ec26aa-2f1b-4f95-ba1d-1934fa524507", "Max. Diameter")}(
                        {isMetric ? "mm" : "in"})
                    </SectionTitle>
                    {/* <Textbox type={"number"} regex={"[0-9]*"} onChange={onMaxDiameterChanged} /> */}
                    <div>
                        <input
                            type="number"
                            step="0.01"
                            required
                            className="control textbox"
                            {...register("rollstockTypeValue", {
                                pattern: {
                                    value: "[0-9]*",
                                    message: "Must be numeric",
                                },
                            })}
                        />
                        <span className="error-message">
                            {errors && errors.rollstockTypeValue && errors.rollstockTypeValue.message}
                        </span>
                    </div>
                </div>
            )}

            {getValues("rollstockTypeSelection") === "2" && (
                <div>
                    <SectionTitle
                        required
                        offerKey={offerKey}
                        cms="rollstock-max-impressions"
                        orderPlacementApi={orderPlacementApi}
                        imageUrl="https://epacflexibles.com/wp-content/uploads/2020/02/Closures.png"
                    >
                        {language.translateLabel("af46391a-4c72-4a44-88af-251876a2e26a", "Max. Impressions")}
                    </SectionTitle>
                    {/* <Textbox type={"number"} regex={"[0-9]*"} onChange={onMaxImpressionsChanged} /> */}
                    <div>
                        <input
                            required
                            type="number"
                            className="control textbox"
                            {...register("rollstockTypeValue", {
                                pattern: {
                                    value: "[0-9]*",
                                    message: "Must be numeric",
                                },
                            })}
                        />
                        <span className="error-message">
                            {errors && errors.rollstockTypeValue && errors.rollstockTypeValue.message}
                        </span>
                    </div>
                </div>
            )}
        </div>
    );
};
