import React, { useEffect, useState, useContext } from 'react';
import { useParams } from 'react-router';
import { ApiDataAccess } from '../infrastructure/ApiDataAccess';
import { Breadcrumbs, Breadcrumb } from '../core/Breadcrumbs';
import { Carousel } from '../core/Carousel';
import { Tabs, Tab } from '../core/Tabs';
import { TileContainer, ButtonTile } from '../core/ImageTile';
import { Page } from '../core/Page';
import { TabContent, ActiveTab } from '../components/TabContent';
import { useCheckoutContext } from '../infrastructure/CheckoutContext';
import { Language } from '../infrastructure/Languages';
import { RenderModel } from '../components/ThreeDModel';
import { CapabilityName } from '../infrastructure/Constants';
import { CapabilitiesContext } from '../infrastructure/Contexts';
import { Screen } from '../infrastructure/Screen';


export const AccountAccessRequestApprovalPage = () => {

    const language = useContext(Language);
    const capabilitiesContext = useContext(CapabilitiesContext);
    const loader = capabilitiesContext.getCapability(CapabilityName.Loader);
    const [registrationInfo, setRegistrationInfo] = useState(null);
    const { key } = useParams();
    const [requestFailure, setRequestFailure] = useState(false);

    useEffect(() => {
        loader.show();
        approveAccessRequest(key);
    }, []);

    const approveAccessRequest = key => {
        var dataAccess = new ApiDataAccess('/api/accessRequest');
        dataAccess.post(`/${key}/approve`)
            .then(data => {
                setRegistrationInfo(data);
                loader.hide();
            })
            .catch(ex => {
                // redirect to home page?
                loader.hide();
                setRequestFailure(true);
                setRegistrationInfo(null);
            })
    }


    return (
        <div>

            <Breadcrumbs>
                <Breadcrumb title={language.translateDomain('cbe3c799-8b35-43fb-a934-3d353751ae9b', 0, 'Access Requested', 'Breadcrumb')} />
                <Breadcrumb title={language.translateDomain('cbe3c799-8b35-43fb-a934-3d353751ae9b', 1, 'Access Approved', 'Breadcrumb')} active={true} />
            </Breadcrumbs>

            <Page className='accountAccessRequest'>
                <h2>{language.translateLabel('3eaa8868-1ab6-47f7-b973-264a453fb16a', 'Access Request', 'Label')}</h2>

                {registrationInfo
                    ?
                    <div>
                        <p>{language.translateLabel('45b8b9b7-1728-447f-877e-7a7d4477f0b5', 'You successfully granted access to another user.', 'Label')}</p>
                    </div>
                    : requestFailure ? <div><p>{language.translateLabel('33cca4a4-2f57-4a31-b2a1-deb3ed039ca5', 'Your request was not processed. The link may be expired or an error ocurred processing your request.', 'Label')}</p></div>
                        : <div><p>{language.translateLabel('73824645-d2a6-4499-b490-180198e21a7c', 'Please wait while we are processing the approval request.', 'Label')}</p></div>
                }
            </Page>

        </div>
        )
}
