import React, { useEffect, useRef, useContext, useState } from 'react';
import { useParams } from 'react-router';
import { useNavigate } from 'react-router-dom';
import { Page } from '../core/Page';
import { CapabilityName } from '../infrastructure/Constants';
import { CapabilitiesContext } from '../infrastructure/Contexts';
import { Tabs, Tab } from "../core/Tabs";
import { Textbox } from "../core/Textbox";
import { TabContent, ActiveTab } from "../components/TabContent";
import { Language } from '../infrastructure/Languages';

import './AccountLogin.css';




export const AccountLogin = () => {
    const language = useContext(Language);
    const popup = useRef(null);
    const { region } = useParams();
    const navigate = useNavigate();   
    const [request, setRequest] = useState({ salesforceAccountId: "", name: "" });
    const capabilitiesContext = useContext(CapabilitiesContext);
    const loader = capabilitiesContext.getCapability(CapabilityName.Loader);

    useEffect(() => {

    }, []);



    const onChange = async (id, txt) => {
        console.log(txt)
        var r = request;
        r[id] = txt;
        console.log(request);
        setRequest(r);
        if (request.salesforceAccountId === '') {
            console.log("disable")
        }
        isValid();
    }

    const onSubmit = async () => {
        console.log("REQUEST: ", request);
    }

    const isValid = async () => {
        var result = false;
        if (request.salesforceAccountId === '' || request.name === '') {
            result = false;
        }
        else { result = true; }

        console.log("IS VASLID RESULTS: ", result)
        return result;
    }

    return (
        <Page className='accountLogin' onRenderHeader={() => {
            return (
                <>
                    <Tabs>
                        <Tab title={language.translateLabel('04ea9f38-1c48-4a34-9f44-ebbefbcc6436', 'Your Selections', 'Tab')}><TabContent activeTab={ActiveTab.Login} /></Tab>
                    </Tabs>

                </>
            )
        }} >

            <h2>Temporary Log In Screen!</h2>
            <Textbox placeholder='Enter salesforce account id' onChange={txt => { onChange("salesforceAccountId", txt) }} />
            <Textbox placeholder='Enter Opportunity Name' onChange={txt => { onChange("name", txt) }} />
            <button type="button" className="btn btn-secondary pull-right" onClick={(e) => onSubmit(e)}>{language.translateLabel('67faab93-8201-40e5-9261-1db130febea3', 'Proceed to Checkout', 'Button')}</button>


        </Page>
    )
}



