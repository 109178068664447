import React, { useEffect, useState, forwardRef } from 'react';
import { Search } from 'react-feather';

export const Searchbox = forwardRef(({ id, value, className, maxLength, delay, placeholder, onSubmit }, ref) => {

    const [val, setVal] = useState(value ? value : '');
    const [timeoutRef, setTimeoutRef] = useState(null);


    const submit = () => {
        if (onSubmit) {
            onSubmit(val);
        }
    }

    const setValue = v => {
        setVal(v);
    }

    const onKeyUp = evt => {        
        clearTimeout(timeoutRef);
        if (evt.keyCode === 13) {
            submit();
        }
        else {
            var timeout = setTimeout(() => {
                submit();
            }, delay ? parseInt(delay) : 1000);
            setTimeoutRef(timeout);
        }        
    }

    const onChange = evt => {
        setValue(evt.target.value);
    }

    return (
        <div className={`control search ${className ? className : ''}`}>
            <input id={id} type='text' className={'control textbox'} value={val} placeholder={placeholder ? placeholder : 'Search'} maxLength={maxLength} onKeyUp={onKeyUp} onChange={onChange} />
            <Search className='icon' onClick={() => { submit() }} />
        </div>
    )

});