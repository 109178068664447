import { useCallback, useEffect, useState, useContext } from "react";
import { useLocation, useMatch, useRoutes, useParams, Navigate, redirect, useNavigate } from "react-router-dom";
import { OrderCompleteLayout } from "../pages/offer/OrderCompleteLayout";
import { useUserContext } from './UserContext';
import OrderPlacementApi from "./OrderPlacementApi";
import { CapabilityName, OrderType } from "./Constants";
import { CapabilitiesContext } from "./Contexts";

export function useGlobalOfferId() {
    const { globalOfferId } = useParams();
    const [globalOffer, setGlobalOffer] = useState({
        plantId: 0,
        offerId: null,
        globalOfferId: null,
    });
    const [globalOfferParsingError, setGlobalOfferParsingError] = useState(false);
    useEffect(() => {
        console.log("useGlobalOfferId parse params", globalOfferId);
        if (globalOfferId) {
            if (typeof globalOfferId === "string") {
                var dash = globalOfferId.indexOf("-");
                if (dash > 0 && dash + 1 < globalOfferId.length) {
                    setGlobalOffer((previous) => {
                        const newState = {
                            ...previous,
                            ...{
                                plantId: globalOfferId.substring(0, dash),
                                offerId: globalOfferId.substring(dash + 1, globalOfferId.length),
                                globalOfferId: globalOfferId,
                            },
                        };
                        return newState;
                    });
                }
            }
        }

        setGlobalOfferParsingError(true);
    }, []);
    return globalOffer;
}

export function useLoadGlobalOfferId(globalOfferId) {
    const capabilitiesContext = useContext(CapabilitiesContext);
    const loader = capabilitiesContext.getCapability(CapabilityName.Loader);

    const [offer, setOffer] = useState(null);
    const [salesContact, setSalesContact] = useState(null);
    const [formattedSizeString, setFormattedSizeString] = useState(null);
    const [salesforceAccount, setSalesforceAccount] = useState(null);
    const [orderReference, setOrderReference] = useState(null);
    const [analyticsMetadata, setAnalyticsMetadata] = useState(null);
    const [checkoutIntentStateId, setCheckoutIntentStateId] = useState(null);
    const [offerLockStatus, setOfferLockStatus] = useState(null);
    const [error, setError] = useState(null);
    const [status, setStatus] = useState("init");
    const [orderIntent, setOrderIntent] = useState(null);
    const [orderConfiguration, setOrderConfiguration] = useState(null);
    const [backwardsCompatibilityOffer, setBackwardsCompatibilityOffer] = useState(null);
    const { updateSalesforceAccount } = useUserContext();
    const [termsConditionUrl, setTermsConditionUrl] = useState(null);
    const [customerSubmitDate, setCustomerSubmitDate] = useState(null);

    useEffect(() => {
        if (!globalOfferId) {
            console.log("Global offer id is empty or could not be parsed");
            return;
        }

        if (status !== 'init') {
            return;
        }

        setStatus("pending");
        const init = async () => {
            console.log("useLoadGlobalOfferId init");

            loader.show();

            const api = new OrderPlacementApi("/");
            const loadOffer = async () => {
                return await api.getOfferWithSalesContactAndArtwork(globalOfferId);
            };

            setError(null);
            setOffer(null);
            setSalesContact(null);
            setFormattedSizeString(null);
            setSalesforceAccount(null);
            setOrderReference(null);
            setOrderIntent(null);
            setTermsConditionUrl(null);

            var offer = await loadOffer();

            if (offer.hasSuccess) {
                setStatus("success");
                console.log("hook load offer", offer.response, offer.response.offer.currentStateId);
                let orderType = OrderType.Other;

                if (offer.response.offer.offer.testRollType) {
                    orderType = OrderType.TestRoll;
                }
                else if (offer.response.offer.offer.isPressProof) {
                    orderType = OrderType.PressProof;
                }

                setOffer(offer.response.offer.offer);
                setSalesforceAccount({
                    id: offer.response.offer.salesForceAccountId,
                    name: offer.response.offer.salesForceAccountName,
                });
                setSalesContact(offer.response.salesContact);
                updateSalesforceAccount(offer.response.salesContact);
                setFormattedSizeString(offer.response.formattedSizeString);
                setOrderReference({
                    customerName: offer.response.offer.salesForceAccountName,
                    orderNumber: offer.response.offer.offer.salesForceOpportunity.referenceNumber,
                    convertingMethod: offer.response.offer.offer.convertingMethod.value,
                    product: offer.response.offer.offer.product.value,
                    orderType: orderType
                });
                setAnalyticsMetadata(offer.response.analyticsMetadata);
                setOrderIntent(offer.response.orderIntent);
                setOrderConfiguration(offer.response.orderConfiguration);
                setBackwardsCompatibilityOffer(offer.response);
                setOfferLockStatus({
                    hold: false,
                    expired: offer.response.offer.currentStateId === 120 && (offer.response.orderIntent.id <= 0),
                    blocked: offer.response.canNotSellToCustomer,
                    invalidForPurchase: offer.response.invalidForPurchase,
                    salesForceQuoteStatus: offer.response.salesForceQuoteStatus
                });
                setCheckoutIntentStateId(offer.response.checkoutIntentStateId);
                setTermsConditionUrl(offer.response.offer.termsConditionUrl);
                setCustomerSubmitDate(offer.response.customerSubmitDate);
            } else if (!offer.hasSuccess) {
                if (offer.statusCode === 404) {
                    setStatus("notfound");
                }
                else {
                    setStatus("error");
                    setError(offer.messages);
                }
            }
            loader.hide();
        };

        init();
    }, [globalOfferId, status]);

    const refresh = () => setStatus("init");

    return {
        offer,
        salesContact,
        formattedSizeString,
        salesforceAccount,
        orderReference,
        error,
        status,
        orderIntent,
        orderConfiguration,
        backwardsCompatibilityOffer,
        analyticsMetadata,
        refresh,
        offerLockStatus,
        checkoutIntentStateId,
        termsConditionUrl,
        customerSubmitDate
    };
}

export function useGlobalOfferContext(globalOfferId) {
    const api = new OrderPlacementApi("/");

    const [offerContext, setOfferContext] = useState(null);
    const [offerCheckoutMetadata, setOfferCheckoutMetadata] = useState(null);
    const [loadOfferContextError, setError] = useState(null);
    const [loadOfferContextStatus, setStatus] = useState("init");
    const [saving, setSaving] = useState(false);

    useEffect(() => {
        if (!globalOfferId || loadOfferContextStatus !== "init") {
            return;
        }
        console.log("hook load offerContext pending");
        setStatus("pending");
        const init = async () => {
            const loadOfferContext = async () => {
                return await api.getOfferContext(globalOfferId);
            };

            setError(null);
            setOfferContext(null);

            var offerContext = await loadOfferContext();
            if (offerContext.hasSuccess) {
                setStatus("success");
                setOfferContext(offerContext.response.offerContext);
                console.log("hook load offerContext success!", offerContext.response);
            } else if (offerContext.statusCode === 404) {
                //not found
                console.log("hook load offerContext not found!");
                setStatus("success");
            } else {
                setStatus("error");
                setError(offerContext.messages);
            }
        };

        init();
    }, [globalOfferId, loadOfferContextStatus, offerContext]);

    useEffect(() => {
        if (!saving) {
            return;
        }
        console.log("save api call end begin", saving);
        const save = async () => {
            await api.updateOrderContext(globalOfferId, {
                metadata: offerContext,
            });
            setSaving(false);
            console.log("save api call end");
        };
        save();
    }, [saving, offerContext]);

    const reloadContext = async () => {
        setStatus("init");
    };

    const updateContext = (key, data) => {
        console.log("update " + key, data);
        setOfferContext((prev) => ({ ...prev, [key]: data }));
    };

    const updateCheckoutMetadata = (metadata) => {
        console.log("updated", { ...metadata, ...offerCheckoutMetadata });
        setOfferCheckoutMetadata((prev) => ({ ...prev, ...metadata }));
    };

    const save = async () => {
        //NOTE: This is here temporarily to force everything to version 1 for the initial release
        //This can be removed later when fully launched and all orders are using version 1
        updateContext("version", 1);
        updateContext("checkoutMetadata", offerCheckoutMetadata);
        console.log("Save initiated", offerContext);
        setSaving(true);
        // await api.updateOrderContext(globalOfferId, {
        //     metadata: offerContext,
        // });
    };

    const saveContext = async (payload, page) => {
        let updated = { ...offerContext, ...payload };

        //Force version 1 always, for now, even if the property isn't passed in
        updated.version = 1;

        if (page){
            updated.offerContextSource = page;
        }

        console.log("saving this payload", updated);

        var saveResponse = await api.updateOrderContext(globalOfferId, {
            metadata: updated,
        }, page);

        return saveResponse;
    };

    const submitCheckoutIntent = async () => {
        console.log("submitting for checkout");

        var response = await api.submitOrderForCheckout(globalOfferId);

        console.log("checkout intent submit response", response);

        return response;
    };

    const getEstimatedAmountDue = async (globalOfferId, payload) => {

        var response = await api.getEstimatedAmountDue(globalOfferId, payload)

        return response;
    };

    return {
        saving,
        offerContext,
        loadOfferContextError,
        loadOfferContextStatus,
        reloadContext,
        updateCheckoutMetadata,
        saveContext,
        submitCheckoutIntent,
        getEstimatedAmountDue
    };
}

export function useOrderPlacementRouter() {
    const globalOfferId = useGlobalOfferId();
    const {
        offer,
        salesContact,
        formattedSizeString,
        salesforceAccount,
        orderReference,
        status,
        orderIntent,
        orderConfiguration,
        backwardsCompatibilityOffer,
        analyticsMetadata,
        refresh,
        offerLockStatus,
        checkoutIntentStateId,
        termsConditionUrl,
        customerSubmitDate
    } = useLoadGlobalOfferId(globalOfferId.globalOfferId);

    const location = useLocation();
    const params = useParams();
    // const match = useMatch();

    const [initialized, setInitialized] = useState(false);
    const [route, setRoute] = useState(null);
    const [redirect, setRedirect] = useState(false);
    const [orderPlacementStateReference, setOrderPlacementStateReference] = useState("Checkout");
    const [orderPlacementStep, setOrderPlacementStep] = useState(null);

    useEffect(() => {

        if (status === "success") {
            if (!offer) {
                //Offer not found
                console.log(">>>> useRedirectForGlobalOfferState can't find offer", status, params, location);
                setRoute("/offer/404");
                setRedirect(true);
                setInitialized(true);
                return;
            }

            if (status === "notfound") {
                setRoute("/offer/404");
                setRedirect(true);
                setInitialized(true);
                setOrderPlacementStateReference("NotFound");
                return;
            }

            setInitialized(true);
        }
    }, [status]);

    return {
        route,
        globalOfferId,
        status,
        initialized,
        redirect,
        offer,
        salesContact,
        formattedSizeString,
        salesforceAccount,
        orderReference,
        backwardsCompatibilityOffer,
        orderIntent,
        orderConfiguration,
        orderPlacementStep,
        analyticsMetadata,
        orderPlacementStateReference,
        refresh,
        offerLockStatus,
        checkoutIntentStateId,
        termsConditionUrl,
        customerSubmitDate
    };
}
