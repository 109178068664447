import React, { useEffect, useRef, useContext, useState } from 'react';
import { Popup } from '../core/Popup';
import { DropdownList } from '../core/DropdownList';
import { SettingsContext } from '../infrastructure/Contexts';

import './Home.css';

export const HomePage = () => {
    const popup = useRef(null);    
    const settings = useContext(SettingsContext);

    useEffect(() => {
        const init = async () => {
            popup.current.show();
        }
        init();
    }, []);

    const closeModal = (region) => {
        popup.current.close();       
        document.location = `/${region.id}`;
    }

    const getRegions = () => {
        var result = [];
        settings.regions.forEach(item => {
            var region = {
                id: item.regionCulture.cultureString,
                name: item.regionCulture.name
            };
            result.push(region);
        });
        return result;
    }

    return (
        <div>
            <Popup ref={popup}>
                <RegionSelectModal regions={getRegions()} onSelected={(region) => { closeModal(region) }}></RegionSelectModal>
            </Popup>
        </div>
        )
}



export const RegionSelectModal = ({ regions, onSelected }) => {

    const [region, setRegion] = useState(null);

    const onChange = item => {
        setRegion(item);
    }

    const onClick = () => {
        if (onSelected) {
            onSelected(region);
        }
    }

    return (
        <div className='regionSelectModal'>
            <div className='topSection'>
                <div className='center'><h4>Your Region</h4></div>
            </div>

            <div className='contentSection'>
                <p>Please select your Region:</p>
                <DropdownList items={regions} onChange={item => { onChange(item) }} />

                <button className='btn btn-primary pull-right' disabled={!region} onClick={() => { onClick() }}>Select</button>
            </div>

        </div>
    )

}