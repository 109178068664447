import { ApiDataAccess } from "../../infrastructure/ApiDataAccess";
import _ from "lodash";

export class FormatProvider {
    static Current = null;

    static Get(name) {
        return FormatProvider.Current && FormatProvider.Current[name] ? FormatProvider.Current[name] : name;
    }

    static Format(name, t0) {
        var txt = FormatProvider.Get(name);
        return txt.replace("{0}", t0);
    }

    static Format(name, t0, t1) {
        var txt = FormatProvider.Get(name);
        return txt.replace("{0}", t0).replace("{1}", t1);
    }

    static Format(name, t0, t1, t2) {
        var txt = FormatProvider.Get(name);
        return txt.replace("{0}", t0).replace("{1}", t1).replace("{2}", t2);
    }

    static LoadAsync(culture) {
        return new Promise((resolve) => {
            var language = new ApiDataAccess("/api/Localization");
            language.get("/", culture).then((items) => {
                FormatProvider.Current = items;
                resolve(FormatProvider.Current);
            });
        });
    }

    static FormatNumberWithCommas(number) {
        var num = parseInt(number);
        var formattedNumber = num.toLocaleString();
        return formattedNumber;
    }

    static FormatDecimalWithCommas = (number, precision) => {
        var formatted = _.round(number, precision).toLocaleString(undefined, {
            minimumFractionDigits: 2,
        });
        return formatted;
    };
}
