import React, { useEffect, useState, useContext } from 'react';
import { useParams } from 'react-router';
import { useTracking } from "react-tracking";
import { useCookies } from 'react-cookie';
import { useNavigate, useLocation } from 'react-router-dom';
import { ShoppingBreadcrumbs } from '../core/Breadcrumbs';
import { Carousel } from '../core/Carousel';
import { Tabs, Tab } from '../core/Tabs';
import { TileContainer, ButtonTile } from '../core/ImageTile';
import { Page } from '../core/Page';
import { TabContent, ActiveTab } from '../components/TabContent';
import { ApiDataAccess } from '../infrastructure/ApiDataAccess';
import { useCheckoutContext } from '../infrastructure/CheckoutContext';
import { Language } from '../infrastructure/Languages';
import { RenderModel } from '../components/ThreeDModel';
import { CapabilityName } from '../infrastructure/Constants';
import { CapabilitiesContext } from '../infrastructure/Contexts';
import { Screen } from '../infrastructure/Screen';
import { useUserContext } from '../infrastructure/UserContext';

import { GoogleAnalyticsContext } from '../infrastructure/GoogleAnalyticsContext';

import './ConvertingMethod.css';


export const ConvertingMethodPage = () => {
    const { group } = useParams();
    const language = useContext(Language);
    const capabilitiesContext = useContext(CapabilitiesContext);
    const loader = capabilitiesContext.getCapability(CapabilityName.Loader);
    const { userRegion } = useUserContext();
    const { trackEvent } = useTracking({ page: "ConvertingMethodPage" });

    const navigate = useNavigate();
    const location = useLocation();
    const convertingMethodsDataAccess = new ApiDataAccess('/api/convertingmethods');
    const [searchResults, setSearchResults] = useState(null);
    const { product, updateConvertingMethod, updateDimensions, updateMaterial, updateFeature, updateSkuCount, updateCheckoutFlag, convertingMethod } = useCheckoutContext();
    const [cookies, setCookie] = useCookies(['visitorId']);
    const [visitorId, setVisitorId] = useState(null);
    const googleAnalyticsContext = new GoogleAnalyticsContext('order_shopping_1', cookies.visitorId, product.industryName, product.industryCategoryName);

    useEffect(() => {
        load(group);

        var id = cookies.visitorId;
        if (id) {
            setVisitorId(id);
        }

        Screen.scrollToElement('layout');
    }, [location.pathname]);


    const load = id => {
        loader.show();
        setSearchResults(null);
        convertingMethodsDataAccess.get(`/productGroup/${id}`)
            .then(items => {
                console.log('CONVERTING METHODS', items);
                var validItems = getConvertingMethodsHavingStandardDimensions(items);

                setSearchResults(validItems);
                loader.hide();
            })
            .catch(ex => {
                loader.hide();
                setSearchResults(null);
                navigate(`/${userRegion}/search`, { replace: true });
            });
    }

    const getConvertingMethodsHavingStandardDimensions = (items) => {
        var result = {};
        Object.keys(items).forEach(key => {
            var convertingMethods = items[key].filter(e => e.standardDimensions && e.standardDimensions.length > 0);
            if (convertingMethods && convertingMethods.length > 0) {
                result[key] = convertingMethods;
            }
        });
        return result;
    }


    const onSelected = item => {

        if (convertingMethod && convertingMethod.convertingMethodId !== item.id) {
            updateDimensions(null);
            updateMaterial(null);
            updateFeature(null);
            updateSkuCount(null);
            updateCheckoutFlag(false);
        }

        var model = {
            convertingMethodId: item.id,
            convertingMethodName: item.value,
            convertingMethodDescription: item.description,
            componentTypeId: item.componentType.id,
            componentTypeName: item.componentType.value,
            standardDimensions: item.standardDimensions,
            customDimensions: [],
            eyemarkDefault: item.eyemarkDefault,
            eyemarkMeasurement: item.eyemarkMeasurement,
            bleedMeasurement: item.bleedMeasurement,
            minPouchGusset: item.minPouchGusset,
            maxPouchGusset: item.maxPouchGusset,
            minPouchLength: item.minPouchLength,
            maxPouchLength: item.maxPouchLength,
            minPouchWidth: item.minPouchWidth,
            maxPouchWidth: item.maxPouchWidth,
            gussetRequired: item.gussetRequired,
            lengthRequired: item.lengthRequired,
            widthRequired: item.widthRequired,
            onlyStandardDimensionsAllowed: item.onlyStandardDimensionsAllowed,
            skuMetadata: {
                minSkuCount: item.minSkuCount,
                minSkuQuantity: item.minSkuQuantity,
                maxSkuCount: item.maxSkuCount,
                maxSkuQuantity: item.maxSkuQuantity
            }
        }

        updateConvertingMethod(model);

        trackEvent({
            event: 'ordering_tool_interactions',
            eventProps: {
                'step': 'order_shopping_1',        // order_shopping_1
                'product_category': product.industryName,        // e.g. Food & Beverage
                'product_sub_category': product.industryCategoryName, // E.g. Dry Mix
                'selection': model.convertingMethodName,        // e.g. 2 Side Seal Pouch
                'element': 'choose_this_style', // e.g. choose_this_style, information, photo_scroll, back
            },
            userId: visitorId
        });
        trackEvent({
            event: 'ordering_tool_selections',
            eventProps: {
                'step': 'order_shopping_1',        // order_shopping_1
                'product_category': product.industryName,        // e.g. Food & Beverage
                'product_sub_category': product.industryCategoryName, // E.g. Dry Mix
                'selection': model.convertingMethodName,        // e.g. 2 Side Seal Pouch
            },
            userId: visitorId
        });

        navigate(`/${userRegion}/group/${group}/convertingMethod/${item.id}/dimensions`);
    }

    const getImageUrl = (item, idx) => {
        return language.imageUrl('c1d0a342-4463-4cca-8bb7-1c323b9942c4', `${item.id}_${idx}`, `Product Group - ${item.value}`, `Carousel Image ${idx}`);
    }

    const onBackButtonClicked = () => {
        trackEvent({
            event: 'ordering_tool_interactions',
            eventProps: {
                'step': 'order_shopping_1',        // order_shopping_1
                'product_category': product.industryName,        // e.g. Food & Beverage
                'product_sub_category': product.industryCategoryName, // E.g. Dry Mix
                'element': 'back', // e.g. choose_this_style, information, photo_scroll, back
            },
            userId: visitorId
        });

        navigate(`/${userRegion}${product.url}`);
    }


    return (
        <div>

            <ShoppingBreadcrumbs active={0} />

            <Page className='convertingMethod' onRenderHeader={() => {

                return (
                    <Tabs>
                        <Tab title={language.translateLabel('04ea9f38-1c48-4a34-9f44-ebbefbcc6436', 'Your Selections', 'Tab')}><TabContent activeTab={ActiveTab.ConvertingMethod} /></Tab>
                    </Tabs>
                )
            }} >

                <h2>{language.translateLabel('2fe0275e-01cb-4980-aa4f-b6b69c173636', 'What type of pouch would you like?')}</h2>
                {searchResults &&
                    Object.keys(searchResults).map((key, idx) => {
                        return (
                            <div className='componentType' key={`section${idx}`}>
                                <h3 className='text-align-center'>{language.translateDomain('7a44adbe-b16c-4ce0-baf6-f6f6ccb1027b', searchResults[key][0].componentType.id, key, 'Component Type')}</h3>
                                <TileContainer>
                                    {searchResults[key].map((item, idx) => {
                                        return (
                                            <ButtonTile key={`tile_${idx}`} className={convertingMethod && convertingMethod.convertingMethodId == item.id ? 'active-border' : ''} height={420} text={language.translateLabel('e34ecdbd-00a0-437d-9ae8-be2fd969107d', 'Choose this style', 'Button')} onClick={() => { onSelected(item) }} helpContext={`Converting Method ${item.value}`} gaContext={googleAnalyticsContext.CreateContext(item.value)}>
                                                <div className='tileContent'>
                                                    <CarouselProvider item={item} gaContext={googleAnalyticsContext.CreateContext(item.value)} />
                                                    <h3 className='text-align-center'>{language.translateDomain('cebe10d4-8c6e-4b94-84ae-60d7ce2c47d8', item.id, item.value, 'Product Group')}</h3>
                                                    <p>{language.translateDomain('4e68728d-729b-47db-b530-af0b97925c15', item.id, item.description, 'Product Group Description')}</p>
                                                </div>
                                            </ButtonTile>
                                        )
                                    })}
                                </TileContainer>
                            </div>
                        )
                    })}

                {product &&
                    <button className='btn btn-tertiary-outline back' onClick={() => { onBackButtonClicked() }}><span className='btn-text'>{language.translateLabel('48cda936-8f88-4703-a02c-69648eb3fa2a', 'Back', 'Button')}</span></button>
                }
            </Page>
        </div>
    )
}

export const CarouselProvider = ({ item, gaContext }) => {

    const language = useContext(Language);
    const [urls, setUrls] = useState([]);

    useEffect(() => {
        init();

    }, []);


    const init = async () => {
        var u = [];

        var url = null;
        for (var i = 0; i < 4; i++) {
            url = getImageUrl(item, i);
            if (url) {
                u.push({ type: 'static', url: url });
            }
        }

        url = await RenderModel.GetModelUrlAsync({ ProductGroupType: RenderModel.MapProductGroupType(item.id) });
        if (url) {
            u.push({ type: '3d', url: url });
        }

        setUrls(u);
    }


    const getImageUrl = (item, idx) => {
        console.log("GET IMAGE")
        return language.imageUrl('c1d0a342-4463-4cca-8bb7-1c323b9942c4', `${item.id}_${idx}`, `Product Group - ${item.value}`, `Carousel Image ${idx}`);
    }


    return (
        <Carousel gaContext={gaContext}>
            {urls.map((item, idx) => {
                if (item.type == '3d')
                    return (<RenderModel key={`img${idx}`} url={item.url} length={220} width={150} gusset={50} viewWidth={220} viewHeight={220} />)
                else
                    return (<div key={`img${idx}`} className='photo' style={{ backgroundImage: `url("${item.url}")` }} />)
            })}
        </Carousel>
        )
}



