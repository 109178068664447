import React, { useContext } from "react";
import { Language } from "../../infrastructure/Languages";
import { Card } from "../../core/Card";
import { SelectableSkuLineItem } from "./SelectableSkuLineItem";
import { SectionTitle } from "../../core/SectionTitle";

import "./ArtworkSelectionDisplay.css";

export const ArtworkSelectionDisplay = ({ globalOrderId, skus, additionalSkus, onSkuSelectionChanged }) => {

    const language = useContext(Language);

    return (
        <div className="artwork-selection">
            <Card
                title={language.translateLabel('e683c64e-34b8-419b-9074-cf2538ee91b5', 'Artworks')}
                subtitle={language.translateLabel('ba0d05ed-e3aa-4bf8-8593-fa605119d496', 'Please select all the artworks to reorder')}>
                <SectionTitle cms="artwork-from-your-order">
                    {language.translateLabel('3902d8d3-b190-4799-9899-e2bbc49bb523', 'From Your Order')} (#{globalOrderId})
                </SectionTitle>
                <div className="sku-list-container">
                    {
                        skus.map((sku, idx) => {
                            return (
                                <div className="sku-list-item">
                                    <SelectableSkuLineItem key={`sku${idx}`} sku={sku} onClick={(sku, selected) => { onSkuSelectionChanged(sku, selected) }} />
                                </div>
                            )
                        })
                    }
                </div>

                {
                    additionalSkus && additionalSkus.length > 0 &&
                    <div>
                        <SectionTitle cms="artwork-from-other-order" >
                            {language.translateLabel('c49ce1be-4da3-415f-a188-774f59c3cc0d', 'From Other Orders')}
                        </SectionTitle>
                        <div className="sku-list-container">
                            {
                                additionalSkus.map((sku, idx) => {
                                    return (
                                        <div className="sku-list-item">
                                            <div>
                                                <SelectableSkuLineItem key={`sku${idx}`} sku={sku} onClick={(sku, selected) => { onSkuSelectionChanged(sku, selected) }} />
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                }
            </Card>
        </div>

    );
};