import React, { useState, useEffect, useContext, useRef } from 'react';
import { useMsal, useAccount } from '@azure/msal-react';
import { useNavigate } from 'react-router-dom';
import { Translation } from '../../core/Translation';
import { ShoppingBreadcrumbs } from '../../core/Breadcrumbs';
import { Card } from '../../core/Card';
import { Popup } from '../../core/Popup';
import { useGlobalOfferId, useGlobalOfferContext } from '../../infrastructure/GlobalOfferId';
import OrderPlacementApi from '../../infrastructure/OrderPlacementApi';
import { SecureApiDataAccess } from '../../infrastructure/SecureApiDataAccess';
import { CapabilitiesContext } from "../../infrastructure/Contexts";
import { CapabilityName } from "../../infrastructure/Constants";
import { ArtworkWaive } from '../../components/reorder/ArtworkWaive';

import "../offer/ArtworkUpload.css";

export const ArtworkReview = ({ offer, orderIntent, orderConfiguration }) => {

    const globalOfferId = useGlobalOfferId();
    const { offerContext, saveContext } = useGlobalOfferContext(globalOfferId.globalOfferId);
    const { accounts } = useMsal();
    const account = useAccount(accounts[0] || {});
    const capabilitiesContext = useContext(CapabilitiesContext);
    const loader = capabilitiesContext.getCapability(CapabilityName.Loader);
    const navigate = useNavigate();
    const popup = useRef(null);

    const reorderDataAccess = new SecureApiDataAccess("/api/reorder");
    const orderPlacementApi = new OrderPlacementApi("/");

    const [submitEnabled, setSubmitEnabled] = useState(!account);
    const [artworkApprovalWaived, setArtworkApprovalWaived] = useState(false);
    const [checklistArticle, setChecklistArticle] = useState(false);

    const paymentsDisabledOnRegion = orderConfiguration ? orderConfiguration.paymentsDisabled : false;
    var paymentCompleted = orderIntent.paymentStateId === 'COMPL' || orderIntent.paymentStateId === 'AWCHK';

    useEffect(() => {
        const loadData = async () => {
            getArtworkChecklist(globalOfferId.globalOfferId);
        }

        if (globalOfferId.globalOfferId)
            loadData();

    }, [offer, globalOfferId]);


    const getArtworkChecklist = async (globalOfferId) => {
        await orderPlacementApi.getArticleByName(globalOfferId, "dieline-checklist-pdf")
            .then(r => {
                if (r.hasSuccess) {
                    setChecklistArticle(r.response);
                }
            })
    }

    const onArtworkWaiveClick = async (value) => {
        setArtworkApprovalWaived(value);
        setSubmitEnabled(true);
    }

    const leavePage = async () => {
        offerContext.reorderDetailsCheckout.artworkPageViewed = true;
        saveContext(offerContext, null);

        if (paymentCompleted) {
            await orderPlacementApi.submitForOrderPlacement(globalOfferId.globalOfferId);
        }
        loader.hide();
        document.location = `/offer/${globalOfferId.globalOfferId}`;
    }

    const handleSubmit = async () => {
        loader.show();
        offerContext.reorderDetailsCheckout = {};
        if (artworkApprovalWaived) {
            var request = {
                emailAddress: account.username
            };
            await reorderDataAccess.postAsJson(`/offer/${globalOfferId.globalOfferId}/artwork`, request)
                .then(r => {
                    if (r.hasSuccess)
                    {
                        offerContext.reorderDetailsCheckout.artworkApprovalWaived = {
                            emailAddress: account.username,
                            dateTime: r.data.submitDateTime
                        };
                        leavePage();
                    }
                })
                .catch(e => {
                    popup.current.show();
                    loader.hide();
                })
        }
        else {
            leavePage();
        }
    }

    const render = () => {
        if (offer && checklistArticle) {
            return (
                <div>
                    <ShoppingBreadcrumbs active={8} ignore={offer.salesChannel === 'Estimator' ? 4 : 0} paymentsDisabled={paymentsDisabledOnRegion} />

                    <div className='artworkLayout'>
                        <Card
                            title={
                                <Translation
                                    id='547179e1-a4ba-4c2b-b8e5-b36e2feb4534'
                                    defaultMessage='Artwork'
                                    category='Card Title' />
                            }
                            subtitle={
                                <Translation
                                    id='6c14ae2b-b39b-4508-91d2-5d7a672fa172'
                                    defaultMessage='Please review your approved artwork for this order. You can view or download the artwork but you cannot upload new artwork for a reorder.'
                                    category='Card Sub Title' />
                            }>

                            <button className='btn btn-primary-outline' onClick={() => { document.location = `api/order/${globalOfferId.globalOfferId}/dieline/pdf`; }}>
                                <Translation id='8344101e-1add-4d46-a816-ec06799a48b2' defaultMessage='Download Dielines (Artwork Templates)' category='Label' />
                            </button>

                            <div className='flexContainer'>
                                {
                                    offer.reorderDetails.skus.map((sku, idx) => {
                                        return (
                                            <DownloadLink
                                                testIndex={idx}
                                                key={`tile${idx}`}
                                                sku={sku.description}
                                                defaultLabel={
                                                    <Translation id='fa84beb7-b8c4-436e-802b-ef7adc6be11d'
                                                        defaultMessage='Download'
                                                        category='Label'
                                                    />}
                                                id={sku.skuId}
                                                downloadUrl={sku.artwork}
                                                previewUrl={sku.thumbnail} />
                                        )
                                    })
                                }
                            </div>
                            <ArtworkWaive onSelection={onArtworkWaiveClick} />
                        </Card>
                    </div>
                    <button
                        className="btn btn-tertiary-outline back"
                        onClick={() => {
                            navigate(`/offer/${globalOfferId.globalOfferId}`);
                        }}
                    ><span className='btn-text'><Translation id='03473ff0-be47-4bd3-9ed8-190fedf344c5' defaultMessage='Back' category='Button' /></span>
                    </button>
                    <button
                        data-testid="order-placement-upload-artwork-finish-button"
                        type="submit"
                        className='btn btn-primary pull-right'
                        disabled={!submitEnabled}
                        onClick={async () => await handleSubmit()}>
                        Submit
                    </button>
                    <Popup closeOnClickAway={true} ref={popup}>
                        <div className='contentSection'>
                            Error during artwork submit
                        </div>
                    </Popup>
                </div>
            );
        }
        return <div></div>
    }

    return render();
}


export const DownloadLink = props => {
    return (
        <div className="uploadLink buttonTile">
            <div className="content">
                <h4>{props.sku}</h4>
                <img className="previewImage" src={props.previewUrl} />
            </div>
            <div className="footer">
                <a href={props.url}>
                    <button
                        data-testid={`order-placement-download-artwork-file-button-${props.testIndex}`}
                        className="btn btn-secondary"
                        onClick={() => { document.location = props.downloadUrl; }}>
                        <span> Download </span>
                    </button>
                </a>
            </div>
        </div>
    );
}