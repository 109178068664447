import React, { useEffect, useState, useContext, useRef, Component } from 'react';
import { useParams } from 'react-router';
import { useNavigate, useLocation } from 'react-router-dom';
import { ApiDataAccess } from '../infrastructure/ApiDataAccess';
import { ShoppingBreadcrumbs } from '../core/Breadcrumbs';
import { Carousel } from '../core/Carousel';
import { Tab, Tabs } from '../core/Tabs';
import { TileContainer, ButtonTile } from '../core/ImageTile';
import { Page } from '../core/Page';
import { SettingsContext } from '../infrastructure/Contexts';
import { TabContent, ActiveTab } from '../components/TabContent';
import { Popup } from '../core/Popup';
import { Textbox } from '../core/Textbox';
import { XSquare } from 'react-feather';
import { Language } from '../infrastructure/Languages';
import { PouchPreview, RenderModel } from '../components/ThreeDModel';
import { useCheckoutContext } from '../infrastructure/CheckoutContext';
import { CapabilityName, ProductGroup } from '../infrastructure/Constants';
import { CapabilitiesContext } from '../infrastructure/Contexts';
import { Screen } from '../infrastructure/Screen';
import { useUserContext } from '../infrastructure/UserContext';
import { useTracking } from "react-tracking";
import { useCookies } from 'react-cookie';

import './Dimensions.css';


export const DimensionsPage = () => {
    const { group } = useParams();
    const popup = useRef(null);
    const materialsDataAccess = new ApiDataAccess('/api/packagingmaterials');
    const settingsContext = useContext(SettingsContext);
    const { userRegion } = useUserContext();
    const navigate = useNavigate();
    const location = useLocation();
    const [filter, setFilter] = useState(null);
    const [searchResults, setSearchResults] = useState(null);
    const [formattedStandardDimensions, setFormattedStandardDimensions] = useState(null);
    const [formattedCustomDimensions, setFormattedCustomDimensions] = useState(null);
    const [measureUnitSymbol, setMeasureUnitSymbol] = useState(null);
    const [measureUnitId, setMeasureUnitId] = useState(null);
    const [hasCustomDimError, setCustomDimsError] = useState(false);
    const [isCustomDimsEnabled, setCustomDimsEnabledToggle] = useState(false);
    const [sort, setSort] = useState(0);
    const language = useContext(Language);
    const capabilitiesContext = useContext(CapabilitiesContext);
    const loader = capabilitiesContext.getCapability(CapabilityName.Loader);
    const { trackEvent } = useTracking({ page: "DimensionsPage" });
    const [cookies, setCookie] = useCookies(['visitorId']);
    const [visitorId, setVisitorId] = useState(null);

    const [unitName, setUnitName] = useState(null);

    const { convertingMethod, updateDimensions, updateMaterial, updateFeature, updateSkuCount, updateCheckoutFlag, dimensions, product } = useCheckoutContext();

    useEffect(() => {
        Screen.scrollToElement('layout');
        applyFilter({});

        var isCustomDimsEnabled = settingsContext.settings["ApiFeatureSettings:IsCustomDimensionsEnabled"] ? settingsContext.settings["ApiFeatureSettings:IsCustomDimensionsEnabled"] : false;
        setCustomDimsEnabledToggle(isCustomDimsEnabled);

        var id = cookies.visitorId;
        if (id) {
            setVisitorId(id);
        }
    }, [location.pathname]);


    const applyFilter = filter => {
        setSearchResults(null);

        if (convertingMethod) {
            var standardDims = convertingMethod.standardDimensions.length > 0 ? convertingMethod.standardDimensions : null;
            if (standardDims) {
                formatDimensions(standardDims);
            }

            if (dimensions) {
                if (dimensions.customdims) {
                    if (dimensions.customdims.length > 0) {
                        formatCustomDimensions(dimensions.customdims);
                    }
                }
            }
        }
    }


    const formatDimensions = dims => {
        var unitSymbol = formatUnitOfMeasureSymbol(dims[0].measureUnitId);
        setMeasureUnitSymbol(unitSymbol); // "
        setMeasureUnitId(dims[0].measureUnitId); //13

        var unitName = formatUnitOfMeasureName(dims[0].measureUnitId);
        setUnitName(unitName); // "IN.

        var formattedDimensions = [];
        dims.map((item, idx) => {

            var formattedLength = item.length + unitSymbol;
            var formattedWidth = item.width + unitSymbol;
            var formattedGusset = item.gusset ? item.gusset + unitSymbol : null;
            var formattedSize = `${formattedWidth} x ${formattedLength}${item.gusset ? " x " + formattedGusset : ""}`
            var dim = {
                length: item.length,
                width: item.width,
                gusset: item.gusset,
                formattedLength: formattedLength,
                formattedWidth: formattedWidth,
                formattedGusset: formattedGusset,
                bleed: item.bleed,
                eyemark: item.eyemark,
                measureUnitId: item.measureUnitId,
                formattedSize: formattedSize
            }
            formattedDimensions.push(dim);
        })
        setFormattedStandardDimensions(formattedDimensions);
    }


    const formatCustomDimensions = dims => {
        var unitSymbol = formatUnitOfMeasureSymbol(dims[0].measureUnitId);


        var formattedDimensions = [];
        dims.map((item, idx) => {

            var formattedLength = item.length + unitSymbol;
            var formattedWidth = item.width + unitSymbol;
            var formattedGusset = item.gusset ? item.gusset + unitSymbol : null;
            var formattedSize = `${formattedWidth} x ${formattedLength}${item.gusset ? " x " + formattedGusset : ""}`
            var dim = {
                length: item.length,
                width: item.width,
                gusset: item.gusset,
                formattedLength: formattedLength,
                formattedWidth: formattedWidth,
                formattedGusset: formattedGusset,
                bleed: item.bleed,
                eyemark: item.eyemark,
                measureUnitId: item.measureUnitId,
                formattedSize: formattedSize
            }
            formattedDimensions.push(dim);
        })

        setFormattedCustomDimensions(formattedDimensions);
    }



    const formatUnitOfMeasureSymbol = measureUnitId => {
        if (measureUnitId === 13) {
            return "\"";
        } else {
            return "mm";
        }
    }

    const formatUnitOfMeasureName = measureUnitId => {
        if (measureUnitId === 13) {
            return "in.";
        } else {
            return "mm";
        }
    }



    const evaluateStructureSpecOptions = async (filter, dims) => {

        filter['productGroup'] = product ? product.productGroupId : group;
        filter['componentType'] = convertingMethod ? convertingMethod.componentTypeId : null;
        filter['convertingMethods'] = convertingMethod ? [convertingMethod.convertingMethodId] : null;
        filter['pouchDimensions'] = {};
        if (convertingMethod) {
            filter['pouchDimensions'] = {
                width: dims.width,
                length: dims.length,
                gusset: dims.gusset,
                measureUnitId: convertingMethod.bleedMeasurement.uomId,
                bleed: convertingMethod.bleedMeasurement.value,
                eyemark: convertingMethod.eyemarkMeasurement.value
            };
            filter['eyemark'] = convertingMethod.eyemarkDefault.id;
        }

        var result = await materialsDataAccess.post(`/filter`, filter);

        console.log('RESULT', result);
        setFilter(result.filter);

        switch (sort) {
            case 0: {
                result.structureSpecs.sort((a, b) => (a.id > b.id) ? 1 : -1);
                break;
            };
            case 1: {
                result.structureSpecs.sort((a, b) => (a.value > b.value) ? 1 : -1);
                break;
            };
            case 2: {
                result.structureSpecs.sort((a, b) => (a.priceCategory > b.priceCategory) ? 1 : -1);
                break;
            };
        }

        //console.log("Structure Specs: ", result.structureSpecs)
        setSearchResults(result.structureSpecs);

        return result.structureSpecs;
    }


    const onSelected = async (item, customDims) => {

        if ((item && dimensions && dimensions.selectedDims && dimensions.selectedDims.formattedSize !== item.formattedSize) || (customDims && dimensions && dimensions.customDims && dimensions.customDims.formattedSize !== customDims.formattedSize)) {
            updateMaterial(null);
            updateFeature(null);
            updateSkuCount(null);
            updateCheckoutFlag(false);
        }

        var structureSpecs = await evaluateStructureSpecOptions({}, item);

        if (structureSpecs.length > 0) {
            var model = {
                customdims: customDims,
                selectedDims: item
            }

            updateDimensions(model);

            trackEvent({
                event: 'ordering_tool_interactions',
                eventProps: {
                    'step': 'order_shopping_2',        // order_shopping_2
                    'product_category': product.industryName,        // e.g. Food & Beverage
                    'product_sub_category': product.industryCategoryName, // E.g. Dry Mix                    
                    'selection': model.selectedDims.formattedSize,        // 3"x3"
                    "element": "choose_this_size"
                },
                userId: visitorId
            });
            trackEvent({
                event: 'ordering_tool_selections',
                eventProps: {
                    'step': 'order_shopping_2',        // order_shopping_2
                    'product_category': product.industryName,        // e.g. Food & Beverage
                    'product_sub_category': product.industryCategoryName, // E.g. Dry Mix                    
                    'selection': model.selectedDims.formattedSize,        // 3"x3"
                },
                userId: visitorId
            });

            navigate(`/${userRegion}/group/${group}/convertingMethod/${convertingMethod.convertingMethodId}/dimensions/material`);
        }
    }

    const openPopup = () => {
        popup.current.show();
    }

    const validate = async (dims) => {
        var structureSpecs = await evaluateStructureSpecOptions({}, dims);
        var isValid = structureSpecs.length > 0 ? true : false;
        return isValid;
    }

    const setCustomDimensions = async (data) => {
        var customDimensions = formattedCustomDimensions ? formattedCustomDimensions : [];
       // console.log("measureUnitSymbol", measureUnitSymbol)
        if (data.length && data.width) {

            var formattedLength = data["length"] + measureUnitSymbol;
            var formattedWidth = data["width"] + measureUnitSymbol;
            var formattedGusset = data["gusset"] ? data["gusset"] + measureUnitSymbol : null;
            var formattedSize = `${formattedWidth}x${formattedLength}${data["gusset"] ? "x" + formattedGusset : ""}`
            var dim = {
                length: data["length"],
                width: data["width"],
                gusset: data["gusset"],
                formattedLength: formattedLength,
                formattedWidth: formattedWidth,
                formattedGusset: formattedGusset,
                bleed: null,
                eyemark: null,
                measureUnitId: measureUnitId,
                formattedSize: formattedSize
            }
            var result = await validate(dim)
            if (result) {
                customDimensions.push(dim);
                setFormattedCustomDimensions(customDimensions);
                onSelected(dim, customDimensions);
                setCustomDimsError(false)
            }
            else {
                setCustomDimsError(true)
            }
        }
    }
    const onBackButtonClicked = () => {
        trackEvent({
            event: 'ordering_tool_interactions',
            eventProps: {
                'step': 'order_shopping_2',        // order_shopping_1
                'product_category': product.industryName,        // e.g. Food & Beverage
                'product_sub_category': product.industryCategoryName, // E.g. Dry Mix                
                'element': 'back', // e.g. choose_this_style, information, photo_scroll, back
            },
            userId: visitorId
        });

        navigate(`/${userRegion}/group/${product.productGroupId}/convertingMethod`);
    }

    return (
        <div>

            <ShoppingBreadcrumbs active={1} />

            <Page className='dimensions' onRenderHeader={() => {
                return (
                    <Tabs>
                        <Tab title={language.translateLabel('04ea9f38-1c48-4a34-9f44-ebbefbcc6436', 'Your Selections', 'Tab')}><TabContent activeTab={ActiveTab.Dimensions} /></Tab>
                    </Tabs>
                )
            }} >


                <PouchPreview request={{ ProductGroupType: RenderModel.MapProductGroupType(convertingMethod.convertingMethodId) }} />

                <h2>{language.translateLabel('83b3fa02-50d9-44ae-9fe5-1c35eca346db', 'What size pouch would you like?')}</h2>
                {!convertingMethod.onlyStandardDimensionsAllowed && isCustomDimsEnabled &&
                    <div className='footerContent'>
                        <div className='popupText'  ><span onClick={() => openPopup()}>{language.translateLabel('a6a7a08b-ca53-41b8-8b9d-b407e3047f58', 'Want a Custom Size?')}</span>
                            <Popup className='customDimensions' closeOnClickAway={true} ref={popup}>
                                <CustomDimensions language={language} onSave={setCustomDimensions} error={hasCustomDimError} convertingMethod={convertingMethod} popup={popup} unitName={unitName} unitSymbol={measureUnitSymbol} />
                            </Popup>

                        </div>
                    </div>}
                <div className='dimensions'>

                    {formattedStandardDimensions &&
                        <div>
                            <div>
                                <hr />
                                <h3>{language.translateLabel('afee1f39-5d64-4bf3-bc5d-53bed66080a1', 'Standard Sizes')}</h3>
                                <p className='text-align-center'>{language.translateLabel('aaaec139-42e7-4fa0-9728-82e7b79484ea', 'Standard sizes are optimized to reduce waste and give you the best possible price.')}</p>


                                <TileContainer>
                                    {formattedStandardDimensions.map((item, idx) => {
                                        return (
                                            <ButtonTile key={`tile_${idx}`} className={dimensions && dimensions.selectedDims && dimensions.selectedDims.formattedSize == item.formattedSize ? 'active-border' : ''} height={420} text={language.translateLabel('83b269ba-cbc8-4570-b788-1b5e055ae741', 'Choose This Size')} onClick={() => { onSelected(item, null) }} onInfoClick={() => { alert('selected') }}>
                                                <div className='tileContent'>
                                                    <PouchDimensionImage convertingMethod={convertingMethod} dims={item} />
                                                    <h3 className='text-align-center'>{item.formattedWidth} x {item.formattedLength}{item.gusset ? " x " + item.formattedGusset : null}</h3>
                                                </div>
                                            </ButtonTile>
                                        )
                                    })}
                                </TileContainer>
                            </div>
                        </div>
                    }
                    {formattedCustomDimensions && !convertingMethod.onlyStandardDimensionsAllowed && isCustomDimsEnabled &&
                        <div>
                            <div >
                                <hr />
                                <h3>{language.translateLabel('3312b2e7-3bae-4082-87bc-31b57fafd666', 'Custom Sizes')}</h3>

                                <TileContainer>
                                    {formattedCustomDimensions.map((item, idx) => {
                                        return (
                                            <ButtonTile key={`tile_${idx}`} className={dimensions && dimensions.customdims && dimensions.customdims.formattedSize == item.formattedSize ? 'active-border' : ''} height={420} text={language.translateLabel('83b269ba-cbc8-4570-b788-1b5e055ae741', 'Choose This Size')} onClick={() => { onSelected(item) }} onInfoClick={() => { alert('selected') }}>
                                                <div className='tileContent'>
                                                    <PouchDimensionImage convertingMethod={convertingMethod} dims={item} />
                                                    <h3>{item.formattedWidth} x {item.formattedLength}{item.gusset ? " x " + item.formattedGusset : null}</h3>
                                                </div>
                                            </ButtonTile>
                                        )
                                    })}
                                </TileContainer>
                            </div>
                        </div>
                    }

                </div>

                {product &&
                    <button className='btn btn-tertiary-outline back' onClick={() => { onBackButtonClicked() }}><span className='btn-text'>{language.translateLabel('48cda936-8f88-4703-a02c-69648eb3fa2a', 'Back', 'Button')}</span></button>
                }

            </Page >
        </div >
    )
}

export class CustomDimensions extends Component {
    constructor(props) {
        super(props);
        this.language = props.language;

        this.state = {
            width: 0,
            length: 0,
            gusset: 0,
            loading: false,
            widthError: null,
            lengthError: null,
            gussetError: null
        }

        this.dims = {
            'width': 0,
            'length': 0,
            'gusset': 0
        }
        this.convertingMethod = this.props.convertingMethod;
        this.unitName = this.props.unitName ? this.props.unitName : "in.";
        this.unitSymbol = this.props.unitSymbol ? this.props.unitSymbol : "\"";

        this.onSave = this.onSave.bind(this);
        this.setValue = this.setValue.bind(this);
        this.validateMeasurement = this.validateMeasurement.bind(this);
    }


    setValue(id, val) {
        if (id === 'length') {
            this.dims.length = val;
            this.setState({ lengthError: false });
            var isValid = this.validateMeasurement(this.convertingMethod.minPouchLength, this.convertingMethod.maxPouchLength, val);
            isValid ? this.setState({ lengthError: false, length: val }) : this.setState({ lengthError: true, length: val });
        }
        if (id === 'width') {
            this.dims.width = val;
            this.setState({ widthError: false });
            var isValid = this.validateMeasurement(this.convertingMethod.minPouchWidth, this.convertingMethod.maxPouchWidth, val);
            isValid ? this.setState({ widthError: false, width: val }) : this.setState({ widthError: true, width: val });
        }
        if (id === 'gusset') {
            this.dims.gusset = val;
            this.setState({ gussetError: false });

            var isValid = this.validateMeasurement(this.convertingMethod.minPouchGusset, this.convertingMethod.maxPouchGusset, val);
            isValid ? this.setState({ gussetError: false, gusset: val }) : this.setState({ gussetError: true, gusset: val });
        }
        this.setState({ customDims: this.dims });
    }

    formatUnitOfMeasureName(measureUnitId) {
        if (measureUnitId === 13) {
            return "in.";
        } else {
            return "mm";
        }
    }

    validateMeasurement(min, max, val) {
        if (val === "") {
            return false;
        }
        var validMin = min == null || val >= min.value ? true : false;
        var validMax = max == null || val <= max.value ? true : false;
        return validMin && validMax ? true : false;

    }


    onSave() {
        var self = this;
        this.props.onSave(self.state.customDims);
    }

    onValid(id, val) {
        var isValid = true;
        if (id === 'length') {
            isValid = this.validateMeasurement(this.convertingMethod.minPouchLength, this.convertingMethod.maxPouchLength, val);
        }
        if (id === 'width') {
            isValid = this.validateMeasurement(this.convertingMethod.minPouchWidth, this.convertingMethod.maxPouchWidth, val);
        }
        if (id === 'gusset') {
            isValid = this.validateMeasurement(this.convertingMethod.minPouchGusset, this.convertingMethod.maxPouchGusset, val);

        }
        return isValid;
    }

    isSubmitDisabled() {
        var result = true;
        var we = true;
        var le = true;
        var ge = true;

        if (this.props.convertingMethod.lengthRequired && this.props.convertingMethod.widthRequired && !this.props.convertingMethod.gussetRequired) {
            
            if (this.state.widthError != null && this.state.lengthError != null) {
                we = this.state.widthError;
                le = this.state.lengthError;
            }
            if (!we && !le) {
                return false;
            }
        }

        if (this.props.convertingMethod.lengthRequired && this.props.convertingMethod.widthRequired && this.props.convertingMethod.gussetRequired) {
         
            if (this.state.widthError != null && this.state.lengthError != null && this.state.gussetError!=null) {
                we = this.state.widthError;
                le = this.state.lengthError;
                ge = this.state.gussetError;
            }
            if (!we && !le && !ge) {
                return false;
            }
        }
        return result;
    }

    render() {
        return (
            <div>
                <div className="topSection">
                    <div className='left'></div>
                    <div className='center'><h4>{this.language.translateLabel('730a0b80-875e-4eab-aea4-0c8e09e95d09', 'Custom Size')} ({this.unitName})</h4></div>
                    <div className='right'><XSquare className='icon btn-close clickable' onClick={() => { this.props.popup.current.close() }} /></div>
                </div>
                <div className="contentSection">
                    <p className>{this.language.translateLabel('b8c62354-4d48-4af8-8553-0fe0a27b0ca5', 'Custom Sizing requires a higher minimum order amount')}</p>
                    <div className="inputs">
                        {this.props.convertingMethod.widthRequired &&
                            <div>
                                <div className="row">
                                    <Textbox requireValidInput={true} onValidating={(txt) => this.onValid('width', txt)} type='number' regex={'[0-9]*(\.[0-9]{0,4})?'} placeholder={this.language.translateLabel('b90c4955-66aa-4a89-a3d2-a0880df60953', 'Width')} id="width" required={true} onChange={txt => { this.setValue('width', txt) }} />
                                </div>
                                {this.state.widthError &&
                                    <div className="errors">
                                        {this.state.width < this.props.convertingMethod.minPouchWidth?.value &&
                                            <p>{this.language.translateLabel('d228438c-2cab-4d70-9d48-f9fbbf6b2f28', 'Min width allowed is ')} {this.props.convertingMethod.minPouchWidth.value} {this.unitName}</p>
                                        }
                                        {this.state.width > this.props.convertingMethod.maxPouchWidth?.value &&
                                            <p>{this.language.translateLabel('42eeff52-035b-4ad5-b5dc-8719ef62935b', 'Max width allowed is ')} {this.props.convertingMethod.maxPouchWidth.value}{this.unitName} </p>
                                        }

                                    </div>
                                }
                            </div>
                        }
                        {this.props.convertingMethod.lengthRequired &&
                            <div>
                                <div className="row">
                                    <Textbox requireValidInput={true} onValidating={(txt) => this.onValid('length', txt)} type='number' regex={'[0-9]*(\.[0-9]{0,4})?'} placeholder={this.language.translateLabel('031a867a-5538-4a1b-8d4a-29d3f96b7f99', 'Length')} id="length" required={true} onChange={txt => { this.setValue('length', txt) }} />
                                </div>
                                {this.state.lengthError &&
                                    <div className="errors">
                                        {this.state.length < this.props.convertingMethod.minPouchLength?.value &&
                                            <p>{this.language.translateLabel('9ce69515-2450-4d02-bba9-448fb963d8ae', 'Min length allowed is:')} {this.props.convertingMethod.minPouchLength.value}{this.unitName} </p>
                                        }
                                        {this.state.length > this.props.convertingMethod.maxPouchLength?.value &&
                                            <p>{this.language.translateLabel('29630726-6f70-47f1-977d-d338a2d433b1', 'Max length allowed is:')} {this.props.convertingMethod.maxPouchLength.value}{this.unitName} </p>
                                        }

                                    </div>
                                }
                            </div>
                        }

                        {this.props.convertingMethod.gussetRequired &&
                            <div>
                                <div className="row">
                                    <Textbox requireValidInput={true} onValidating={(txt) => this.onValid('gusset', txt)} type='number' regex={'[0-9]*(\.[0-9]{0,4})?'} placeholder={this.language.translateLabel('f5201e4c-56ab-46ac-92a3-79afc9fbbbb4', 'Gusset')} id="gusset" required={true} onChange={txt => { this.setValue('gusset', txt) }} />
                                </div>
                                {this.state.gussetError &&
                                    <div className="errors">
                                        {this.state.gusset < this.props.convertingMethod.minPouchGusset?.value &&
                                            <p>{this.language.translateLabel('9ff71e47-a7f3-425f-b00e-d898015b5bd7', 'Min gusset allowed is:')} {this.props.convertingMethod.minPouchGusset.value}{this.unitName} </p>
                                        }
                                        {this.state.gusset > this.props.convertingMethod.maxPouchGusset?.value &&
                                            <p>{this.language.translateLabel('1ad03975-62f0-44b2-a349-8f47c43d6307', 'Max gusset allowed is:')} {this.props.convertingMethod.maxPouchGusset.value}{this.unitName} </p>
                                        }

                                    </div>
                                }
                            </div>
                        }
                    </div>
                    <div className="display">
                        {this.state.width ? this.state.width : 'W'}{this.unitSymbol} x  {this.state.length ? this.state.length : 'L'}{this.unitSymbol}
                        {this.props.convertingMethod.gussetRequired ? this.state.gusset ? ' x ' + this.state.gusset + this.unitSymbol : ' x G' + this.unitSymbol : null}
                    </div>
                </div>
                <div className="footerSection">
                    <button className='btn btn-secondary btn-modal-full' disabled={this.isSubmitDisabled()} onClick={this.onSave}>{this.language.translateLabel('b5e8af97-25c9-413c-89a6-eb090846613b', 'Save', 'Button')}</button>
                </div>
                {this.props.error &&
                    <div className="errors">
                        <p>{this.language.translateLabel('81638386-25c9-4fac-a743-c08364128742', 'Please try different dimensions. We are unable to produce a pouch this size')}</p>
                    </div>
                }
            </div>
        )
    }
}

export const PouchDimensionImage = ({ dims, convertingMethod }) => {

    return (
        <>
            {convertingMethod.convertingMethodId === ProductGroup.ThreeSideSealPouch &&
                <div className='pouchDimensionImage ThreeSideSealPouch'>
                    <div className='dimensionImage' style={{ backgroundImage: 'url(/images/dimensions/threeSideSealPouch.png)' }}>
                        <span className='widthLabel'>{dims.formattedWidth}</span>
                        <span className='lengthLabel'>{dims.formattedLength}</span>
                        <span className='gussetLabel'>{dims.gusset ? dims.formattedGusset : null}</span>
                    </div>
                </div>
            }

            {convertingMethod.convertingMethodId === ProductGroup.TwoSideSealPouch &&
                <div className='pouchDimensionImage TwoSideSealPouch'>
                    <div className='dimensionImage' style={{ backgroundImage: 'url(/images/dimensions/twoSideSealPouch.png)' }}>
                        <span className='widthLabel'>{dims.formattedWidth}</span>
                        <span className='lengthLabel'>{dims.formattedLength}</span>
                        <span className='gussetLabel'>{dims.gusset ? dims.formattedGusset : null}</span>
                    </div>
                </div>
            }

            {convertingMethod.convertingMethodId === ProductGroup.PlowBottomPouch &&
                <div className='pouchDimensionImage PlowBottomPouch'>
                    <div className='dimensionImage' style={{ backgroundImage: 'url(/images/dimensions/plowBottomPouch.png)' }}>
                        <span className='widthLabel'>{dims.formattedWidth}</span>
                        <span className='lengthLabel'>{dims.formattedLength}</span>
                        <span className='gussetLabel'>{dims.gusset ? dims.formattedGusset : null}</span>
                    </div>
                </div>
            }

            {convertingMethod.convertingMethodId === ProductGroup.SkirtSealPouch &&
                <div className='pouchDimensionImage SkirtSealPouch'>
                    <div className='dimensionImage' style={{ backgroundImage: 'url(/images/dimensions/skirtSealPouch.png)' }}>
                        <span className='widthLabel'>{dims.formattedWidth}</span>
                        <span className='lengthLabel'>{dims.formattedLength}</span>
                        <span className='gussetLabel'>{dims.gusset ? dims.formattedGusset : null}</span>
                    </div>
                </div>
            }

            {convertingMethod.convertingMethodId === ProductGroup.Doyen &&
                <div className='pouchDimensionImage Doyen'>
                    <div className='dimensionImage' style={{ backgroundImage: 'url(/images/dimensions/doyen.png)' }}>
                        <span className='widthLabel'>{dims.formattedWidth}</span>
                        <span className='lengthLabel'>{dims.formattedLength}</span>
                        <span className='gussetLabel'>{dims.gusset ? dims.formattedGusset : null}</span>
                    </div>
                </div>
            }

            {convertingMethod.convertingMethodId === ProductGroup.QuadSealPouch &&
                <div className='pouchDimensionImage QuadSealPouch'>
                    <div className='dimensionImage' style={{ backgroundImage: 'url(/images/dimensions/quadSealPouch.png)' }}>
                        <span className='widthLabel'>{dims.formattedWidth}</span>
                        <span className='lengthLabel'>{dims.formattedLength}</span>
                        <span className='gussetLabel'>{dims.gusset ? dims.formattedGusset : null}</span>
                    </div>
                </div>
            }

            {convertingMethod.convertingMethodId === ProductGroup.FinSealPouch &&
                <div className='pouchDimensionImage FinSealPouch'>
                    <div className='dimensionImage' style={{ backgroundImage: 'url(/images/dimensions/finSealPouch.png)' }}>
                        <span className='widthLabel'>{dims.formattedWidth}</span>
                        <span className='lengthLabel'>{dims.formattedLength}</span>
                        <span className='gussetLabel'>{dims.gusset ? dims.formattedGusset : null}</span>
                    </div>
                </div>
            }


        </>
    )

}


