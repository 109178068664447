import React, { Component } from 'react';
import { Payment } from '../../core/Payment';

export class PaymentComponent extends Component {

    constructor(props) {
        super(props);

        this.paymentApiUrl = this.props.paymentApiUrl;
        this.provider = this.props.provider;
        this.paymentMethod = this.props.method;
        this.config = this.props.config ? this.props.config : {};

        this.onInit = this.onInit.bind(this);
        this.onProcessed = this.onProcessed.bind(this);
    }

    componentDidMount() {

        if (this.props.onPreInit) {
            this.props.onPreInit();
        }

        let options = {
            width: '99%',
            height: this.props.height ? this.props.height : 400,
            frameborder: 0,
            scrolling: 'no',
            style: 'display: block;'
        };


        Payment.CreateForm(
            this.paymentApiUrl,
            `${this.provider}`,
            `${this.paymentMethod}`,
            this.props.amount,
            `${this.props.currency}`,
            `${this.props.orderNumber}`,
            this.props.session,
            this.props.saveSessionCallback,
            `mycontainer${this.props.id}`,
            this.config,
            this.onProcessed,
            options
        )
            .then(p => {
                console.log("Payment.CreateForm succeeded from PaymentComponent");
                this.onInit(p);
            })
            .catch(p => {
                console.log("Payment.CreateForm failed from PaymentComponent");
                this.onInit(null);
            })
    }

    onInit(payment) {
        if (this.props.onInit) {
            this.props.onInit(payment);
        }
    }

    onProcessed(status, data) {

        console.log('PROCESSED', status, data);

        if (this.props.onProcessed) {
            this.props.onProcessed(status, data);
        }
    }

    render() {
        return (
            <div id={`mycontainer${this.props.id}`}>
            </div>
        )
    }

}