import React from "react";
import { DownloadLink } from "../../core/DownloadLink";
import { ClickableImage } from "../ClickableImage";
import { SectionLabel } from "../../core/SectionLabel";

import "./SelectableSkuLineItem.css";
 
export const SelectableSkuLineItem = ({ sku, selected, onClick }) => {

    const [checked, setChecked] = React.useState(selected);

    const handleChange = () => {
        setChecked(!checked);

        if (onClick) {
            onClick(sku, !checked);
        }
    };

    return (
        <div className={'selectableSkuLineItem' + (checked ? ' checked' : ' unchecked')}>
            <div className="selectable-area" onClick={handleChange}>
                <div className='selector'>
                    <input type="checkbox" checked={checked}/>
                </div>
                <SectionLabel description={sku.description} />
            </div>
            <ClickableImage className="confirmation popup" src={sku.thumbnail} />
            <DownloadLink href={sku.thumbnail} />
        </div>
    )
}