import React, { Component, useState, forwardRef, useImperativeHandle, useRef, useEffect } from 'react';
import { ApiDataAccess } from '../infrastructure/ApiDataAccess';
import { ProductGroup, FinishType, HangHole, RoundedCorners, SealWidth, TearNotch, Vent, Zipper } from '../infrastructure/Constants';
import { PlusSquare, MinusSquare } from 'react-feather';
import { LengthMeasurement } from '../infrastructure/Measurements';
import { useCheckoutContext } from '../infrastructure/CheckoutContext';
import { useUserContext } from '../infrastructure/UserContext';

import './ThreeDModel.css';

export class ModelSettings {

    static BottomFillType = {
        None: 1,
        Standard: 2
    };

    static DoubleCutWidthType = {
        None: 1,
        OneMillimeter: 2,
        TwoMillimeter: 3,
    };

    static EyemarkType = {
        None: 1,
        OutsideOneSide: 2,
        OutsideBothSides: 3,
        OnArtworkOneSide: 4,
        OnArtworkBothSides: 5,
        OutsideCenterBothSides: 6
    };

    static FinishType = {
        Gloss: 1,
        Matte: 2,
        SoftTouchMatte: 3
    };

    static HangHoleType = {
        None: 1,
        Sombrero_30: 2,
        Round_6_4: 3,
        Round_5: 4,
        Round_6: 5,
        Round_8: 6,
        Round_10: 7,
        Sombrero_25: 8,
        Sombrero_20: 9,
    };

    static ProductGroupType = {
        TwoSideSeal: 1,
        ThreeSideSeal: 2,
        PlowBottom: 3,
        SkirtSeal: 4,
        Doyen: 5,
        FinSeal: 6,
        QuadSeal: 7,
        FlatBottom: 8,
    };

    static RoundedCornerType = {
        None: 1,
        SixMillimeters: 2,
    };

    static ScanCodeType = {
        None: 1,
        Standard: 2,
    };

    static SealWidthType = {
        FiveMillimeters: 1,
        EightMillimeters: 2,
        TenMillimeters: 3
    };

    static TearNotchType = {
        None: 1,
        Standard: 2
    };

    static ValveType = {
        None: 1,
        Standard: 2
    };

    static ZipperType = {
        None: 1,
        PTC: 2,
        PowderResistant: 3,
        ChildResistant: 4,
        CleanTear: 5
    };
}


export const Animation = forwardRef(({ children }, ref) => {

    const [current, setCurrent] = useState(1);

    const applyOpacity = (value, speed, onComplete) => {
        var val = current;
        var myInterval = setInterval(() => {            
            val = val > value ? val - 0.01 : val + 0.01;
            setCurrent(val);

            if (Math.abs(val - value) <= 0.01) {
                clearInterval(myInterval);
                if (onComplete) {
                    onComplete();
                }
            }           
        }, speed);
    }

    useImperativeHandle(ref, () => ({

        setOpacity(value, speed, onComplete) {
            applyOpacity(value, speed, onComplete);
        }
    }));

    return (
        <div style={{ opacity: current }}>
            {children}
        </div>
    );
});


export const PouchPreview = forwardRef(({ request = RenderModel.DefaultRequest }, ref) => {
    const { defaultPouchColor, updateDefaultPouchColor, userPreferredRenderSize, updateUserPreferredRenderSize } = useUserContext();

    if (defaultPouchColor) {
        request.PouchColor = defaultPouchColor;
    }

    const getDefaultSize = () => {
        var width = (window.innerWidth > 0) ? window.innerWidth : window.screen.width;
        if (width < 1200) {
            return 2;
        } 
        return userPreferredRenderSize ? userPreferredRenderSize : 2;
    }

    const [visible, setVisible] = useState(false);
    const [ctrl, setCtrl] = useState(null);
    const [req, setReq] = useState(request);
    const [size, setSize] = useState(getDefaultSize());
    const animation = useRef(null);




    const maximize = () => {
        applySize(3);
    }

    const reset = () => {     
        applySize(2);
    }

    const minimize = () => {
        applySize(1);
    }

    const applySize = size => {
        setSize(size);
        updateUserPreferredRenderSize(size);
    }

    const applyFinishType = async (finishTypeId) => {
        var request = req;
        request.FinishType = RenderModel.MapFinishType(finishTypeId);
        setReq(request);
        await ctrl.refresh(req);
    }

    const applyHangHoleType = async (hangHoleTypeId, offset, uom) => {
        var request = req;
        request.HangHoleType = RenderModel.MapHangHole(hangHoleTypeId);
        request.HangholeOffsetInMillimeters = (new LengthMeasurement(offset, uom)).convertToMillimeters();

        setReq(request);
        await ctrl.refresh(req);
    }

    const applyZipperType = async (zipperTypeId, offset, uom) => {
        var request = req;
        request.ZipperType = RenderModel.MapZipper(zipperTypeId);
        request.ZipperOffsetInMillimeters = (new LengthMeasurement(offset, uom)).convertToMillimeters();

        setReq(request);
        await ctrl.refresh(req);
    }

    const applyTearNotch = async (tearNotchTypeId, offset, uom) => {
        var request = req;
        request.TearNotchType = RenderModel.MapTearNotch(tearNotchTypeId);
        request.TearNotchOffsetInMillimeters = (new LengthMeasurement(offset, uom)).convertToMillimeters();

        setReq(request);
        await ctrl.refresh(req);
    }

    const applyRoundedCorners = async (roundedCornerTypeId) => {
        var request = req;
        request.RoundedCornerType = RenderModel.MapRoundedCorners(roundedCornerTypeId);
        
        setReq(request);
        await ctrl.refresh(req);
    }

    const applySealWidth = async (sealWidthId) => {
        var request = req;
        request.RoundedCornerType = RenderModel.MapSealWidth(sealWidthId);

        setReq(request);
        await ctrl.refresh(req);
    }

    const applyVentType = async (ventTypeId, offset, uom) => {
        var request = req;
        request.ValveType = RenderModel.MapVent(ventTypeId);
        request.ValveOffsetInMillimeters = (new LengthMeasurement(offset, uom)).convertToMillimeters();

        setReq(request);
        await ctrl.refresh(req);
    }

    const setColor = async (color) => {
        updateDefaultPouchColor(color);
        animation.current.setOpacity(0, 1, async () => {
            var request = req;
            request.PouchColor = color;
            setReq(request);
            await ctrl.refresh(req);
        });
    }


    const onLoaded = () => {
        animation.current.setOpacity(1, 20);
    }

    useImperativeHandle(ref, () => ({

        setFinishType(finishTypeId) {
            animation.current.setOpacity(0, 1, () => {
                applyFinishType(finishTypeId);
            });            
        },

        setHangHoleType(hangHoleId, offset, uom) {
            animation.current.setOpacity(0, 1, () => {
                applyHangHoleType(hangHoleId, offset, uom);
            });            
        },

        setZipperType(zipperTypeId, offset, uom) {
            animation.current.setOpacity(0, 1, () => {
                applyZipperType(zipperTypeId, offset, uom);
            });            
        },

        setTearNotch(tearNotchTypeId, offset, uom) {
            animation.current.setOpacity(0, 1, () => {
                applyTearNotch(tearNotchTypeId, offset, uom);
            });            
        },

        setRoundedCorners(roundedCornerTypeId) {
            animation.current.setOpacity(0, 1, () => {
                applyRoundedCorners(roundedCornerTypeId);
            });            
        },

        setSealWidth(sealWidthId) {
            animation.current.setOpacity(0, 1, () => {
                applySealWidth(sealWidthId);
            });            
        },

        setVentType(ventTypeId, offset, uom) {
            animation.current.setOpacity(0, 1, () => {
                applyVentType(ventTypeId, offset, uom);
            });            
        }

    }));

    const getWindow = size => {
        var pouchPreviewClass = 'pouchPreview';
        var containerClass = 'container';
        var viewWidth = 300;
        var viewHeight = 300;

        switch (size) {
            case 1:
                viewWidth = 100;
                viewHeight = 100;
                pouchPreviewClass += ' minimized';
                containerClass += ' minimized';
                return (
                    <>
                        <div className={pouchPreviewClass}>
                            <div className={containerClass}>
                                <div className='frame'>
                                    <PlusSquare className='icon pull-right' onClick={() => { reset() }} />
                                    <Animation ref={animation}>
                                        <RenderModel key={'pouchPreview2'} onRendered={v => { setVisible(v) }} request={req} viewWidth={viewWidth} viewHeight={viewHeight} onInit={ctrl => { setCtrl(ctrl) }} onLoaded={() => { onLoaded() }} />
                                    </Animation>
                                </div>
                            </div>
                        </div>
                    </>
                    )
                break;
            case 2:     
                viewWidth = 300;
                viewHeight = 300;
                pouchPreviewClass += '';
                containerClass += '';
                return (
                    <>
                        <div className={pouchPreviewClass}>
                            <div className={containerClass}>
                                <div className='frame'>
                                    <PlusSquare className='icon pull-right' onClick={() => { maximize() }} />
                                    <MinusSquare className='icon pull-right' onClick={() => { minimize() }} />
                                    <Animation ref={animation}>
                                        <RenderModel key={'pouchPreview2'} onRendered={v => { setVisible(v) }} request={req} viewWidth={viewWidth} viewHeight={viewHeight} onInit={ctrl => { setCtrl(ctrl) }} onLoaded={() => { onLoaded() }} />
                                    </Animation>
                                    {/* 
                                    <div className='toolbar'>
                                        <div className='swatch background-light-grey' onClick={() => { setColor('C1C1C1') }}></div>
                                        <div className='swatch background-orange' onClick={() => { setColor('F47745') }}></div>
                                        <div className='swatch background-navy' onClick={() => { setColor('1886BB') }}></div>
                                        <div className='swatch background-green' onClick={() => { setColor('7CBD42') }}></div>
                                        <div className='swatch background-yellow' onClick={() => { setColor('F8DD01') }}></div>
                                    </div>
                                    */}
                                </div>
                            </div>
                        </div>
                    </>
                    )
                break;
            case 3:               
                viewWidth = 640;
                viewHeight = 640;
                pouchPreviewClass += ' maximized';
                containerClass += ' maximized';
                return (
                    <>
                        <div className={pouchPreviewClass}>
                            <div className={containerClass}>
                                <div className='frame'>
                                    <MinusSquare className='icon pull-right' onClick={() => { reset() }} />
                                    <Animation ref={animation}>
                                        <RenderModel key={'pouchPreview1'} onRendered={v => { setVisible(v) }} request={req} viewWidth={viewWidth} viewHeight={viewHeight} onInit={ctrl => { setCtrl(ctrl) }} onLoaded={() => { onLoaded() }} />
                                    </Animation>
                                    {/* 
                                    <div className='toolbar'>
                                        <div className='swatch background-light-grey' onClick={() => { setColor('C1C1C1') }}></div>
                                        <div className='swatch background-orange' onClick={() => { setColor('F47745') }}></div>
                                        <div className='swatch background-navy' onClick={() => { setColor('1886BB') }}></div>
                                        <div className='swatch background-green' onClick={() => { setColor('7CBD42') }}></div>
                                        <div className='swatch background-yellow' onClick={() => { setColor('F8DD01') }}></div>
                                    </div>
                                    */}
                                </div>
                            </div>
                        </div>
                    </>
                    )
                break;
        }
    }

    return (
        <div className={visible ? '' : 'hidden'}>
            {getWindow(size)}            
        </div>
    )
});


export class RenderModel extends Component { 

    
    static DefaultRequest = {
        ProductGroupType: ModelSettings.ProductGroupType.PlowBottom,
        FinishType: ModelSettings.FinishType.Gloss,
        LengthInMillimeters: 220,
        WidthInMillimeters: 150,
        GussetInMillimeters: 50,

        EyemarkType: ModelSettings.EyemarkType.None,
        BottomFillType: ModelSettings.BottomFillType.None,
        TearNotchType: ModelSettings.TearNotchType.None,
        TearNotchOffsetInMillimeters: 0,
        HangHoleType: ModelSettings.HangHoleType.None,
        HangholeOffsetInMillimeters: 0,
        ZipperType: ModelSettings.ZipperType.None,
        ZipperOffsetInMillimeters: 0,
        ValveType: ModelSettings.ValveType.None,
        ValveOffsetInMillimeters: 0,
        SealWidthType: ModelSettings.SealWidthType.FiveMillimeters,
        RoundedCornerType: ModelSettings.RoundedCornerType.None,
        DoubleCutWidthType: ModelSettings.DoubleCutWidthType.None,
        ScanCodeType: ModelSettings.ScanCodeType.None,
        PouchColor: 'C1C1C1'
    };


    constructor(props) {
        super(props);

        this.url = this.props.url;
        this.request = this.props.request;
        this.animationSpeed = 0.005;
        this.backgroundColor = 'ffffff';
        //this.pouchColor = this.props.pouchColor ? this.props.pouchColor : 'cccccc'; //'c2730a';
        this.viewHeight = this.props.viewHeight;
        this.viewWidth = this.props.viewWidth;

        this.state = {
            srcUrl: this.url ? this.url : null
        }
    }

    componentDidMount() {
        this.refresh(this.request);
        if (this.props.onInit) {
            this.props.onInit(this);
        }
    }

    onLoaded() {
        if (this.props.onLoaded) {
            this.props.onLoaded();
        }
    }

    async refresh(request) {
        if (request) {
            this.setState({ srcUrl : null});
            try {                
                var u = await RenderModel.GetModelUrlAsync(request);
                if (this.props.onRendered) {
                    this.props.onRendered(u ? true : false);
                }

                this.setState({
                    srcUrl: u
                });                
            }
            catch {
                this.setState({ srcUrl: null });
                if (this.props.onRendered) {
                    this.props.onRendered(false);
                }
            }
        }
        else {
            this.setState({ srcUrl: this.url });
        }
    }


    static MapProductGroupType(value) {
        return FeatureMapper.MapProductGroupType(value);
    }

    static MapFinishType(value) {
        return FeatureMapper.MapFinishType(value);
    }

    static MapHangHole(value) {
        return FeatureMapper.MapHangHole(value);
    }

    static MapRoundedCorners(value) {
        return FeatureMapper.MapRoundedCorners(value);
    }

    static MapSealWidth(value) {
        return FeatureMapper.MapSealWidth(value);
    }

    static MapTearNotch(value) {
        return FeatureMapper.MapTearNotch(value);
    }

    static MapVent(value) {
        return FeatureMapper.MapVent(value);
    }

    static MapZipper(value) {
        return FeatureMapper.MapZipper(value);
    }

    static async GetModelUrlAsync(request = RenderModel.DefaultRequest) {

        try {
            var req = { ...RenderModel.DefaultRequest, ...request }            
            const dataAccess = new ApiDataAccess('/api/illustrations');
            return await dataAccess.post('/', req);
        }
        catch {
            return null;
        }
    }



    render() {
        return (
            <>
                {this.state.srcUrl &&
                    <iframe className='threeDModel' onLoad={() => { this.onLoaded() }} scrolling='no' src={`${this.state.srcUrl}?width=${this.viewWidth}&height=${this.viewHeight}&animation=${this.animationSpeed}&backroundcolor=${this.backgroundColor}&pouchcolor=${this.request && this.request.PouchColor ? this.request.PouchColor : 'cccccc' }`} height={this.viewHeight} width={this.viewWidth} />
                }
            </>
            )
    }

}



export class FeatureMapper {


    static MapProductGroupType(value) {
        switch (value) {
            case ProductGroup.SkirtSealPouch:
                return ModelSettings.ProductGroupType.SkirtSeal;
                break;
            case ProductGroup.PlowBottomPouch:
                return ModelSettings.ProductGroupType.PlowBottom;
                break;
            case ProductGroup.Doyen:
                return ModelSettings.ProductGroupType.Doyen;
                break;
            case ProductGroup.TwoSideSealPouch:
                return ModelSettings.ProductGroupType.TwoSideSeal;
                break;
            case ProductGroup.ThreeSideSealPouch:
                return ModelSettings.ProductGroupType.ThreeSideSeal;
                break;
            case ProductGroup.QuadSealPouch:
                return ModelSettings.ProductGroupType.QuadSeal;
                break;
            case ProductGroup.FinSealPouch:
                return ModelSettings.ProductGroupType.FinSeal;
                break;
            case ProductGroup.FlatBottomPouch:
                return ModelSettings.ProductGroupType.FlatBottom;
                break;
            default:
                throw new Error(`Product Group ${value} is not supported`);
        }
    }

    static MapFinishType(value) {
        switch (value) {
            case FinishType.Matte:
                return ModelSettings.FinishType.Matte;
                break;
            case FinishType.Gloss:
                return ModelSettings.FinishType.Gloss;
                break;
            case FinishType.SoftTouchMatte:
                return ModelSettings.FinishType.SoftTouchMatte;
                break;
            default:
                throw new Error(`Finish Type ${value} is not supported`);
        }
    }

    static MapHangHole(value) {
        switch (value) {
            case HangHole.None:
                return ModelSettings.HangHoleType.None;
                break;
            case HangHole.Round_5_mm:
                return ModelSettings.HangHoleType.Round_5;
                break;
            case HangHole.Round_6_mm:
                return ModelSettings.HangHoleType.Round_6;
                break;
            case HangHole.Round_6_4_mm:
                return ModelSettings.HangHoleType.Round_6_4;
                break;
            case HangHole.Round_8_mm:
                return ModelSettings.HangHoleType.Round_8;
                break;
            case HangHole.Round_10_mm:
                return ModelSettings.HangHoleType.Round_10;
                break;
            case HangHole.Sombrero_20_mm:
                return ModelSettings.HangHoleType.Sombrero_20;
                break;               
            case HangHole.Sombrero_25_mm:
                return ModelSettings.HangHoleType.Sombrero_25;
                break;
            case HangHole.Sombrero_30_mm:
                return ModelSettings.HangHoleType.Sombrero_30;
                break;
            default:
                throw new Error(`Hang Hole ${value} is not supported`);
        }
    }

    static MapRoundedCorners(value) {
        switch (value) {
            case RoundedCorners.None:
                return ModelSettings.RoundedCornerType.None;
                break;
            case RoundedCorners.SixMillimeters:
                return ModelSettings.RoundedCornerType.SixMillimeters;
                break;
            default:
                throw new Error(`Rounded Corner ${value} is not supported`);
        }
    }

    static MapSealWidth(value) {
        switch (value) {
            case SealWidth.None:
            case SealWidth.Seal_5_mm:
                return ModelSettings.SealWidthType.FiveMillimeters
                break;
            case SealWidth.Seal_8_mm:
                return ModelSettings.SealWidthType.EightMillimeters;
                break;
            case SealWidth.Seal_10_mm:
                return ModelSettings.SealWidthType.TenMillimeters;
                break;
            default:
                throw new Error(`Seal Width ${value} is not supported`);
        }
    }

    static MapTearNotch(value) {
        switch (value) {
            case TearNotch.None:
                return ModelSettings.TearNotchType.None;
                break;
            case TearNotch.Default:
                return ModelSettings.TearNotchType.Standard;
                    break;
            default:
                throw new Error(`Tear Notch ${value} is not supported`);
        }
    }

    static MapVent(value) {
        switch (value) {
            case Vent.None:
                return ModelSettings.ValveType.None;
                break;
            case Vent.Valve:
                return ModelSettings.ValveType.Standard;
                break;
            case Vent.VentHole:
                return ModelSettings.ValveType.None;
                break;
            default:
                throw new Error(`Vent ${value} is not supported`);
        }
    }

    static MapZipper(value) {
        switch (value) {
            case Zipper.None:
                return ModelSettings.ZipperType.None;
                break;    
            case Zipper.PowderProof:
                return ModelSettings.ZipperType.PowderResistant;
                break;    
            case Zipper.PTC:
                return ModelSettings.ZipperType.PTC;
                break;    
            case Zipper.ChildResistant:
                return ModelSettings.ZipperType.ChildResistant;
                break;    
            case Zipper.Recyclable:
                return ModelSettings.ZipperType.PTC;
                break;    
            case Zipper.Compostable: 
                return ModelSettings.ZipperType.PTC;
                break;    
            case Zipper.Compostable_CR: 
                return ModelSettings.ZipperType.ChildResistant;
                break;    
            case Zipper.CleanTear:
                return ModelSettings.ZipperType.CleanTear;
                break;    
            default:
                throw new Error(`Zipper ${value} is not supported`);
        }
    }

}