import { AuthenticatedTemplate, useAccount, useIsAuthenticated, useMsal } from "@azure/msal-react";
import { SecureApiDataAccess } from "../../infrastructure/SecureApiDataAccess";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useCheckoutContext } from "../../infrastructure/CheckoutContext";
import { useContext, useEffect, useState } from "react";
import { useUserContext } from "../../infrastructure/UserContext";
import { useTracking } from "react-tracking";
import { useCookies } from "react-cookie";
import { loginRequest, protectedResources } from "../../authConfig";
import { CapabilitiesContext } from "../../infrastructure/Contexts";
import { CapabilityName } from "../../infrastructure/Constants";
import { Page } from "../../core/Page";
import { PendingRegistration } from "../registration/PendingRegistration";

export const RegisterAccount = () => {
    const { instance, accounts, inProgress } = useMsal();
    const capabilitiesContext = useContext(CapabilitiesContext);

    const loader = capabilitiesContext.getCapability(CapabilityName.Loader);

    const account = useAccount(accounts[0] || {});
    const userDataAccess = new SecureApiDataAccess("/api/user");
    const isAuthenticated = useIsAuthenticated();
    const navigate = useNavigate();
    const [pendingRegistration, setPendingRegistration] = useState(false);
    //const { trackEvent } = useTracking({ page: "RegisterPage" });
    const [searchParams, setSearchParams] = useSearchParams();

    const handleRegistrationRedirectFromLogin = async (accessToken) => {
        const salesforceRegistrationId = searchParams.get("sfid");
        const redirectLocation = searchParams.get("to") ?? "/";
        console.log("will redirect to", redirectLocation);
        if (salesforceRegistrationId) {
            console.log("sfid registration path", redirectLocation);
        }

        var registerResponse = await userDataAccess.postAsJson(
            "/account/register",
            {
                emailAddress: account.username,
                identityProviderAccountId: account.idTokenClaims.oid,
                salesforceAccountId: salesforceRegistrationId,
            },
            accessToken,
            {
                throwOnNonSuccess: false,
            }
        );
        if (registerResponse?.hasSuccess) {
            if (registerResponse.data.registrationStatusId === "COMPL") {
                // trackEvent({
                //     event: "sign_up",
                //     method: account.environment,
                //     userId: cookies.visitorId,
                // });

                window.location.href = decodeURIComponent(redirectLocation);

            } else if (registerResponse.data.registrationStatusId === "PENDG") {
                setPendingRegistration(true);
            }
        }
    };

    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        const init = async () => {

            if (!isAuthenticated) {
                navigate("/", {
                    replace: true,
                });
            }

            const response = await instance.acquireTokenSilent({
                scopes: [...protectedResources.shopApp.scopes],
                ...loginRequest,
                account: account,
            });

            if (!response.accessToken) {
                throw new Error("Could not retrieve access token.");
            }

            await handleRegistrationRedirectFromLogin(response.accessToken);

            setLoaded(true);
        };
        init();
    }, []);

    return (
        loaded && (
            <Page>
                <AuthenticatedTemplate>
                    {pendingRegistration ? <PendingRegistration /> : <div>Please wait while we sign you in...</div>}
                </AuthenticatedTemplate>
            </Page>
        )
    );
};
