import React from "react";
import { ContextHelp } from "./ContextHelp";

export const ColumnTitle = (props) => {
    return (
        <div className="sectionTitle">
        {props.description || props.cms
            ? (
                <div>{props.children} <ContextHelp context={props.cms} /></div>
            )   
            : (
            <div>{props.children}</div>
            )
        }
    </div>
    );
};
