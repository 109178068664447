import React, { useEffect, useRef, useContext } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { TileContainer, ButtonTile } from '../core/ImageTile';
import { Popup } from '../core/Popup';
import { XSquare, RotateCw } from 'react-feather'; 
import { useCheckoutContext } from '../infrastructure/CheckoutContext';
import { Language } from '../infrastructure/Languages';
import { useUserContext } from '../infrastructure/UserContext';
import { ProductGroup } from '../infrastructure/Constants';

import './TabContent.css';

export const ActiveTab = {
    Product : 1,
    ConvertingMethod: 2,
    Dimensions: 3,
    Material: 4,
    Feature: 5,
    Sku: 6,
    Login:7
}

export const TabContent = ({ activeTab }) => {

    const navigate = useNavigate();
    const language = useContext(Language);    
    const popup = useRef(null);
    const { product, convertingMethod, dimensions, material, feature, skuCount, reset } = useCheckoutContext();
    const { userRegion } = useUserContext();
    
    const onResetClick = () => {
        reset();
        navigate(`/${userRegion}`);
    }

    const getDimsImage = () => {
        switch (convertingMethod.convertingMethodId) {
            case ProductGroup.TwoSideSealPouch:
                return '/images/dimensions/twoSideSealPouch.png';
            case ProductGroup.ThreeSideSealPouch:
                return '/images/dimensions/threeSideSealPouch.png';
            case ProductGroup.PlowBottomPouch:
                return '/images/dimensions/plowBottomPouch.png';
            case ProductGroup.SkirtSealPouch:
                return '/images/dimensions/skirtSealPouch.png';
            case ProductGroup.Doyen:
                return '/images/dimensions/doyen.png';
            case ProductGroup.FinSealPouch:
                return '/images/dimensions/finSealPouch.png';
            case ProductGroup.QuadSealPouch:
                return '/images/dimensions/quadSealPouch.png';
            default:
                return '/images/dimensions/plowBottomPouch.png';
        }
    }

    const getFeatureImage = () => {
        var result = null
        Object.keys(feature).forEach(key => {
            if (feature[key].imageUrl && !result) {
                result = feature[key].imageUrl;
            }
        });
        return result;
    }

    return (
        <div>
            <Popup ref={popup}>            


                <div className='contentSection'>
                    <span>{language.translateLabel('41a4bbc9-f949-4b21-bdd1-c98272697eb4', 'Are you sure to start over?', 'Tab')}</span>
                </div>

                <div className='footerSection'>
                    <button className='btn btn-secondary btn-modal' onClick={() => { onResetClick(); }}>{language.translateLabel('dbaf68ea-89df-4a1e-8b55-8599f212a319', 'Yes', 'Button')}</button>
                    <button className='btn btn-primary btn-modal' onClick={() => { popup.current.close(); }}>{language.translateLabel('ffa0b5ab-5199-4771-8950-69bb9f078aef', 'No', 'Button')}</button>
                </div>

            </Popup>

            <div className='buttonbar'>
                <button className='btn btn-primary btn-block' onClick={() => { popup.current.show(); }}><RotateCw className='icon' /> {language.translateLabel('893b8af3-fa7b-478c-8473-da132f526d23', 'Start Over', 'Button')}</button>
            </div>


            <div className='scroll'>
                <div className='wrapper'>
                    <TileContainer>
                        {product &&
                            <ButtonTile className={activeTab == ActiveTab.Product ? 'active-border' : ''} width={200} height={200}>
                                <TabTileContent title={language.translateLabel('ff988bfb-7f46-4aed-8844-7e2c8f9e5745', 'Product', 'Tab')} caption={language.translateDomain('c5ac7c91-98da-42e4-bfe7-9b49d00e2b86', product.key, product.productName, 'Product')} onClick={() => { navigate(`/${userRegion}${product.url}`); }}>
                                    <div className='image' style={{ backgroundImage: `url(${product.productImage})` }} />
                                </TabTileContent>
                            </ButtonTile>
                        }
                        {product && convertingMethod &&
                            <ButtonTile className={activeTab == ActiveTab.ConvertingMethod ? 'active-border' : ''} width={200} height={200}>
                                <TabTileContent title={language.translateLabel('e46538ba-ef17-4524-a1a7-0d86c7ac2dfc', 'Pouch Type', 'Tab')} caption={language.translateDomain('cebe10d4-8c6e-4b94-84ae-60d7ce2c47d8', convertingMethod.convertingMethodId, convertingMethod.convertingMethodName, 'Product Group')} onClick={() => { navigate(`/${userRegion}/group/${product.productGroupId}/convertingMethod`); }}>
                                    <div className='image' style={{ backgroundImage: `url(${language.imageUrl('c1d0a342-4463-4cca-8bb7-1c323b9942c4', `${convertingMethod.convertingMethodId}_0`, `Product Group - ${convertingMethod.convertingMethodName}`, `Carousel Image 0`)})` }} />
                                </TabTileContent>
                            </ButtonTile>
                        }
                        {product && convertingMethod && dimensions && dimensions.selectedDims &&
                            <ButtonTile className={activeTab == ActiveTab.Dimensions ? 'active-border' : ''} width={200} height={200}>
                                <TabTileContent title={language.translateLabel('e7af9218-4af7-4124-aaac-074b5441eaa4', 'Size', 'Tab')} caption={dimensions.selectedDims.formattedSize} onClick={() => { navigate(`/${userRegion}/group/${product.productGroupId}/convertingMethod/${convertingMethod.convertingMethodId}/dimensions`); }}>
                                    <div className='image' style={{ backgroundImage: `url(${getDimsImage()})` }} />
                                </TabTileContent>
                            </ButtonTile>
                        }
                        {product && convertingMethod && dimensions && material &&
                            <ButtonTile className={activeTab == ActiveTab.Material ? 'active-border' : ''} width={200} height={200}>
                                <TabTileContent title={language.translateLabel('aeb9a28a-cf73-4187-b641-5eefe78c630a', 'Material', 'Tab')} caption={`SPEC-${material.id}`} onClick={() => { navigate(`/${userRegion}/group/${product.productGroupId}/convertingMethod/${convertingMethod.convertingMethodId}/dimensions/material`); }}>
                                    <div className='image' style={{ backgroundImage: `url(${material.filmImageUrl})` }} />
                                </TabTileContent>
                            </ButtonTile>
                        }
                        {product && convertingMethod && dimensions && material && feature &&
                            <ButtonTile className={activeTab == ActiveTab.Feature ? 'active-border' : ''} width={200} height={200}>
                                <TabTileContent title={language.translateLabel('6afc78fc-f23f-4c96-b76a-0bdb67b44ddb', 'Features', 'Tab')} caption={` `} onClick={() => { navigate(`/${userRegion}/group/${product.productGroupId}/convertingMethod/${convertingMethod.convertingMethodId}/material/${material.id}/features`); }}>
                                    <div className='image' style={{ backgroundImage: `url(${getFeatureImage()})` }} />
                                </TabTileContent>
                            </ButtonTile>
                        }


                        {product && convertingMethod && dimensions && material && feature && skuCount &&
                            <ButtonTile className={activeTab == ActiveTab.Feature ? 'active-border' : ''} width={200} height={200}>
                                <TabTileContent title={language.translateLabel('6abfbfa0-803a-449c-a96e-2c8954a0342e', 'SKUs', 'Tab')} caption={` `} onClick={() => { navigate(`/${userRegion}/group/${product.productGroupId}/convertingMethod/${convertingMethod.convertingMethodId}/material/${material.id}/skus`); }}>
                                    <div className='image'><div className='text'>{skuCount}</div></div>
                                </TabTileContent>
                            </ButtonTile>
                        }
                    </TileContainer>
                </div>
            </div>
        </div>
    )
}


export const TabTileContent = ({ title, caption, className, children, onClick }) => {
    return (
        <div className={className ? `tabTileContent ${className}` : 'tabTileContent'} onClick={onClick}>
            <h4>{ title}</h4>
            {children}
            <p className='caption'>{caption}</p>
        </div>
        )
}