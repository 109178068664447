import React from "react";
import { User, Mail, Phone } from 'react-feather';
import { Card } from "../../core/Card";
import { Translation } from "../../core/Translation";
import { OrderReference } from '../../core/Breadcrumbs';


import './CustomerOnHold.css';

export const CustomerOnHold = ({ offerData }) => {

    return (
        <div className='customerOnHold'>
            <Card title={<Translation id='037c1643-0b5f-4333-9802-a8b0493c6638' defaultMessage='Contact Us' category='Card Title' />}>
                <p><Translation id='f475bba4-9139-4efc-823d-27d7896cb35a' defaultMessage='Please contact your Sales Rep:' category='Label' /></p>

                <div className='contact'>
                    <p className='color-orange'><User className='icon' />{offerData.salesContact.firstName} {offerData.salesContact.lastName}<br /></p>
                    <p className='color-orange'><Mail className='icon' /><a href={'mailto:' + offerData.salesContact.email}>{offerData.salesContact.email}</a> <br /></p>
                    <p className='color-orange'><Phone className='icon' /><a href={'tel:' + offerData.salesContact.phone}>{offerData.salesContact.phone}</a><br /></p>
                </div>

                <p><Translation id='360de5ec-7283-485b-9506-3aa93a559103' defaultMessage='Currently your account is on a hold status and you can not proceed with the checkout for this offer.' category='Label' /></p>
            </Card>
        </div>
    )
}


export const InvalidForPurchase = ({ offerData }) => {

    var orderReference = {
        orderType: 'Other',
        customerName: offerData.offer.offer.salesForceOpportunity.value,
        convertingMethod: offerData.offer.offer.convertingMethod.value,
        product: offerData.offer.offer.product.value,
        orderNumber: offerData.offer.offer.salesForceOpportunity.referenceNumber
    };

    return (
        <div className='customerOnHold'>
            <OrderReference orderReference={orderReference} />

            <Card title={<Translation id='037c1643-0b5f-4333-9802-a8b0493c6638' defaultMessage='Contact Us' category='Card Title' />}>
                <p><Translation id='f475bba4-9139-4efc-823d-27d7896cb35a' defaultMessage='Please contact your Sales Rep:' category='Label' /></p>

                <div className='contact'>
                    <p className='color-orange'><User className='icon' />{offerData.salesContact.firstName} {offerData.salesContact.lastName}<br /></p>
                    <p className='color-orange'><Mail className='icon' /><a href={'mailto:' + offerData.salesContact.email}>{offerData.salesContact.email}</a> <br /></p>
                    <p className='color-orange'><Phone className='icon' /><a href={'tel:' + offerData.salesContact.phone}>{offerData.salesContact.phone}</a><br /></p>
                </div>

                <p><Translation id='b25b2940-2e9e-4272-a109-d38277542e99' defaultMessage='The packaging material or some of the packaging features are not longer available and you can not proceed with the checkout for this offer.' category='Label' /></p>
            </Card>
        </div>
    )
}