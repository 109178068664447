import React from 'react';


export const Page = ({ children, className, onRenderHeader }) => {

    return (
        <div data-testid="page-checkout" className={className ? `page ${className}` : 'page'}>
            {onRenderHeader &&
                <div>
                    {onRenderHeader()}
                </div>
            }
            <div className='pageContent'>
                {children}
            </div>
        </div>
    )
}