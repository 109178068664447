import React from "react"
import { useState } from "react";
import { createContext } from "react"

const NotificationContext = createContext({
    message: "",
    setMessage: (msg) => {}
});

const NotificationContextProvider = ({ children }) => {
    const [message, setMessage] = useState("");
    const [isProceedingToCheckout, setIsProceedingToCheckout] = useState(false);

    return (
        <NotificationContext.Provider value={{ message, setMessage, canLogout: isProceedingToCheckout, setIsProceedingToCheckout }}>
            {children}
        </NotificationContext.Provider>
    )
}
export { NotificationContext, NotificationContextProvider };
