import React, { useEffect, useState } from "react";
import { Navigate, Outlet, Route, Routes, useLocation } from "react-router-dom";
import AppRoutes from "./AppRoutes";
import { Layout } from "./components/Layout";
import { Configuration } from "./infrastructure/Configuration";
import { CapabilityName } from "./infrastructure/Constants";
import { CapabilityRegistry } from "./infrastructure/Capabilities";
import { BackSplashComponent } from "./capabilities/BackSplashComponent";
import { LoaderComponent } from "./capabilities/LoaderComponent";
import { ModalComponent } from "./capabilities/ModalComponent";
import { CapabilitiesContext, SettingsContext } from "./infrastructure/Contexts";
import { StaticDataSupport, StaticDataProvider } from "./infrastructure/DataContextProvider";
import { OfferLanguageProvider } from "./infrastructure/OfferLanguageContext";
import { LanguageSupport } from "./infrastructure/Languages";
import { MsalProvider } from "@azure/msal-react";
import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { AppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { ReactPlugin } from "@microsoft/applicationinsights-react-js";
import { NotificationContextProvider } from "./capabilities/NotificationContext";
import track from "react-tracking";
import { useCulture } from "./infrastructure/Culture";
import { CheckoutContextRouter } from "./infrastructure/Router";
import Feedback from "feeder-react-feedback";
import { CookiesProvider } from "react-cookie";
import { StateStorageProvider, StateStorageContextManager, Visitor } from "./infrastructure/ServerStateStorage";
import { LoadingSpinner } from "./core/LoadingSpinner";
import "./global.css";
import "./custom.css";
import "./order.css";
import "feeder-react-feedback/dist/feeder-react-feedback.css";


var reactPlugin = new ReactPlugin();

StateStorageContextManager.GlobalState = {
    user: {
        userRegion: "en-US",
        userLanguage: "en-US",
        userPreferredRenderSize: 2,
        defaultPouchColor: "C1C1C1",
        previousCart: "",
    },
    checkout: {
        product: "",
        material: "",
        dimensions: "",
        convertingMethod: "",
        feature: "",
        skuCount: "",
        selectedSkuQuantityBreakdown: "",
        isCheckoutClicked: false,
    },
};

const App = ({ msalInstance }) => {
    const capabilities = new CapabilityRegistry();
    const [config, setConfig] = useState(null);
    const { getCultureFromParam } = useCulture();
    const location = useLocation();

    const isSiteEnabled = (settings) => {
        return settings["Application:IsShoppingSiteEnabled"] === true;
    }

    useEffect(() => {
        (async () => {
            console.log("Application initialization started");

            let configuration = new Configuration();
            try {
                var settings = await configuration.getConfigAsync();
                if (settings) {

                    var culture = getCulture(settings);

                    var config = {
                        settings: settings.settings,
                        regions: settings.regions,
                        capabilities: capabilities,
                        culture: culture,
                    };

                    if (config.settings && config.settings["ApplicationInsights:ConnectionString"]) {
                        configureAppInsights(config.settings["ApplicationInsights:ConnectionString"]);
                    }

                    console.log("config", config);
                    setConfig(config);
                }
            } catch (ex) {
                console.log("Application initialization has failed. Redirecting to login page.", ex);
                //window.location.replace("http://www.epacflexibles.com");

                // Let's do this during development --> ApplicationController is set to Authorize
                window.location.replace("/");
            }
            console.log("Application initialization finished");

        })();
    }, []);

    useEffect(() => {
        if (config) {
            if (!isSiteEnabled(config.settings) && !location.pathname.startsWith("/offer")) {
                console.log("Force redirect -- site disabled at this time and order placement route not found");
                window.location = config.settings["Application:ShoppingSiteDisabledRedirectUrl"];
            }
        }
    }, [location, config]);

    //https://learn.microsoft.com/en-us/azure/azure-monitor/app/javascript-react-plugin
    const configureAppInsights = (connectionString) => {
        console.log("Enabling Application Insights");
        var appInsights = new ApplicationInsights({
            config: {
                connectionString: connectionString,
                extensions: [reactPlugin],
                enableAutoRouteTracking: true,
            },
        });
        appInsights.loadAppInsights();
        appInsights.trackPageView();
    };

    const primeLanguage = async (culture) => {
        if (window.location.pathname.toLowerCase().endsWith("/search")) {
            var data = await StaticDataProvider.GetProductDataAsync();
            var products = data.products;

            products.forEach((product) => {
                var metaData = {
                    productId: product.productId,
                };
                culture.translateDomain(
                    "c5ac7c91-98da-42e4-bfe7-9b49d00e2b86",
                    product.key,
                    product.name,
                    "Product",
                    metaData
                );
            });
        }
    };

    const getCulture = (settings) => {
        var result = {
            plantCulture: "en-US",
            customerCulture: {
                cultureString: "en-US",
            },
        };

        if (window.location.pathname.toLowerCase() != "/sign-in") {
            var plantCulture = getCultureFromParam();
            if (plantCulture) {
                var regions = settings.regions;
                var plantRegion = regions.find((e) => e.cultureString === plantCulture);
                if (plantRegion) {
                    var customerCulture = getCustomerCountry(plantRegion, settings);
                    result = {
                        plantCulture: plantCulture,
                        customerCulture: customerCulture ? customerCulture : plantCulture,
                    };
                } else {
                    window.location.replace("/");
                }
            }
        }

        return result;
    };

    const getCustomerCountry = (region, settings) => {
        var result = region;
        var regions = settings.regions;
        var plantRegion = regions.find((e) => e.cultureString === region);
        if (plantRegion && plantRegion.supportedCountries) {
            var userCulture =
                navigator.languages && navigator.languages.length ? navigator.languages[0] : navigator.language;
            var country = plantRegion.supportedCountries.find((e) => e.cultureString === userCulture);
            if (country && country.cultureString) {
                result = country.cultureString;
            }
        }
        return result;
    };


    const routeCollection = AppRoutes.map((route, index) => {
        const { element, ...rest } = route;

        return <Route key={index} {...rest} element={element} />;
    });

    const onChatFrameLoaded = (evt) => {
        var iframe = document.getElementById(evt.target.id);

        iframe.addEventListener('mouseover', () => {
            iframe.style.height = "500px";
        });

        document.body.addEventListener('mouseout', () => {
            iframe.style.height = "66px";
        });

        document.body.addEventListener('click', () => {
            iframe.style.height = "66px";
        });
    }




    return (
        <div>
            <CookiesProvider>
                <NotificationContextProvider>
                    <BackSplashComponent capabilityRegistry={capabilities} id={CapabilityName.BackSplash} />
                    <LoaderComponent capabilityRegistry={capabilities} id={CapabilityName.Loader} />
                    <ModalComponent capabilityRegistry={capabilities} id={CapabilityName.Modal} />
                    <StateStorageProvider>
                        {config ? (
                            <AppInsightsContext.Provider value={reactPlugin}>
                                <MsalProvider instance={msalInstance}>
                                    <SettingsContext.Provider value={config}>
                                        <CapabilitiesContext.Provider value={config.capabilities}>
                                            <CheckoutContextRouter plantRegion={config.culture.plantCulture}>
                                                <OfferLanguageProvider>
                                                    <LanguageSupport
                                                        source={config.culture.plantCulture}
                                                        target={config.culture.customerCulture.cultureString}
                                                        onInit={async (culture) => {
                                                            await primeLanguage(culture);
                                                        }}
                                                    >
                                                        <StaticDataSupport>
                                                            <Layout settings={config.settings}>
                                                                <Routes>
                                                                    {routeCollection}
                                                                    {/* {createOrderPlacementRoutes()} */}
                                                                </Routes>
                                                            </Layout>
                                                        </StaticDataSupport>
                                                    </LanguageSupport>
                                                </OfferLanguageProvider>
                                            </CheckoutContextRouter>
                                        </CapabilitiesContext.Provider>
                                    </SettingsContext.Provider>
                                </MsalProvider>
                            </AppInsightsContext.Provider>
                        ) : (
                            <LoadingSpinner />
                        )}

                        {/*
                        <Feedback
                            projectId="63a49d75f767ff0004c21e2c"
                            email={true}
                            emailRequired={false}
                            feedbackTypes={["bug", "idea"]}
                            subProject={Visitor.Id}
                            postSubmitButtonMsg={"Thank you for your feedback!"}
                        />
                        */}

                    </StateStorageProvider>
                </NotificationContextProvider>
            </CookiesProvider>

            {config && config.settings && config.settings["Application:ChatVersion"]===1 &&
                <div id="chatContainer">
                    <iframe id="chatFrame" src="https://epacflexibles.com/chat.html" onLoad={(evt) => { onChatFrameLoaded(evt) }}></iframe>
                </div>
            }

        </div>
    );
};

const GoogleAnalyticsDecoratedApp = track(
    // app-level tracking data
    { app: "Shop" },

    // top-level options
    {
        // custom dispatch to console.log in addition to pushing to dataLayer[]
        dispatch: (data) => {
            console.log(">> tracking: ", data);
            var dataLayer = (window.dataLayer = window.dataLayer || []);
            dataLayer.push(data);
            // here is where you add your custom analytics call
        },
    }
)(App);

export default GoogleAnalyticsDecoratedApp;
