import { ApiDataAccess } from './ApiDataAccess';
import { CultureInfo } from './Culture';

export class Configuration {

    getConfigAsync() {
        var actions = [            
            this.getSettingsAsync(),
            this.getRegions()
        ];

        return new Promise((resolve, reject) => {
            Promise.all(actions)
                .then(res => {
                    var config = {                        
                        settings: res[0],
                        regions: res[1]
                    };

                    resolve(config);
                })
                .catch(ex => reject(ex));
        });
    }

    async getSettingsAsync() {
        var dataAccess = new ApiDataAccess('api/application');
        return await dataAccess.get('/settings');
    }

    async getRegions() {
        var result = [];
        const dataAccess = new ApiDataAccess('/api/application');
        var regions = await dataAccess.get('/regions');
        Object.keys(regions).forEach(key => {
            result.push(new Region(regions[key]));
        });
        return result;
    }
}


export class Region {

    constructor(dto) {
        this.regionCulture = new CultureInfo(dto.region.cultureString, dto.region.name);
        this.cultureString = this.regionCulture.cultureString;
        this.defaultAdress = dto.defaultAddress;

        this.supportedCountries = [];
        Object.keys(dto.supportedCountries).forEach(key => {           
            this.supportedCountries.push(new CultureInfo(key, dto.supportedCountries[key]));
        });      
    }

    getCultureString() {
        return this.cultureString;
    }

    getRegionCulture() {
        return this.regionCulture;
    }

    getSupportedCounrties() {
        return this.supportedCountries;
    }

    getDefaultAddress() {
        return this.defaultAdress;
    }
}

