import React, { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useCheckoutContext } from '../infrastructure/CheckoutContext';
import { useUserContext } from './UserContext';
import { CultureString } from '../infrastructure/Culture';

export const CheckoutContextRouter = ({ children, plantRegion }) => {

    const { userRegion, previousCart, createUrlFromCart } = useUserContext();
    const { createUrlFromContext, setCart } = useCheckoutContext();
    const [searchParams] = useSearchParams();    
    const reloadCart = searchParams.get("reviveCart") === 'true';
    const navigate = useNavigate();


    useEffect(() => {

        if (reloadCart && previousCart && userRegion) {
            setCart(previousCart);
            navigate(createUrlFromCart(userRegion));
        }
        else {            
            if (!userRegion && window.location.pathname == '/') {
            }
            else {                
                
                if (shouldHandle()) {                   
                    var url = createUrlFromContext(plantRegion);
                    if (url.toLocaleLowerCase() !== window.location.pathname.toLowerCase()) {
                        navigate(url);
                    }
                }
            }           
        }         
    }, []);


    const shouldHandle = () => {

        if (window.location.pathname == '/') {
            return true;
        }

        if (window.location.pathname.toLowerCase() == '/sign-in') {
            return false;
        }

        if (window.location.pathname.toLowerCase().startsWith('/offer')) {
            return false;
        }

              
        var params = window.location.pathname.split('/')
        if (params && params.length >= 1) {
            var valid = CultureString.IsValid(params[1]);
            if (!valid) {
                return false;
            }

            if (params.length == 4 && params[2].toLowerCase() == 'search') {
                return false;
            }

            if (params.length == 4 && params[2].toLowerCase() == 'categories') {                
                return false;
            }

            if (params.length > 2 && params[2].toLowerCase() == 'offer') {
                return false;
            }
           
            return true;
        }
        return false;
    }



    return (
        <>
            {children}
        </>
    );
}