import React, { useContext, forwardRef, useImperativeHandle, useState, useEffect } from "react";
import { Alert } from "../core/Alert";
import _ from "lodash";
import { Language } from '../infrastructure/Languages';
import { useUserContext } from '../infrastructure/UserContext';


import "./SkuQuantitySelection.css";

export const SkuQuantitySelection = ({ skuQuantityBreakdown, quantitySelectionChanged, userSelectedQuantity, onRemoveClicked, minSellablePrice }) => {
    const language = useContext(Language);

    const [selectedQuantity, setSelectedQuantity] = useState(userSelectedQuantity ? userSelectedQuantity : 0);

    const hasNoSellItems = skuQuantityBreakdown.findIndex(e => !e.canSell) >= 0

    const onQuantitySelected = (qty) => {
        setSelectedQuantity(qty);
        if (quantitySelectionChanged) {
            quantitySelectionChanged(qty);
        }
    }
    useEffect(() => {
        //  console.log("SkuQuantitySelection useEffect", userSelectedQuantity)
    }, []);

    const formatDecimalWithCommas = (number, precision) => {
        var formatted = _.round(number, precision).toLocaleString(undefined, {
            minimumFractionDigits: 2,
        });
        return formatted;
    };


    return (
        <div>
            <div className="grid-container">

                {skuQuantityBreakdown.map((item, index) => {
                    return (
                        <SkuQuantitySelectionRow
                            userSelectedQuantity={userSelectedQuantity}
                            selected={item.orderedQty === parseFloat(userSelectedQuantity) ? true : false}
                            key={index}
                            index={index}
                            unitPrice={item.price.unitPrice.amount}
                            currency={item.price.unitPrice.currency}
                            quantity={item.orderedQty}
                            totalPrice={item.price.totalPrice.amount}
                            savingsPercentage={item.savingsPercentage}
                            canSell={item.canSell}
                            onQuantitySelected={onQuantitySelected}
                            onRemoveClicked={onRemoveClicked}
                        /*  selected={index ===0 ? true: false}*/
                        />
                    );
                })}

            </div>

            {hasNoSellItems &&
                <Alert type='warn'>{language.translateLabel('6eb97cfd-fb3e-45eb-a37f-2e4917e050c4', 'Some of the Quantities are too low to be selected. A miniumum order amount of {0} is required to proceed.', 'Label', { 0: '$' + formatDecimalWithCommas(minSellablePrice) })}</Alert>
            }

        </div>
    );
};

const SkuQuantitySelectionRow = forwardRef(
    (
        {
            userSelectedQuantity,
            selected,
            index,
            unitPrice,
            currency,
            quantity,
            totalPrice,
            canSell,
            onQuantitySelected,
            onRemoveClicked,
            savingsPercentage
        },
        ref
    ) => {
        const { userRegion } = useUserContext();

        const formatNumberWithCommas = (number) => {
            var num = parseInt(number);
            var formattedNumber = num.toLocaleString();
            return formattedNumber;
        };

        useImperativeHandle(ref, () => ({}));

        const formatDecimalWithCommas = (number, precision, minimumFractionDigits = 4, isCurrency = false) => {
            if (isCurrency) {
                var formattedCurrency = _.round(number, precision).toLocaleString(userRegion, {
                    minimumFractionDigits: minimumFractionDigits,
                    style: 'currency',
                    currency: currency
                });
                return formattedCurrency;
            }
            else {
                var formatted = _.round(number, precision).toLocaleString(undefined, {
                    minimumFractionDigits: 4,
                });
                return formatted;
            }
        };

        const onSelected = (quantity) => {
            if (onQuantitySelected) {
                onQuantitySelected(quantity);
            }
        };

        return (
            <>
                {/* TODO: Apply country specific formatting for numeric values */}
                <div data-testid={canSell ? "sku-quantity-selection" : ""} className={"grid-item" + (selected ? " selected left leftSelect" : "") + (!canSell ? " nosell" : "")} onClick={() => onSelected(quantity)}>
                    {formatNumberWithCommas(quantity)}
                </div>

                <div className={"grid-item" + (selected ? " selected" : "") + (!canSell ? " nosell" : "")} onClick={() => onSelected(quantity)}>
                    {formatDecimalWithCommas(unitPrice, 4, 4, true)}/pouch
                </div>

                <div className={"grid-item" + (selected ? " selected" : "") + (!canSell ? " nosell" : "")} onClick={() => onSelected(quantity)}>
                    {formatDecimalWithCommas(unitPrice * quantity, 2, 2, true)}
                </div>

                <div className={"grid-item" + (selected ? " selected right rightSelect" : "") + (!canSell ? " nosell" : "")} onClick={() => onSelected(quantity)}>
                    {savingsPercentage > 0 &&
                        <label className="savings" >
                            <span> Save {savingsPercentage}%</span>
                        </label>
                    }
                </div>
            </>
        );
    }
);

