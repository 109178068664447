import { Logger } from "./Logger";
import { msalInstance } from "../index";
import { loginRequest, protectedResources } from "../authConfig";

export class SecureApiResonse {

    constructor(statusCode, data, statusText) {
        this.statusCode = statusCode;
        this.data = data;
        this.statusText = statusText;
        this.hasSuccess = this.statusCode >= 200 && this.statusCode <= 299;
    }
}

export class SecureApiDataAccess {
    constructor(baseUrl, settings = SecureApiDataAccess.settings) {
        if (!("fetch" in window)) {
            throw Error("Browser does not support fetch API");
        }

        this.props = {
            baseUrl: baseUrl,
            cacheName: "default-cache",
            settings: settings,
        };
    }

    static settings = {
        mode: "cors", // no-cors, cors, *same-origin
        cache: "default", // *default, no-cache, reload, force-cache, only-if-cached
        credentials: "include", // include, same-origin, *omit
        redirect: "follow", // manual, *follow, error
        referrer: "no-referrer", // no-referrer, *client
        headers: {
            "Content-Type": "application/json; charset=utf-8",
            "Accept-Language": "*",
        },
    };
    
    async #getToken() {
        console.log(msalInstance);
        const account = msalInstance.getActiveAccount();
        if (!account) {
            throw Error("No active account! Verify a user has been signed in and setActiveAccount has been called.");
        }
        const response = await msalInstance.acquireTokenSilent({
            scopes: [...protectedResources.shopApp.scopes],
            ...loginRequest,
            account: account
        });
        if (!response.accessToken){
            throw new Error("Could not retrieve access token.");
        }

        return response.accessToken;
    };

    getAbsoluteUrl(url) {
        return this.props.baseUrl + url;
    }

    async get(url, accessToken, responseHandlerOptions) {
        if (!accessToken) {
            accessToken = await this.#getToken();
        }
        var settings = { ...this.props.settings };
        const bearer = `Bearer ${accessToken}`;
        
        settings.headers["Authorization"] = bearer;
        settings.method = "GET";

        var uri = this.getAbsoluteUrl(url);
        Logger.writeInfo("SecureApiDataAccess.get.request - " + uri, settings);

        try {
            var response = await fetch(uri, settings);
            
            return await this.#getJsonResponse(response, responseHandlerOptions);
        }
        catch (ex) {
            Logger.writeError("SecureApiDataAccess.get - " + uri, ex);
            throw ex;
        }
    }

    async postAsJson(url, data, accessToken, responseHandlerOptions) {
        if (!accessToken) {
            accessToken = await this.#getToken();
        }

        var settings = { ...this.props.settings };
        const bearer = `Bearer ${accessToken}`;
        
        settings.headers["Authorization"] = bearer;
        settings.method = "POST";
        settings.body = JSON.stringify(data);

        var uri = this.getAbsoluteUrl(url);
        Logger.writeInfo("SecureApiDataAccess.post.request - " + uri, settings);

        try {
            var response = await fetch(uri, settings);
            
            return await this.#getJsonResponse(response, responseHandlerOptions);
        }
        catch (ex) {
            Logger.writeError("SecureApiDataAccess.post - " + uri, ex);
            throw ex;
        }
    }

    async put(url, data, accessToken, responseHandlerOptions) {
        if (!accessToken) {
            accessToken = await this.#getToken();
        }

        var settings = { ...this.props.settings };
        const bearer = `Bearer ${accessToken}`;

        settings.headers["Authorization"] = bearer;
        settings.method = "PUT";
        settings.body = JSON.stringify(data);

        var uri = this.getAbsoluteUrl(url);
        Logger.writeInfo("SecureApiDataAccess.put.request - " + uri, settings);

        try {
            var response = await fetch(uri, settings);

            return await this.#getJsonResponse(response, responseHandlerOptions);
        }
        catch (ex) {
            Logger.writeError("SecureApiDataAccess.put - " + uri, ex);
            throw ex;
        }
    }

    async delete(url, accessToken, responseHandlerOptions) {
        if (!accessToken) {
            accessToken = await this.#getToken();
        }

        var settings = { ...this.props.settings };
        const bearer = `Bearer ${accessToken}`;

        settings.headers["Authorization"] = bearer;
        settings.method = "DELETE";

        var uri = this.getAbsoluteUrl(url);
        Logger.writeInfo("SecureApiDataAccess.delete.request - " + uri, settings);

        try {
            var response = await fetch(uri, settings);

            return await this.#getJsonResponse(response, responseHandlerOptions);
        }
        catch (ex) {
            Logger.writeError("SecureApiDataAccess.delete - " + uri, ex);
            throw ex;
        }
    }

    async deleteAsync(url, data, accessToken, responseHandlerOptions) {
        if (!accessToken) {
            accessToken = await this.#getToken();
        }

        var settings = { ...this.props.settings };
        const bearer = `Bearer ${accessToken}`;

        settings.headers["Authorization"] = bearer;
        settings.method = "DELETE";
        settings.body = JSON.stringify(data);

        var uri = this.getAbsoluteUrl(url);
        Logger.writeInfo("SecureApiDataAccess.delete.request - " + uri, settings);

        try {
            var response = await fetch(uri, settings);

            return await this.#getJsonResponse(response, responseHandlerOptions);
        }
        catch (ex) {
            Logger.writeError("SecureApiDataAccess.delete - " + uri, ex);
            throw ex;
        }
    }

    async #getResponse(apiResponse) {
        
        const contentType = apiResponse.headers.get("content-type");
        
        if (contentType && contentType.indexOf("application/json") !== -1) {
            return await this.getJsonResponse(apiResponse); 
        }
    }

    async getTextResponse(apiResponse) {
        return await apiResponse.text();
    }

    #throwIfUnauthorizedOrForbidden(apiResponse) {
        if (apiResponse.status === 0 || apiResponse.status === 401 || apiResponse.status === 403) {
            throw new Error(`Unauthorized response received. Status code was ${apiResponse.status}`);
        }
    }

    #throwIfNonSuccess(apiResponse) {
        if (!apiResponse.ok){
            throw new Error("Request was not successful. Response status code was " + apiResponse.status);
        }
    }

    async #getJsonResponse(apiResponse, responseHandlerOptions) {

        if (!responseHandlerOptions) {
            this.#throwIfNonSuccess(apiResponse);
        }
        else {
            if (responseHandlerOptions.throwOnNonSuccess)
            {
                this.#throwIfNonSuccess(apiResponse);
            }
        }

        this.#throwIfUnauthorizedOrForbidden(apiResponse);
        
        const contentType = apiResponse.headers.get("content-type");
        
        if (contentType && (contentType.indexOf("application/json") !== -1 || contentType.indexOf("application/problem+json" !== -1))) {
            var json = await apiResponse.json();
            return new SecureApiResonse(apiResponse.status, json, apiResponse.statusText);
        }
        Logger.writeWarn("Received a successful response but could not find application/json in the content type. Attempting to read body of response as text");
        
        var text = await apiResponse.text();

        return new SecureApiResonse(apiResponse.status, text, apiResponse.statusText);
    }

    evaluateResponse(response) {
        if (response.status === 0 || response.status === 401 || response.status === 403) {
            console.log(
                `Unauthorized response received. Status code was ${
                    response.status
                }. Redirecting to ${encodeURIComponent(window.location.pathname)}`
            );
            window.location.replace("/account/login?returnUrl=" + encodeURIComponent(window.location.pathname));
            window.location.href = "/account/login?returnUrl=" + encodeURIComponent(window.location.pathname);
            //window.location.replace('/');
            return false;
        } else {
            return new Promise((resolve, reject) => {
                const contentType = response.headers.get("content-type");

                if (contentType && contentType.indexOf("application/json") !== -1) {
                    response
                        .json()
                        .then(function (json) {
                            Logger.writeDebug("ApiDataAccess.response.json - " + response.url, json);
                            if (response.ok) {
                                resolve(json);
                            } else {
                                reject(json);
                            }
                        })
                        .catch((err) => {
                            Logger.writeError("ApiDataAccess.response.json - " + response.url, err);
                            reject(err);
                        });
                } else {
                    if (response.status === 204) {
                        if (response.ok) {
                            resolve({ hasSuccess: true });
                        } else {
                            reject();
                        }
                    } else {
                        response
                            .text()
                            .then(function (json) {
                                Logger.writeDebug("ApiDataAccess.response.text - " + response.url, json);
                                if (response.ok) {
                                    resolve(json);
                                } else {
                                    reject(json);
                                }
                            })
                            .catch((err) => {
                                Logger.writeError("ApiDataAccess.response.text - " + response.url, err);
                                reject(err);
                            });
                    }
                }
            });
        }
    }
}
