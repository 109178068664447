import React, { Component } from "react";

export const Column = ({ width, className, children }) => {
  const displayName = Column.name;

  return (
    <div
      className={
        width ? "column w" + width + " " + (className ?? "") : "column w2 " + (className ?? "")
      }
    >
      <div className="spacer">{children}</div>
    </div>
  );
};
