import React, { useEffect } from "react";

export const BackSplash = ({ onClick, children, clear, noscroll }) => {
    useEffect(() => {
        if (noscroll !== false) {
            document.body.classList.add("noscroll");
        }

        return () => document.body.classList.remove("noscroll");
    }, []);

    const onClicked = () => {
        if (onClick) {
            onClick(this);
        }
    };

    return (
        <div className={clear === true ? "backSplash clear" : "backSplash"} onClick={onClicked}>
            {children}
        </div>
    );
};
