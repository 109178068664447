import React, { useState, useEffect } from "react";
import { useTracking } from "react-tracking";
import { useCookies } from 'react-cookie';
import { ShoppingBreadcrumbs, OrderReference } from '../../core/Breadcrumbs';
import { Card } from "../../core/Card";
import { Row } from "../../core/Row";
import { Column } from "../../core/Column";
import { CapabilityName, SalesChannel, CountriesWithSubSelection } from "../../infrastructure/Constants";
import { useForm, get, useFieldArray } from "react-hook-form";
import OrderPlacementApi from "../../infrastructure/OrderPlacementApi";
import { useGlobalOfferContext } from "../../infrastructure/GlobalOfferId";
import { DisplayAddress } from "../../components/offer/DisplayAddress";
import { EditArtworkContact } from "../../components/offer/EditArtworkContact";
import { EditEpacConnectContact } from "../../components/offer/EditEpacConnectContact";
import { PurchaseOrderUpload } from "../../components/offer/PurchaseOrderUpload";
import { Translation } from "../../core/Translation";
import { SkuEditor } from "../../components/offer/SkuEditor";
import { HookEditAddress } from "../../components/offer/HookEditAddress";
import { AdditionalShippingAddressEditor } from "../../components/offer/AdditionalShippingAddressEditor";
import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import { CapabilitiesContext } from "../../infrastructure/Contexts";
import { ApiDataAccess } from '../../infrastructure/ApiDataAccess';
import { SettingsContext } from '../../infrastructure/Contexts';

import "./ProductSpecs.css";
import "./EditSkusAddress.css";


export const EditSkusAddress = ({ status, orderReference, orderConfiguration, salesforceAccount, offer, analyticsMetadata, globalOffer }) => {

    const navigate = useNavigate();
    const orderPlacementApi = new OrderPlacementApi("/");

    const [loaded, setLoaded] = useState(false);
    const { trackEvent } = useTracking({ page: "QuantitiesShippingPage" });

    const { offerContext, loadOfferContextStatus, saveContext } = useGlobalOfferContext(globalOffer.globalOfferId);

    const {
        register,
        handleSubmit,
        trigger,
        unregister,
        setValue,
        formState: { errors, isValidating, isSubmitting, },
        getValues,
        reset,
        control,
        watch,
        setError,
        clearErrors,
        setFocus,
        resetField
    } = useForm({
        shouldFocusError: true,
        defaultValues: {
            shippingAddress: {
                name: "",
                addressLine1: "",
                addressLine2: "",
                city: "",
                state: "",
                postalCode: "",
                country: "",
                specialInstructions:""
            },
            billingAddress: {
                name: "",
                addressLine1: "",
                addressLine2: "",
                city: "",
                state: "",
                postalCode: "",
                country: ""
            },
            additionalShippingAddress: {
                name: "",
                addressLine1: "",
                addressLine2: "",
                city: "",
                state: "",
                postalCode: "",
                country: "",
                specialInstructions: "",
                salesforceLocationId: "",
                accountId: ""
            },
            hasAdditionalShippingAddresses: false,
            artworkContact: {
                firstName: "",
                lastName: "",
                email: "",
                phone: "",
            },
            skuLineItems: [],
            poNumber: "",
            epacConnectData: {
                firstName: "",
                lastName: "",
                emailAddress: "",
            },
            selectedQuantityBreak: "",
            plantPickup: false,
            plantPickupName: null,
            checkoutMetadata: {
                useShippingAddressForBillingAddress: false,
                purchaseOrderUploadAttachmentId: "",
                purchaseOrderFileName: "",
                epacConnectRegistrationRequired: false
            },
            skuTotal: 0
        }
    });

    const { fields, append } = useFieldArray({
        control,
        name: "skuLineItems",
    });

    const { fields: additionalShippingAddresses, append: appendAddress, remove: removeAddress } = useFieldArray({
        control,
        name: "additionalShippingAddresses",
    });

    const watchUseBillingForShipping = watch("checkoutMetadata.useShippingAddressForBillingAddress");
    const requiresBillingAddress = true;

    const [plantAddress, setPlantAddress] = useState(null);
    const [epacConnectRegistrationForm, setEpacConnectRegistrationForm] = useState(false);
    const [requiresArtworkContact, setRequiresArtworkContact] = useState(false);
    const [plantPickup, setPlantPickup] = useState(null);
    const [defaultPlantPickupAddress, setDefaultPlantPickupAddress] = useState(null);
    const [defaultShippingAddress, setDefaultShippingAddress] = useState(null);
    const [defaultBillingAddress, setDefaultBillingAddress] = useState(null);
    const [salesChannel, setSalesChannel] = useState(null);
    const [countries, setCountryListState] = useState([]);
    const [plantPickupLocations, setPlantPickupLocations] = useState([]);
    const [additionalShippingAddressSalesforceOptions, setAdditionalShippingAddressOptions] = useState([]);
    const [hasAdditionalShippingAddresses, setHasAdditionalShippingAddresses] = useState(false);
    const [cookies, setCookie] = useCookies(['visitorId']);
    const [visitorId, setVisitorId] = useState(null);


    const capabilitiesContext = useContext(CapabilitiesContext);
    const loader = capabilitiesContext.getCapability(CapabilityName.Loader);

    const settings = useContext(SettingsContext);
    const paymentsDisabledOnRegion = orderConfiguration ? orderConfiguration.paymentsDisabled : false;

    const hasPlantPickupOptionsEnabled = () => settings && settings.settings["Application:PlantPickupOptionsEnabled"] === true;
    const companyName = () => settings ? settings.settings["Application:CompanyName"] : "";
    const hasAdditionalShippingAddressesFeatureEnabled = settings.settings["ApiFeatureSettings:HasAdditionalShippingAddressesEnabled"] === true;
    const testRoll = offer?.testRollType;

    useEffect(() => {
        isSubmitting ? loader.show() : loader.hide();
    }, [isSubmitting]);

    /*Clean up the lingering modal until it can be slightly refactored to unload correctly */
    useEffect(() => {
        return () => {
            loader.hide();
        };
    }, []);

    //Initialization
    useEffect(() => {
        var plantId = offer.plant.id;
        const getPlants = async () => {
            var dataAccess = new ApiDataAccess('api/application');
            var data = await dataAccess.get("/plantAddresses/" + plantId);
            if (data) {
                setPlantPickupLocations(data)
            }
        }



        const getCountryList = async () => {
            var result = await orderPlacementApi.getCountries();
            if (result.hasSuccess) {
                if (salesChannel === SalesChannel.Website) {
                    var country = result.response.find(
                        (x) => x.value.toLowerCase() === offer.plant.country.toLowerCase()
                    );
                    //console.log(country)
                    setCountryListState({
                        countries: [country],
                    });
                } else {
                    setCountryListState({
                        countries: result.response,
                    });
                }
            }


        };

        const getArtworkContact = async () => {
            var data = await orderPlacementApi.getArtworkContacts(offer.salesForceAccountId);
            if (data.hasSuccess && data.response && data.response.length > 0) {
                console.log("Artwork Contact found", data.response[0]);
                return data.response[0];
            }
        };

        const getEpacConnectUser = async () => {
            var data = await orderPlacementApi.getEpacConnectUser(offer.salesForceAccountId);
            if (data.response) {
                console.log("retrieved epac connect user", data.response);
                return data.response;
            }

            return null;
        };


        const initialize = async () => {

            if (loadOfferContextStatus !== "success")
                return;

            console.group("%c edit-skus-address init", "background: #333; color: #3ac9b4");

            var showEpacConnectRegistration = (offer.packagingFeature.qrCodeType && offer.packagingFeature.qrCodeType.id > 1) === true;
            setSalesChannel(offer.salesChannel ? offer.salesChannel : null);
            // let initialAddress = {};
            await getCountryList();

            var billingAddress = {};
            var shippingAddress = {};
            
            var additionalShippingAddressSalesforceOptions = [];

            var initialEpacConnect = {};

            console.log("hasPlantPickupOptionsEnabled", hasPlantPickupOptionsEnabled());
            console.log("companyName", companyName);

            var plantAddress = null;
            if (hasPlantPickupOptionsEnabled()) {
                await getPlants();
            } else {

                var ma = offer.plant.manufacturingAddress;
                console.log(ma)

                if (ma) {
                    plantAddress = {
                        name: salesforceAccount ? salesforceAccount.name : '',
                        addressLine1: ma.addressLine1,
                        addressLine2: ma.addressLine2,
                        city: ma.city,
                        country: ma.countryName,
                        //countryId: 1,
                        postalCode: ma.postalCode,
                        state: ma.stateName,
                    };
                    setPlantAddress(plantAddress);
                    setDefaultPlantPickupAddress(plantAddress);
                }
            }

            if (offerContext?.shippingAddress) {
                shippingAddress = offerContext.shippingAddress;

                console.log("defaulting shipping to saved offer context", offerContext.shippingAddress);
            } else {
                var shippingAddressFromServer = await orderPlacementApi.getShippingAddress(offer.salesForceAccountId);

                console.log("defaulting shipping to api response", shippingAddress.response);

                shippingAddress = {
                    name: shippingAddressFromServer.response.name ? shippingAddressFromServer.response.name : salesforceAccount ? salesforceAccount.name : '',
                    addressLine1: shippingAddressFromServer.response.street,
                    addressLine2: null,
                    city: shippingAddressFromServer.response.city,
                    state: shippingAddressFromServer.response.state,
                    postalCode: shippingAddressFromServer.response.postalCode,
                    country: shippingAddressFromServer.response.country,
                    specialInstructions: shippingAddressFromServer.response.specialInstructions

                };
            }
            setDefaultShippingAddress(shippingAddress);

            if (offerContext?.billingAddress) {
                console.log("defaulting billing address to saved offer context", offerContext.billingAddress);
                if (offerContext.checkoutMetadata?.useShippingAddressForBillingAddress) {
                    billingAddress = offerContext.shippingAddress;
                } else {
                    billingAddress = offerContext?.billingAddress;
                }
            } else {
                var billingAddressFromServer = await orderPlacementApi.getBillingAddress(offer.salesForceAccountId);
                console.log(billingAddressFromServer);
                billingAddress = {
                    name: billingAddressFromServer.response.name ? billingAddressFromServer.response.name : salesforceAccount ? salesforceAccount.name : '',
                    addressLine1: billingAddressFromServer.response.street,
                    addressLine2: null,
                    city: billingAddressFromServer.response.city,
                    state: billingAddressFromServer.response.state,
                    postalCode: billingAddressFromServer.response.postalCode,
                    country: billingAddressFromServer.response.country,
                    email: billingAddressFromServer.response.email
                };
            }
            setDefaultBillingAddress(billingAddress);


            console.log("LOADED OFFERCONTEXT:", offerContext)

            // hide plant pickup options if there are additional shipping addresses
            if (offerContext?.additionalShippingAddresses?.length > 0) {
                setHasAdditionalShippingAddresses(true)
            }
            else {
                setHasAdditionalShippingAddresses(false)
            }

            let additionalShippingAddressesFromContext = [];

            // retrieve salesforce account shipping locations to choose from
            var additionalShipingAddressesFromServer = await orderPlacementApi.getAdditionalShippingAddresses(offer.salesForceAccountId);
            if (additionalShipingAddressesFromServer.hasSuccess) {
                additionalShipingAddressesFromServer.response.forEach(address => {
                    var sa = {
                        name: address.name ? address.name : salesforceAccount ? salesforceAccount.name : '',
                        addressLine1: address.streetAddress1,
                        addressLine2: address.streetAddress2,
                        city: address.city,
                        state: address.state,
                        postalCode: address.postalCode,
                        country: address.country,
                        specialInstructions: address.specialInstructions,
                        salesforceLocationId: address.salesforceLocationId,
                        accountId: address.accountId
                    };
                    additionalShippingAddressSalesforceOptions.push(sa);
                })
            }

            if (offerContext?.additionalShippingAddresses) {
                offerContext.additionalShippingAddresses.forEach(item => {
                    additionalShippingAddressesFromContext.push(item);
                })
            }
            if (additionalShippingAddressSalesforceOptions.length > 0) {
                setAdditionalShippingAddressOptions(additionalShippingAddressSalesforceOptions);
            }

           console.log("DEBUG >> additionalShippingAddressSalesforceOptions:: ", additionalShippingAddressSalesforceOptions)

            if (offerContext?.epacConnectData) {
                console.log("use offerContext epacConnect");
                //If we still haven't saved the data off, go ahead and allow the user to edit it
                initialEpacConnect = offerContext.epacConnectData;

                var epacConnectUserData = await getEpacConnectUser();
                if (epacConnectUserData == null) {
                    showEpacConnectRegistration = true;
                }
            } else {
                if (showEpacConnectRegistration) {
                    console.log("use search for epac connect");

                    var epacConnectDataResponse = await getEpacConnectUser();

                    if (epacConnectDataResponse != null) {

                        initialEpacConnect = {
                            firstName: epacConnectDataResponse.firstName,
                            lastName: epacConnectDataResponse.lastName,
                            emailAddress: epacConnectDataResponse.email,
                            accountToken: epacConnectDataResponse.accountToken
                        };
                        showEpacConnectRegistration = false;
                    }
                }
            }

            setEpacConnectRegistrationForm(showEpacConnectRegistration);

            let initialArtworkContact = {};
            if (offerContext?.artworkContact) {
                initialArtworkContact = {
                    contactId: offerContext.artworkContact.contactId,
                    firstName: offerContext.artworkContact.firstName,
                    lastName: offerContext.artworkContact.lastName,
                    name: offerContext.artworkContact.name,
                    email: offerContext.artworkContact.email,
                    phone: offerContext.artworkContact.phone,
                };
            }
            else {
                var contact = await getArtworkContact();
                console.log("artwork required?", contact && !contact.email);
                setRequiresArtworkContact(!contact || (contact && !contact.email));

                if (contact) {

                    var name = getFirstLastFromName(contact.name);

                    initialArtworkContact = {
                        contactId: contact.id,
                        firstName: name.firstName,
                        lastName: name.lastName,
                        name: contact.name,
                        email: contact.email,
                        phone: contact.phone,
                    };
                }
                else {
                    initialArtworkContact = {
                        contactId: null,
                        name: null,
                        firstName: null,
                        lastName: null,
                        email: null,
                        phone: null,
                    };
                }
            }

            let skuLineItemsFromContext = [];

            if (offerContext && loadOfferContextStatus === "success") {
                if (offerContext.skuLineItems) {
                    console.log("Set default sku data from offer context", offerContext.skuLineItems);
                    var skuItems = [];

                    offerContext.skuLineItems.forEach((sku, index) => {
                        var description = testRoll != null ? "Test Roll" : sku.description
                        skuItems.push({
                            approvedSkuId: sku.approvedSkuId,
                            description: description,
                            quantity: sku.quantity,
                            unitPrice: sku.unitPrice,
                        });
                    });
                    skuLineItemsFromContext = skuItems;

                    console.log("reconstructed sku line item data", skuLineItemsFromContext);
                }
                else if (testRoll) {
                    append({
                        description: "Test Roll",
                        quantity: 1,
                        unitPrice: offer.calculationBreakdown[0].price.unitPrice.amount
                    });
                }
            }
            else if (!offerContext && testRoll) {
                append({
                    description: "Test Roll",
                    quantity: 1,
                    unitPrice: offer.calculationBreakdown[0].price.unitPrice.amount
                });
            }
            
            var finalUpdate = {
                shippingAddress: shippingAddress,
                billingAddress: billingAddress,
                additionalShippingAddresses: additionalShippingAddressesFromContext ? additionalShippingAddressesFromContext: [],
                poNumber: offerContext?.poNumber,
                artworkContact: initialArtworkContact,
                skuLineItems: skuLineItemsFromContext ? skuLineItemsFromContext : [],
                epacConnectData: initialEpacConnect,
                plantPickup: offerContext?.plantPickup,
                plantPickupName: offerContext?.plantPickupName,
                checkoutMetadata: {
                    useShippingAddressForBillingAddress: offerContext?.checkoutMetadata?.useShippingAddressForBillingAddress,
                    purchaseOrderFileName: offerContext?.checkoutMetadata?.purchaseOrderFileName,
                    purchaseOrderUploadAttachmentId: offerContext?.checkoutMetadata?.purchaseOrderUploadAttachmentId,
                    epacConnectRegistrationRequired: showEpacConnectRegistration
                },
            };

            setPlantPickup(offerContext?.plantPickup);

            setPlantAddress(shippingAddress);

            console.log("%c final update", "background: #333; color: #bada55", finalUpdate);

            reset(finalUpdate);

            setLoaded(true);
            console.groupEnd();

        };

        if (status !== "success" || !offer) {
            console.log("useEffect offer not initialized yet");
            return;
        }

        if (loadOfferContextStatus === "success" && !offerContext) {
            console.log("Load offer context is empty after loading!");
        }

        var id = cookies.visitorId;
        if (id) {
            setVisitorId(id);
        }

        console.log(
            "initialize status, offercontext status, loaded",
            status,
            loadOfferContextStatus,
            loaded,
            offer,
            offerContext
        );
        if (!loaded) {
            console.log("not loaded, initializing");
            initialize();
        }
    }, [offer, offerContext]);

    const getFirstLastFromName = (name) => {
        if (name) {
            name = name.replace(/\s\s+/g, " ");

            var idx = name.indexOf(" ");
            return idx > 0
                ? {
                    firstName: name.substring(0, idx),
                    lastName: name.substring(idx + 1),
                }
                : {
                    firstName: "",
                    lastName: name,
                };
        }
        return {
            firstName: "",
            lastName: "",
        };
    };


    const onError = (errors, e) => {
        console.log("%c errors", "color: #ff00000", errors);
    }

    const onSubmit = async (data) => {
        console.group("%c submit-for-checkout", "background: #333; color: #bada55");
        console.log("form payload", data);

        if (data.checkoutMetadata?.useShippingAddressForBillingAddress) {
            data.billingAddress.name = data.shippingAddress.name;
            data.billingAddress.addressLine1 = data.shippingAddress.addressLine1;
            data.billingAddress.addressLine2 = data.shippingAddress.addressLine2;
            data.billingAddress.city = data.shippingAddress.city;
            data.billingAddress.state = data.shippingAddress.state;
            data.billingAddress.postalCode = data.shippingAddress.postalCode;
            data.billingAddress.country = data.shippingAddress.country;
            data.billingAddress.name = data.shippingAddress.name;
            data.billingAddress.email = data.shippingAddress.email
        }

        var result = await saveContext(data, 'skus');

        console.log("the save result", result);


        if (result.hasSuccess) {
            console.log("plant address data: ", plantPickupLocations)

            console.log("Form save was executed", offerContext, data);

            console.groupEnd();

            trackEvent({ ecommerce: null });  // Clear the previous ecommerce object.
            trackEvent({
                event: 'add_shipping_info',
                ecommerce: {
                    items: [
                        {

                            item_id: offer.product.id,
                            item_name: offer.product.value,
                            currency: offer.plant.currency,
                            //  index: quoteCount,            //the nth quote added, starting at 1
                            unit_price: offer.calculationBreakdown[0].price.unitPrice.amount,
                            item_category: offer.productGroup.value,  //what packaging category
                            item_category2: analyticsMetadata.industryCategoryName,      //what packaging sub-category
                            item_category3: offer.convertingMethod.componentType.value,     //pouch type
                            item_category4: offer.dimensions.width + " x " + offer.dimensions.length,      // width x length
                            price: offer.calculationBreakdown[0].price.totalPrice.amount,
                            quantity: offer.calculationBreakdown[0].quantityBreak,
                            skuCount: offer.skuCount
                        }
                    ],
                    shipping_tier: plantPickup ? "Pickup" : "Ground",
                    currency: offer.plant.currency,
                    value: offer.calculationBreakdown[0]?.initiallyDue?.shipping?.unitPrice?.amount
                },
                userId: cookies.visitorId
            });
            navigate(`/offer/${globalOffer.globalOfferId}/review`);
        } else {
            if (result.statusCode === 400) {

                if (result.response && result.response['epacConnectData.emailAddress']) {
                    setError("epacConnectData.emailAddress", {
                        type: "validation",
                        message: result.response["epacConnectData.emailAddress"][0]
                    }, {
                        shouldFocus: true
                    });
                }
            }
            else {
                setError("Unknown error");
            }
        }
    }


    const onPlantPickUpSelected = (value, valid) => {
        setPlantPickup(value); //true or false

        if (value === true) {
            setValue("checkoutMetadata.useShippingAddressForBillingAddress", false);
            setValue('billingAddress', defaultBillingAddress);
            if (!hasPlantPickupOptionsEnabled()) {
                setValue('shippingAddress.addressLine1', defaultPlantPickupAddress.addressLine1);
                setValue('shippingAddress.addressLine2', defaultPlantPickupAddress.addressLine2);
                setValue('shippingAddress.city', defaultPlantPickupAddress.city);
                setValue('shippingAddress.state', defaultPlantPickupAddress.state);
                setValue('shippingAddress.postalCode', defaultPlantPickupAddress.postalCode);
                setValue('shippingAddress.country', defaultPlantPickupAddress.country);
                setPlantAddress(defaultPlantPickupAddress)
            }
        }
        else if (defaultShippingAddress) {
            setValue('shippingAddress.addressLine1', defaultShippingAddress.addressLine1);
            setValue('shippingAddress.addressLine2', defaultShippingAddress.addressLine2);
            setValue('shippingAddress.city', defaultShippingAddress.city);
            setValue('shippingAddress.state', defaultShippingAddress.state);
            setValue('shippingAddress.postalCode', defaultShippingAddress.postalCode);
            setValue('shippingAddress.country', defaultShippingAddress.country);
            setValue('plantPickupName', null);
            setPlantAddress(defaultShippingAddress);
        }
        else
            reset({
                shippingAddress: {
                    addressLine1: '',
                    addressLine2: '',
                    city: '',
                    state: '',
                    postalCode: '',
                    country: '',
                    name: ''
                }
            });
    };



    const onSelectPickupLocation = (value) => {
        var p = plantPickupLocations.find(x => x.name === value);
        var pickupPlantAddress = p ? p : plantAddress;

        setPlantAddress(pickupPlantAddress)

        if (plantPickup === true) {
            setValue('shippingAddress.addressLine1', pickupPlantAddress.addressLine1);
            setValue('shippingAddress.addressLine2', pickupPlantAddress.addressLine2);
            setValue('shippingAddress.city', pickupPlantAddress.city);
            setValue('shippingAddress.state', pickupPlantAddress.state);
            setValue('shippingAddress.postalCode', pickupPlantAddress.postalCode);
            setValue('shippingAddress.country', pickupPlantAddress.country);
            setValue('plantPickupName', value);
        }
        else {
            reset({
                shippingAddress: {
                    addressLine1: '',
                    addressLine2: '',
                    city: '',
                    state: '',
                    postalCode: '',
                    country: '',
                    name: ''
                },
                plantPickupName: null
            });
        }
    }

    const toggleShippingPickupOptionsAreHidden = (flag) => { 
        setHasAdditionalShippingAddresses(flag);
    }
 


    return (
        loaded &&
        offer &&
        status === "success" && (
            <div>
                <ShoppingBreadcrumbs active={6} ignore={offer.salesChannel == 'Estimator' ? 4 : 0} paymentsDisabled={paymentsDisabledOnRegion} />
                <OrderReference orderReference={orderReference} />

                <div className="reviewQuantitiesStep">
                    {
                        <form onSubmit={handleSubmit(onSubmit, onError)}>
                            <Card title={<Translation id="56f84d4a-d8cd-4c89-ab30-651e599059af" defaultMessage="Quantities" category="Card Title" />} >
                                <SkuEditor
                                    offerInfo={offer}
                                    control={control}
                                    orderPlacementApi={orderPlacementApi}
                                    skus={fields}
                                    register={register}
                                    getErrorMessage={get}
                                    errors={errors}
                                    setValue={setValue}
                                    append={append}
                                    clearErrors={clearErrors}
                                    setError={setError}
                                    getValues={getValues}
                                    watch={watch}
                                    trigger={trigger}
                                    trackEvent={trackEvent}
                                    analyticsMetadata={analyticsMetadata}
                                    orderConfiguration={orderConfiguration}
                                />
                            </Card>

                            <Card
                                title={
                                    <Translation
                                        id="7469dfd8-be15-4ece-a8fc-2a42f3bd20bf"
                                        defaultMessage="Shipping Address"
                                        category="Card Title"
                                    />
                                }
                                subtitle={
                                    <Translation
                                        id="729795b8-df2b-47bc-9867-a2ceb5e705a8"
                                        defaultMessage="Please enter your Shipping Address"
                                        category="Card Sub Title"
                                    />
                                }
                            >
                                {plantPickup ? (
                                    <DisplayAddress address={plantAddress} />
                                ) : (
                                    <div>
                                        <HookEditAddress
                                            control={control}
                                            unregister={unregister}
                                            getErrorMessage={get}
                                            trigger={trigger}
                                            setValue={setValue}
                                            getValues={getValues}
                                            register={register}
                                            countriesForStateValidation={CountriesWithSubSelection}
                                            errors={errors}
                                            countries={countries.countries}
                                            id="shippingAddress"
                                            addressType="shippingAddress"
                                            orderPlacementApi={orderPlacementApi}
                                            requireEmail={getValues("checkoutMetadata.useShippingAddressForBillingAddress")}
                                            requireCompanyName={true}
                                            reset={reset}
                                        />

                                    </div>
                                )}



                                {requiresBillingAddress && !plantPickup && hasAdditionalShippingAddresses === false && (
                                    <div>
                                        <label
                                            htmlFor="useShippingForBillingId"
                                            className="plantPickup controlContainer control checkbox"
                                        >
                                            <input
                                                data-testid="order-placement-use-shipping-for-billing-checkbox"
                                                id="useShippingForBillingId"
                                                type="checkbox"
                                                className="checkbox"
                                                {...register("checkoutMetadata.useShippingAddressForBillingAddress", {
                                                    onChange: (e) => {
                                                        setValue("billingAddress", {
                                                            name: getValues("shippingAddress.name"),
                                                            addressLine1: getValues("shippingAddress.addressLine1"),
                                                            addressLine2: getValues("shippingAddress.addressLine2"),
                                                            city: getValues("shippingAddress.city"),
                                                            state: getValues("shippingAddress.state"),
                                                            postalCode: getValues("shippingAddress.postalCode"),
                                                            country: getValues("shippingAddress.country")
                                                        },
                                                            {
                                                                shouldValidate: false
                                                            });
                                                    }
                                                })}
                                            />
                                            <div className="payload">
                                                <Translation
                                                    id="f4ac1e11-d620-44e3-bc34-1d65242398d9"
                                                    defaultMessage="Use this address for the Billing Address"
                                                    category="Label"
                                                />
                                            </div>
                                        </label>
                                    </div>
                                )}
                                {plantAddress && hasAdditionalShippingAddresses === false && (
                                    <div>
                                        <label
                                            htmlFor="plantPickupId"
                                            className="plantPickup controlContainer control checkbox">
                                            <input
                                                {...register("plantPickup")}
                                                id="plantPickupId"
                                                type="checkbox"
                                                className="checkbox"
                                                onChange={(e) => onPlantPickUpSelected(e.target.checked, true)}
                                            />
                                            <div className="payload">
                                                <Translation
                                                    id='adda43b3-ff83-48ab-9ff0-5e79c171fdf6'
                                                    defaultMessage='I will pick up my order at the {companyName} plant'
                                                    category='Label'
                                                    values={{ "companyName": companyName }}
                                                />
                                            </div>
                                        </label>
                                    </div>

                                )}

                                {plantPickup && hasPlantPickupOptionsEnabled() &&
                                    <div className="plantPickupControl">
                                        <Row>
                                            <Column>
                                                <select
                                                    {...register(`plantPickupName`, {
                                                        required: {
                                                            value: true,
                                                            message: 'Please Select Epac Plant'
                                                        }
                                                    })}
                                                    className="control dropdown"
                                                    onChange={(e) => {
                                                        setValue('plantPickupName', e.target.value);
                                                        onSelectPickupLocation(e.target.value);
                                                    }}
                                                >
                                                    <option key={0} value={""}>
                                                        Please Select Epac Plant...
                                                    </option>
                                                    {plantPickupLocations.map((item, i) => {
                                                        return (
                                                            <option key={item.plantId} value={item.name}>
                                                                {item.name}
                                                            </option>
                                                        );
                                                    })}

                                                </select>


                                            </Column>

                                            <Column>
                                                <span className="required">*</span>
                                                <span><i>Shipping Charges Will Be Waived</i></span>
                                            </Column>

                                        </Row>



                                    </div>
                                }
                            </Card>

                            {hasAdditionalShippingAddressesFeatureEnabled === true &&
                                    <div>
                                        <Card
                                            title={
                                                <Translation
                                                    id="4d19c765-701d-49e5-b8a9-d9db3679403c"
                                                    defaultMessage=" "
                                                    category="Card Title"
                                                />
                                            }
                                            subtitle={
                                                <Translation
                                                    id="b2583696-f1fb-4ccf-9d0d-c8ea3bbec458"
                                                    defaultMessage="Additional Shipping Addresses For This Order"
                                                    category="Card Sub Title"
                                                />
                                            }
                                        >
                                        <AdditionalShippingAddressEditor
                                            unregister={unregister}
                                            trigger={trigger}
                                            setValue={setValue}
                                            getValues={getValues}
                                            register={register}
                                            countriesForStateValidation={CountriesWithSubSelection}
                                            errors={errors}
                                            countries={countries.countries}
                                            id="additionalShippingAddress"
                                            addressType="additionalShippingAddress"
                                            orderPlacementApi={orderPlacementApi}
                                            requireCompanyName={true}
                                            reset={reset}
                                            resetField={resetField}
                                            control={control}
                                            append={appendAddress}
                                            remove={removeAddress}
                                            setFocus={setFocus}
                                            watch={watch}
                                            additionalShippingAddressOptions={additionalShippingAddressSalesforceOptions}
                                            additionalShippingAddresses={additionalShippingAddresses}
                                            toggleShippingPickupOptionsAreHidden={toggleShippingPickupOptionsAreHidden}
                                        />
                                    </Card>
                                    </div>
                                }


                            {!watchUseBillingForShipping && requiresBillingAddress && (
                                <Card
                                    title={
                                        <Translation
                                            id="9488d8c8-49fb-473a-9c98-698f8e943114"
                                            defaultMessage="Billing Address"
                                            category="Card Title"
                                        />
                                    }
                                    subtitle={
                                        <Translation
                                            id="b9aba4ff-c266-4434-81bb-e7ea50b42c43"
                                            defaultMessage="Please enter your Billing Address"
                                            category="Card Sub Title"
                                        />
                                    }
                                >
                                    <HookEditAddress
                                        control={control}
                                        register={register}
                                        trigger={trigger}
                                        unregister={unregister}
                                        reset={reset}
                                        getErrorMessage={get}
                                        countriesForStateValidation={CountriesWithSubSelection}
                                        getValues={getValues}
                                        errors={errors}
                                        setValue={setValue}
                                        countries={countries.countries}
                                        id="billingAddress"
                                        addressType="billingAddress"
                                        requireEmail={true}
                                        requireCompanyName={true}
                                        orderPlacementApi={orderPlacementApi}
                                    />
                                </Card>
                            )}

                            {epacConnectRegistrationForm && (
                                <Card
                                    title={
                                        <Translation
                                            id="1d540293-fff8-470f-b1ae-5fe3485d1179"
                                            defaultMessage="ePac Connect"
                                            category="Card Title"
                                        />
                                    }
                                    subtitle={
                                        <Translation
                                            id="f0d00ef6-9593-4cb6-aa94-05bdc3c94f8a"
                                            defaultMessage="Enter ePac Connect contact information to create an account"
                                            category="Card Sub Title"
                                        />
                                    }
                                >
                                    <EditEpacConnectContact
                                        orderPlacementApi={orderPlacementApi}
                                        errors={errors}
                                        getErrorMessage={get}
                                        register={register}
                                        trigger={trigger}
                                        setValue={setValue}
                                        getValues={getValues}
                                        isValidating={isValidating}
                                        setError={setError}
                                        clearErrors={clearErrors}
                                    />
                                </Card>
                            )}

                            {requiresArtworkContact && (
                                <Card
                                    title={
                                        <Translation
                                            id="2df7dd03-4e1e-4e4f-b9a8-89f04b490d61"
                                            defaultMessage="Artwork Contact"
                                            category="Card Title"
                                        />
                                    }
                                    subtitle={
                                        <Translation
                                            id="a10ae618-911a-4b40-b69f-79518ecfce81"
                                            defaultMessage="Contact for questions regarding your Artwork"
                                            category="Card Sub Title"
                                        />
                                    }
                                >
                                    <EditArtworkContact
                                        errors={errors}
                                        getErrorMessage={get}
                                        register={register}
                                        trigger={trigger}
                                    />
                                </Card>
                            )}

                            <Card
                                title={
                                    <Translation
                                        id="9209abb8-a2b0-41aa-8129-aa52dd821926"
                                        defaultMessage="Purchase Order & Attachments"
                                        category="Card Title"
                                    />
                                }
                                subtitle={
                                    <Translation
                                        id="3d4b8cf4-7c1f-4b55-af02-fc941bc114c9"
                                        defaultMessage="Optional"
                                        category="Card Sub Title"
                                    />
                                }
                            >
                                <PurchaseOrderUpload
                                    globalOfferId={globalOffer.globalOfferId}
                                    register={register}
                                    getErrorMessage={get}
                                    errors={errors}
                                    existingFileName={getValues("checkoutMetadata.purchaseOrderFileName")}
                                    onFileReceived={(file, attachmentId) => {
                                        console.log(file, attachmentId);
                                        setValue("checkoutMetadata.purchaseOrderUploadAttachmentId", attachmentId);
                                        setValue("checkoutMetadata.purchaseOrderFileName", file.name);
                                    }}
                                />
                            </Card>

                            <button
                                className="btn btn-tertiary-outline back"
                                onClick={(e) => {
                                    e.preventDefault();
                                    navigate(`/offer/${globalOffer.globalOfferId}/product`);
                                }}
                            ><span className='btn-text'><Translation id='03473ff0-be47-4bd3-9ed8-190fedf344c5' defaultMessage='Back' category='Button' /></span>
                            </button>

                            <div className="pull-right">
                                <button
                                    data-testid="order-placement-skus-next-button"
                                    className="btn btn-primary"
                                    disabled={isSubmitting}
                                    onClick={async () => {
                                        handleSubmit(async (data) => onSubmit(data));
                                    }}
                                >
                                    Next
                                </button>
                            </div>
                        </form>
                    }
                </div>
            </div>
        ));
}