import { FeatureLocationType } from "../../core/offer/Constants";

export class DielineHelper {

    static hasFeatureDependency(featureLocationType, featureDependencies) {
        var result = false;
        if (featureDependencies) {
            var code = this.getFeatureCode(featureLocationType);
            var idx = featureDependencies.findIndex((e) => e.child == code);
            if (idx > -1) {
                result = true;
            }
        }
        return result;
    };

    static getFeatureCode(featureLocationType) {
        switch (featureLocationType) {
            case FeatureLocationType.Zipper:
                return "ZP";
            case FeatureLocationType.TearNotch:
                return "TN";
            case FeatureLocationType.HangHole:
                return "HH";
            case FeatureLocationType.Valve:
                return "VL";
            default:
                throw new Error(`${featureLocationType} is not supported`);
        }
    };

    static hasFeatureError(feature, dielineErrors) {
        let err = dielineErrors.find((e) => e.feature1 === feature);
        return err ? true : false;
    };

    static getFeatureError(feature, dielineErrors) {
        let err = dielineErrors.find((e) => e.feature1 === feature);
        return err ? err.errorMessage : null;
    };
};
