import React, { useContext, useState } from "react";
import { Row } from "../../core/Row";
import { Column } from "../../core/Column";
import { Translation } from "../../core/Translation";
import { SectionTitle } from "../../core/SectionTitle";
import { Language } from "../../infrastructure/Languages";
import { useEffect } from "react";
import './HookEditMyAccountAddress.css';
import { Col } from "../../core/Grid";

export const HookEditMyAccountAddress = ({
    addressType,
    errors,
    countries,
    unregister,
    getValues,
    setValue,
    register,
    countriesForStateValidation,
    getErrorMessage,
    requireEmail,
    requireCompanyName,
    additionalFields,
    orderPlacementApi,
    reset,
    theCountry
}) => {
    const language = useContext(Language);
    const [selectedCountry, setSelectedCountry] = useState(null);
    const [countryHasStateSelection, setCountryHasStateSelection] = useState(false);
    const [states, setStates] = useState(null);
    const [countryStateConfig, setCountryStateConfig] = useState(null);

    const getStatesByCountry = async (countryId) => {
        var result = await orderPlacementApi.getStatesByCountry(countryId);
        return result?.response;
    }

    const [loaded, setLoaded] = useState(false);


    const setStateForCountry = async (countryName) => {

        await loadCountrySubSelection(countryName);

        console.groupEnd();

    }

    const loadCountrySubSelection = async (countryName) => {
        var countryStateConfig = countriesForStateValidation.filter(
            (x) => x.country === countryName
        )[0];

        setCountryStateConfig(countryStateConfig);
        var hasCountryForStateReload = !!countryStateConfig;
        setCountryHasStateSelection(hasCountryForStateReload);

        var lookup = getCountryLookup(countries);
        var countryId = lookup.get(countryName);

        console.log("has country for state reload", hasCountryForStateReload);
        if (hasCountryForStateReload) {

            var selectedState = getValues(`${addressType}.state`);
            setValue(`${addressType}.state`, selectedState ? selectedState : "");

            console.log("get states for country ", countryName)
            var states = await getStatesByCountry(countryId);
            setStates(states);
            console.log("Loaded states for country change", states);
        }
        else {
            unregister(`${addressType}.state`);
            console.log("HookEditAddress state was unregistered");
        }
    }

    const getCountryLookup = (countries) => {
        var lookup = new Map();

        countries.forEach(x => {
            lookup.set(x.value, x.key);
        });
        return lookup;
    }

    useEffect(() => {
        if (loaded) {
            return;
        }

        const init = async () => {
            console.group("%c " + addressType + " HookEditAddress init", "background: #333; color: #bada55");

            if (countries) {
                var countryName = getValues(`${addressType}.country`);

                console.log("found country", countryName);
                await loadCountrySubSelection(countryName);
                setLoaded(true);
            }
            console.groupEnd();
        }
        init();
    }, []);


    useEffect(() => {
        if (!loaded) {
            return;
        }
        const refresh = async () => {
            if (selectedCountry) {

                await setStateForCountry(selectedCountry);
            }
        }
        refresh();
    }, [selectedCountry]);


    const getControlClassName = (field) => {
        return `control textbox ${getErrorMessage(errors, `${addressType}.${field}`)?.message ? "error" : null}`;
    }

    return loaded && (
        <div>
            <div className="hook-edit-my-address">
                <Row>
                    <Column width={2}>
                        <div className="input-validation">
                            <SectionTitle required>
                                <Translation id='0fffb175-6ffe-4d9e-967b-62278ac007ae-0' defaultMessage='Country' category='Label' />
                            </SectionTitle>

                            <select
                                data-testid={"order-placement-" + addressType + "-country"}
                                {...register(`${addressType}.country`, {
                                    required: {
                                        value: true,
                                        message: <Translation id='9db65cd0-6ee3-4af2-b435-bacdb8d7b4db' defaultMessage='Please select a country' category='Label' />
                                    },
                                    onChange: e => {
                                        console.log(e.target.value + " was selected");
                                        setSelectedCountry(e.target.value);
                                    }
                                })}
                                // defaultValue={getValues(`${addressType}`.country)}
                                className="control dropdown"
                            >
                                <option key={0} value={""}>
                                    {language.translateLabel('0eed91bc-2f44-4440-aedf-8be50856ae42', 'Please select...', 'Label')}
                                </option>
                                {countries.map((item, i) => {
                                    return (
                                        <option key={item.key} value={item.value}>
                                            {item.value}
                                        </option>
                                    );
                                })}
                            </select>
                            {getErrorMessage(errors, `${addressType}.country`) && <span className="error-message">{getErrorMessage(errors, `${addressType}.country`)?.message}</span>}
                        </div>
                    </Column>
                    <Column></Column>
                </Row>
                <Row>
                    <Column width={2}>
                        {requireCompanyName === true &&
                            <div className="input-validation">
                                <SectionTitle required>
                                    <Translation id="452cf8ff-b390-4b5f-98fa-23a3286f9c60" defaultMessage="Name" category='Label' />
                                </SectionTitle>
                                <input
                                    data-testid={"order-placement-" + addressType + "-name"}
                                    type="text"
                                    className={getControlClassName("name1")}
                                    placeholder=""
                                    {...register(`${addressType}.name1`,
                                        {
                                            required: {
                                                value: true,
                                                message: <Translation id='b69cc6b7-4904-4d2e-8126-755436830d51' defaultMessage='Please enter a name' category='Label' />
                                            },
                                            maxLength: 50
                                        })}
                                />
                                {getErrorMessage(errors, `${addressType}.name1`) && <span className="error-message">{getErrorMessage(errors, `${addressType}.name1`)?.message}</span>}
                            </div>
                        }
                    </Column>
                    <Column></Column>
                </Row>
                <Row>
                    <Column width={2}>
                        <div className="input-validation">
                            <SectionTitle required>
                                <Translation id='60ba9716-0e2c-4b41-95f0-8ceaf0f07469' defaultMessage='Street' category='Label' />
                            </SectionTitle>
                            <input
                                data-testid={"order-placement-" + addressType + "-streetAddress1"}
                                type="text"
                                className={getControlClassName("street")}
                                placeholder="Street"
                                {...register(`${addressType}.streetAddress1`,
                                    {
                                        required: {
                                            value: true,
                                            message: <Translation id='b14d4662-5838-4516-881f-2a142ecb7845' defaultMessage='Please enter an address' category='Label' />
                                        },
                                        maxLength: 100
                                    })}
                            />
                            {getErrorMessage(errors, `${addressType}.streetAddress1`) && <span className="error-message">{getErrorMessage(errors, `${addressType}.streetAddress1`)?.message}</span>}
                        </div>
                    </Column>

                    <Column></Column>
                </Row>
                <Row>
                    <Column width={2}>
                        <div className="input-validation">

                            <input
                                data-testid={"order-placement-" + addressType + "-streetAddress2"}
                                type="text"
                                className="control textbox"
                                placeholder="Suite, Unit, Building"
                                {...register(`${addressType}.streetAddress2`,
                                    {
                                        maxLength: 50
                                    })}
                            />
                            {getErrorMessage(errors, `${addressType}.streetAddress2`) && <span className="error-message">{getErrorMessage(errors, `${addressType}.streetAddress2`)?.message}</span>}
                        </div>
                    </Column>
                    <Column></Column>
                </Row>

                <Row>
                    <Column width={4}>
                        <div className="input-validation">
                            <SectionTitle required>
                                <Translation id="76f3a034-2c36-4f58-b2e3-573cc204a5d3" defaultMessage="City" category='Label' />
                            </SectionTitle>
                            <input
                                data-testid={"order-placement-" + addressType + "-city"}
                                type="text"
                                className={getControlClassName("city")}
                                placeholder="City"
                                {...register(`${addressType}.city`,
                                    {
                                        required: {
                                            value: true,
                                            message: <Translation id='7d2ce4a4-5ce3-45e8-858c-d3844f4880ef' defaultMessage='Please enter a city' category='Label' />
                                        },
                                        maxLength: 30
                                    })}
                            />
                            {getErrorMessage(errors, `${addressType}.city`) && <span className="error-message">{getErrorMessage(errors, `${addressType}.city`)?.message}</span>}
                        </div>
                    </Column>
                    <Column width={4}>
                        {countryHasStateSelection && countryStateConfig && states && states.length > 0 &&
                            <div className="input-validation">
                                <SectionTitle required>
                                    {countryStateConfig.selectionLabel == "State" &&
                                        <Translation id="f0d8f2b5-38fc-49b7-b802-bdf91885ea5a" defaultMessage={countryStateConfig.selectionLabel} category='Label' />
                                    }
                                    {countryStateConfig.selectionLabel == "Province" &&
                                        <Translation id='12b9c440-267d-4a8d-a44b-6205b3011d87' defaultMessage={countryStateConfig.selectionLabel} category='Label' />
                                    }
                                </SectionTitle>

                                <select
                                    data-testid={"order-placement-" + addressType + "-state"}
                                    {...register(`${addressType}.state`, {
                                        required: {
                                            value: true,
                                            message: <Translation id='b6b52dea-31fe-4e53-b02f-65244b24e5c9' defaultMessage='Please select a state' category='Label' />
                                        }
                                    })}
                                    className="control dropdown"
                                >
                                    <option key={0} value={""}>
                                        {language.translateLabel('0eed91bc-2f44-4440-aedf-8be50856ae42', 'Please select...', 'Label')}
                                    </option>

                                    {states.map((item, i) => {
                                        return (
                                            <option key={item.key} value={item.value}>
                                                {item.value}
                                            </option>
                                        );
                                    })}
                                </select>
                                {getErrorMessage(errors, `${addressType}.state`) && <span className="error-message">{getErrorMessage(errors, `${addressType}.state`)?.message}</span>}
                            </div>
                        }
                    </Column>
                    <Column width={4}>
                        <div className="input-validation">
                            <SectionTitle required>
                                <Translation id="0b842d4e-d67d-47ab-8b36-bf494f7c2fa7" defaultMessage="Postal Code" category='Label' />
                            </SectionTitle>
                            <input
                                data-testid={"order-placement-" + addressType + "-postalCode"}
                                type="text"
                                className={getControlClassName("postalCode")}
                                placeholder="Postal Code"
                                {...register(`${addressType}.postalCode`,
                                    {
                                        required: {
                                            value: true,
                                            message: <Translation id='0b3d4755-7414-4d6f-9793-64585824a677' defaultMessage='Please enter a postal code' category='Label' />
                                        },
                                        maxLength: 20
                                    })}
                            />
                            {getErrorMessage(errors, `${addressType}.postalCode`) && <span className="error-message">{getErrorMessage(errors, `${addressType}.postalCode`)?.message}</span>}
                        </div>
                    </Column>
                </Row>
                <Row>

                    <Column>
                        {requireEmail === true &&

                            <div className="input-validation">
                                <SectionTitle required>
                                    <Translation id="15b53b85-df5e-46db-a112-10950c5dfe37" defaultMessage="Email" category='Label' />
                                </SectionTitle>
                                <input
                                    data-testid={"order-placement-" + addressType + "-email"}
                                    type="text"
                                    className={getControlClassName("email")}
                                    placeholder="Email"
                                    {...register(`${addressType}.email`,
                                        {
                                            required: {
                                                value: true,
                                                message: <Translation id='e8a0a63d-eeae-40c6-934d-b8064da86226' defaultMessage='Please enter your email' category='Label' />
                                            },
                                            maxLength: 75,
                                            pattern: {
                                                value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                                message: "E-mail address is not correctly formatted"
                                            }
                                        })}
                                />
                                {getErrorMessage(errors, `${addressType}.email`) && <span className="error-message">{getErrorMessage(errors, `${addressType}.email`)?.message}</span>}
                            </div>
                        }
                    </Column>
                </Row>
                {additionalFields && additionalFields.length > 0 &&
                    additionalFields.map((field, i) => {
                        return (
                            <Row>
                                <Column width={2}>
                                    <div className="input-validation">
                                        <SectionTitle required={field.required}>
                                            {field.title}
                                        </SectionTitle>
                                        <input
                                            data-testid={"order-placement-" + addressType + "-" + field.name}
                                            type={field.type}
                                            className={field.type == "checkbox" ? "form-checkbox" : getControlClassName(field.name)}
                                            placeholder={field.placeholder}
                                            {...register(`${addressType}.${field.name}`,
                                                {
                                                    required: {
                                                        value: field.required
                                                    },
                                                    maxLength: field.maxLength && field.maxLength > 0 ? field.maxLength : 50,
                                                    onChange: field.onChange
                                                })}
                                        />
                                        {field.description}
                                        {getErrorMessage(errors, `${addressType}.${field.name}`) && <span className="error-message">{getErrorMessage(errors, `${addressType}.${field.name}`)?.message}</span>}
                                    </div>
                                </Column>
                            </Row>
                        );
                    })}
            </div>
        </div>
    );
}