import { HeaderTileOption } from './HeaderTileOption'
import { MyAccountEpacContacts } from '../pages/account/MyAccountEpacContacts'

import './MyAccountPageHeaderOptions.css';

export const MyAccountPageHeaderOptions = ({ salesforceAccountId, selectedHeaderNumber }) => {

    return (
        <div >
            <div>
                <MyAccountEpacContacts salesforceAccountId={salesforceAccountId} />
            </div>

            <div className="headerContainer">
                <HeaderTileOption title="My Orders" selected={selectedHeaderNumber} route="/myorders" number={1} />
                <HeaderTileOption title="My Addresses" selected={selectedHeaderNumber} route="/myaddresses" number={2} />
                {/*<HeaderTileOption title="Contacts & Notifications" selected={selectedHeaderNumber} route="/notifications" number={3} />*/}
                {/*<HeaderTileOption title="Account Information" selected={selectedHeaderNumber} route="/accountinfo" number={4} />*/}
                {/*<HeaderTileOption title="Payment & Invoices" selected={selectedHeaderNumber} route="/paymentinvoices" number={5} />*/}
            </div>
        </div>
    )
}
