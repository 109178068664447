import { useMsal } from "@azure/msal-react";
import React, { useEffect, useContext } from "react";
import { LogOut } from 'react-feather';
import { useCheckoutContext } from "../infrastructure/CheckoutContext";
import { NotificationContext } from '../capabilities/NotificationContext';

export const SignoutButton = () => {
    const { instance, accounts, inProgress } = useMsal();
    const { isProceedingToCheckout } = useCheckoutContext();

    const { canLogout } = useContext(NotificationContext);


    const logOut = () => {
        instance.logoutRedirect({
            postLogoutRedirectUri: "/signout"
        }).catch(error => {
            console.log("error signing out", error);
        })
    }

    return (
        <div>
            <button className='btn btn-primary signout' disabled={canLogout} onClick={() => logOut()}><LogOut className='icon' /><span className='caption'>Log Out</span></button>
        </div>
    );
}