import React, { useState, useEffect, useRef, useContext } from "react";
import { useParams } from "react-router-dom";
import { useNavigate, useLocation } from "react-router-dom";
import { Popup } from '../../core/Popup';
import { Translation } from "../../core/Translation";
import { SecureApiDataAccess } from "../../infrastructure/SecureApiDataAccess";
import { ReorderSkuSelectionComponent } from "../../components/reorder/ReorderSkuSelectionComponent";
import { CapabilityName, ReorderDeniedReasons } from "../../infrastructure/Constants";
import { CapabilitiesContext } from "../../infrastructure/Contexts";


export const ReorderSkuSelection = () => {

    let { globalOrderId } = useParams();

    const popup = useRef(null);
    const navigate = useNavigate();
    const location = useLocation();
    const [orderDetails, setOrderDetails] = useState();
    const [reorderDeniedReasonCode, setReorderDeniedReasonCode] = useState();

    const salesforceAccountId = location.state.salesforceAccountId;
    const emailAddress = location.state.emailAddress;
    const acceptedOfferId = location.state.acceptedOfferId;
    const globalOfferId = location.state.globalOfferId;

    const secureDataAccess = new SecureApiDataAccess('/api/reorder/');

    const capabilitiesContext = useContext(CapabilitiesContext);
    const loader = capabilitiesContext.getCapability(CapabilityName.Loader);

    useEffect(() => {
        if (!!salesforceAccountId && !!emailAddress && !!acceptedOfferId && !!globalOfferId)
            init();
        else
            goBack();

    }, []);

    const init = async () => {
        loader.show()
        var available = await checkAvailability();
        if (available)
            await loadConfig();
        loader.hide();
    }

    const loadConfig = async () => {
        try {
            var response = await secureDataAccess.get(`order/${globalOrderId}/configuration`);
            console.log("Data", response);
            setOrderDetails(response.data);
        }
        catch (ex) {
            popup.current.show();
        }
    }

    const checkAvailability = async () => {
        try {
            var response = await secureDataAccess.get(`order/${globalOrderId}/availability`);
            response = response.data;
            console.log("Reorder Availability", response);
            if (!response.isReorderable) {
                setReorderDeniedReasonCode(response.deniedDetails.reasonCode);
                await createReorderRequestForSalesTeam(response.deniedDetails);   
                popup.current.show();
            }
            return response.isReorderable;
        }
        catch (ex) {
            popup.current.show();
            return false;
        }
    }

    const createReorderRequestForSalesTeam = async (reorderDeniedDetails) => {
        if (reorderDeniedDetails.reasonCode == ReorderDeniedReasons.NoSkuFound)
            return;

        var request = {
            acceptedOfferId: acceptedOfferId,
            globalOfferId: globalOfferId,
            reorderDeniedDetails: reorderDeniedDetails
        };
        await secureDataAccess.postAsJson(`request`, request);
    }

    const getErrorMessage = (reorderDeniedReasonCode) => {
        switch (reorderDeniedReasonCode) {
            case ReorderDeniedReasons.NoSkuFound:
                return <Translation id='41bd92c1-d902-46af-9a5b-63f91d22fa2f' defaultMessage='The order you are trying to reorder does not have approved art yet. Please complete the art approval process on the current order before reordering' category='Label' />
            case ReorderDeniedReasons.ConvertingMethodNotAllowed:
            case ReorderDeniedReasons.HasOutsourcingCost:
                return <Translation id='dbacf968-b6d9-49c0-943a-0e0741c0ccd8' defaultMessage='This order can not be reordered automatically due to its specific requirements. A salesperson has been notified and will contact you soon to assist with completing your order' category='Label' />
            default:
                return <Translation id='e0ee3a6e-9a62-45e4-a817-baaf5661550a' defaultMessage='Your reorder request can not be managed' category='Label' />
        }
    }

    const goBack = () => {
        navigate(-1);
    }

    const goToQuantitySelectionPage = async (selectedSkus) => {
        navigate("quantity", {
            state:
            {
                emailAddress: emailAddress,
                selectedSkus: selectedSkus,
                salesforceAccountId: salesforceAccountId,
                globalOrderId: globalOrderId
            }
        });
    }

    return (
        <div>
            {
                orderDetails &&
                <ReorderSkuSelectionComponent orderDetails={orderDetails} onSubmit={(selectedSkus) => { goToQuantitySelectionPage(selectedSkus) }} onCancel={() => { goBack() }} />
            }
            <Popup closeOnClickAway={true} onClose={goBack} ref={popup}>
                <div className='contentSection'>
                    {getErrorMessage(reorderDeniedReasonCode)}
                </div>
            </Popup>
        </div >
    )
}