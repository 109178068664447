import React, { useContext } from "react";
import { Language } from "../infrastructure/Languages";

export const Translation = ({ id, sub, defaultMessage, category, values }) => {
    const language = useContext(Language);
    const key = `${id}`;
    const keyVariant = sub ? parseInt(sub) : 0;
    const cat = category ? category : "label";

    const getFormattedText = () => {

        var txt = language.translateDomain(key, keyVariant, defaultMessage, cat);
        if (values) {
            Object.keys(values).forEach((key) => {
                var token = "{" + key + "}";
                txt = txt.replace(token, values[key]);
            });
        }
        return txt;
    };

    return <span>{getFormattedText()}</span>;
};
