import React, { useEffect } from 'react';
import { useMsal } from "@azure/msal-react";
import { useCheckoutContext } from '../infrastructure/CheckoutContext';
import { useUserContext } from '../infrastructure/UserContext';

export const Reset = () => {

    const { instance } = useMsal();
    const { reset } = useCheckoutContext();  
    const { clearSettings } = useUserContext();  

    useEffect(() => {
        reset();
        clearSettings();

        instance.logoutRedirect({
            postLogoutRedirectUri: "/"
        }).catch(error => {
            console.log("error signing out", error);
        })

        document.location = '/';
    }, []);

    return (
        <>
            <h1>Please wait until we are signing out...</h1>
        </>
    )
}