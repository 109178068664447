import React, { useContext, useState, clearErrors } from "react";
import { Row } from "../../core/Row";
import { Column } from "../../core/Column";
import { Card } from "../../core/Card";
import { Alert } from "../../core/Alert";
import { Translation } from "../../core/Translation";
import { SectionTitle } from "../../core/SectionTitle";
import { Language } from "../../infrastructure/Languages";
import { useForm, get, useFieldArray, useWatch, Controller, useFormState } from "react-hook-form";
import { useEffect } from "react";
import "./HookEditAddress.css";
import { AdditionalShippingAddress } from "./AdditionalShippingAddress";

export const AdditionalShippingAddressEditor = ({
    countries,
    unregister,
    getValues,
    setValue,
    register,
    states,
    countriesForStateValidation,
    orderPlacementApi,
    trigger,
    resetField,
    append,
    remove,
    control,
    additionalShippingAddressOptions,
    additionalShippingAddresses,
    watch,
}) => {
    const [rowEditingIndex, setRowIsEditingIndex] = useState(-1);
    const [addingIndex, setAddingIndex] = useState(-1);

    return (
        <div>
            <div style={{ margin: "10px 0px 10px 0px" }}>
                Want to ship to multiple locations? Select or add another one below.
            </div>
            <div style={{ cursor: "pointer", margin: "10px 0px 10px 0px" }}>
                <span>
                    <button
                        type="button"
                        className="btn btn-primary"
                        disabled={addingIndex !== -1}
                        onClick={() => {
                            append(
                                {
                                    name: "",
                                    addressLine1: "",
                                    addressLine2: "",
                                    city: "",
                                    state: "",
                                    postalCode: "",
                                    country: "",
                                    specialInstructions: "",
                                    salesforceLocationId: "",
                                    accountId: "",
                                },
                                {
                                    focusName: `additionalShippingAddresses.${additionalShippingAddresses.length}.name`,
                                }
                            );

                            setRowIsEditingIndex(additionalShippingAddresses.length);
                            setAddingIndex(additionalShippingAddresses.length);
                        }}
                    >
                        <Translation
                            id="b0e2c9de-16bb-4cd8-9361-cd7883d254ab"
                            defaultMessage=" + Add Additional Shipping Address"
                            category="Label"
                        />
                    </button>
                </span>
            </div>
            {additionalShippingAddresses.length > 0 && (
                <div className="shippingLocationList">
                    {additionalShippingAddresses.map((item, index) => {
                        return (
                            <div key={item.id}>
                                <AdditionalShippingAddress
                                    unregister={unregister}
                                    getErrorMessage={get}
                                    trigger={trigger}
                                    setValue={setValue}
                                    getValues={getValues}
                                    register={register}
                                    countriesForStateValidation={countriesForStateValidation}
                                    countries={countries}
                                    states={states}
                                    addressType="additionalShippingAddresses"
                                    orderPlacementApi={orderPlacementApi}
                                    requireCompanyName={true}
                                    append={append}
                                    addressIndex={index}
                                    resetField={resetField}
                                    control={control}
                                    rowEditingIndex={rowEditingIndex}
                                    updateRowEditingIndex={(index) => setRowIsEditingIndex(index)}
                                    addingIndex={addingIndex}
                                    updateAddingIndex={(index) => setAddingIndex(index)}
                                    salesforceShippingAddresses={additionalShippingAddressOptions}
                                    watch={watch}
                                    removeItem={(index) => {
                                        remove(index);
                                    }}
                                />
                            </div>
                        );
                    })}
                    <div>
                        <Alert type="warn">
                            {" "}
                            Our Sales Support Team will contact you once the order has been produced for logistics and
                            quantities to ship to each location.
                        </Alert>
                    </div>
                </div>
            )}
        </div>
    );
};
