import React, { useState, useEffect } from 'react';

import './Tabs.css';


export const Tabs = ({ children }) => {

    const tabs = children.length > 0 ? children : [children];
    const [sticky, setSticky] = useState(false);
    const [activeTab, setActiveTab] = useState(-1);
    const [contents, setContents] = useState(children.length ? new Array(children.length) : new Array(1));

    useEffect(() => {

        const handleScroll = e => {
            const scrollTop = e.target.documentElement.scrollTop;
            setSticky(scrollTop > 185);
        }

        if (document) {
            document.addEventListener("scroll", handleScroll);
        }

        return () => {
            if (document) {
                document.removeEventListener("scroll", handleScroll);
            }
        };

    }, []);

    const onInitHandler = (idx, c) => {
        contents[idx] = c;
        setContents(contents);        
    }


    const onClickHandler = (idx, c) => {
        if (idx !== activeTab) {
            setActiveTab(idx);
        } else {
            setActiveTab(-1);
        }        
    }


    return (
        <div className='tabs'>

            {activeTab > -1 &&
                <div className='backsplash' onClick={() => { setActiveTab(-1) }} />
            }

            <div className={sticky ? 'container sticky' : 'container'}>
                <table cellSpacing={0} cellPadding={0}>
                    <tbody>
                        <tr className={activeTab > -1 ? 'inactiveRow' : 'inactiveRow noborder'}>
                            {tabs.map((tab, idx) => {
                                return (
                                    <td key={`tab1${idx}`}>
                                        {idx !== activeTab &&
                                            <>
                                            {React.cloneElement(tab, { onClick: (content) => { onClickHandler(idx, content) }, onInit: (content) => { onInitHandler(idx, content)} })}
                                            </>
                                        }
                                    </td>
                                );
                            })}          
                            <td width='100%'></td>
                        </tr>

                        <tr className={activeTab > -1 ? 'contentRow' : 'contentRow noborder'}>
                            <td colSpan={tabs.length + 1}>
                                {contents.map((con, idx) => {
                                    return (<div key={`con${idx}`} className={idx == activeTab ? `contentPanel content${idx} visible` : `contentPanel content${idx} hidden`}>{con}</div>)
                                })}

                            </td>
                        </tr>

                        <tr className={activeTab > -1 ? 'activeRow' : 'activeRow noborder'}>
                            {tabs.map((tab, idx) => {
                                return (
                                    <td key={`tab2${idx}`}>
                                        {idx == activeTab &&
                                            <>
                                            {React.cloneElement(tab, { onClick: (content) => { onClickHandler(idx, content) }, onInit: (content) => { onInitHandler(idx, content) } })}
                                            </>
                                        }
                                    </td>
                                );
                            })}
                            <td width='100%'></td>
                        </tr>
                    </tbody>
                </table>

            </div>
        </div>
    )
}


export const Tab = ({ title, className, children, onClick, onInit }) => {

    const onClickHandler = () => {
        if (onClick) {
            onClick(children);
        }
    }

    useEffect(() => {
        if (onInit) {
            onInit(children);
        }
    }, []);

    return (
        <div className={className ? `tab ${className}` : 'tab'} onClick={onClickHandler}>{title}</div>
    )

}