import React, { useEffect, useState, useContext } from 'react';
import { ApiDataAccess } from './ApiDataAccess';
import Fuse from 'fuse.js';
import { CapabilityName } from './Constants';
import { CapabilitiesContext } from './Contexts';
import { OfferLanguageContext } from "./OfferLanguageContext";
import { useUserContext } from './UserContext';
import { CultureString } from './Culture';

export const Language = React.createContext();

export const LanguageSupport = ({ source, target, children, onInit }) => {

    const { userRegion, userLanguage, updateUserRegion, updateUserLanguage } = useUserContext();
    
    const offerLanguage = useContext(OfferLanguageContext).offerLanguage;
    const capabilitiesContext = useContext(CapabilitiesContext);
    const loader = capabilitiesContext.getCapability(CapabilityName.Loader);
    const [ready, setReady] = useState(false);
    const [culture, setCulture] = useState(null);
    const [sourceLanguage, setSourceLanguage] = useState(source);
    const [targetLanguage, setTargetLanguage] = useState(target); 

    if (offerLanguage && (sourceLanguage != offerLanguage.sourceLanguage || targetLanguage != offerLanguage.targetLanguage)) {
        setSourceLanguage(offerLanguage.sourceLanguage);
        setTargetLanguage(offerLanguage.targetLanguage);
    }

    useEffect(() => {
        const localInit = async () => {
            if (sourceLanguage && targetLanguage) {
                console.log("init has run from LanguageSupport")
                await init(sourceLanguage, targetLanguage);
            }
            else {
                setReady(true);
            }
        }
        localInit();
    }, [sourceLanguage, targetLanguage]);

    const init = async (source, target) => {
        if (loader) {
            loader.show();
        }

        try {
            var config = getCulture(source, target);

            setReady(false);
            var provider = new LanguageProvider('/api/cultures' /*, async () => { await init(source, target) } */);
            var culture = await provider.setCultureAsync(config.source, config.target);

            if (onInit) {
                onInit(culture);
            }

            setCulture(culture);
            setReady(true);
        }
        catch (ex) {
            console.error(ex);
        }

        if (loader) {
            loader.hide();
        }
    }

    const getCulture = (source, target) => {

        var result = {
            source: source,
            target: target
        };

       // when offerLanguage is set the userLanguage is always offerLanguage.target
        if (!offerLanguage && userLanguage && result.target != userLanguage)
            result.target = userLanguage;

        updateUserRegion(result.source);
        updateUserLanguage(result.target);

        return result;
    }


    return (
        <div>
            {ready &&
                <Language.Provider value={culture}>
                    {children}
                </Language.Provider>
            }
        </div>
        )
}


export class LanguageProvider {

    constructor(url, onUploadComplete) {
        this.onUploadComplete = onUploadComplete;
        this.dataAccess = new ApiDataAccess(url);
        this.uploadProcessor = new UploadProcessor(this.dataAccess, onUploadComplete);
    }

    async setCultureAsync(sc, tc) {

        var sourceCulture = CultureString.Parse(sc);
        var targetCulture = CultureString.Parse(tc);

        var application = await this.getApplicationAsync();
        var settings = await this.getApplicationSettingsAsync(sourceCulture, targetCulture);
        var translations = settings.enabled === true ? await this.getTranslationsAsync(sourceCulture, targetCulture) : [];
        var images = await this.getImagesAsync(sourceCulture);

        return new Culture(sourceCulture, targetCulture, application, settings, translations, images, this.dataAccess);
    }

    async getApplicationAsync() {
        var response = await this.dataAccess.get(`/application`);
        if (!response.hasSuccess) {
            throw new Error('Could not retrieve Application Info for Language Provider!');
        }
        return response.value.value.dataObject;
    }

    async getApplicationSettingsAsync(sourceCulture, targetCulture) {
        var response = await this.dataAccess.get(`/application/details`);
        if (!response.hasSuccess) {
            throw new Error('Could not retrieve Application Details for Language Provider!');
        }
        var item = response.value.value.items.find(e => e.dataObject.sourceCultureId == sourceCulture && e.dataObject.targetCultureId == targetCulture);
        return item && item.dataObject ? item.dataObject : {learn: true, enabled: true};
    }


    async getTranslationsAsync(sourceCulture, targetCulture) {
        var result = [];
        var hasMoreItems = true;
        var pageSize = 2000;
        var pageIndex = 0;

        while (hasMoreItems) {
            hasMoreItems = false;

            console.log(`Retrieving Translations from ${sourceCulture} to ${targetCulture}`);
            var response = await this.dataAccess.get(`/translations?pageIndex=${pageIndex}&pagesize=${pageSize}&sourceculture=${sourceCulture}&targetculture=${targetCulture}`);
            if (!response.hasSuccess) {
                throw new Error('Could not retrieve Translations!');
            }

            var items = response.value && response.value.value ? response.value.value.items : null;
            if (items && items.length > 0) {

                items.forEach(item => {
                    item.dataObject.metaData = item.dataObject.metaData ? JSON.parse(item.dataObject.metaData) : null;
                    result.push(item);
                });

                if (items.length == pageSize)
                    hasMoreItems = true;
            }
            pageIndex++;
        }

        return new TranslationRegistry(result, sourceCulture, this.dataAccess, this.uploadProcessor);
    }


    async getImagesAsync(sourceCulture) {
        var result = [];
        var hasMoreItems = true;
        var pageSize = 1000;
        var pageIndex = 0;

        while (hasMoreItems) {
            hasMoreItems = false;

            console.log(`Retrieving Images for ${sourceCulture}`);
            var response = await this.dataAccess.get(`/images?pageIndex=${pageIndex}&pagesize=${pageSize}&sourceculture=${sourceCulture}`);
            if (!response.hasSuccess) {
                throw new Error('Could not retrieve Images!');
            }

            var items = response.value && response.value.value ? response.value.value.items : null;
            if (items && items.length > 0) {
                result = result.concat(items);
                if (items.length == pageSize)
                    hasMoreItems = true;
            }
            pageIndex++;
        }

        return new ImageRegistry(result, sourceCulture, this.dataAccess, this.uploadProcessor);
    }


}

export class Indicator {

    constructor() {
        this.name = 'language_training_indicator';

        this.element = document.getElementById(this.name);
        if (!this.element) {
            const div = document.createElement('div');
            div.id = this.name;
            div.innerHTML = 'learning vocabulary...';
            div.setAttribute("style", "color:#ffffff;background:#550000;padding: 5px;position:fixed;z-index:10000;right:0px;bottom:0px;");

            document.body.appendChild(div);
            this.element = document.getElementById(this.name);
        }


        this.hide();
    }

    show() {
        var self = this;
        this.element.style.display = 'block';
        setTimeout(() => {
            self.hide();
        }, 500);
    }

    hide() {
        this.element.style.display = 'none';
    }


}


export class UploadProcessor {

    constructor(dataAccess, onUploadComplete) {
        this.dataAccess = dataAccess;
        this.onUploadComplete = onUploadComplete;
        this.indicator = new Indicator();
        this.queue = [];
        this.uploaded = [];
        setInterval(async () => { await this.dequeueAsync() }, 250);
    }

    enqueueTranslation(key, keyVariant, sourceCulture, targetCulture, sourceVocablePhrase, category, metaData) {

                //TODO: Uncomment this


        if (!this.containsItem('translation', key, keyVariant)) {
            var request = {
                requestType: 'translation',
                key: key,
                keyVariant: `${keyVariant}`,
                sourceCultureId: sourceCulture,
                sourceVocablePhrase: sourceVocablePhrase,
                targetCultureId: targetCulture,
                category: category,
                metaData: metaData ? JSON.stringify(metaData) : null
            };

            if (this.isRequestValid(request)) {
                 this.queue.push(request);
            }
            else {
                console.log(`Translation not uploaded due to missing information`);
            }

        }
        else {
            console.log(`Skipped translation ${key}-${keyVariant}`);
        }

    }

    enqueueImage (key, keyVariant, sourceCulture, category, description) {

        if (!this.containsItem('translation', key, keyVariant)) {
            var request = {
                requestType: 'image',
                key: key,
                keyVariant: keyVariant,
                sourceCultureId: sourceCulture,
                category: category,
                description: description
            };

            if (this.isRequestValid(request)) {
                this.queue.push(request);
            }
            else {
                console.log(`Image not uploaded due to missing information`);
            }

        }
        else {
            console.log(`Skipped image ${key}-${keyVariant}`);
        }
    }


    containsItem(requestType, key, keyVariant) {
        var idx = this.queue.findIndex(e => e.requestType == requestType && e.key == key && e.keyVariant == `${keyVariant}`);
        if (idx >= 0) {
            return true;
        }

        var idx = this.uploaded.findIndex(e => e.requestType == requestType && e.key == key && e.keyVariant == `${keyVariant}`);
        if (idx >= 0) {
            return true;
        }

        return false;
    }

    isRequestValid(request) {

        if (!request) {
            return false;
        }

        if (!request.requestType) {
            return false;
        }

        if (!request.key) {
            return false;
        }

        if (!request.sourceCultureId) {
            return false;
        }

        if (request.requestType == 'translation') {
            if (!request.targetCultureId) {
                return false;
            }

            if (!request.sourceVocablePhrase) {
                return false;
            }
        }

        if (request.requestType == 'image') {
            if (!request.category) {
                return false;
            }

            if (!request.description) {
                return false;
            }
        }

        return true;
    }


    async dequeueAsync() {
        try {
            if (this.queue && this.queue.length > 0) {
                var item = JSON.parse(JSON.stringify(this.queue[0]));
                this.queue.splice(0, 1);

                console.log(`Uploading queue item (${this.queue.length}) ${item.key}-${item.keyVariant}`, item);
                
                await this.uploadAsync(item);
                this.uploaded.push(item);

                if (this.queue.length === 0 && this.onUploadComplete) {
                    this.onUploadComplete();
                }
            }
        }
        catch (ex) {
            console.error(ex);
        }
    }

    async uploadAsync(request) {
        this.indicator.show();
        var response = request.requestType == 'translation'
            ? await this.dataAccess.post('/translations', request)
            : await this.dataAccess.post('/images', request);

        if (!response.hasSuccess) {
            console.log('Item was not added!', response);
        }
        this.indicator.hide();
    }
}

export class TranslationRegistry {

    constructor(translations, sourceCulture, dataAccess, uploadProcessor) {
        this.translations = translations;
        this.sourceCulture = sourceCulture;
        this.dataAccess = dataAccess;
        this.uploadProcessor = uploadProcessor;
    }

    getTranslation(key) {
        var translation = this.translations.find(e => e.key == key);
        return translation ? translation.dataObject : null;
    }

    async addAsync(key, keyVariant, sourceCulture, targetCulture, sourceVocablePhrase, category, metaData) {
        this.uploadProcessor.enqueueTranslation(key, keyVariant, sourceCulture, targetCulture, sourceVocablePhrase, category, metaData);
    }
}


export class ImageRegistry {

    constructor(images, sourceCulture, dataAccess, uploadProcessor) {
        this.images = images;
        this.sourceCulture = sourceCulture;
        this.dataAccess = dataAccess;
        this.uploadProcessor = uploadProcessor;
    }

    getImage(key) {
        var image = this.images.find(e => e.key == key);
        return image ? image.dataObject : null;
    }

    async addAsync(key, keyVariant, sourceCulture, category, description) {
        this.uploadProcessor.enqueueImage(key, keyVariant, sourceCulture, category, description);
    }

}


export class Culture {

    constructor(sourceCulture, targetCulture, application, settings, translations, images, dataAccess) {
        this.sourceCulture = sourceCulture;
        this.targetCulture = targetCulture;
        this.application = application;
        this.settings = settings;
        this.translations = translations;
        this.images = images;
        this.dataAccess = dataAccess;
    }

    static TranslationRequest = {
        sourceCulture: null,
        targetCulture: null,
        learn: null,
        key: null,
        keyVariant: 0,
        category: 'Undefined',
        metaData: null
    }

    static ImageRequest = {
        sourceCulture: null,
        key: null,
        keyVariant: 0,
        description: null,
        category: 'Undefined',
    }

    getSourceCulture() {
        return this.sourceCulture;
    }

    getTargetCulture() {
        return this.targetCulture;
    }

    searchPhrase(phrase, category) {
        var items = this.translations.translations.filter((e) => e.dataObject.category == category && e.dataObject.metaData);
        const options = {
            includeScore: true,
            keys: ['dataObject.targetVocablePhrase']
        }

        const fuse = new Fuse(items, options);
        const results = fuse.search(phrase);
        return results.filter(e => e.score < 0.4).map(a => a.item);
    }

    translateLabel(key, learn, category, values, metaData) {
        var txt = this.translate({ key: key, learn: learn, sourceCulture: this.sourceCulture, category: category ? category : 'Label', metaData: metaData });
        if (values) {
            Object.keys(values).forEach(key => {
                var token = '{' + key + '}';
                txt = txt.replace(token, values[key]);
            });
        }
        return txt;
    }

    translateDomain(key, keyVariant, learn, category, metaData) {
        return this.translate({ key: key, keyVariant: keyVariant, learn: learn, sourceCulture: this.sourceCulture, category: category ? category : 'Domain', metaData: metaData });
    }

    translate(request = Culture.TranslationRequest) {
        try {
            var settings = { ...Culture.TranslationRequest, ...request };

            if (this.settings.enabled === true) {

                var sourceCulture = request.sourceCulture ? request.sourceCulture : this.sourceCulture;
                var targetCulture = request.targetCulture ? request.targetCulture : this.targetCulture;

                var key = KeyParser.generateTranslationKey(settings.key, `${settings.keyVariant}`, sourceCulture, targetCulture);
                var translation = this.translations.getTranslation(key);

                if (!translation) {
                    if (this.settings.learn === true) {
                        this.translations.addAsync(settings.key, `${settings.keyVariant}`, sourceCulture, targetCulture, settings.learn, settings.category, settings.metaData);
                    }
                    return settings.learn;
                }
                else {
                    return translation.targetVocablePhrase ? translation.targetVocablePhrase : translation.sourceVocablePhrase;
                }
            }
            else {
                return settings.learn;
            }

        }
        catch (ex) {
            console.error(ex);
            return request.learn;
        }
    }

    imageUrl(key, keyVariant, category, description) {
        var image = this.image({ key: key, keyVariant: keyVariant, category: category, description: description, sourceCulture: this.sourceCulture });
        return image && image.imageUrl ? image.imageUrl : null;
    }


    image(request = Culture.ImageRequest) {

        try {
            var settings = { ...Culture.ImageRequest, ...request };

            var sourceCulture = request.sourceCulture ? request.sourceCulture : this.sourceCulture;

            var key = KeyParser.generateImageKey(settings.key, `${settings.keyVariant}`, sourceCulture);
            var image = this.images.getImage(key);

            if (!image) {
                this.images.addAsync(settings.key, `${settings.keyVariant}`, sourceCulture, settings.category, settings.description);
            }

            return image ? image : null;
        }
        catch (ex) {
            console.error(ex);
            return null;
        }

    }


}



export class KeyParser {

    static generateTranslationKey(key, keyVariant, sourceCulture, targetCulture) {

        if (!key) {
            throw new Error('Key is required');
        }

        if (!keyVariant < 0) {
            throw new Error('Key Variant is required');
        }

        if (!sourceCulture) {
            throw new Error('Source Culture is required');
        }

        if (!targetCulture) {
            throw new Error('Target Culture is required');
        }

        return `${sourceCulture}-${key}-${keyVariant}-${targetCulture}`;
    }

    static generateImageKey(key, keyVariant, sourceCulture) {

        if (!key) {
            throw new Error('Key is required');
        }

        if (!keyVariant < 0) {
            throw new Error('Key Variant is required');
        }

        if (!sourceCulture) {
            throw new Error('Source Culture is required');
        }

        return `${sourceCulture}-${key}-${keyVariant}`;
    }

}
