import { useNavigate } from "react-router-dom";

import './HeaderTileOption.css';




export const HeaderTileOption = ({ title, selected, route, number }) => {
    const navigate = useNavigate();

    const getClassName = () => {
        if (selected === number) {
            return 'headerLabel active';
        }
        else {
            return 'headerLabel inactive'; 
        }
    }

    return (
     
        <div className={getClassName()}>
                <button type="button" className="btn btn-secondary btn-sm" onClick={() => navigate(route)}>  {title}</button>
          
            </div>     
    )
}