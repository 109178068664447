export class DelayedAction {
    static timer = {};

    static RunAsync(name, timeInMilliSeconds) {
        if (DelayedAction.timer[name]) {
            clearTimeout(DelayedAction.timer[name]);
        }

        return new Promise((resolve) => {
            DelayedAction.timer[name] = window.setTimeout(() => {
                resolve();
            }, timeInMilliSeconds);
        });
    }
}
