import React, { useContext, useState } from "react";
import { Row } from "../../core/Row";
import { Column } from "../../core/Column";
import { Translation } from "../../core/Translation";
import { SectionTitle } from "../../core/SectionTitle";
import { Language } from "../../infrastructure/Languages";
import { useEffect } from "react";
import "./HookEditAddress.css";
import { Controller, useWatch } from "react-hook-form";

export const HookEditAddress = ({
    control,
    addressType,
    errors,
    countries,
    unregister,
    getValues,
    setValue,
    register,
    countriesForStateValidation,
    getErrorMessage,
    requireEmail,
    requireCompanyName,
    additionalFields,
    orderPlacementApi
}) => {
    const language = useContext(Language);

    const [states, setStates] = useState(null);
    const [countryStateConfig, setCountryStateConfig] = useState(null);

    const stateWatch = useWatch({
        control,
        name: `${addressType}.state`
    });

    const countryWatch = useWatch({
        control,
        name: `${addressType}.country`
    });


    const getStatesByCountry = async (countryId) => {
        var result = await orderPlacementApi.getStatesByCountry(countryId);
        return result?.response;
    };

    const loadCountrySubSelection = async (countryName, resetState) => {
        var countryStateConfig = countriesForStateValidation.filter((x) => x.country === countryName)[0];

        setCountryStateConfig(countryStateConfig);
        var hasCountryForStateReload = !!countryStateConfig;

        var lookup = getCountryLookup(countries);
        var countryId = lookup.get(countryName);

        console.log("has country for state", countryName);
        if (hasCountryForStateReload) {
            var selectedState = getValues(`${addressType}.state`);
            console.log("DEBUG>>selectedState", selectedState);

            if (selectedState === null) {
                resetState = true;
            }
            if (resetState) {
                setValue(`${addressType}.state`, "");
            }

            var states = await getStatesByCountry(countryId);

            setStates(states);
            console.log("Loaded states for country change", states.length);
        } else {
            unregister(`${addressType}.state`);
            console.log("HookEditAddress state was unregistered");
        }
    };

    useEffect(() => {
        if (countryWatch == null || countryWatch === "") {
            return;
        }
        const init = async () => {
            await loadCountrySubSelection(countryWatch, false);
        }
        init();
    }, [countryWatch]);

    const getCountryLookup = (countries) => {
        var lookup = new Map();

        countries.forEach((x) => {
            lookup.set(x.value, x.key);
        });
        return lookup;
    };

    const getControlClassName = (field) => {
        return `control textbox ${getErrorMessage(errors, `${addressType}.${field}`)?.message ? "error" : null}`;
    };


    return (
        <div>
            <div>
                <Row>
                    <Column>
                        <div className="input-validation">
                            <SectionTitle required>
                                <Translation
                                    id="c6e595bb-34a8-4047-b9c9-8054b15c00b2"
                                    defaultMessage="Country"
                                    category="Label"
                                />
                            </SectionTitle>
                            <Controller
                                control={control}
                                name={`${addressType}.country`}
                                rules={{
                                    required: {
                                        value: true,
                                        message: (
                                            <Translation
                                                id="9db65cd0-6ee3-4af2-b435-bacdb8d7b4db"
                                                defaultMessage="Please select a country"
                                                category="Label"
                                            />
                                        ),
                                    },
                                }}
                                render={({ field }) => (
                                    <select
                                        data-testid={"order-placement-" + addressType + "-country"}
                                        className="control dropdown"
                                        value={field.value}
                                        onChange={async (e) => {
                                            console.log(e.target.value + " was selected");
                                            field.onChange(e.target.value);
                                            await loadCountrySubSelection(e.target.value, true);
                                        }}
                                    >
                                        <option key={0} value={""}>
                                            {language.translateLabel(
                                                "0eed91bc-2f44-4440-aedf-8be50856ae42",
                                                "Please select...",
                                                "Label"
                                            )}
                                        </option>
                                        {countries.map((item, i) => {
                                            return (
                                                <option key={item.key} value={item.value}>
                                                    {item.value}
                                                </option>
                                            );
                                        })}
                                    </select>
                                )}
                            />
                            {getErrorMessage(errors, `${addressType}.country`) && (
                                <span className="error-message">
                                    {getErrorMessage(errors, `${addressType}.country`)?.message}
                                </span>
                            )}
                        </div>
                    </Column>
                    <Column>
                        {requireCompanyName === true && (
                            <div className="input-validation">
                                <SectionTitle required>
                                    <Translation
                                        id="91c88f05-b534-4048-a7c2-5a1c8c9ad374"
                                        defaultMessage="Company Name"
                                        category="Label"
                                    />
                                </SectionTitle>
                                <input
                                    data-testid={"order-placement-" + addressType + "-name"}
                                    type="text"
                                    className={getControlClassName("name")}
                                    placeholder="Name"
                                    {...register(`${addressType}.name`, {
                                        required: {
                                            value: true,
                                            message: (
                                                <Translation
                                                    id="b69cc6b7-4904-4d2e-8126-755436830d51"
                                                    defaultMessage="Please enter a name"
                                                    category="Label"
                                                />
                                            ),
                                        },
                                        maxLength: 50,
                                    })}
                                />
                                {getErrorMessage(errors, `${addressType}.name`) && (
                                    <span className="error-message">
                                        {getErrorMessage(errors, `${addressType}.name`)?.message}
                                    </span>
                                )}
                            </div>
                        )}
                    </Column>
                </Row>
                <Row>
                    <Column>
                        <div className="input-validation">
                            <SectionTitle required>
                                <Translation
                                    id="04ea4b06-699e-497d-af8b-5843fb832290"
                                    defaultMessage="Address Line 1"
                                    category="Label"
                                />
                            </SectionTitle>
                            <input
                                data-testid={"order-placement-" + addressType + "-addressLine1"}
                                type="text"
                                className={getControlClassName("addressLine1")}
                                placeholder="Address Line 1"
                                {...register(`${addressType}.addressLine1`, {
                                    required: {
                                        value: true,
                                        message: (
                                            <Translation
                                                id="b14d4662-5838-4516-881f-2a142ecb7845"
                                                defaultMessage="Please enter an address"
                                                category="Label"
                                            />
                                        ),
                                    },
                                    maxLength: 100,
                                })}
                            />
                            {getErrorMessage(errors, `${addressType}.addressLine1`) && (
                                <span className="error-message">
                                    {getErrorMessage(errors, `${addressType}.addressLine1`)?.message}
                                </span>
                            )}
                        </div>
                    </Column>
                    <Column>
                        <div className="input-validation">
                            <SectionTitle>
                                <Translation
                                    id="91ce24b8-736a-4bac-9b50-1cec9a24f3b2"
                                    defaultMessage="Address Line 2"
                                    category="Label"
                                />
                            </SectionTitle>
                            <input
                                data-testid={"order-placement-" + addressType + "-addressLine2"}
                                type="text"
                                className="control textbox"
                                placeholder="Address Line 2"
                                {...register(`${addressType}.addressLine2`, {
                                    maxLength: 50,
                                })}
                            />
                            {getErrorMessage(errors, `${addressType}.addressLine2`) && (
                                <span className="error-message">
                                    {getErrorMessage(errors, `${addressType}.addressLine2`)?.message}
                                </span>
                            )}
                        </div>
                    </Column>
                </Row>
                <Row>
                    <Column>
                        <div className="input-validation">
                            <SectionTitle required>
                                <Translation
                                    id="76f3a034-2c36-4f58-b2e3-573cc204a5d3"
                                    defaultMessage="City"
                                    category="Label"
                                />
                            </SectionTitle>
                            <input
                                data-testid={"order-placement-" + addressType + "-city"}
                                type="text"
                                className={getControlClassName("city")}
                                placeholder="City"
                                {...register(`${addressType}.city`, {
                                    required: {
                                        value: true,
                                        message: (
                                            <Translation
                                                id="7d2ce4a4-5ce3-45e8-858c-d3844f4880ef"
                                                defaultMessage="Please enter a city"
                                                category="Label"
                                            />
                                        ),
                                    },
                                    maxLength: 30,
                                })}
                            />
                            {getErrorMessage(errors, `${addressType}.city`) && (
                                <span className="error-message">
                                    {getErrorMessage(errors, `${addressType}.city`)?.message}
                                </span>
                            )}
                        </div>
                    </Column>
                    <Column>
                        {stateWatch != null && countryStateConfig && states && states.length > 0 && (
                            <div className="input-validation">
                                <SectionTitle required>
                                    {countryStateConfig.selectionLabel == "State" && (
                                        <Translation
                                            id="f0d8f2b5-38fc-49b7-b802-bdf91885ea5a"
                                            defaultMessage={countryStateConfig.selectionLabel}
                                            category="Label"
                                        />
                                    )}
                                    {countryStateConfig.selectionLabel == "Province" && (
                                        <Translation
                                            id="12b9c440-267d-4a8d-a44b-6205b3011d87"
                                            defaultMessage={countryStateConfig.selectionLabel}
                                            category="Label"
                                        />
                                    )}
                                </SectionTitle>
                                <Controller
                                    control={control}
                                    name={`${addressType}.state`}
                                    rules={{
                                        required: {
                                            value: true,
                                            message: (
                                                <Translation
                                                    id="b6b52dea-31fe-4e53-b02f-65244b24e5c9"
                                                    defaultMessage="Please select a state"
                                                    category="Label"
                                                />
                                            ),
                                        },
                                    }}
                                    render={({ field }) => (
                                        <select
                                            data-testid={"order-placement-" + addressType + "-state"}
                                            className="control dropdown"
                                            onChange={field.onChange}
                                            value={field.value}
                                        >
                                            <option key={0} value={""}>
                                                {language.translateLabel(
                                                    "0eed91bc-2f44-4440-aedf-8be50856ae42",
                                                    "Please select...",
                                                    "Label"
                                                )}
                                            </option>

                                            {states.map((item, i) => {
                                                return (
                                                    <option key={item.key} value={item.value}>
                                                        {item.value}
                                                    </option>
                                                );
                                            })}
                                        </select>
                                    )}
                                />
                                {getErrorMessage(errors, `${addressType}.state`) && (
                                    <span className="error-message">
                                        {getErrorMessage(errors, `${addressType}.state`)?.message}
                                    </span>
                                )}
                            </div>
                        )}
                    </Column>
                </Row>
                <Row>
                    <Column>
                        <div className="input-validation">
                            <SectionTitle required>
                                <Translation
                                    id="0b842d4e-d67d-47ab-8b36-bf494f7c2fa7"
                                    defaultMessage="Postal Code"
                                    category="Label"
                                />
                            </SectionTitle>
                            <input
                                data-testid={"order-placement-" + addressType + "-postalCode"}
                                type="text"
                                className={getControlClassName("postalCode")}
                                placeholder="Postal Code"
                                {...register(`${addressType}.postalCode`, {
                                    required: {
                                        value: true,
                                        message: (
                                            <Translation
                                                id="0b3d4755-7414-4d6f-9793-64585824a677"
                                                defaultMessage="Please enter a postal code."
                                                category="Label"
                                            />
                                        ),
                                    },
                                    maxLength: 20,
                                    validate: {
                                        checkPostalCodeLength: (value) => {
                                            if (
                                                value.length > 5 &&
                                                getValues(`${addressType}.country`)?.toLowerCase() === "united states"
                                            ) {
                                                return language.translateLabel(
                                                    "77104189-c086-4377-bb75-bff3c40209d8",
                                                    "Please enter a 5-digit postal code."
                                                );
                                            }
                                            return true;
                                        },
                                    },
                                })}
                            />
                            {getErrorMessage(errors, `${addressType}.postalCode`) && (
                                <span className="error-message">
                                    {getErrorMessage(errors, `${addressType}.postalCode`)?.message}
                                </span>
                            )}
                        </div>
                    </Column>

                    <Column>
                        {addressType !== "billingAddress" && (
                            <div className="input-validation">
                                <SectionTitle cms="shipping-requirements">
                                    <Translation
                                        id="0ebbea5a-b555-4045-9fd6-254f0384054e"
                                        defaultMessage="Other Shipping Requirements"
                                        category="Label"
                                    />
                                </SectionTitle>
                                <input
                                    data-testid={"order-placement-specialInstructions"}
                                    type="text"
                                    className="control textbox shippingRequirements"
                                    placeholder="Examples: Liftgate or appointment required, contact a person when delivery has arrived, etc."
                                    {...register(`${addressType}.specialInstructions`, {
                                        required: {
                                            value: false,
                                        },
                                        maxLength: 1000,
                                    })}
                                />
                            </div>
                        )}
                    </Column>

                    <Column>
                        {requireEmail === true && (
                            <div className="input-validation">
                                <SectionTitle required>
                                    <Translation
                                        id="15b53b85-df5e-46db-a112-10950c5dfe37"
                                        defaultMessage="Email"
                                        category="Label"
                                    />
                                </SectionTitle>
                                <input
                                    data-testid={"order-placement-" + addressType + "-email"}
                                    type="text"
                                    className={getControlClassName("email")}
                                    placeholder="Email"
                                    {...register(`${addressType}.email`, {
                                        required: {
                                            value: true,
                                            message: (
                                                <Translation
                                                    id="e8a0a63d-eeae-40c6-934d-b8064da86226"
                                                    defaultMessage="Please enter your email"
                                                    category="Label"
                                                />
                                            ),
                                        },
                                        maxLength: 75,
                                        pattern: {
                                            value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                            message: "E-mail address is not correctly formatted.",
                                        },
                                    })}
                                />
                                {getErrorMessage(errors, `${addressType}.email`) && (
                                    <span className="error-message">
                                        {getErrorMessage(errors, `${addressType}.email`)?.message}
                                    </span>
                                )}
                            </div>
                        )}
                    </Column>
                </Row>

                {additionalFields &&
                    additionalFields.length > 0 &&
                    additionalFields.map((field, i) => {
                        return (
                            <Row>
                                <Column>
                                    <div className="input-validation">
                                        <SectionTitle required={field.required}>{field.title}</SectionTitle>
                                        <input
                                            data-testid={"order-placement-" + addressType + "-" + field.name}
                                            type={field.type}
                                            className={
                                                field.type == "checkbox"
                                                    ? "form-checkbox"
                                                    : getControlClassName(field.name)
                                            }
                                            placeholder={field.placeholder}
                                            {...register(`${addressType}.${field.name}`, {
                                                required: {
                                                    value: field.required,
                                                },
                                                maxLength:
                                                    field.maxLength && field.maxLength > 0 ? field.maxLength : 50,
                                                onChange: field.onChange,
                                            })}
                                        />
                                        {field.description}
                                        {getErrorMessage(errors, `${addressType}.${field.name}`) && (
                                            <span className="error-message">
                                                {getErrorMessage(errors, `${addressType}.${field.name}`)?.message}
                                            </span>
                                        )}
                                    </div>
                                </Column>
                            </Row>
                        );
                    })}
            </div>
        </div>
    );
};
