import React, { useState, useRef } from 'react';
import { Popup } from './Popup';
import { Info } from 'react-feather';
import { ApiDataAccess } from '../infrastructure/ApiDataAccess';
import { XSquare } from 'react-feather';
import { useTracking } from "react-tracking";

import './ContextHelp.css';

export const ContextHelp = ({ context, gaContext }) => {
    const dataAccess = new ApiDataAccess('/api/application');
    const popup = useRef(null);
    const [article, setArticle] = useState(null);
    const { trackEvent } = useTracking({ page: "ContextHelp" });


    const loadArticle = async () => {
        var data = await dataAccess.get(`/cms/${context}`);
        var article = {
            name: data.name,
            html: data.html
        };
        setArticle(article);
    }


    const onClick = async () => {
        await loadArticle();

        if (gaContext) {
            trackEvent({
                event: 'ordering_tool_interactions',
                eventProps: {
                    'step': gaContext.step,        // order_shopping_1
                    'product_category': gaContext.industryName,        // e.g. Food & Beverage
                    'product_sub_category': gaContext.industryCategoryName, // E.g. Dry Mix
                    'selection': gaContext.selection,        // e.g. 2 Side Seal Pouch
                    'element': 'information', // e.g. choose_this_style, information, photo_scroll, back
                },
                userId: gaContext.userId
            });
        }

        popup.current.show();
    }

    const onCloseClick = () => {
        popup.current.close();
    }

    return (
        <div>
            <span><Info className='icon clickable' onClick={() => onClick()} /></span>

            <Popup closeOnClickAway={true} className='helpPopup' ref={popup}>
                <div className='topSection'>
                    <div className='left'></div>
                    <div className='center'><h4>{article?.name}</h4></div>
                    <div className='right'><XSquare className='icon btn-close clickable' onClick={onCloseClick} /></div>
                </div>
                <div dangerouslySetInnerHTML={{ __html: article?.html }}></div>
            </Popup>
        </div>
    )
};


