import React, { useContext } from "react";
import { AddressItem } from "./AddressItem";
import { SectionTitle } from "../../core/SectionTitle";
import { Translation } from "../../core/Translation";
import { Language } from "../../infrastructure/Languages";

export const    EditArtworkContact = ({
    register,
    getErrorMessage,
    errors,
}) => {
    
    return (
        <div>
            <div className="input-validation">
                <SectionTitle required>
                    <Translation
                        id="8eb7936c-b737-4f03-b8d8-e282d9f43c6a"
                        defaultMessage="First Name"
                        category="Label"
                    />
                </SectionTitle>
                <input
                    placeholder="First Name"
                    className={"control textbox"}
                    {...register("artworkContact.firstName", {
                        required: "First name is required",
                        maxLength: {
                            value: 255,
                            message: "First name must be less than 255 characters",
                        },
                    })}
                />
                {getErrorMessage(errors, "artworkContact.firstName") && (
                    <span className="error-message">
                        {getErrorMessage(errors, "artworkContact.firstName")?.message}
                    </span>
                )}
            </div>
            {/* <AddressItem
                translationKey="8eb7936c-b737-4f03-b8d8-e282d9f43c6a"
                register={register}
                errors={errors}
                trigger={trigger}
                getErrorMessage={getErrorMessage}
                name="artworkContact.firstName"
                placeholder="First Name"
                id="firstName"
                enableEdit={true}
                hookFormRegistration={{
                    required: {
                        value: true,
                        message: "First name is required",
                    },
                }}
            /> */}
            <div className="input-validation">
                <SectionTitle required>
                    <Translation
                        id="cedc02f5-9290-4fa0-9107-c052919a958c"
                        defaultMessage="Last Name"
                        category="Label"
                    />
                </SectionTitle>
                <input
                    placeholder="Last Name"
                    className={"control textbox"}
                    {...register("artworkContact.lastName", {
                        required: "Last name is required",
                        maxLength: {
                            value: 255,
                            message: "last name must be less than 255 characters",
                        },
                    })}
                />
                {getErrorMessage(errors, "artworkContact.lastName") && (
                    <span className="error-message">{getErrorMessage(errors, "artworkContact.lastName")?.message}</span>
                )}
            </div>
            {/* <AddressItem
                translationKey="cedc02f5-9290-4fa0-9107-c052919a958c"
                register={register}
                errors={errors}
                trigger={trigger}
                getErrorMessage={getErrorMessage}
                name="artworkContact.lastName"
                placeholder="Last Name"
                id="lastName"
                enableEdit={true}
                hookFormRegistration={{
                    required: {
                        value: true,
                        message: "Last name is required",
                    },
                }}
            /> */}
            <div className="input-validation">
                <SectionTitle required>
                    <Translation id='8c80e88f-b776-4497-bb1d-e76b8894e1df' defaultMessage='Artwork Contact Email' category='Label' />
                </SectionTitle>
                <input
                    placeholder="Email"
                    className={"control textbox"}
                    {...register("artworkContact.email", {
                        required: { value: true, message: "E-mail address is required" },
                        pattern: {
                            value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                            message: "E-mail address is not correctly formatted",
                        },
                    })}
                />
                {getErrorMessage(errors, "artworkContact.email") && (
                    <span className="error-message">{getErrorMessage(errors, "artworkContact.email")?.message}</span>
                )}
            </div>

            {/* <AddressItem
                translationKey="15b53b85-df5e-46db-a112-10950c5dfe37"
                register={register}
                errors={errors}
                trigger={trigger}
                getErrorMessage={getErrorMessage}
                placeholder="Email"
                id="email"
                name="artworkContact.email"
                enableEdit={true}
                hookFormRegistration={{
                    required: { value: true, message: "E-mail address is required" },
                    pattern: {
                        value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                        message: "E-mail address is not correctly formatted",
                    },
                }}
            /> */}

            <div className="input-validation">
                <SectionTitle required>
                    <Translation id="6c280ccd-1c9a-403e-afb1-b8a413da45bd" defaultMessage="Phone" category="Label" />
                </SectionTitle>
                <input
                    placeholder="Phone"
                    className={"control textbox"}
                    {...register("artworkContact.phone", {
                        required: {
                            value: true,
                            message: "Phone Number is required",
                        },
                        pattern: {
                            value: /^[0-9-]+$/,
                            message: "Phone number must contain only numbers",
                        },
                    })}
                />
                {getErrorMessage(errors, "artworkContact.phone") && (
                    <span className="error-message">{getErrorMessage(errors, "artworkContact.phone")?.message}</span>
                )}
            </div>

            {/* <AddressItem
                translationKey="6c280ccd-1c9a-403e-afb1-b8a413da45bd"
                register={register}
                errors={errors}
                trigger={trigger}
                getErrorMessage={getErrorMessage}
                name="artworkContact.phone"
                placeholder="Phone"
                id="phone"
                enableEdit={true}
                hookFormRegistration={{
                    required: {
                        value: true,
                        message: "Phone Number is required",
                    },
                    pattern: {
                        value: /^[0-9-]+$/,
                        message: "Phone number must be numbers only",
                    },
                }}
            /> */}
        </div>
    );
};
