import React, { useEffect, useState, useContext, useRef } from 'react';
import { useParams } from 'react-router';
import { useNavigate, useLocation } from 'react-router-dom';
import { ApiDataAccess } from '../infrastructure/ApiDataAccess';
import { ShoppingBreadcrumbs } from '../core/Breadcrumbs';
import { Tabs, Tab } from '../core/Tabs';
import { ContextHelp } from '../core/ContextHelp';
import { Popup } from '../core/Popup';
import { Page } from '../core/Page';
import { TabContent, ActiveTab } from '../components/TabContent';
import { ChevronDown, ChevronUp, XSquare, Download } from 'react-feather';
import { useCheckoutContext } from '../infrastructure/CheckoutContext';
import { Language } from '../infrastructure/Languages';
import { Screen } from '../infrastructure/Screen';
import { PouchPreview, RenderModel } from '../components/ThreeDModel';
import { LengthMeasurement } from '../infrastructure/Measurements';
import { CapabilityName } from '../infrastructure/Constants';
import { CapabilitiesContext } from '../infrastructure/Contexts';
import { useUserContext } from '../infrastructure/UserContext';
import { useTracking } from "react-tracking";
import { useCookies } from 'react-cookie';
import { GoogleAnalyticsContext } from '../infrastructure/GoogleAnalyticsContext';

import './Material.css';


export const MaterialPage = () => {
    const { group } = useParams();
    const popup = useRef(null);
    const { product, updateMaterial, dimensions, convertingMethod, material, updateSkuCount, updateCheckoutFlag, updateFeature } = useCheckoutContext();
    const language = useContext(Language);
    const { userRegion } = useUserContext();
    const navigate = useNavigate();
    const location = useLocation();
    const materialsDataAccess = new ApiDataAccess('/api/packagingmaterials');
    const [filter, setFilter] = useState(null);
    const [searchResults, setSearchResults] = useState(null);
    const [sort, setSort] = useState(0);
    const [showGuidedExperienceLink, setShowGuidedExperienceLink] = useState(false);
    const [model, setModel] = useState(null);
    const [selectedItem, setSelectedItem] = useState(null);
    const pouchPreview = useRef(null);
    const capabilitiesContext = useContext(CapabilitiesContext);
    const loader = capabilitiesContext.getCapability(CapabilityName.Loader);
    const { trackEvent } = useTracking({ page: "MaterialPage" });
    const [cookies, setCookie] = useCookies(['visitorId']);
    const [visitorId, setVisitorId] = useState(null);

    const [sectionCollapsed, setSectionCollapsed] = useState({
        additional: true
    });

    let startDate = new Date();
    let elapsedTime = 0;

    useEffect(() => {
        Screen.scrollToElement('layout');
        applyFilter({});
        if (!material) {
            popup.current.show();
        }
        startDate = new Date();

        var id = cookies.visitorId;
        if (id) {
            setVisitorId(id);
        }
    }, []);




    const endTimer = () => {
        const endDate = new Date();
        const spentTime = endDate.getTime() - startDate.getTime();
        elapsedTime += spentTime;

        const timeInSec = elapsedTime / 1000;

        console.log("END TIMER ELAPSED: ", timeInSec + "sec");
        trackEvent({
            event: 'ordering_tool_interactions',
            eventProps: {
                'step': 'order_shopping_3',        // order_shopping_3
                'product_category': product.industryName,        // e.g. Food & Beverage
                'product_sub_category': product.industryCategoryName, // E.g. Dry Mix
                'selection': "material_selection_timer",
                'element': timeInSec + "sec",
            },
            userId: visitorId
        });
    }


    const applyFilter = filter => {
        loader.show();

        setSearchResults(null);
        if (!filter) {
            filter = {};
        }

        filter['productGroup'] = product ? product.productGroupId : group;
        filter['componentType'] = convertingMethod ? convertingMethod.componentTypeId : null;
        filter['convertingMethods'] = convertingMethod ? [convertingMethod.convertingMethodId] : null;
        console.log(dimensions);
        if (dimensions && convertingMethod) {
            filter['pouchDimensions'] = {
                width: dimensions.selectedDims.width,
                length: dimensions.selectedDims.length,
                gusset: dimensions.selectedDims.gusset,
                measureUnitId: convertingMethod.bleedMeasurement.uomId,
                bleed: convertingMethod.bleedMeasurement.value,
                eyemark: convertingMethod.eyemarkMeasurement.value
            };
            filter['eyemark'] = convertingMethod.eyemarkDefault.id;
        }
        console.log(convertingMethod);

        console.log('FILTER', filter);

        materialsDataAccess.post(`/filter`, filter)
            .then(result => {
                console.log('RESULT', result);

                var c1 = result.filter.finishTypes.filter(e => e.available).length;
                var c2 = result.filter.transparencies.filter(e => e.available).length;
               // var c3 = result.filter.sustainabilities.filter(e => e.available).length;
                setShowGuidedExperienceLink(c1 > 1 || c2 > 1 );

                setFilter(result.filter);

                switch (sort) {
                    case 0: {
                        result.structureSpecs.sort((a, b) => (a.id > b.id) ? 1 : -1);
                        break;
                    };
                    case 1: {
                        result.structureSpecs.sort((a, b) => (a.value > b.value) ? 1 : -1);
                        break;
                    };
                    case 2: {
                        result.structureSpecs.sort((a, b) => (a.priceCategory > b.priceCategory) ? 1 : -1);
                        break;
                    };
                }

                setSearchResults(result.structureSpecs);
                loader.hide();
            }).
            catch(ex => {
                loader.hide();
                setSearchResults(null);
                setFilter(null);
                navigate(`/${userRegion}/search`, { replace: true });
            });

    }

    const setFilterValue = (section, value, checked) => {
        var f = {};
        Object.keys(filter).forEach(key => {
            filter[key].forEach(node => {
                if (node.selected) {
                    if (!(section == key && node.item.id == value)) {
                        if (!f[key]) {
                            f[key] = [];
                        }
                        f[key].push(node.item.id);
                    }
                }
            });
        });

        if (!f[section] || f[section].indexOf(value) < 0) {
            if (checked) {
                if (!f[section]) {
                    f[section] = [];
                }
                f[section].push(value);
            }
        }

        if (!checked) {
            if (f[section]) {
                var idx = f[section].indexOf(value);
                if (idx > 0) {
                    f[section].splice(idx);
                }
            }
        }

        applyFilter(f);
    }


    const onSelected = selectedMaterial => {

        if (material && material.id !== selectedMaterial.id) {
            updateFeature(null);
            updateSkuCount(null);
            updateCheckoutFlag(false);
        }

        pouchPreview.current.setFinishType(selectedMaterial.finishType.id)
        setSelectedItem(selectedMaterial);

        var model = {
            id: selectedMaterial.id,
            name: selectedMaterial.value,
            secondaryName: selectedMaterial.secondaryName,
            specSheetUrl: selectedMaterial.pdfUrl,
            finishTypeId: selectedMaterial.finishType.id,
            filmImageUrl: selectedMaterial && selectedMaterial.pdfUrl ? selectedMaterial.pdfUrl.toLowerCase().replace('/api/', '/data/').replace('/pdf', '/image') : null
        }

        console.log('SELECTED MATERIAL', model);

        updateMaterial(model);
        setModel(model);
        Screen.scrollToElement('btnNext');

        trackEvent({
            event: 'ordering_tool_interactions',
            eventProps: {
                'step': 'order_shopping_3',        // order_shopping_3
                'product_category': product.industryName,        // e.g. Food & Beverage
                'product_sub_category': product.industryCategoryName, // E.g. Dry Mix
                'selection': selectedMaterial.finishType.value + ", " + selectedMaterial.transparency.value ,        // e.g. finish type, seal type
                'element': 'material_box',        // e.g.
            },
            userId: visitorId
        });

    }



    const onSectionToggled = (section, collapsed) => {
        var sec = sectionCollapsed;
        sec[section] = collapsed;
        setSectionCollapsed(sec);
    }

    const applySort = (type) => {
        switch (type) {
            case 0: {
                searchResults.sort((a, b) => (a.id > b.id) ? 1 : -1);
                break;
            };
            case 1: {
                searchResults.sort((a, b) => (a.value > b.value) ? 1 : -1);
                break;
            };
            case 2: {
                searchResults.sort((a, b) => (a.priceCategory > b.priceCategory) ? 1 : -1);
                break;
            };
        }
        setSearchResults(searchResults);
    }

    const showGuidedExperiencePopup = () => {
        //applyFilter({});
        popup.current.show();
    }

    const onNext = () => {

        endTimer();

        trackEvent({
            event: 'ordering_tool_selections',
            eventProps: {
                'step': 'order_shopping_3',        // order_shopping_3
                'product_category': product.industryName,        // e.g. Food & Beverage
                'product_sub_category': product.industryCategoryName, // E.g. Dry Mix
                'selection': selectedItem.finishType.value + ", " + selectedItem.transparency.value ,        // e.g. finish type, seal type
            },
            userId: visitorId
        });
        navigate(`/${userRegion}/group/${group}/convertingMethod/${convertingMethod.convertingMethodId}/material/${model.id}/features`);
    }

    const onBackButtonClicked = () => {
        trackEvent({
            event: 'ordering_tool_interactions',
            eventProps: {
                'step': 'order_shopping_3',        // order_shopping_3
                'product_category': product.industryName,        // e.g. Food & Beverage
                'product_sub_category': product.industryCategoryName, // E.g. Dry Mix
                'element': 'back', // e.g. choose_this_style, information, photo_scroll, back
            },
            userId: visitorId
        });

        navigate(`/${userRegion}/group/${product.productGroupId}/convertingMethod/${convertingMethod.convertingMethodId}/dimensions`);
    }

    return (
        <div>

            <Popup closeOnClickAway={true} ref={popup}>
                <GuidedExperienceWizard productGroupId={parseInt(group)} onCloseClick={() => { popup.current.close() }} onFilterSet={f => { applyFilter(f) }} />
            </Popup>

            <ShoppingBreadcrumbs active={2} />

            {searchResults &&
                <Page className='material' onRenderHeader={() => {
                    return (
                        <>
                            {filter &&
                                <Tabs>
                                    <Tab title={language.translateLabel('04ea9f38-1c48-4a34-9f44-ebbefbcc6436', 'Your Selections', 'Tab')}><TabContent activeTab={ActiveTab.Material} /></Tab>
                                    <Tab title={language.translateLabel('c20d2e2e-6020-4169-aef0-5602d6cc7429', 'Filter', 'Tab')} className='filterTab'><Filter filter={filter} onSelected={setFilterValue} onToggled={onSectionToggled} sectionCollapsed={sectionCollapsed} onResetClick={() => applyFilter({})} /></Tab>
                                </Tabs>
                            }
                        </>
                    )
                }} >

                    <PouchPreview ref={pouchPreview} request={{
                        ProductGroupType: RenderModel.MapProductGroupType(convertingMethod.convertingMethodId),
                        LengthInMillimeters: (new LengthMeasurement(dimensions.selectedDims.length, dimensions.selectedDims.measureUnitId)).convertToMillimeters(),
                        WidthInMillimeters: (new LengthMeasurement(dimensions.selectedDims.width, dimensions.selectedDims.measureUnitId)).convertToMillimeters(),
                        GussetInMillimeters: (new LengthMeasurement(dimensions.selectedDims.gusset, dimensions.selectedDims.measureUnitId)).convertToMillimeters(),
                    }} />


                    <h2>{language.translateLabel('2f263e3a-5bc5-4ac5-af0e-1c4f44d959e1', 'Select Packaging Material')}</h2>
                    <div className='subTitle'>
                        {showGuidedExperienceLink &&
                            <a onClick={() => { showGuidedExperiencePopup() }}>{language.translateLabel('f4436770-0b80-49b4-91da-b454ea538997', 'Guide me through the options')}</a>
                        }
                    </div>


                    <div className='filterComponent'>
                        {filter && searchResults &&
                            <Filter visitorId={visitorId} filter={filter} onSelected={setFilterValue} onToggled={onSectionToggled} sectionCollapsed={sectionCollapsed} onResetClick={() => applyFilter({})} />
                        }

                        {searchResults &&
                            <div className='materialPanel'>
                                {searchResults.length > 1 &&
                                    <div className='toolbar'>
                                        <button onClick={() => { setSort(0); applySort(0); }} className={sort == 0 ? 'btn btn-tertiary' : 'btn btn-tertiary-outline'}>{language.translateLabel('ee04e556-8161-4f4a-9f7f-601c14e6469f', 'Sort by Spec', 'Button')}</button>
                                        <button onClick={() => { setSort(1); applySort(1); }} className={sort == 1 ? 'btn btn-tertiary' : 'btn btn-tertiary-outline'}>{language.translateLabel('d5745d67-2cfb-40d4-8788-48034ad651e9', 'Sort by Material', 'Button')}</button>
                                        <button onClick={() => { setSort(2); applySort(2); }} className={sort == 2 ? 'btn btn-tertiary' : 'btn btn-tertiary-outline'}>{language.translateLabel('1e10e504-4b33-470f-b759-189570e8ca59', 'Sort by Price', 'Button')}</button>
                                    </div>
                                }


                                {searchResults.map((item, idx) => {
                                    return (
                                        <MaterialPanelItem key={`spec${idx}`} material={item} onSelected={onSelected} selected={material && material.id == item.id} />
                                    )
                                })}


                                {product && convertingMethod &&
                                    <button className='btn btn-tertiary-outline back' onClick={() => { onBackButtonClicked() }}><span className='btn-text'>{language.translateLabel('48cda936-8f88-4703-a02c-69648eb3fa2a', 'Back', 'Button')}</span></button>
                                }

                                {product && convertingMethod &&
                                    <button data-testid="material-selection-button-next" id='btnNext' className='btn btn-secondary pull-right next' disabled={!model} onClick={() => { onNext() }}><span className='btn-text'>{language.translateLabel('bd4339c0-1b67-459a-aaa7-6c4896f46ff7', 'Next', 'Button')}</span></button>
                                }


                            </div>
                        }
                    </div>

                </Page>
            }
        </div>
    )
}


export const MaterialPanelItem = ({ material, selected, onSelected }) => {

    const language = useContext(Language);
    const filmImageUrl = material && material.pdfUrl ? material.pdfUrl.toLowerCase().replace('/api/', '/data/').replace('/pdf', '/image') : null;
    const thickness = material.filmWidth.uomId == 13 ? `${(material.thickness * 1000).toFixed(2)} mil` : `${material.thickness * 1000} μm`;

    const onClicked = () => {

        if (onSelected) {
            onSelected(material);
        }
    }

    return (
        <div data-testid="material-panel-item-selection" className={selected ? 'materialPanelItem shadow corners-round-4 active-border' : 'materialPanelItem shadow corners-round-4 default-border'}>
            <div className='panelTitle'>
                <div className='title' onClick={onClicked}><h4>{material.value} ({`SPEC-${material.id}`})</h4></div>
                <div className='icon'><a href={material.pdfUrl}><Download className='icon' /></a></div>
            </div>
            <div className='panel' onClick={onClicked}>
                <div className='filmVisualizer' style={{ backgroundImage: `url(${filmImageUrl})` }}></div>
                <div className='itemsPanel'>

                    <div className='item'>
                        <h5>{language.translateLabel('7b54bbee-bdab-49ae-8ce5-65a8a3c5ea9f', 'Cost', 'Material Attribute')}</h5>
                        <PriceIndicator priceCategory={material.priceCategory} />
                    </div>

                    <div className='item'>
                        <h5>{language.translateLabel('efd0ac56-6065-4f85-9ec9-d1165084087b', 'Finish Type', 'Material Attribute')}</h5>
                        <IconLabel name={language.translateDomain('d459e324-75cd-4cc3-84aa-12976d0a837b', `finishTypes${material.finishType.id}`, material.finishType.value, 'Material Attribute Value')} url={`/api/images/styleguide/icons/material/finish_type_${material.finishType.id}.png`} />
                    </div>

                    <div className='item'>
                        <h5>{language.translateLabel('9459020e-ef63-4b22-b06b-759a0afdce6e', 'Thickness', 'Material Attribute')}</h5>
                        <span>{thickness}</span>
                    </div>

                    <div className='item'>
                        <h5>{language.translateLabel('99520b65-9c05-4ddb-b8b3-f4eb4abab0d1', 'Barrier Type', 'Material Attribute')}</h5>
                        <IconLabel name={language.translateDomain('d459e324-75cd-4cc3-84aa-12976d0a837b', `barrierTypes${material.barrierType.id}`, material.barrierType.value, 'Material Attribute Value')} />
                    </div>

                    <div className='item'>
                        <h5>{language.translateLabel('c184f0b4-a30b-47e9-bf01-972ec09db9bd', 'Sustainability', 'Material Attribute')}</h5>
                        <IconLabel name={language.translateDomain('d459e324-75cd-4cc3-84aa-12976d0a837b', `sustainabilities${material.sustainability.id}`, material.sustainability.value, 'Material Attribute Value')} url={`/api/images/styleguide/icons/material/sustainability_${material.sustainability.id}.png`} />
                    </div>

                    <div className='item'>
                        <h5>{language.translateLabel('ed7b367f-8381-4838-bcb7-8f93bb337579', 'Window', 'Material Attribute')}</h5>
                        <IconLabel name={material.transparency.isLookThrough ? language.translateLabel('bc6dccd1-ec9a-42df-ac0b-71a6841cd1df', 'Yes') : language.translateLabel('0b0fa336-f0f1-4c5c-aea1-236e9ff25aa4', 'No')} url={`/api/images/styleguide/icons/material/transparency_${material.transparency.isLookThrough ? 1 : 2}.png`} />
                    </div>

                </div>
            </div>
        </div>
    )
}

export const Filter = ({ filter, visitorId, onSelected, onToggled, sectionCollapsed, onResetClick }) => {

    const language = useContext(Language);
    const { product } = useCheckoutContext();
    const googleAnalyticsContext = new GoogleAnalyticsContext('order_shopping_3', visitorId, product.industryName, product.industryCategoryName);

    const canReset = () => {
        var result = false;
        Object.keys(filter).forEach(key => {
            if (key !== 'convertingMethods' && key !== 'filmWidth') {
                var obj = filter[key].find(e => e.available == true && e.selected == true);
                if (obj) {
                    result = true;
                }
            }
        });
        return result;
    }

    return (
        <div className='filterPanel'>

            {canReset() &&
                <div className='filterSection'>
                    <button onClick={onResetClick} className='btn btn-tertiary-outline'>{language.translateLabel('427c1733-9fbe-49ab-a1f9-d93fb5946b41', 'Reset Filter', 'Button')}</button>
                </div>
            }

            <FilterCollection gaContext={googleAnalyticsContext.CreateContext('Finish Type')} name={language.translateLabel('efd0ac56-6065-4f85-9ec9-d1165084087b', 'Finish Type', 'Material Attribute')} filter={filter} section={'finishTypes'} onSelected={onSelected} />
            <FilterCollection gaContext={googleAnalyticsContext.CreateContext('Transparency')} name={language.translateLabel('418cb75e-78c3-47c0-8937-141c2e580082', 'Transparency', 'Material Attribute')} filter={filter} section={'transparencies'} onSelected={onSelected} />
            <FilterCollection gaContext={googleAnalyticsContext.CreateContext('Sustainability')} name={language.translateLabel('c184f0b4-a30b-47e9-bf01-972ec09db9bd', 'Sustainability', 'Material Attribute')} filter={filter} section={'sustainabilities'} onSelected={onSelected} />

            <Collapsible name={language.translateLabel('3b81832a-27b0-4dca-8990-1863faba7b25', 'Additional Filters', 'Label')} onToggled={value => { onToggled('additional', value) }} expanded={!sectionCollapsed['additional']}>
                <FilterCollection gaContext={googleAnalyticsContext.CreateContext('Barrier Type')} name={language.translateLabel('99520b65-9c05-4ddb-b8b3-f4eb4abab0d1', 'Barrier Type', 'Material Attribute')} filter={filter} section={'barrierTypes'} onSelected={onSelected}  />
                <FilterCollection gaContext={googleAnalyticsContext.CreateContext('Seal Type')} name={language.translateLabel('e4c671a1-0a8f-4106-afcc-f81d7b8ac73a', 'Seal Type', 'Material Attribute')} filter={filter} section={'sealTypes'} onSelected={onSelected} />
                <FilterCollection gaContext={googleAnalyticsContext.CreateContext('Seal Wrap Type')} name={language.translateLabel('6e6ec33b-17a3-4423-95d0-a4fcb670df8f', 'Seal Wrap Type', 'Material Attribute')} filter={filter} section={'sealWrapTypes'} onSelected={onSelected} />
                <FilterCollection gaContext={googleAnalyticsContext.CreateContext('Fill Type')} name={language.translateLabel('26301275-49db-4729-ac40-2ed55b27a6fd', 'Fill Type', 'Material Attribute')} filter={filter} section={'fillTypes'} onSelected={onSelected} />
                <FilterCollection gaContext={googleAnalyticsContext.CreateContext('Application')} name={language.translateLabel('f8d3ea13-adf5-4d6d-933b-de06a1c8e2e4', 'Application', 'Material Attribute')} filter={filter} section={'applications'} onSelected={onSelected}  />
                <FilterCollection gaContext={googleAnalyticsContext.CreateContext('Film Width')} name={language.translateLabel('2fca8d2f-0d53-4c5f-989b-e8a762927493', 'Film Width', 'Material Attribute')} filter={filter} section={'filmWidth'} onSelected={onSelected}  />
            </Collapsible>

        </div>
    )
}

export const FilterCollection = ({ name, filter, section, gaContext, onSelected }) => {

    const language = useContext(Language);

    const onClicked = (item, evt) => {
        if (onSelected) {
            onSelected(section, item.id, evt.target.checked);
        }
    }

    return (
        <div className='filterSection'>
            <div className='sectionHeader'>
                <div className='text'>
                    <h3>{name}</h3>
                </div>
                <div className='controls clickable'>
                    <h3><ContextHelp context={section} gaContext={gaContext} /></h3>
                </div>

            </div>

            <div className='sectionContent'>
                {filter[section].map((itm, idx) => {
                    return (
                        <div key={`section${idx}`}>
                            <label className='checkboxContainer'>
                                <input type="checkbox" disabled={!itm.available} value={itm.item.id} defaultChecked={itm.selected} onChange={evt => { onClicked(itm.item, evt) }} />
                                <div className='caption'>{language.translateDomain('d459e324-75cd-4cc3-84aa-12976d0a837b', `${section}${itm.item.id}`, itm.item.value, 'Material Attribute Value')}</div>
                                <span className="checkmark"></span>
                            </label>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}


export const Collapsible = ({ name, children, expanded, onToggled }) => {

    const [collapsed, setCollapsed] = useState(!expanded);

    const onToggle = () => {
        setCollapsed(!collapsed);
        if (onToggled) {
            onToggled(!collapsed);
        }
    }

    return (
        <div className={'filterSection collapsible'}>

            <div className='sectionHeader'>
                <div className='text'>
                    <h3 className={collapsed ? 'clickable collapsed' : 'clickable'} onClick={() => { onToggle() }}>{name}</h3>
                </div>
                <div className='controls clickable'>
                    <h3>{collapsed ? <ChevronDown className='icon' onClick={() => { onToggle() }} /> : <ChevronUp className='icon' onClick={() => { onToggle() }} />}</h3>
                </div>
            </div>

            {!collapsed &&
                <div>{children}</div>
            }

        </div>
    )
}


{/*
export const FilterSection = ({ name, filter, section, onSelected, collapsible, expanded, gaContext, onToggled }) => {

    const [collapsed, setCollapsed] = useState(!expanded);

    const onToggle = () => {
        setCollapsed(!collapsed);
        if (onToggled) {
            onToggled(section, !collapsed);
        }
    }

    return (
        <div className={collapsible ? 'filterSection collapsible' : 'filterSection'}>
            {collapsible
                ? <div className='sectionHeader'>
                    <div className='text'>
                        <h3 className={collapsed ? 'clickable collapsed' : 'clickable'} onClick={() => { onToggle() }}>{name}</h3>
                    </div>
                    <div className='controls clickable'>
                        <h3>{collapsed ? <ChevronDown className='icon' onClick={() => { onToggle() }} /> : <ChevronUp className='icon' onClick={() => { onToggle() }} />} <ContextHelp context={section} /></h3>
                    </div>
                </div>
                : <div className='sectionHeader'>
                    <div className='text'>
                        <h3>{name}</h3>
                    </div>
                    <div className='controls clickable'>
                        <h3><ContextHelp context={section} gaContext={gaContext} /></h3>
                    </div>
                </div>
            }

            {(!collapsible || !collapsed) &&
                <FilterCollection name={name} filter={filter} section={section} onSelected={onSelected}  />
            }
        </div>
    )
}
*/}


export const PriceIndicator = ({ priceCategory }) => {

    return (
        <div className='priceIndicator'>

            <div className={priceCategory >= 1 ? 'symbol active' : 'symbol inactive'}>$</div>
            <div className={priceCategory >= 2 ? 'symbol active' : 'symbol inactive'}>$</div>
            <div className={priceCategory >= 3 ? 'symbol active' : 'symbol inactive'}>$</div>
            <div className={priceCategory >= 4 ? 'symbol active' : 'symbol inactive'}>$</div>
        </div>
    )
}


export const GuidedExperienceWizard = ({  productGroupId, onCloseClick, onFilterSet }) => {


    const language = useContext(Language);
    const materialsDataAccess = new ApiDataAccess('/api/packagingmaterials');
    const { trackEvent } = useTracking({ page: "GuidedExperienceWizard" });

    const [stepIndex, setStepIndex] = useState(1);
    const [filter, setFilter] = useState(null);
    const [filters, setFilters] = useState(new Array(2));

    const { product, convertingMethod, dimensions } = useCheckoutContext();
    const [cookies, setCookie] = useCookies(['visitorId']);
    const [visitorId, setVisitorId] = useState(null);

    useEffect(() => {
        applyFilter({});

        var id = cookies.visitorId;
        if (id) {
            setVisitorId(id);
        }
    }, []);


    const applyFilter = filter => {

        if (!filter) {
            filter = {};
        }

        filter['productGroup'] = product ? product.productGroupId : productGroupId;
        filter['componentType'] = convertingMethod ? convertingMethod.componentTypeId : null;
        filter['convertingMethods'] = convertingMethod ? [convertingMethod.convertingMethodId] : null;

        if (dimensions && convertingMethod) {
            filter['pouchDimensions'] = {
                width: dimensions.selectedDims.width,
                length: dimensions.selectedDims.length,
                gusset: dimensions.selectedDims.gusset,
                measureUnitId: convertingMethod.bleedMeasurement.uomId,
                bleed: convertingMethod.bleedMeasurement.value,
                eyemark: convertingMethod.eyemarkMeasurement.value
            };
            filter['eyemark'] = convertingMethod.eyemarkDefault.id;
        }

        console.log('FILTER', filter);

        materialsDataAccess.post(`/filter`, filter)
            .then(result => {
                console.log('RESULT', result);

                if (getItems(result.filter.finishTypes).length == 1 && getItems(result.filter.transparencies).length == 1) {
                    if (onFilterSet) {
                        onFilterSet(filter);
                    }
                    onCloseClick();
                }
                else {
                    if (getItems(result.filter.finishTypes).length > 1) {
                        filters[0] = JSON.parse(JSON.stringify(filter));
                        setFilters(filters);
                        setStepIndex(1);
                    }
                    else if (getItems(result.filter.transparencies).length > 1) {
                        filters[1] = JSON.parse(JSON.stringify(filter));
                        setFilters(filters);
                        setStepIndex(2);
                    }
                    //else if (getItems(result.filter.sustainabilities).length > 1) {
                    //    filters[2] = JSON.parse(JSON.stringify(filter));
                    //    setFilters(filters);
                    //    setStepIndex(3);
                    //}
                }

                setFilter(result.filter);
            }).
            catch(ex => {
                onCloseClick();
            });
    }


    const getItems = (sectionItems) => {
        var result = [];
        sectionItems.forEach(item => {
            if (item.available) {
                result.push(item.item);
            }
        })
        return result;
    }


    const setFilterValue = (section, value, checked) => {
        var f = {};
        Object.keys(filter).forEach(key => {
            filter[key].forEach(node => {
                if (node.selected) {
                    if (!(section == key && node.item.id == value)) {
                        if (!f[key]) {
                            f[key] = [];
                        }
                        f[key].push(node.item.id);
                    }
                }
            });
        });

        if (!f[section] || f[section].indexOf(value) < 0) {
            if (checked) {
                if (!f[section]) {
                    f[section] = [];
                }
                f[section].push(value);
            }
        }

        if (!checked) {
            if (f[section]) {
                var idx = f[section].indexOf(value);
                if (idx > 0) {
                    f[section].splice(idx);
                }
            }
        }

        applyFilter(f);

        // get selected filter value for GA event
        var element = null;
        filter[section].map((x) => {
            if (x.item.id === value) {
                element = x.item.value;
            }
        });


        console.log(element);

        if (section === 'finishTypes') {
            trackEvent({
                event: 'ordering_tool_interactions',
                eventProps: {
                    'step': 'order_shopping_3',
                    'guided_shopping_step': 'guided_shopping_step_1',
                    'product_category': product.industryName,        // e.g. Food & Beverage
                    'product_sub_category': product.industryCategoryName, // E.g. Dry Mix
                    'selection': section,   // finishTypes
                    'element': element  // Gloss
                },
                userId: visitorId
            });
        }
        if (section === 'transparencies') {
            trackEvent({
                event: 'ordering_tool_interactions',
                eventProps: {
                    'step': 'order_shopping_3',
                    'guided_shopping_step': 'guided_shopping_step_2',
                    'product_category': product.industryName,        // e.g. Food & Beverage
                    'product_sub_category': product.industryCategoryName, // E.g. Dry Mix
                    'selection': section,   // transparencies
                    'element': element  // eg. Opaque White
                },
                userId: visitorId
            });
        }

        //if (section === 'sustainabilities') {
        //    trackEvent({
        //        event: 'ordering_tool_interactions',
        //        eventProps: {
        //            'step': 'order_shopping_3',
        //            'guided_shopping_step': 'guided_shopping_step_3',
        //            'product_category': product.industryName,        // e.g. Food & Beverage
        //            'product_sub_category': product.industryCategoryName, // E.g. Dry Mix
        //            'selection': section,   // sustainabilities
        //            'element': element  // eg. Recycleable
        //        },
        //        userId: visitorId
        //    });
        //}

    }

    const onBackClick = () => {
        if (stepIndex === 2) {
            console.log(filters[0]);
            applyFilter(filters[0]);
        }

    }

    return (
        <div className='guidedExperienceWizard' data-testid="guided-experience-wizard">
            {filter && stepIndex == 1 &&
                <div>
                    <div className='topSection'>
                        <div className='left'>1/2</div>
                        <div className='center'><h4>{language.translateLabel('417cba6d-c618-490d-a48d-acd18949d3d7', 'Let me help you narrow down your packaging material finish type today')}</h4></div>
                        <div className='right'><XSquare data-testid="guided-experience-wizard-close" className='icon btn-close' onClick={onCloseClick} /></div>
                    </div>

                    <div className='contentSection'>
                        <h4>{language.translateLabel('05714200-7beb-41f6-bc63-f0bd6941f22f', 'How do you want your package to look and feel?')}</h4>
                        {getItems(filter.finishTypes).map((item, idx) => {
                            return (
                                <div data-test-id="guided-expierence-wizard-choice" key={`item${idx}`} className='resultItem shadow corners-round-4' onClick={() => { setFilterValue('finishTypes', item.id, true) }}>
                                    <div className='image'>
                                        <img src={language.imageUrl('d299f54d-6b7c-4556-bc6e-d488b4b366b4', `finishTypes_${convertingMethod.convertingMethodId}_${item.id}`, `Material Finish Types (${convertingMethod.convertingMethodName})`, item.value)} />
                                    </div>
                                    <div className='explain'>
                                        <h3>{language.translateDomain('d459e324-75cd-4cc3-84aa-12976d0a837b', `finishTypes${item.id}`, item.value, 'Material Attribute Value')}</h3>
                                        <p>{language.translateDomain('18259fed-b8c6-4fbb-984e-d8dc09af432d', `finishTypes${item.id}`, item.description, 'Material Attribute Description')}</p>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
            }

            {filter && stepIndex == 2 &&
                <div>
                    <div className='topSection'>
                        <div className='left'>2/2</div>
                        <div className='center'><h4>{language.translateLabel('418cb75e-78c3-47c0-8937-141c2e580082', 'Transparency', 'Material Attribute')}</h4></div>
                        <div className='right'><XSquare className='icon btn-close' onClick={onCloseClick} /></div>
                    </div>

                    <div className='contentSection'>
                        <h4>{language.translateLabel('2c7d0af0-8700-4b41-8d52-0fc2426199b5', 'Would you like a window to see your product?')}</h4>
                        {getItems(filter.transparencies).map((item, idx) => {
                            return (
                                <div key={`item${idx}`} className='resultItem shadow corners-round-4' onClick={() => { setFilterValue('transparencies', item.id, true) }}>
                                    <div className='image'>
                                        <img src={language.imageUrl('f6787346-f2dd-4865-ace0-0266e40002fc', `transparencies_${convertingMethod.convertingMethodId}_${item.id}`, `Material Transparency (${convertingMethod.convertingMethodName})`, item.value)} />
                                    </div>
                                    <div className='explain'>
                                        <h3>{language.translateDomain('d459e324-75cd-4cc3-84aa-12976d0a837b', `transparencies${item.id}`, item.value, 'Material Attribute Value')}</h3>
                                        <p>{language.translateDomain('18259fed-b8c6-4fbb-984e-d8dc09af432d', `transparencies${item.id}`, item.description, 'Material Attribute Description')}</p>
                                    </div>
                                </div>
                            )
                        })}
                        <button className='btn btn-tertiary-outline back' onClick={onBackClick}><span className='btn-text'>{language.translateLabel('48cda936-8f88-4703-a02c-69648eb3fa2a', 'Back', 'Button')}</span></button>
                    </div>
                </div>
            }



            {/*{filter && stepIndex == 3 &&*/}
            {/*    <div>*/}
            {/*        <div className='topSection'>*/}
            {/*            <div className='left'>3/3</div>*/}
            {/*            <div className='center'><h4>{language.translateLabel('c184f0b4-a30b-47e9-bf01-972ec09db9bd', 'Sustainability', 'Material Attribute')}</h4></div>*/}
            {/*            <div className='right'><XSquare className='icon btn-close' onClick={onCloseClick} /></div>*/}
            {/*        </div>*/}

            {/*        <div className='contentSection'>*/}
            {/*            {getItems(filter.sustainabilities).map((item, idx) => {*/}
            {/*                return (*/}
            {/*                    <div key={`item${idx}`} className='resultItem shadow corners-round-4' onClick={() => { setFilterValue('sustainabilities', item.id, true) }}>*/}
            {/*                        <div className='image'>*/}
            {/*                            <img src={language.imageUrl('1892b995-dfff-40ec-969b-a650782fb444', `sustainabilities${item.id}`, 'Material Sustainability', item.value)} />*/}
            {/*                        </div>*/}
            {/*                        <div className='explain'>*/}
            {/*                            <h3>{language.translateDomain('d459e324-75cd-4cc3-84aa-12976d0a837b', `sustainabilities${item.id}`, item.value, 'Material Attribute Value')}</h3>*/}
            {/*                            <p>{language.translateDomain('18259fed-b8c6-4fbb-984e-d8dc09af432d', `sustainabilities${item.id}`, item.description, 'Material Attribute Description')}</p>*/}
            {/*                        </div>*/}
            {/*                    </div>*/}
            {/*                )*/}
            {/*            })}*/}
            {/*            <button className='btn btn-tertiary-outline back' onClick={onBackClick}><span className='btn-text'>{language.translateLabel('48cda936-8f88-4703-a02c-69648eb3fa2a', 'Back', 'Button')}</span></button>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*}*/}
        </div>
    )
}

export const IconLabel = ({ name, url }) => {

    return (
        <div className='iconLabel'>
            {url &&
                <img src={url} />
            }
            <span>{name}</span>
        </div>
    )
}
