import React, { useEffect, useState, forwardRef, useImperativeHandle } from "react";
import { ChevronRight, ChevronDown } from 'react-feather';

import './ExpandableCard.css';

export const ExpandableCard = forwardRef(({ expanded, disabled, onInit, onChange, title, subtitle, children, id, useHide}, ref) => {
    const displayName = ExpandableCard.name;

    const [collapsed, setCollapsed] = useState(expanded ? !expanded : true)

    useEffect(() => {
        if (onInit) {
            onInit(this);
        }
    }, [])

    useImperativeHandle(ref, () => ({
        expand: () => setCollapsed(false),
        collapse: () => setCollapsed(true)
    }));


    const toggle = () => {
        if (!disabled) {
            let newState = !collapsed;
            setCollapsed(newState);

            if (onChange) {
                onChange(!newState);
            }
        }
    }

    const collapse = () => setCollapsed(true);

    const expand = () => setCollapsed(false);

    return (
        <div className={'card expandable'} id={id}>
            {title &&
                <div className='title' onClick={toggle}>
                    <div className='imageContainer'>
                        {collapsed
                            ? <ChevronRight className='icon' />
                            : <ChevronDown className='icon' />
                        }
                    </div>

                    <div className='textContainer'>
                        <div className='titleText'>{title}</div>
                        {subtitle && !collapsed &&
                            <div className='subText'>{subtitle}</div>
                        }
                    </div>
                </div>
            }

            {useHide == true
                ?
                <>
                    <div className={collapsed ? 'container hidden' : 'container'}>
                        {children}
                    </div>
                </>
                : 
                <>
                    {!collapsed &&
                        <div className='container'>
                            {children}
                        </div>
                    }
                </>
            }


        </div>
    );
});

