import React, { useEffect, useState, useContext, useRef } from 'react';
import { SecureApiDataAccess } from "../../infrastructure/SecureApiDataAccess";
import { CapabilitiesContext } from "../../infrastructure/Contexts";
import { CapabilityName } from "../../infrastructure/Constants";
import { Language } from "../../infrastructure/Languages";
import { Popup } from '../../core/Popup';
import { useMsal, useAccount, AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import { loginRequest, protectedResources } from "../../authConfig";
import { useNavigate } from "react-router-dom";
import { MyAccountPageHeaderOptions } from '../../core/MyAccountPageHeaderOptions'
import { Login } from "./Login";

import './MyAddresses.css';


export const MyAddresses = () => {

    const capabilitiesContext = useContext(CapabilitiesContext);
    const loader = capabilitiesContext.getCapability(CapabilityName.Loader);
    const language = useContext(Language);
    const userDataAccess = new SecureApiDataAccess("/api/user");
    const [addresses, setAddresses] = useState(null);
    const [salesforceAccountId, setSalesforceAccountId] = useState(null);
    const { instance, accounts } = useMsal();
    const account = useAccount(accounts[0] || {});
    const navigate = useNavigate();
    const popup = useRef(null);
    var contactToDelete = "";
    var locationToDelete = "";

    useEffect(() => {
        const initUser = async () => {
            loader.show();
            await getUser();
            loader.hide();
        }
        initUser();
    }, [account]);


    useEffect(() => {
        const load = async () => {
            loader.show();
            if (salesforceAccountId) {
                await loadAccountAddresses(salesforceAccountId);
            }
            loader.hide();
        }
        load();
    }, [salesforceAccountId]);


    const loadAccountAddresses = async (salesforceAccountId) => {
        if (salesforceAccountId != null) {
            var addresses = await userDataAccess.get(
                `/account/${encodeURIComponent(salesforceAccountId)}/addresses`
            );
            setAddresses(addresses.data);
            console.log(addresses.data)
        }
    }

    const getUser = async () => {
        const response = await instance.acquireTokenSilent({
            scopes: [...protectedResources.shopApp.scopes],
            ...loginRequest,
            account: account,
        });

        if (!response.accessToken) {
            throw new Error("Could not retrieve access token.");
        }
        var user = await userDataAccess.get(
            `/search?emailAddress=${encodeURIComponent(
                account.username
            )}&identityProviderAccountId=${encodeURIComponent(account.idTokenClaims.oid)}`,
            response.accessToken,
            {
                throwOnNonSuccess: false
            }
        );

        if (user.hasSuccess) {
            console.log("A user was found", user);
            setSalesforceAccountId(user.data.salesforceAccountId);
            return user;

        } else {

            console.log("Didn't find a user.", user.statusCode);
            return null;
        }
    }

    const showDeleteConfirmationPopup = async (contactId, locationId) => {
        if (contactId != null) {
            contactToDelete = contactId;
        }
        if (locationId != null) {
            locationToDelete = locationId;
        }
        popup.current.show();
    }

    const onDeleteShippingAddress = async (contactId, locationId) => {
        try {
            loader.show();
            var request = {
                contactId: contactId,
                locationId: locationId
            };
            await userDataAccess.deleteAsync(`/address`, request);
            await loadAccountAddresses(salesforceAccountId);
        }
        catch (ex) {
            console.log(ex.message);
        }
        finally {
            popup.current.close();
            loader.hide();
        }
    }

    return (
        <div>
            <AuthenticatedTemplate>
                <MyAccountPageHeaderOptions salesforceAccountId={salesforceAccountId} selectedHeaderNumber={2} />
                {addresses &&
                    <div data-testid="my-addresses" className="my-addresses">
                        <div className="address-title">
                            <h3>
                                {language.translateLabel('0dce70b6-384c-4494-8bef-74deb920ac9f', 'My Shipping Addresses', 'Label')}
                                <a className="my-addresses-add-link" onClick={() => navigate(`add-shipping-address`, {
                                    state: {
                                        salesforceAccountId: salesforceAccountId,
                                        forceDefault: !addresses.shippingLocations?.find(x => x.isDefault == true) // if default does not exist
                                    }
                                })}>
                                    Add New
                                </a>
                            </h3>
                        </div>
                        <div className="shipping-address-container">
                            {addresses.defaultShippingAddress &&
                                <div>

                                    {
                                        addresses.shippingLocations.length > 0 ?
                                            addresses.shippingLocations.map((address, idx) => {
                                                return (
                                                    <div className="my-address" key={idx}>

                                                        {address.isDefault === true ?
                                                            <div className="default-label">{language.translateLabel('e70fc2a3-8b0d-4bac-9b66-5e668e159607', 'Default', 'Label')}<hr></hr></div>

                                                            : ''
                                                        }
                                                        <div className="name">
                                                            {address.name}
                                                        </div>
                                                        <div className="addressLineFormat">
                                                            {address.streetAddress1} {address.streetAddress2}
                                                        </div>
                                                        <div className="addressLineFormat">
                                                            {address.city},  {address.state},  {address.postalCode}
                                                        </div>
                                                        <div>
                                                            {address.country}
                                                        </div>

                                                        <div>
                                                            {address.phone}
                                                        </div>

                                                        <div>

                                                            <div>
                                                                <a className="my-addresses-link" onClick={() => navigate(`edit-shipping-address`, {
                                                                    state: {
                                                                        address: address,
                                                                        salesforceAccountId: salesforceAccountId,
                                                                        salesforceLocationContactId: address.salesforceLocationContactId

                                                                    }
                                                                })}>
                                                                    Change
                                                                </a>
                                                                {address.isDefault === true ?
                                                                    '' :
                                                                    <a className="my-addresses-link" onClick={() => showDeleteConfirmationPopup(address.salesforceLocationContactId, address.salesforceLocationId)}>
                                                                        Delete
                                                                    </a>
                                                                }
                                                            </div>
                                                        </div>


                                                    </div>
                                                )
                                            }) :
                                            <div>
                                                No Data
                                            </div>
                                    }
                                </div>
                            }
                        </div>
                        <div className="address-title">
                            <h3> {language.translateLabel('e79903e2-6336-46bd-aeac-ff24dd5c5efd', 'My Billing Address', 'Label')} </h3>
                        </div>
                        <div className="my-address">
                            {
                                addresses.billingAddress ?
                                    <div>
                                        <div className="default-label">{language.translateLabel('e70fc2a3-8b0d-4bac-9b66-5e668e159607', 'Default', 'Label')}<hr></hr></div>
                                        <div>
                                            {addresses.billingAddress.street}
                                        </div>
                                        <div>
                                            {addresses.billingAddress?.city}, {addresses.billingAddress?.state}, {addresses.billingAddress?.postalCode}
                                        </div>
                                        <div>
                                            {addresses.billingAddress?.country}
                                        </div>
                                    </div>
                                    :
                                    "No Data"

                            }


                            <div>
                                <a className="my-addresses-link" onClick={() => navigate(`/myaddresses/billing/edit`, {
                                    state: {
                                        address: addresses.billingAddress,
                                        accountId: salesforceAccountId
                                    }
                                })}>
                                    Change
                                </a>
                            </div>
                        </div>
                        <Popup closeOnClickAway={true} ref={popup}>
                            <div className='contentSection'>
                                <span>{language.translateLabel('16a10bae-8f37-44dd-a3d0-4148f3d2aa8d', 'Do you want to delete this shipping address?', 'Label')}</span>
                            </div>
                            <div className='footerSection'>
                                <button className='btn btn-secondary btn-modal' onClick={() => { onDeleteShippingAddress(contactToDelete, locationToDelete); }}>{language.translateLabel('dbaf68ea-89df-4a1e-8b55-8599f212a319', 'Yes', 'Button')}</button>
                                <button className='btn btn-primary btn-modal pull-right' onClick={() => { popup.current.close() }}>{language.translateLabel('ffa0b5ab-5199-4771-8950-69bb9f078aef', 'No', 'Button')}</button>
                            </div>
                        </Popup>
                    </div >
                }
            </AuthenticatedTemplate>
            <UnauthenticatedTemplate>
                <Login />
            </UnauthenticatedTemplate>
        </div >
    )
}

