import React, { useContext } from 'react';
import { Language } from '../../infrastructure/Languages';


export const PendingRegistration = () => {

    const language = useContext(Language);

    return (
        <>
            <h1>{language.translateLabel('ac86f727-079e-4c57-85b0-cf2328afcaad', 'Approval Pending', 'Label')}</h1>
            <p>{language.translateLabel('b83d36e7-54f4-4e3b-9307-87a915f3f886', 'Your account is pending review to be added to the account to shop. Once the adminstrator of the account approves the request you will receive an email to proceed in the checkout process.', 'Label')}</p>
        </>
        )
    
}