import React from "react";
import { ContextHelp } from "./ContextHelp";
import "./SectionTitle.css";

export const SectionTitle = (props) => {
    return (
        <div className="sectionTitle">
            {props.description || props.cms ? (
                <h3>
                    {props.children}
                    {props.required ? <div className="required">*</div> : ''}
                    <ContextHelp context={props.cms} gaContext={props.gaContext} />
                </h3>
            ) : (
                <h3>
                    {props.children}
                    {props.required ? <div className="required">*</div> : ''}
                </h3>
            )}
        </div>
    );
};
