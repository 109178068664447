import React from "react";
import { useController } from "react-hook-form";

export const HookFormNumberInput = (props) => {
    const { field, fieldState } = useController(props);

    return (
        <div className={props.inputstyle ? props.inputstyle : "text-action-container-textbox"}>
            <input
                data-testid={"form-input-" + props.name}
                type="number"
                {...field}
                className={`control textbox ${fieldState.error && fieldState.error.message ? "error" : ""}`}
                autoComplete="none"
                placeholder={props.placeholder}
                max={props.max}
                min={props.min}
                onChange={(e) => {
                    field.onChange(e.target.value);
                    if (props.onChange) {
                        props.onChange(e.target.value);
                    }
                }}
                onBlur={(e) => {
                    field.onBlur(e.target.value);
                    if (props.onBlur){
                        props.onBlur(e.target.value)
                    }
                }}
            />
        </div>
    );
};
