import React, { useState, useContext } from "react";
import { UploadLink } from "./UploadLink";
import { SectionTitle } from "../../core/SectionTitle";
import { Translation } from "../../core/Translation";

export const PurchaseOrderUpload = ({
    globalOfferId,
    onFileReceived,
    register,
    getErrorMessage,
    errors,
    existingFileName
}) => {

    let url = `api/order/${globalOfferId}/attachment/upload`;

    const onClickUpload = () => {
        let element = document.getElementById("upload_po");
        element.click();
    }

    const onUploadComplete = async (e, file, xhrResponse) => {
        if (file) {
            if (onFileReceived) {
                try {
                    var parsed = JSON.parse(xhrResponse);

                    if (parsed && parsed.attachmentId) {
                        onFileReceived(file, parsed.attachmentId);
                    }
                }
                catch (err) {
                    console.log("purchase order upload failed");
                }
            }
        }
    }

    return (
        <div>
            <SectionTitle><Translation id='12035920-3f8e-4820-b706-978c086dd2f6' defaultMessage='Enter PO#' category='Label' /></SectionTitle>
            <input
                className="control textbox"
                {...register("poNumber", {
                    maxLength: {
                        value: 50,
                        message: "Purchase order cannot be more than 50 characters"
                    }
                })} />
            {getErrorMessage(errors, 'poNumber') && <span className="error-message">{getErrorMessage(errors, 'poNumber')?.message}</span>}


            <SectionTitle><Translation id='2afa8026-d1c1-49af-b235-a435845577fd' defaultMessage='Attach PO' category='Label' /></SectionTitle>
            <div className='iconLink'>
                <UploadLink
                    onUploadComplete={onUploadComplete}
                    defaultLabel={<Translation id='d9fb0130-cd7a-4ce1-8bbb-904eec8187b7' defaultMessage='Upload' category='Label' />}
                    id={"upload_po"}
                    existingFileName={existingFileName}
                    url={url} />
            </div>
        </div>
    );
};
