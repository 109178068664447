import React, { useState, useImperativeHandle } from "react";
import { forwardRef } from "react";
import { BackSplash } from "./BackSplash";

export const Confirmation = forwardRef((props, ref) => {
    const displayName = Confirmation.name;

    const [enabled, setEnabled] = useState(false);

    useImperativeHandle(ref, () => ({
        enableSaveButton: (value) => setEnabled(value),
    }));

    const onCancelClicked = () => {
        if (props.onCancelClick) {
            props.onCancelClick(this);
        }
    };

    const onSaveClicked = () => {
        if (enabled && props.onSaveClick) {
            props.onSaveClick(this);
        }
    };

    return (
        <div>
            <BackSplash onClick={onCancelClicked} />
            <div className="confirmation">
                <div className="modalContent">{props.children}</div>
                <div className="button left" onClick={onCancelClicked}>
                    Cancel
                </div>
                <div className={enabled ? "button right" : "button right disabled"} onClick={onSaveClicked}>
                    Save
                </div>
            </div>
        </div>
    );
});
