import React, { useImperativeHandle, forwardRef, useRef } from "react";
import { Row } from "../../core/Row";
import { Column } from "../../core/Column";
import { SectionTitle } from "../../core/SectionTitle";
import { Translation } from "../../core/Translation";

export const DisplayAddress = (props) => {
    const address = props.address;

    return (
        <div>
            <Row>
                <Column>
                    <div className="input-validation">
                        <SectionTitle>
                            <Translation
                                id="0fffb175-6ffe-4d9e-967b-62278ac007ae"
                                defaultMessage="Country"
                                category="Label"
                            />
                        </SectionTitle>
                        <input
                            className="control textbox"
                            readOnly={true}
                            name="x.country"
                            value={address.country ? address.country : ""}
                        />
                    </div>
                </Column>
                <Column>
                    <div className="input-validation">
                        <SectionTitle>
                            <Translation
                                id="452cf8ff-b390-4b5f-98fa-23a3286f9c60"
                                defaultMessage="Name"
                                category="Label"
                            />
                        </SectionTitle>
                        <input
                            className="control textbox"
                            readOnly={true}
                            name="x.name"
                            value={address.name ? address.name : ""}
                        />
                    </div>
                </Column>
            </Row>
            <Row>
                <Column>
                    <div className="input-validation">
                        <SectionTitle>
                            <Translation
                                id="2406a7fe-ceac-4bb7-b364-bc4c0eabac80"
                                defaultMessage="Address Line 1"
                                category="Label"
                            />
                        </SectionTitle>
                        <input
                            className="control textbox"
                            readOnly={true}
                            name="addressLine1"
                            value={address.addressLine1 ? address.addressLine1 : ""}
                        />
                    </div>
                </Column>
                <Column>
                    <div className="input-validation">
                        <SectionTitle>
                            <Translation
                                id="64ed757b-82ce-4228-85aa-cbdc0e107446"
                                defaultMessage="Address Line 2"
                                category="Label"
                            />
                        </SectionTitle>
                        <input
                            className="control textbox"
                            readOnly={true}
                            name="addressLine2"
                            value={address.addressLine2 ? address.addressLine2 : ""}
                        />
                    </div>
                </Column>
            </Row>
            <Row>
                <Column>
                    <div className="input-validation">
                        <SectionTitle>
                            <Translation
                                id="16afea26-72ac-4087-b7fa-f7b696a9b9ff"
                                defaultMessage="City"
                                category="Label"
                            />
                        </SectionTitle>
                        <input
                            className="control textbox"
                            readOnly={true}
                            name="city"
                            value={address.city ? address.city : ""}
                        />
                    </div>
                </Column>
                <Column>
                    <div className="input-validation">
                        <SectionTitle>
                            <Translation
                                id="6c335b2a-8d6f-4377-ac9a-b67ea581a289"
                                defaultMessage="State"
                                category="Label"
                            />
                        </SectionTitle>
                        <input
                            className="control textbox"
                            readOnly={true}
                            name="state"
                            value={address.state ? address.state : ""}
                        />
                    </div>
                </Column>
            </Row>
            <Row>
                <Column>
                    <div className="input-validation">
                        <SectionTitle>
                            <Translation
                                id="7bf5c884-f430-4793-8f7a-fe26833aeffa"
                                defaultMessage="Postal Code"
                                category="Label"
                            />
                        </SectionTitle>
                        <input
                            className="control textbox"
                            readOnly={true}
                            name="postalCode"
                            value={address.postalCode ? address.postalCode : ""}
                        />
                    </div>
                </Column>
                <Column></Column>
            </Row>
        </div>
    );
};
