
export class CultureInfo {

    constructor(cultureString, name) {
        this.cultureString = CultureString.Parse(cultureString);
        this.name = name;
        this.country = this.cultureString && this.cultureString.split('-').length == 2 ? this.cultureString.split('-')[1].toLowerCase() : '';

    }

    getCultureString() {
        return this.cultureString;
    }

    getName() {
        return this.name;
    }

    getCountryFromString() {
        return this.country;
    }
}

export class CultureString {

    constructor(str) {
        this.name = CultureString.Parse(str);
    }

    static Parse(str) {
        var frags = str ? str.split('-') : [];
        if (frags.length == 2) {
            return `${frags[0].toLowerCase()}-${frags[1].toUpperCase()}`;
        }
        else {
            throw new Error(`${str} could not be parsed to a culture`)
        }
    }

    static IsValid(str) {
        try {
            CultureString.Parse(str);
            return true;
        }
        catch {
            return false;
        }
    }

    getName() {
        return this.name;
    }
}



export function useCulture() {
    
    const getCultureFromParam = () => {             
        try {
                       
            var params = window.location.pathname.split('/')
            if (params && params.length >= 1) {                
                return CultureString.Parse(params[1]);
            }
            else {
                return CultureString.Parse('en-US');
            }            
        }
        catch {            
            return CultureString.Parse('en-US');
        }        
    }
    
    return {
        getCultureFromParam
    }
}