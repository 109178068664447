import React from 'react';
import { Capability } from '../infrastructure/Capabilities';
import { LoadingSpinner } from '../core/LoadingSpinner';

export class LoaderComponent extends Capability {

    constructor(props) {
        super(props);

        this.state = {
            visible: false
        }
    }

    show() {
        this.setState({
            visible: true
        })
    }

    hide() {
        this.setState({
            visible: false
        })
    }

    render() {
        return (
            <div>
                {this.state.visible &&
                    <LoadingSpinner />
                }
            </div>
        )
    }
}