import React, { useState, useContext, } from "react";
import { Language } from '../../infrastructure/Languages';
import { ComponentType } from "../../infrastructure/Constants";
import { GoogleAnalyticsContext } from "../../infrastructure/GoogleAnalyticsContext";
import { FeaturesDisplay } from "../../components/offer/FeaturesDisplay";
import { SpecificationsDisplay } from "../offer/SpecificationsDisplay";
import { ArtworkSelectionDisplay } from "./ArtworkSelectionDisplay";

import "./ReorderSkuSelectionComponent.css";

export const ReorderSkuSelectionComponent = ({ orderDetails, onSubmit, onCancel }) => {

    const language = useContext(Language);
    const [selectedSkus, setSelectedSkus] = useState([]);
    const [valid, setValid] = useState(false);
    const googleAnalyticsContext = new GoogleAnalyticsContext('reorder_1', "unknown", "", "");
    const isRollStock = orderDetails.componentTypeId === ComponentType.Rollstock;

    const onSkuSelectionChanged = (sku, selected) => {
        var items = selectedSkus;
        var idx = items.findIndex(e => e == sku.approvedSkuId);
        if (selected) {
            if (idx == -1)
                items.push(sku.approvedSkuId);
        }
        else {
            if (idx > -1)
                items.splice(idx, 1);
        }

        setSelectedSkus(items);
        setValid(items.length > 0);
    }

    const onSubmitClick = async () => {
        onSubmit(selectedSkus);
    }

    const getLocationObject = (offset) => {
        if (offset && offset > 0)
            return {
                uomId: orderDetails.uomId,
                value: offset
            }
    }

    return (
        <div className="sku-selection-component">
            {
                orderDetails &&
                <div>
                    <div>
                        <div className="converting-method">
                            <h3>
                                {orderDetails.convertingMethod}
                            </h3>
                        </div>

                        <div>
                            Your order this product last on {new Date(orderDetails.latest).toLocaleDateString('en-us', { weekday: "long", year: "numeric", month: "short", day: "numeric" })}
                        </div>
                        <div>
                            You have {orderDetails.skus.length} different artwork available to reorder sharing these product details
                        </div>

                        {
                            !isRollStock &&
                            <FeaturesDisplay
                                zipper={orderDetails.zipper}
                                zipperLocation={getLocationObject(orderDetails.zipperLocationValue)}
                                tearNotch={orderDetails.tearNotch}
                                tearNotchLocation={getLocationObject(orderDetails.tearNotchLocationValue)}
                                hangHole={orderDetails.hangHole}
                                hangHoleLocation={getLocationObject(orderDetails.hangHoleLocationValue)}
                                valve={orderDetails.valve}
                                valveLocation={getLocationObject(orderDetails.valveLocationValue)}
                                offsetReadonly={true}
                                featureDependencies={[]}
                                applyFeatureLocation={() => { }}
                                dielineErrors={[]}
                                googleAnalyticsContext={googleAnalyticsContext}
                            />
                        }

                        <SpecificationsDisplay
                            dimensions={orderDetails.dimensions}
                            eyemark={orderDetails.eyemark}
                            roundedCorners={orderDetails.roundedCorners}
                            sealWidth={orderDetails.sealWidth}
                            qrCodeType={orderDetails.epacConnect}
                            doubleCut={orderDetails.doubleCut}
                            gussetType={orderDetails.gussetType}
                            bottomFill={orderDetails.bottomFill}
                            core={orderDetails.core}
                            sealWrapType={orderDetails.sealWrapType}
                            colorSpec={orderDetails.colorSpec}
                            structureSpecId={orderDetails.specId}
                            isRollStock={orderDetails.componentTypeId === ComponentType.Rollstock}
                            cultureString={orderDetails.cultureString}
                            googleAnalyticsContext={googleAnalyticsContext}
                        />

                        <ArtworkSelectionDisplay
                            globalOrderId={orderDetails.globalOrderId}
                            skus={orderDetails.skus}
                            additionalSkus={orderDetails.additionalSkus}
                            onSkuSelectionChanged={onSkuSelectionChanged}
                        />
                    </div>


                    <button data-testid="sku-selection-button-next" id='btnNext' className='btn btn-secondary pull-right next' disabled={!valid} onClick={() => { onSubmitClick() }}><span className='btn-text'>{language.translateLabel('20fdf7cc-ff08-4444-96e3-8b79cff46628', 'Continue', 'Button')}</span></button>
                    {onCancel &&
                        <button className='btn btn-tertiary-outline back' onClick={() => { onCancel(); }}><span className='btn-text'>{language.translateLabel('48cda936-8f88-4703-a02c-69648eb3fa2a', 'Back', 'Button')}</span></button>
                    }
                </div>
            }
        </div >
    )
}