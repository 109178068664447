import { useContext } from "react";
import { React } from "react";
import { NotificationContext } from "../capabilities/NotificationContext";

export const NotificationCenter = () => {

    const { message, setMessage } = useContext(NotificationContext);

    return (
        message && <div>
            <p onClick={e => setMessage("")}>{message}</p>
        </div>
    )
}