import { useController } from "react-hook-form";

export const RequiredRegistrationTextbox = (props) => {
    
    const { field, fieldState } = useController(props);

    return (
        <div>
            <span>{props.label} <span className="registration-validation-error" >*</span></span>
            <input
                type="text"
                {...field}
                autoComplete="none"
                className={`control textbox ${fieldState.error && fieldState.error.message ? "error" : ""}`}
                placeholder={props.placeHolder}
                required={props.required}
            />
            {fieldState.error && <span className="registration-validation-error">{fieldState.error.message}</span>}
        </div>
    );
};
