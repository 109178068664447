import React from "react";
import { CheckCircle } from 'react-feather';

export const Card = ({ subtitle, title, transparent, children, className }) => {

    return (
        <div className={transparent === true ? `card transparent ${className ? className : ''}` : `card ${className ? className : ''}`} >
            {title && (
                <div className="title">
                    <div className="imageContainer">
                        <CheckCircle className="icon" />
                    </div>

                    <div className="textContainer">
                        <div className="titleText">{title}</div>
                        {subtitle && (
                            <div className="subText">{subtitle}</div>
                        )}
                    </div>
                </div>
            )}

            <div className="container">{children}</div>
        </div>
    );
};