import React from "react";

import "./QuoteExpiredLayout.css";

export const QuoteExpiredLayout = (props) => {

    return (
        <div className="layout">
            <div className="header-section">
            </div>
            <div className="body-section">
                <h3>Our prices update every 30 days.</h3>
                <h3>Your quote is older than 30 days and <b>has expired</b>.</h3>
                <h3>Please contact your sales representative to receive an updated quote!</h3>
            </div>
        </div >)

}
