import React from 'react';
import { ContextHelp } from './ContextHelp';

export const TileContainer = ({ children }) => {

    return (
        <div data-testid="tile-container" className='tileContainer'>
            {children}
        </div>
    )
}

export const Tile = ({ id, children, className, onClick }) => {

    const handleClick = () => {
        if (onClick) {
            onClick();
        }
    }

    return (
        <div id={id} className={`tile ${className ? className : ''}`} onClick={handleClick}>
            {children}
        </div>
    )
}


export const ImageTile = ({ id, src, value, className, width, height, onClick, index }) => {

    const handleClick = () => {
        if (onClick) {
            onClick();
        }
    }
    return (
        <div data-testid={index >= 0 ? `tile-${index}` : ''}  id={id} className={`imageTileCircle ${className ? className : ''}`} style={{ width: width ? width : 135, height: height ? height : 150 }} onClick={handleClick}>
            <div className='image' style={{ backgroundImage: `url(${src})` }} />
            <div className='caption'><span>{value}</span></div>
        </div>
    )
}

export const ButtonTile = ({ id, className, width, height, children, onClick, text, helpContext, gaContext }) => {

    return (
        <div id={id} className={className ? `buttonTile ${className}` : 'buttonTile'} style={{ width: width ? width : 280, height: height ? height : 400 }}>
            {helpContext &&
                <div className='header'>
                    <ContextHelp context={helpContext} gaContext={gaContext} />
                </div>
            }

            <div className={onClick && !text ? 'content clickable' : 'content'} onClick={onClick && !text ? onClick : null}>
                {children}
            </div>

            {text && onClick &&
                <div data-testid="component-button-tile-footer" className='footer'>
                    <button data-testid="component-button-tile" className='btn btn-secondary' onClick={onClick}>{text}</button>
                </div>
            }
        </div>
    )
}