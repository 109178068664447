import React from "react";

import "./QuoteExpiredLayout.css";

export const OrderBeingProcessedNotification = (props) => {

    return (
        <div className="layout">
            <div className="header-section">
            </div>
            <div className="body-section">
                <h3>We are processing your order. If you have any questions, please contact your Sales Representative or Sales Support Specialist.</h3>
            </div>
        </div >)

}
