import React, { useEffect, useContext, useState } from "react";
import { Page } from "../../core/Page";
import { SkuQuantitySelection } from "../SkuQuantitySelection";
import { Language } from '../../infrastructure/Languages';
import { ReadonlyTextbox } from "../../core/ReadonlyTextbox";
import { NumberInput } from "../../core/NumberInput";

export const QuantitySelectionComponent = ({ getEstimation, createOffer, skuCount, preselectedIndex, onCancel }) => {

    const language = useContext(Language);

    const [quantityBreaks, setQuantityBreaks] = useState([]);
    const [selectedQuantityBreak, setSelectedQuantityBreak] = useState({});
    const [estimatedQuantity, setEstimatedQuantity] = useState();
    const [minSellablePrice, setMinSellablePrice] = useState();

    useEffect(() => {
        estimate();
    }, []);

    const estimate = async (quantity) => {
        var estimation = await getEstimation(quantity);
        if (!estimation)
            throw new Error("Estimation wrong!");

        var newQuantityBreaks = formatQuantities(estimation.estimates.estimate, estimation.minSellablePrice);
        var selectedQuantityBreak = quantity ? newQuantityBreaks.find(x => x.orderedQty == quantity) : newQuantityBreaks[preselectedIndex];

        setQuantityBreaks(newQuantityBreaks);
        setMinSellablePrice(estimation.minSellablePrice ? parseFloat(estimation.minSellablePrice) : 0);
        setSelectedQuantityBreak(selectedQuantityBreak);
        setEstimatedQuantity(selectedQuantityBreak.orderedQty);
    }

    const formatQuantities = (quantityBreaks, minSellablePrice) => {
        var original = quantityBreaks[0].price.unitPrice.amount;

        var formattedQuantities = [];
        minSellablePrice = minSellablePrice ? parseFloat(minSellablePrice) : 0;
        quantityBreaks.forEach((quantity) => {
            if (minSellablePrice >= 0) {
                var canSell = parseFloat(quantity.price.unitPrice.amount * quantity.orderedQty) >= minSellablePrice;
                var savingsPercent = calculateSavings(original, quantity.price.unitPrice.amount);
                quantity.canSell = canSell;
                quantity.savingsPercentage = savingsPercent;
            }
            formattedQuantities.push(quantity);
        })

        formattedQuantities.sort((a, b) => (a.orderedQty > b.orderedQty) ? 1 : -1);
        return formattedQuantities;
    }

    const calculateSavings = (originalPrice, price2) => {
        var percentSavings = parseFloat(((originalPrice - price2) / originalPrice) * 100).toFixed(0);
        return Math.abs(percentSavings);
    }

    const onQuantitySelected = (quantity) => {
        var breakdownSelected = quantityBreaks.find(x => x.orderedQty === parseInt(quantity));
        setSelectedQuantityBreak(breakdownSelected);
    }

    const onSubmitClick = async () => {
        if (createOffer)
            createOffer(selectedQuantityBreak.orderedQty);
    }

    const onCancelClick = () => {
        if (onCancel)
            onCancel();
    }

    const isCalculatePriceDisabled = () => {
        if (!estimatedQuantity || estimatedQuantity < 1000)
            return true;
        if (estimatedQuantity == selectedQuantityBreak.orderedQty)
            return true;
        return false;
    }

    return (
        <div className='skuPage'>
            <Page className="skusPage">
                <div className='numberEntry'>
                    <div className='section'>
                        <div className='inline'>
                            <h3>{language.translateLabel('895bb603-71f7-4f81-b4e5-5fa7800d1175', 'How many different SKUs will be using this package?')}</h3>
                            <ReadonlyTextbox
                                name="skuCount"
                                value={skuCount}
                                inputstyle="text-action-container-textbox-sm"
                            />

                        </div>
                    </div>

                    <div className='section'>
                        <div className='inline'>
                            <h3>{language.translateLabel('a231544b-e8f3-483d-a1fb-3448c95690e5', 'How many pouches would you like?')}</h3>
                            <div className="text-action-container">
                                <NumberInput
                                    name="quantity"
                                    min={1000}
                                    value={estimatedQuantity}
                                    onChange={(quantity) => setEstimatedQuantity(parseInt(quantity))}
                                />
                            </div>
                            <button
                                className={`btn btn-secondary text-action-container-button add`}
                                onClick={(e) => {
                                    e.preventDefault();
                                    estimate(estimatedQuantity);
                                }}
                                disabled={isCalculatePriceDisabled()}>
                                <span className='btn-text'>{language.translateLabel('cc6d9072-4c57-48d2-9cb3-867be029f308', 'Calculate Price', 'Button')}</span>
                            </button>
                        </div>
                    </div>

                </div>

                <div className='section'>

                    {quantityBreaks.length > 0 &&
                        <div className="estimateList">
                            <h3>{language.translateLabel('293de6ab-f6d4-42a6-8389-2cc2a9657857', 'Choose Your Quantity')}</h3>
                            <SkuQuantitySelection
                                userSelectedQuantity={selectedQuantityBreak ? selectedQuantityBreak.orderedQty : 0}
                                skuQuantityBreakdown={quantityBreaks}
                                quantitySelectionChanged={onQuantitySelected}
                                minSellablePrice={minSellablePrice}
                            />
                        </div>
                    }
                </div>
                <button
                    type="button"
                    data-testid="button-go-back"
                    className='btn btn-tertiary-outline back'
                    onClick={() => { onCancelClick(); }}>
                    <span className='btn-text'>
                        {language.translateLabel('48cda936-8f88-4703-a02c-69648eb3fa2a', 'Back', 'Button')}
                    </span>
                </button>
                <button
                    type="button"
                    data-testid="button-proceed-to-checkout"
                    className="btn btn-secondary pull-right next"
                    onClick={() => { onSubmitClick() }}
                    disabled={selectedQuantityBreak ? !selectedQuantityBreak.canSell : true}>
                    <span className='btn-text'>
                        {language.translateLabel('67faab93-8201-40e5-9261-1db130febea3', 'Proceed to Checkout', 'Button')}
                    </span>
                </button>
            </Page>
        </div>
    );
};
