import React, { useState, useContext, useEffect, useRef } from "react";
import Carousel from "nuka-carousel"
import { useNavigate } from "react-router-dom";
import { useTracking } from "react-tracking";
import { useCookies } from 'react-cookie';
import { useForm } from "react-hook-form";
import { UnauthenticatedTemplate, useMsal, useAccount } from "@azure/msal-react";

import { CapabilityName, ComponentType, MeasureUnit } from "../../infrastructure/Constants";
import { ShoppingBreadcrumbs, OrderReference } from '../../core/Breadcrumbs';
import { Card } from "../../core/Card";
import { SectionTitle } from "../../core/SectionTitle";
import { RollRestrictionEditor } from "../../components/offer/RollRestrictionEditor";
import { UnwindDirectionDropdown } from "../../components/offer/UnwindDirectionDropdown";
import { DielineImage, ErrorCodes } from "../../components/offer/DielineImage";
import { ExistingOfferThreeDModel } from "../../components/ExistingOfferThreeDModel";
import { FeatureLocationType } from "../../core/offer/Constants";
import { SalesChannel } from "../../infrastructure/Constants";
import { Language } from "../../infrastructure/Languages";
import OrderPlacementApi from "../../infrastructure/OrderPlacementApi";
import { useGlobalOfferContext } from "../../infrastructure/GlobalOfferId";
import { ExpandableCard } from "../../components/offer/ExpandableCard";
import { CapabilitiesContext } from "../../infrastructure/Contexts";
import { Translation } from "../../core/Translation";
import { RegisterSignInPopup } from "../../components/offer/RegisterSignInPopup";
import { GoogleAnalyticsContext } from "../../infrastructure/GoogleAnalyticsContext";
import { FeaturesDisplay } from "../../components/offer/FeaturesDisplay";
import { SpecificationsDisplay } from "../../components/offer/SpecificationsDisplay";

import "./ProductSpecs.css";


export const ProductSpecs = ({
    offer,
    salesforceAccount,
    orderReference,
    formattedSizeString,
    status,
    analyticsMetadata,
    globalOffer,
    isOrderPlacementEnabled }) => {

    const { trackEvent } = useTracking({ page: "ProductPage" });

    const { saveContext, offerContext, loadOfferContextStatus } = useGlobalOfferContext(globalOffer.globalOfferId);

    const [loaded, setLoaded] = useState(false);

    const language = useContext(Language);
    const [threeDError, setThreeDError] = useState(false);
    const [features, setFeaturesValue] = useState("");
    const [feature, setFeatureValue] = useState("");
    const [changeOffsetDisabled, setChangeOffsetDisabled] = useState(false);
    const [key, setKey] = useState("");
    const [offset, setOffset] = useState(null);
    const api = new OrderPlacementApi("/");
    const navigate = useNavigate();
    const capabilitiesContext = useContext(CapabilitiesContext);
    const loader = capabilitiesContext.getCapability(CapabilityName.Loader);
    const isReorder = !!offer.reorderDetails;
    const isRollStock = offer.convertingMethod.componentType.id === ComponentType.Rollstock;

    const [cookies, setCookie] = useCookies(['visitorId']);
    const [visitorId, setVisitorId] = useState(null);
    const [salesForceAccountInUserTable, setSalesforceAccountInUserTable] = useState(false);
    const dielineCard = useRef(null);
    const popup = useRef(null);
    const googleAnalyticsContext = new GoogleAnalyticsContext('placement_1', cookies.visitorId, analyticsMetadata.industryName, analyticsMetadata.industryCategoryName);

    const {
        register,
        formState: { errors, isSubmitting },
        handleSubmit,
        setValue,
        getValues,
        reset,
        control,
    } = useForm({
        // mode: "onBlur",
        shouldFocusError: true,
        defaultValues: {
            colorTargetInstructions: "",
            maxRollstockDiameter: "",
            maxRollstockImpressions: "",
            rollstockUnwindInstruction: "",
            rollstockTypeValue: "",
            rollstockTypeUomId: MeasureUnit.LengthInMillimeters,
        },
    });
    const { instance, accounts } = useMsal();
    const account = useAccount(accounts[0] || {});


    useEffect(() => {
        isSubmitting ? loader.show() : loader.hide();
    }, [isSubmitting]);

    useEffect(() => {
        if (status !== "success" || loadOfferContextStatus !== "success") {
            console.log("useEffect ProductSpecs -- no offer", status, offer);
            // console.log("%c useEffect redirect value is", "color: #ff0000", redirect);
            return;
        }


        var id = cookies.visitorId;
        if (id) {
            setVisitorId(id);
        }

        //gaContext.sendOfferLoad(offer);
        const init = async () => {

            var offset = {};

            if (offerContext) {
                offset = {
                    zipper:
                        offerContext.zipperLocation && offerContext.zipperLocation.location
                            ? offerContext.zipperLocation.location.value
                            : null,
                    hangHole:
                        offerContext.hangHoleLocation && offerContext.hangHoleLocation.location
                            ? offerContext.hangHoleLocation.location.value
                            : null,
                    tearNotch:
                        offerContext.tearNotchLocation && offerContext.tearNotchLocation.location
                            ? offerContext.tearNotchLocation.location.value
                            : null,
                    valve:
                        offerContext.ventLocation && offerContext.ventLocation.location
                            ? offer.ventLocation.value
                            : null,
                };
                setFormData((prev) => ({
                    ...prev,
                    ...{
                        colorTargetInstructions: offerContext.colorTargetInstructions,
                        rollstockTypeSelection: offerContext.rollstockTypeSelection,
                        rollstockTypeValue: offerContext.rollstockTypeValue,
                        rollstockTypeUomId: offerContext.rollstockTypeUomId,
                        unwind: offerContext.unwind,
                    },
                }));

                console.log("init offset from offerContext", offerContext);

                let resetContext = {
                    colorTargetInstructions: offerContext.colorTargetInstructions,
                };

                if (offerContext.maxRollstockDiameter) {
                    resetContext.rollstockTypeSelection = "1";
                    resetContext.rollstockTypeValue = offerContext.maxRollstockDiameter.value;
                } else if (offerContext.maxRollstockImpressions) {
                    resetContext.rollstockTypeSelection = "2";
                    resetContext.rollstockTypeValue = offerContext.maxRollstockImpressions;
                }

                if (offerContext.rollstockUnwindInstruction) {
                    resetContext.rollstockUnwindInstruction = {
                        value: offerContext.rollstockUnwindInstruction.id,
                        label: offerContext.rollstockUnwindInstruction.value,
                    };
                }

                console.log("reset context, offer", resetContext, offer);

                reset(resetContext);

            } else {
                offset = {
                    zipper:
                        offer.packagingFeature.zipper && offer.packagingFeature.zipper.location
                            ? offer.packagingFeature.zipper.location.value
                            : null,
                    hangHole:
                        offer.packagingFeature.hangHole && offer.packagingFeature.hangHole.location
                            ? offer.packagingFeature.hangHole.location.value
                            : null,
                    tearNotch:
                        offer.packagingFeature.tearNotch && offer.packagingFeature.tearNotch.location
                            ? offer.packagingFeature.tearNotch.location.value
                            : null,
                    valve:
                        offer.packagingFeature.vent && offer.packagingFeature.vent.location
                            ? offer.packagingFeature.vent.location.value
                            : null,
                };
            }

            setOffset(offset);

            var features = {
                zipperId: offer.packagingFeature.zipper ? offer.packagingFeature.zipper.id : null,
                tearNotchId: offer.packagingFeature.tearNotch ? offer.packagingFeature.tearNotch.id : null,
                hangHoleId: offer.packagingFeature.hangHole ? offer.packagingFeature.hangHole.id : null,
                valveId: offer.packagingFeature.vent ? offer.packagingFeature.vent.id : null,
            };

            setFeaturesValue(features);

            setFeatureValue(offer.packagingFeature);
            setChangeOffsetDisabled(offer.salesChannel === SalesChannel.WebSite || !isOrderPlacementEnabled || isReorder);
            setKey(globalOffer.globalOfferId);
            setFormData({
                zipper: offset.zipper
                    ? {
                        value: offset.zipper ? offset.zipper : null,
                        uomId: offset.zipper ? offer.dimensions.measureUnitId : null,
                    }
                    : null,
                hangHole: offset.hangHole
                    ? {
                        value: offset.hangHole ? offset.hangHole : null,
                        uomId: offset.hangHole ? offer.dimensions.measureUnitId : null,
                    }
                    : null,
                tearNotch: offset.tearNotch
                    ? {
                        value: offset.tearNotch ? offset.tearNotch : null,
                        uomId: offset.tearNotch ? offer.dimensions.measureUnitId : null,
                    }
                    : null,
                valve: offset.valve
                    ? {
                        value: offset.valve ? offset.valve : null,
                        uomId: offset.valve ? offer.dimensions.measureUnitId : null,
                    }
                    : null,
            });

            if (process.env.REACT_APP_SHOW_REGISTRATION_POPUP) {
                var account = await api.getAsJson(`api/user/search/account?sfid=${salesforceAccount.id}`);
                console.log("account for salesforce", account);
                if (account.hasSuccess) {
                    setSalesforceAccountInUserTable(true);
                }
            }

            setLoaded(true);
        };
        if (status !== "success" || !offer) {
            // console.log("useEffect offer not initialized yet");
            return;
        }

        if (!loaded) {
            //console.log("not loaded, initializing");
            init();
        }
    }, [status, loadOfferContextStatus]);

    useEffect(() => {
        if (loaded && popup.current != null) {
            popup.current.show();
        }
    }, [loaded])

    const [dielineControl, setDielineControl] = useState({});
    const [dielineHasErrors, setDielineHasErrors] = useState(false);
    const [dielineErrors, setDielineErrors] = useState([]);

    const [formData, setFormData] = useState(null);

    const setFormValues = (key, value) => {
        setFormData((prev) => ({
            ...prev,
            ...{
                [key]: value,
            },
        }));

    };

    const updateDielineErrors = (hasErrors, errors) => {
        if (!isReorder) // in case of reorder we ignore error codes from dieline validation temporarely
        {
            setDielineHasErrors(hasErrors);
            let formattedErrors = errors ? ErrorCodes.FromResponse(errors) : [];
            setDielineErrors(formattedErrors);
        }
    };

    const applyFeatureLocation = (featureLocationType, value) => {

        dielineCard.current.expand();

        setFeature(featureLocationType, value);

        if (
            offer.packagingFeature.dielineInstructions &&
            offer.packagingFeature.dielineInstructions.featureDependencies
        ) {
            offer.packagingFeature.dielineInstructions.featureDependencies.forEach((dependency) => {
                var parent = getFeatureLocationType(dependency.parent);
                if (featureLocationType == parent) {
                    var child = getFeatureLocationType(dependency.child);
                    var val = dependency.offset ? parseFloat(value) - parseFloat(dependency.offset) : parseFloat(value);
                    setFeature(child, val);
                }
            });
        }

        var request = {
            ignoreErrorCodes: offer.packagingFeature.dielineInstructions
                ? offer.packagingFeature.dielineInstructions.ignoreErrorCodes
                : null,
            zipperId: features.zipperId,
            zipperOffset: offset.zipper,
            tearNotchId: features.tearNotchId,
            tearNotchOffset: offset.tearNotch,
            hangHoleId: features.hangHoleId,
            hangHoleOffset: offset.hangHole,
            valveId: features.valveId,
            valveOffset: offset.valve,
        };

        dielineControl.setLocationFeatures(request);
    };

    const setFeature = (featureLocationType, value) => {
        var val = parseFloat(value);

        switch (featureLocationType) {
            case FeatureLocationType.Zipper:
                offset.zipper = val;
                setFormValues("zipper", { value: val, uomId: offer.dimensions.measureUnitId });
                // gaContext.sendFeatureLocationChange(FeatureLocationType.Zipper, val, offer.dimensions.measureUnitId);
                break;
            case FeatureLocationType.TearNotch:
                offset.tearNotch = val;
                setFormValues("tearNotch", { value: val, uomId: offer.dimensions.measureUnitId });
                // gaContext.sendFeatureLocationChange(FeatureLocationType.TearNotch, val, offer.dimensions.measureUnitId);
                break;
            case FeatureLocationType.HangHole:
                offset.hangHole = val;
                setFormValues("hangHole", { value: val, uomId: offer.dimensions.measureUnitId });
                // gaContext.sendFeatureLocationChange(FeatureLocationType.HangHole, val, offer.dimensions.measureUnitId);
                break;
            case FeatureLocationType.Valve:
                offset.valve = val;
                setFormValues("valve", { value: val, uomId: offer.dimensions.measureUnitId });
                // gaContext.sendFeatureLocationChange(FeatureLocationType.Valve, val, offer.dimensions.measureUnitId);
                break;
            default:
                throw new Error(`${featureLocationType} is not supported`);
        }
    };

    const getFeatureLocationType = (code) => {
        switch (code) {
            case "ZP":
                return FeatureLocationType.Zipper;
            case "TN":
                return FeatureLocationType.TearNotch;
            case "HH":
                return FeatureLocationType.HangHole;
            case "VL":
                return FeatureLocationType.Valve;
            default:
                throw new Error(`${code} is not supported`);
        }
    };

    const getFeatureValueFromFormData = (key) => {
        if (formData && formData[key]) {
            return {
                value: formData[key].value,
                uomId: formData[key].uomId,
            };
        }
        return null;
    };

    const onSubmit = async (data, errors) => {

        var update = {
            zipperLocation: getFeatureValueFromFormData("zipper"),
            hangHoleLocation: getFeatureValueFromFormData("hangHole"),
            tearNotchLocation: getFeatureValueFromFormData("tearNotch"),
            ventLocation: getFeatureValueFromFormData("valve"),
            colorTargetInstructions: data.colorTargetInstructions
        };

        if (data.rollstockTypeSelection === "1") {
            update.maxRollstockDiameter = {
                value: data.rollstockTypeValue,
                uomId: offer.dimensions.measureUnitId
            };
            update.maxRollstockImpressions = null;
        } else if (data.rollstockTypeSelection === "2") {
            update.maxRollstockImpressions = data.rollstockTypeValue;
            update.maxRollstockDiameter = null;
        }

        if (data.rollstockUnwindInstruction) {
            update.rollstockUnwindInstruction = {
                id: data.rollstockUnwindInstruction.value,
                value: data.rollstockUnwindInstruction.label,
            };
        }

        //Important -- don't remove this
        update.salesForceAccountId = offer.salesForceAccountId;
        update.opportunityReferenceNumber = offer.salesForceOpportunity.referenceNumber;

        console.log("form data", data);
        console.log("update data", update);
        // console.log("Submitting", data, formData, update);

        await saveContext(update);

        navigate(`/offer/${globalOffer.globalOfferId}/skus`);
    };

    const trackHelp = (element) => {

        if (offer && offer.analyticsMetadata) {
            trackEvent({
                event: 'ordering_tool_interactions',
                eventProps: {
                    'step': 'ordering_tool_interactions',
                    'product_category': offer.analyticsMetadata.industryName,
                    'product_sub_category': offer.analyticsMetadata.industryCategoryName,
                    'selection': "information",
                    'element': element
                },
                userId: cookies.visitorId
            });
        }
    }

    const onDielineInit = ctrl => {
        setDielineControl(ctrl);

        if (!isOrderPlacementEnabled) {
            dielineCard.current.expand();
            fitDieline(true);
        }

    }

    const fitDieline = (expanded) => {
        if (expanded) {
            setTimeout(() => {
                if (dielineControl) {
                    dielineControl.fit();
                }
            }, 250);
        }
    }

    return (
        <div>
            {loaded && offer &&
                <div>
                    <ShoppingBreadcrumbs active={5} ignore={offer.salesChannel == 'Estimator' ? 4 : 0} />

                    <OrderReference orderReference={orderReference} />
                    {process.env.REACT_APP_SHOW_REGISTRATION_POPUP === "true" && (salesForceAccountInUserTable === false || (salesForceAccountInUserTable === true && !account)) && (
                        <UnauthenticatedTemplate>
                            <RegisterSignInPopup
                                ref={popup}
                                onCloseClick={() => popup.current.close()}
                                salesforceAccountId={salesforceAccount.id}
                                redirectTo={window.location.href}
                            />
                        </UnauthenticatedTemplate>)}

                    <div className="productSpecs">
                        <form onSubmit={handleSubmit(onSubmit)}>
                            {threeDError
                                ?
                                <ExpandableCard ref={dielineCard} useHide={true} expanded={false} title={language.translateLabel("d2264bda-e338-4632-88f7-773dd59804d7", "Dieline")} onChange={(value) => { fitDieline(value); }}>
                                    <DielineImage
                                        offerKey={key}
                                        dielineContainsErrors={(hasErrors, err) => {
                                            updateDielineErrors(hasErrors, err);
                                        }}
                                        convertingMethodId={offer.convertingMethod.id}
                                        length={offer.dimensions.length}
                                        width={offer.dimensions.width}
                                        gusset={offer.dimensions.gusset}
                                        lengthUomId={offer.dimensions.measureUnitId}
                                        tearNotchId={offer.packagingFeature.tearNotch ? offer.packagingFeature.tearNotch.id : null}
                                        tearLineOffset={
                                            offer.packagingFeature.tearNotch && offer.packagingFeature.tearNotch.location
                                                ? offer.packagingFeature.tearNotch.location.value
                                                : null
                                        }
                                        hangholeId={offer.packagingFeature.hangHole ? offer.packagingFeature.hangHole.id : null}
                                        hangholeOffset={
                                            offer.packagingFeature.hangHole && offer.packagingFeature.hangHole.location
                                                ? offer.packagingFeature.hangHole.location.value
                                                : null
                                        }
                                        zipperId={offer.packagingFeature.zipper ? offer.packagingFeature.zipper.id : null}
                                        zipperOffset={
                                            offer.packagingFeature.zipper && offer.packagingFeature.zipper.location
                                                ? offer.packagingFeature.zipper.location.value
                                                : null
                                        }
                                        valveId={offer.packagingFeature.vent ? offer.packagingFeature.vent.id : null}
                                        valveOffset={
                                            offer.packagingFeature.vent && offer.packagingFeature.vent.location
                                                ? offer.packagingFeature.vent.location.value
                                                : null
                                        }
                                        sideSealWidthId={
                                            offer.packagingFeature.sealWidth ? offer.packagingFeature.sealWidth.id : null
                                        }
                                        roundedCornerId={
                                            offer.packagingFeature.roundedCorner ? offer.packagingFeature.roundedCorner.id : null
                                        }
                                        doubleCutId={offer.packagingFeature.doubleCut ? offer.packagingFeature.doubleCut.id : null}
                                        qrCodeTypeId={
                                            offer.packagingFeature.qrCodeType ? offer.packagingFeature.qrCodeType.id : null
                                        }
                                        gussetTypeId={
                                            offer.packagingFeature.gussetType ? offer.packagingFeature.gussetType.id : null
                                        }
                                        eyemarkId={offer.eyeMark ? offer.eyeMark.id : null}
                                        bottomFillId={offer.bottomFill ? offer.bottomFill.id : null}
                                        ignoreErrorCodes={
                                            offer.packagingFeature.dielineInstructions
                                                ? offer.packagingFeature.dielineInstructions.ignoreErrorCodes
                                                : null
                                        }
                                        onInit={(ctrl) => {
                                            onDielineInit(ctrl);
                                        }}
                                    />
                                </ExpandableCard>
                                :
                                <ExpandableCard ref={dielineCard} useHide={true} expanded={false} title={language.translateLabel("d2264bda-e338-4632-88f7-773dd59804d7", "Dieline")} onChange={(value) => { fitDieline(value); }}>
                                    <Carousel dragging={false} renderCenterLeftControls={({ previousSlide }) => (<div className='carousel-button left' onClick={previousSlide}>3D Model</div>)} renderCenterRightControls={({ nextSlide }) => (<div className='carousel-button right' onClick={() => { nextSlide(); fitDieline(true); }}>Dieline</div>)}>
                                        <ExistingOfferThreeDModel offerKey={key} width={window.innerWidth - (window.innerWidth * 0.2)} height={window.innerWidth - (window.innerWidth * 0.2)} onError={() => { setThreeDError(true) }} />
                                        <DielineImage
                                            offerKey={key}
                                            dielineContainsErrors={(hasErrors, err) => {
                                                updateDielineErrors(hasErrors, err);
                                            }}
                                            convertingMethodId={offer.convertingMethod.id}
                                            length={offer.dimensions.length}
                                            width={offer.dimensions.width}
                                            gusset={offer.dimensions.gusset}
                                            lengthUomId={offer.dimensions.measureUnitId}
                                            tearNotchId={offer.packagingFeature.tearNotch ? offer.packagingFeature.tearNotch.id : null}
                                            tearLineOffset={
                                                offer.packagingFeature.tearNotch && offer.packagingFeature.tearNotch.location
                                                    ? offer.packagingFeature.tearNotch.location.value
                                                    : null
                                            }
                                            hangholeId={offer.packagingFeature.hangHole ? offer.packagingFeature.hangHole.id : null}
                                            hangholeOffset={
                                                offer.packagingFeature.hangHole && offer.packagingFeature.hangHole.location
                                                    ? offer.packagingFeature.hangHole.location.value
                                                    : null
                                            }
                                            zipperId={offer.packagingFeature.zipper ? offer.packagingFeature.zipper.id : null}
                                            zipperOffset={
                                                offer.packagingFeature.zipper && offer.packagingFeature.zipper.location
                                                    ? offer.packagingFeature.zipper.location.value
                                                    : null
                                            }
                                            valveId={offer.packagingFeature.vent ? offer.packagingFeature.vent.id : null}
                                            valveOffset={
                                                offer.packagingFeature.vent && offer.packagingFeature.vent.location
                                                    ? offer.packagingFeature.vent.location.value
                                                    : null
                                            }
                                            sideSealWidthId={
                                                offer.packagingFeature.sealWidth ? offer.packagingFeature.sealWidth.id : null
                                            }
                                            roundedCornerId={
                                                offer.packagingFeature.roundedCorner ? offer.packagingFeature.roundedCorner.id : null
                                            }
                                            doubleCutId={offer.packagingFeature.doubleCut ? offer.packagingFeature.doubleCut.id : null}
                                            qrCodeTypeId={
                                                offer.packagingFeature.qrCodeType ? offer.packagingFeature.qrCodeType.id : null
                                            }
                                            gussetTypeId={
                                                offer.packagingFeature.gussetType ? offer.packagingFeature.gussetType.id : null
                                            }
                                            eyemarkId={offer.eyeMark ? offer.eyeMark.id : null}
                                            bottomFillId={offer.bottomFill ? offer.bottomFill.id : null}
                                            ignoreErrorCodes={
                                                offer.packagingFeature.dielineInstructions
                                                    ? offer.packagingFeature.dielineInstructions.ignoreErrorCodes
                                                    : null
                                            }
                                            onInit={(ctrl) => {
                                                onDielineInit(ctrl);
                                            }}
                                        />
                                    </Carousel>
                                </ExpandableCard>
                            }

                            {
                                !isRollStock &&
                                <FeaturesDisplay
                                    zipper={feature.zipper ? feature.zipper.value : null}
                                    zipperLocation={feature.zipper ? feature.zipper.location : null}
                                    tearNotch={feature.tearNotch ? feature.tearNotch.value : null}
                                    tearNotchLocation={feature.tearNotch ? feature.tearNotch.location : null}
                                    hangHole={feature.hangHole ? feature.hangHole.value : null}
                                    hangHoleLocation={feature.hangHole ? feature.hangHole.location : null}
                                    valve={feature.vent ? feature.vent.value : null}
                                    valveLocation={feature.vent ? feature.vent.location : null}
                                    offsetReadonly={changeOffsetDisabled}
                                    featureDependencies={offer?.packagingFeature?.dielineInstructions?.featureDependencies}
                                    applyFeatureLocation={applyFeatureLocation}
                                    dielineErrors={dielineErrors}
                                    googleAnalyticsContext={googleAnalyticsContext}
                                />
                            }


                            <SpecificationsDisplay
                                dimensions={formattedSizeString}
                                eyemark={offer.eyeMark ? offer.eyeMark.value : "--"}
                                roundedCorners={feature.roundedCorner ? feature.roundedCorner.value : "--"}
                                sealWidth={feature.sealWidth ? feature.sealWidth.value : "--"}
                                qrCodeType={feature.qrCodeType ? feature.qrCodeType.value : "--"}
                                doubleCut={feature.doubleCut ? feature.doubleCut.value : "--"}
                                gussetType={feature.gussetType ? feature.gussetType.value : "--"}
                                bottomFill={offer.bottomFill ? offer.bottomFill.value : "--"}
                                core={offer.core ? offer.core.value : "None"}
                                sealWrapType={feature.sealWrapType ? feature.sealWrapType.value : "--"}
                                colorSpec={offer.colorSpec.value}
                                outsourcedFeatures={offer.packagingFeature.outsourcedFeatures}
                                skuCount={offer.skuCount}
                                structureSpecId={offer.packagingMaterial.structureSpecId}
                                structureSpecUrl={offer.packagingMaterial.pdfUrl}
                                isRollStock={isRollStock}
                                cultureString={offer.supportedCountry.cultureString}
                                googleAnalyticsContext={googleAnalyticsContext}
                                trackHelp={trackHelp}
                            />

                            {isOrderPlacementEnabled &&
                                <Card
                                    title={language.translateLabel(
                                        "07f29958-5d6f-41e9-9f9f-b7ef4bc8417d",
                                        "Additional Manufacturing Instructions"
                                    )}
                                >
                                    <SectionTitle
                                        offerKey={key}
                                        cms="color-target-instructions"
                                        orderPlacementApi={api}
                                        gaContext={googleAnalyticsContext.CreateContext("Color Instructions")}
                                        onShowHelp={() => {
                                            //gaContext.sendHelpClick("Color Instructions");
                                        }}
                                    >
                                        {language.translateLabel("6449df23-7d25-4fd8-952f-94a74963ee75", "Color Instructions")}
                                    </SectionTitle>
                                    <div>
                                        <input
                                            type="text"
                                            maxLength={50}
                                            className="control textbox"
                                            {...register("colorTargetInstructions", {
                                                maxLength: {
                                                    value: 50,
                                                    message: "Color target instructions cannot exceed 50 characters."
                                                }
                                            })}
                                        />
                                    </div>

                                    {isRollStock && (
                                        <div>
                                            <SectionTitle offerKey={key} cms="roll-restrictions" orderPlacementApi={api}
                                                required
                                                gaContext={googleAnalyticsContext.CreateContext("Roll Restrictions")}>
                                                {language.translateLabel(
                                                    "9bc052df-99c1-462f-add7-e581d17bcfe6",
                                                    "Roll Restrictions"
                                                )}
                                            </SectionTitle>
                                            <RollRestrictionEditor
                                                offerKey={key}
                                                offer={offer}
                                                orderPlacementApi={api}
                                                register={register}
                                                errors={errors}
                                                getValues={getValues}
                                                onChange={(type, value) => {
                                                    setFormValues("rollstockTypeSelection", type);
                                                    setFormValues("rollstockTypeValue", value);
                                                    setFormValues("rollstockTypeUomId", MeasureUnit.LengthInMillimeters);
                                                }}
                                            />

                                            <SectionTitle offerKey={key} cms="unwind-direction" orderPlacementApi={api}
                                                required
                                                gaContext={googleAnalyticsContext.CreateContext("Unwind Direction")}>
                                                {language.translateLabel(
                                                    "5a0db080-4516-4225-b33a-add7fcd3bb4c",
                                                    "Unwind Direction"
                                                )}
                                            </SectionTitle>
                                            <UnwindDirectionDropdown
                                                required
                                                offerKey={key}
                                                orderPlacementApi={api}
                                                errors={errors}
                                                control={control}
                                            />
                                        </div>
                                    )}
                                </Card>
                            }

                            {offer.salesChannel !== 'Estimator' &&
                                <button
                                    className="btn btn-tertiary-outline back"
                                    onClick={() => {
                                        trackEvent({
                                            event: 'ordering_tool_interactions',
                                            eventProps: {
                                                'step': 'placement_1',        // order_shopping_5
                                                'product_category': analyticsMetadata.industryName,        // e.g. Food & Beverage
                                                'product_sub_category': analyticsMetadata.industryCategoryName, // E.g. Dry Mix
                                                'element': 'back'
                                            },
                                            userId: cookies.visitorId
                                        });
                                        window.location = !isReorder ? `/?reviveCart=true` : `myreorders`;
                                    }}
                                ><span className='btn-text'><Translation id='03473ff0-be47-4bd3-9ed8-190fedf344c5' defaultMessage='Back' category='Button' /></span>
                                </button>
                            }

                            {isOrderPlacementEnabled &&
                                <div className="pull-right">
                                    <button
                                        data-testid="order-placement-product-next-button"
                                        className="btn btn-primary"
                                        disabled={isSubmitting || dielineHasErrors}
                                        onClick={() => {
                                            trackEvent({
                                                event: 'ordering_tool_interactions',
                                                eventProps: {
                                                    'step': 'placement_1',        // order_shopping_5
                                                    'product_category': analyticsMetadata.industryName,        // e.g. Food & Beverage
                                                    'product_sub_category': analyticsMetadata.industryCategoryName, // E.g. Dry Mix
                                                    'element': 'next'
                                                },
                                                userId: cookies.visitorId
                                            });

                                            trackEvent({ ecommerce: null });  // Clear the previous ecommerce object.
                                            trackEvent({
                                                event: 'begin_checkout',
                                                ecommerce: {
                                                    items: [
                                                        {
                                                            item_id: offer.product.id,
                                                            item_name: offer.product.value,
                                                            currency: offer.plant.currency,
                                                            //  index: quoteCount,            //the nth quote added, starting at 1
                                                            unit_price: offer.calculationBreakdown[0].price.unitPrice.amount,
                                                            item_category: offer.productGroup.value,  //what packaging category
                                                            item_category2: analyticsMetadata.industryCategoryName,      //what packaging sub-category
                                                            item_category3: offer.convertingMethod.componentType.value,     //pouch type
                                                            item_category4: offer.dimensions.width + " x " + offer.dimensions.length,      // width x length
                                                            price: offer.calculationBreakdown[0].price.totalPrice.amount,
                                                            quantity: offer.calculationBreakdown[0].quantityBreak,
                                                            skuCount: offer.skuCount
                                                        }
                                                    ]
                                                },
                                                userId: cookies.visitorId
                                            });
                                            handleSubmit(async (data, errors) => onSubmit(data, errors));
                                        }}
                                    >
                                        Next
                                    </button>
                                </div>
                            }

                        </form>
                    </div>
                </div>
            }
        </div>
    );
};
