import { useContext, useEffect } from 'react';
import { useStateStorage } from './ServerStateStorage';
import { SettingsContext } from './Contexts';
import { Language } from './Languages';

export function useUserContext() {
    const settings = useContext(SettingsContext);
    const language = useContext(Language);

    useEffect(() => {
        var regions = settings.regions;
        var cultureStr = language ? language.getSourceCulture() : 'en-US';
        var region = regions.find(e => e.cultureString == cultureStr);
        var salesforceAccount = {
            email: region && region.defaultAddress ? region.defaultAddress.emailAddress : 'contact@epacflexibles.com',
            phone: region && region.defaultAddress ? region.defaultAddress.phoneNumber : '+1 (844) 623-8603'
        }
        updateSalesforceAccount(salesforceAccount);
    }, []);    

    const update = (globalState, payload) => {
        return {
            ...globalState,
            user: {
                ...globalState.user,
                ...payload,
            },
        };
    };

    const { state, actions } = useStateStorage({
        update
    });

    /*
    const { state, actions } = useStateMachine({
        update
    });
    */

    const clearSettings = () => {
        updateUserRegion('');
        updateUserLanguage('');
        updateUserPreferredRenderSize(2);
        updateDefaultPouchColor('C1C1C1');
        updatePreviousCart('');
    }

    const updateUserRegion = (value) => actions.update({ userRegion: value });

    const updateUserLanguage = (value) => actions.update({ userLanguage: value });

    const updateUserPreferredRenderSize = (value) => actions.update({ userPreferredRenderSize: value });

    const updateDefaultPouchColor = (value) => actions.update({ defaultPouchColor: value });

    const updatePreviousCart = (value) => actions.update({ previousCart: value });

    const updateSalesforceAccount = (value) => actions.update({ salesforceAccount: value });

    const createUrlFromCart = (region) => {
        if (!region) {
            return "/";
        }

        let url = "/" + region;
        var cart = state.user.previousCart;
        if (cart) {
            if (cart.selectedSkuCount) {
                return url + `/group/${cart.product.productGroupId}/convertingMethod/${cart.convertingMethod.convertingMethodId}/material/${cart.material.id}/skus`;
            }
            if (cart.material) {
                return url + `/group/${cart.product.productGroupId}/convertingMethod/${cart.convertingMethod.convertingMethodId}/material/${cart.material.id}/features`;
            }
            if (cart.convertingMethod) {
                return url + `/group/${cart.product.productGroupId}/convertingMethod/${cart.convertingMethod.convertingMethodId}/dimensions`;
            }
            if (cart.product) {
                return url + `/group/${cart.product.productGroupId}/convertingMethod`;
            }
        }

        return url + "/search";
    }


    return {
        createUrlFromCart,
        clearSettings,       
        updateUserRegion,        
        updateUserLanguage,
        updateUserPreferredRenderSize,
        updateDefaultPouchColor,
        updatePreviousCart,
        updateSalesforceAccount,
        userPreferredRenderSize: state.user.userPreferredRenderSize,
        userRegion: state.user.userRegion,
        userLanguage: state.user.userLanguage,
        defaultPouchColor: state.user.defaultPouchColor,
        previousCart: state.user.previousCart,  
        salesforceAccount: state.user.salesforceAccount
    }
}
