import React, { Component, useContext } from 'react';
import { Language } from '../infrastructure/Languages';
import { Translation } from "./Translation";
import { OrderType } from '../infrastructure/Constants';

export const OrderReference = ({ orderReference }) => {

    const getOrderTypeDisplay = () => {
        if (orderReference.orderType !== OrderType.Other)
            return <div>{orderReference.orderType} <Translation id='32590f3a-e55a-4523-9e0b-d69c570f4a85' defaultMessage='for' category='Label' /> {orderReference.convertingMethod} <Translation id='32590f3a-e55a-4523-9e0b-d69c570f4a85' defaultMessage='for' category='Label' /> {orderReference.product}</div>;

        return <div>{orderReference.convertingMethod} <Translation id='32590f3a-e55a-4523-9e0b-d69c570f4a85' defaultMessage='for' category='Label' /> {orderReference.product}</div>;
    }

    return (
        <div className='orderReference'>
            <h3>{orderReference.customerName}<br /> {getOrderTypeDisplay()}
            </h3>
            <p><Translation id='8481215e-adae-43f3-a7f2-fced2211fd67' defaultMessage='Order #' category='Label' />{orderReference.orderNumber}</p>
        </div>
    )
}

export const ShoppingBreadcrumbs = ({ active, ignore, paymentsDisabled }) => {

    const language = useContext(Language);

    return (
        <Breadcrumbs active={active} show={5} ignore={ignore ? ignore : 0}>
            <Breadcrumb title={language.translateDomain('207fdcc2-1c08-4d4e-a3f8-5356a865922c', 0, 'Pouch Type Selection', 'Breadcrumb')} />
            <Breadcrumb title={language.translateDomain('207fdcc2-1c08-4d4e-a3f8-5356a865922c', 1, 'Size Selection', 'Breadcrumb')} />
            <Breadcrumb title={language.translateDomain('207fdcc2-1c08-4d4e-a3f8-5356a865922c', 2, 'Material Selection', 'Breadcrumb')} />
            <Breadcrumb title={language.translateDomain('207fdcc2-1c08-4d4e-a3f8-5356a865922c', 3, 'Features Selection', 'Breadcrumb')} />
            <Breadcrumb title={language.translateDomain('207fdcc2-1c08-4d4e-a3f8-5356a865922c', 4, 'SKU Selection', 'Breadcrumb')} />
            <Breadcrumb title={language.translateDomain('207fdcc2-1c08-4d4e-a3f8-5356a865922c', 6, 'Product Specs')} />
            <Breadcrumb title={language.translateDomain('207fdcc2-1c08-4d4e-a3f8-5356a865922c', 7, 'Quantities & Shipping', 'Breadcrumb')} />
            <Breadcrumb title={language.translateDomain('207fdcc2-1c08-4d4e-a3f8-5356a865922c', 8, 'Order Review', 'Breadcrumb')} />
            {
                paymentsDisabled ?
                    <Breadcrumb title={language.translateDomain('207fdcc2-1c08-4d4e-a3f8-5356a865922c', 11, 'Artwork Upload', 'Breadcrumb')} />
                    :
                    <Breadcrumb title={language.translateDomain('207fdcc2-1c08-4d4e-a3f8-5356a865922c', 9, 'Payment & Checkout', 'Breadcrumb')} />
            }
            <Breadcrumb title={language.translateDomain('207fdcc2-1c08-4d4e-a3f8-5356a865922c', 10, 'Order Confirmation', 'Breadcrumb')} />
        </Breadcrumbs>
    )
}



export const Breadcrumbs = ({ children, active, ignore, show, offset }) => {

    const ofs = offset ? parseInt(offset) : 0;

    const childrenWithProps = React.Children.map(children, (child, idx) => {

        const shouldShow = (show, ignore, active, idx) => {
            if (!show) {
                return true;
            }

            var center = show / 2;

            if (!ignore && active == 0 && idx < show) {
                return true;
            }
            if (idx > ignore && active == 0 && idx <= show) {
                return true;
            }
            else if (idx > ignore && active < center && idx <= show) {
                return true;
            }
            else if (idx > ignore && idx > active - center && idx <= active + center) {
                return true;
            }

            return false;
        }

        if (React.isValidElement(child)) {
            var number = ignore > 0 ? idx + ofs - ignore : idx + ofs + 1;
            var total = children.length + ofs;
            var isActive = idx == active;
            var hasVisited = idx < active
            var sdsw = shouldShow(show, ignore, active, idx);

            if (sdsw) {
                return React.cloneElement(child, { number: number, total: total, active: isActive, visited: hasVisited });
            }
            else {
                return null;
            }
        }
        return child;
    });

    return (
        <div className='breadcrumbs'>
            <div className='crumbs'>
                {childrenWithProps}
            </div>
        </div>
    )
}


export const Breadcrumb = ({ active, visited, title, number, total }) => {
    return (
        <div className={active ? 'step active' : visited ? 'step visited' : 'step'}>
            <div className='connector left' />
            <div className='circle'>
                <span className='number'>{number}</span>
                <ProgressRing size={80} step={number} total={total} title={title} />
            </div>

            <div className='connector right' />
            <span className={visited ? 'title visited' : 'title'}>{title}</span>
        </div>
    )
}



export class ProgressRing extends Component {

    constructor(props) {
        super(props);

        this.size = this.props.size;
        this.progress = (100 / this.props.total) * this.props.step;
        this.strokeWidth = 8;

        this.center = this.size / 2;
        this.radius = this.size / 2 - this.strokeWidth / 2;
        this.circumference = 2 * Math.PI * this.radius;
        this.circleOneStroke = '#EEEEEE';
        this.circleTwoStroke = '#7CBD42';

        this.progressOffset = ((100 - this.progress) / 100) * this.circumference;

        this.style = {
            width: this.props.size,
            height: this.props.size,
            lineHeight: '80px'
        }

        this.containerStyle = {
            height: this.props.size,
            lineHeight: '80px'
        }
    }

    render() {
        return (
            <div className='progress' style={this.containerStyle}>
                <div className="progress-ring" style={this.style}>
                    <svg width={this.size} height={this.size}>
                        <circle
                            className="svg-circle-bg"
                            stroke={this.circleOneStroke}
                            cx={this.center}
                            cy={this.center}
                            r={this.radius}
                            strokeWidth={this.strokeWidth}
                        />
                        <circle
                            className="svg-circle"
                            stroke={this.circleTwoStroke}
                            cx={this.center}
                            cy={this.center}
                            r={this.radius}
                            strokeWidth={this.strokeWidth}
                            strokeDasharray={this.circumference}
                            strokeDashoffset={this.progressOffset}
                        />
                    </svg>
                    <div className='text' style={this.style}>{this.props.step} of {this.props.total}</div>
                </div>

                <h4 className='title' style={this.containerStyle}>{this.props.title}</h4>

            </div>
        )
    }
}