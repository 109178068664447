export class GoogleAnalyticsContext {

    constructor(step, visitorId, industryName, industryCategoryName) {
        this.step = step;
        this.industryName = industryName;
        this.industryCategoryName = industryCategoryName;
        this.visitorId = visitorId;
    }

    CreateContext(element) {
        if (!this || !this.analyticsMetadata) {
            return null;
        }

        var context = {
            step: this.step,
            industryName: this.industryName,
            industryCategoryName: this.industryCategoryName,
            userId: this.visitorId
        };

        if (element) {
            context.selection = element;
        }

        return context;
    }
}