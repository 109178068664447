import React, { useEffect, useState } from 'react';
import { ApiDataAccess } from './ApiDataAccess';

export const StaticData = React.createContext();

export const StaticDataSupport = ({ children }) => {

    const [ready, setReady] = useState(false);
    const [data, setData] = useState(null);

    useEffect(() => {
        init();
    }, []);

    const init = async () => {
        setReady(false);

        var data = await StaticDataProvider.GetProductDataAsync();

        setData(data);
        setReady(true);
    }


    return (
        <div>
            {ready &&
                <StaticData.Provider value={new StaticDataProvider(data)}>
                    {children}
                </StaticData.Provider>
            }
        </div>
        )

}

export class StaticDataProvider {

    constructor(data) {
        this.data = data;
    }

    static async GetProductDataAsync() {
        const dataAccess = new ApiDataAccess('/api/products');
        var categories = await dataAccess.get('/categories');

        var products = [];
        categories.forEach(category => {
            products = products.concat(category.products);
        });

        var data = {
            categories: categories,
            products: products
        };

        return data;
    }

    getCategories() {
        return this.data.categories;
    }

    getProducts() {
        return this.data.products;
    }

}