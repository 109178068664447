import React, { useContext, useState, useEffect } from "react";
import { useNavigate, useLocation } from 'react-router-dom';
import { useForm, get } from "react-hook-form";
import { Translation } from "../../core/Translation";
import { MyAccountPageHeaderOptions } from '../../core/MyAccountPageHeaderOptions'
import { Language } from "../../infrastructure/Languages";
import { SecureApiDataAccess } from '../../infrastructure/SecureApiDataAccess';
import OrderPlacementApi from '../../infrastructure/OrderPlacementApi';
import { CountriesWithSubSelection } from "../../infrastructure/Constants";
import { CapabilitiesContext } from "../../infrastructure/Contexts";
import { CapabilityName } from "../../infrastructure/Constants";
import { HookEditMyAccountAddress } from '../../pages/account/HookEditMyAccountAddress';


export const AddShippingAddress = () => {

    const {
        register,
        unregister,
        reset,
        setValue,
        getValues,
        formState: { errors, isValid },
    } = useForm({
        mode: "onBlur",
        shouldFocusError: true,
        defaultValues: {
        }
    });

    const navigate = useNavigate();
    const location = useLocation();
    const language = useContext(Language);
    const capabilitiesContext = useContext(CapabilitiesContext);
    const orderPlacementApi = new OrderPlacementApi("/");
    const dataAccess = new SecureApiDataAccess('/api/user');

    const salesforceAccountId = location.state.salesforceAccountId;
    const forceDefault = location.state.forceDefault;

    const loader = capabilitiesContext.getCapability(CapabilityName.Loader);
    const [countries, setCountries] = useState(null);
    const [isDefaultAddress, setIsDefaultAddress] = useState(forceDefault);
    const [loaded, setLoaded] = useState(false);

    //const [appointmentRequired, setAppointmentRequired] = useState(false);
    //const [liftgateNeeded, setLiftgateNeeded] = useState(false);
    //const [blindShipment, setBlindShipment] = useState(false);    

    const additionalFields = [
        {
            name: "name",
            type: "text",
            placeholder: "Delivery Contact Name",
            required: true,
            maxLength: 50,
            title: <Translation id="91ce24b8-736a-4bac-9b50-1cec9a24f3b9" defaultMessage="Delivery Contact Name" category='Label' />
        },
        {
            name: "phone",
            type: "text",
            placeholder: "Delivery Phone Number",
            required: true,
            maxLength: 20,
            title: <Translation id="91ce24b8-736a-4bac-9b50-1cec9a24f3b3" defaultMessage="Delivery Phone Number" category='Label' />
        },
        /*
        {
            name: "appointmentRequired",
            type: "checkbox",
            placeholder: "Appointment Required",
            required: false,
            onChange: (e) => setAppointmentRequired(e.target.checked),
            title: <Translation id='892c1fe3-2a2b-4c13-8444-c7e6c3d123e5' defaultMessage='Shipping Instructions' category='Label' />,
            description: <Translation id='91ce24b8-736a-4bac-9b50-1cec9a24f3b4' defaultMessage='Appointment Required' category='Label' />
        },
        {
            name: "liftgateNeeded",
            type: "checkbox",
            placeholder: "Liftgate Needed",
            required: false,
            onChange: (e) => setLiftgateNeeded(e.target.checked),
            description: <Translation id='91ce24b8-736a-4bac-9b50-1cec9a24f3b5' defaultMessage='Liftgate Needed' category='Label' />
        },
        {
            name: "blindShipment",
            type: "checkbox",
            placeholder: "Blind Shipment",
            required: false,
            onChange: (e) => setBlindShipment(e.target.checked),
            description: <Translation id='91ce24b8-736a-4bac-9b50-1cec9a24f3c2' defaultMessage='Blind Shipment' category='Label' />
        },
        */
        {
            name: "specialInstructions",
            type: "text",
            placeholder: "Additional Instructions",
            required: false,
            maxLength: 200,
            title: <Translation id="91ce24b8-736a-4bac-9b50-1cec9a24f3b7" defaultMessage="Additional Instructions" category='Label' />
        }
    ];

    useEffect(() => {
        if (loaded) {
            return;
        }
        const init = async () => {
            console.group("%c Hook Add Shipping Address init", "background: #333; color: #bada55");
            var response = await orderPlacementApi.getCountries();
            setCountries(response.response);
            setLoaded(true);     
        }
        init();
    }, []);

    const onSubmit = async () => {

        var request = {
            isDefaultAddress: isDefaultAddress,
            accountId: salesforceAccountId,
            name: getValues("shippingAddress.name"),
            phone: getValues("shippingAddress.phone"),
            streetAddress1: getValues("shippingAddress.streetAddress1"),
            streetAddress2: getValues("shippingAddress.streetAddress2"),
            city: getValues("shippingAddress.city"),
            postalCode: getValues("shippingAddress.postalCode"),
            state: getValues("shippingAddress.state"),
            country: getValues("shippingAddress.country"),
            specialInstructions: getValues("shippingAddress.specialInstructions"),
            salesforceLocationId: getValues("shippingAddress.salesforceLocationId"),
            email: getValues("shippingAddress.email")
        };

        try {
            loader.show();
            var response = await dataAccess.postAsJson('/contact/shipping', request);
            if (response.hasSuccess)
                navigate('/myaddresses');
        }
        catch (ex) {
            console.log(ex.message);
        }
        finally {
            loader.hide();
        }
    }

    return loaded && (
        <div>
            <MyAccountPageHeaderOptions selectedHeaderNumber={2} />
            <div className="edit-address-title">
                <h2>{language.translateLabel('bb98c752-fb56-4539-bda8-7b8a11d751ab', 'Add Shipping Address')}</h2>
            </div>
            <div className="address-form">
                <input
                    data-testid={"add-shipping-address-is-default-address"}
                    type="checkbox"
                    className="form-checkbox"
                    onChange={(e) => setIsDefaultAddress(e.target.checked)}
                    disabled={forceDefault}
                    defaultChecked={forceDefault}
                />
                <Translation id='1f88365b-9c07-4aad-92d4-4a5477fd5fca' defaultMessage='Make this my default address' category='Label' />
                <HookEditMyAccountAddress
                    unregister={unregister}
                    getErrorMessage={get}
                    setValue={setValue}
                    getValues={getValues}
                    register={register}
                    countriesForStateValidation={CountriesWithSubSelection}
                    errors={errors}
                    countries={countries}
                    id="shippingAddress"
                    addressType="shippingAddress"
                    orderPlacementApi={orderPlacementApi}
                    reset={reset}
                    requireEmail={false}
                    requireCompanyName={false}
                    additionalFields={additionalFields}
                />
            </div>
            <button className='btn btn-secondary pull-right' disabled={!isValid} onClick={() => { onSubmit() }}>{language.translateLabel('b5e8af97-25c9-413c-89a6-eb090846613b', 'Save', 'Button')}</button>
        </div>
    );
}