import React, { Component } from 'react';
import { ApiDataAccess } from "../../infrastructure/ApiDataAccess";
import { DelayedAction } from '../../infrastructure/DelayedAction';
import { Logger } from '../../infrastructure/Logger';
import { Plus, Minus, LifeBuoy } from 'react-feather';
import { Translation } from '../../core/Translation';
import './DielineImage.css';

export class DielineImage extends Component {

    constructor(props) {
        super(props);

        this.id = this.props.id ? `dieline${this.props.id}` : 'dieline';
        this.offerKey = this.props.offerKey;
        this.request = {
            convertingMethodId: this.props.convertingMethodId,
            length: this.props.length,
            width: this.props.width,
            gusset: this.props.gusset,
            lengthUomId: this.props.lengthUomId,
            tearNotchId: this.props.tearNotchId,
            tearLineOffset: this.props.tearLineOffset,
            hangholeId: this.props.hangholeId,
            hangholeOffset: this.props.hangholeOffset,
            zipperId: this.props.zipperId,
            zipperOffset: this.props.zipperOffset,
            eyemarkId: this.props.eyemarkId,
            bottomFillId: this.props.bottomFillId,
            valveId: this.props.valveId,
            valveOffset: this.props.valveOffset,
            sideSealWidthId: this.props.sideSealWidthId,
            roundedCornerId: this.props.roundedCornerId,
            doubleCutId: this.props.doubleCutId,
            qrTypeCodeId: this.props.qrCodeTypeId,
            gussetTypeId: this.props.gussetTypeId,
            ignoreErrorCodes: this.props.ignoreErrorCodes
        };

        this.zoom = this.props.zoom && this.props.zoom > 0 ? this.props.zoom : 1;

        this.state = {
            hasErrors: false,
            size: null,
            mouseMoving: false
        };

        this.layers = null;
        this.dragOrigin = null;
        this.origin = {
            x: 0,
            y: 0
        }

        this.onMouseWheel = this.onMouseWheel.bind(this);
        this.onMouseEvent = this.onMouseEvent.bind(this);        
        this.onZoomInClick = this.onZoomInClick.bind(this);
        this.onZoomOutClick = this.onZoomOutClick.bind(this);
        this.onFitClick = this.onFitClick.bind(this);
    }

    isConvertingMethodSupported() {
        return true;
    }

    componentWillUnmount() {
        var canvas = document.getElementById(this.id + 'canvas');
    }
    
    componentDidMount() {
        if (this.isConvertingMethodSupported()) {
            Logger.writeDebug("DielineImage: Converting Method is supported.");

            this.setState({
                hasErrors: false
            });

            this.refresh(this.zoom)
                .then(() => {

                    if (this.props.onInit) {
                        this.props.onInit(this);
                    }

                    this.fit();

                })
                .catch(() => {
                    if (this.props.onInit) {
                        this.props.onInit(this);
                    }

                    this.fit();
                });

            var canvas = document.getElementById(this.id + 'canvas');
            var ctx = canvas.getContext("2d");


            canvas.addEventListener('wheel', (evt) => {
                evt.preventDefault();
                this.onMouseWheel(evt, ctx, canvas);
            })

            canvas.onmousedown = (evt) => {
                evt.preventDefault();
                var mouse = {
                    mode: 'down',
                    x: evt.x,
                    y: evt.y
                };
                evt.preventDefault();
                this.onMouseEvent(mouse, ctx, canvas);
            };

            canvas.onmouseup = (evt) => {
                evt.preventDefault();
                var mouse = {
                    mode: 'up',
                    x: evt.x,
                    y: evt.y
                };
                this.onMouseEvent(mouse, ctx, canvas);
            };

            canvas.onmousemove = (evt) => {
                evt.preventDefault();
                var mouse = {
                    mode: 'move',
                    x: evt.x,
                    y: evt.y
                };
                this.onMouseEvent(mouse, ctx, canvas);
            };

            canvas.addEventListener("touchstart", (evt) => {
                evt.preventDefault();
                var touch = evt.touches[0];
                var mouseEvent = new MouseEvent("mousedown", {
                    clientX: touch.clientX,
                    clientY: touch.clientY
                });
                canvas.dispatchEvent(mouseEvent);
            }, false);

            canvas.addEventListener("touchend", (evt) => {
                evt.preventDefault();
                var mouseEvent = new MouseEvent("mouseup", {});
                canvas.dispatchEvent(mouseEvent);
            }, false);

            canvas.addEventListener("touchmove", (evt) => {
                evt.preventDefault();
                var touch = evt.touches[0];
                var mouseEvent = new MouseEvent("mousemove", {
                    clientX: touch.clientX,
                    clientY: touch.clientY
                });
                canvas.dispatchEvent(mouseEvent);
            }, false);

            canvas.addEventListener('gestureend', (evt) => {
                evt.preventDefault();
                if (evt.scale < 1.0) {
                    if (this.zoom > 0.75) {
                        this.zoom -= 0.1;
                        this.refresh(this.zoom);
                    }
                } else if (evt.scale > 1.0) {
                    if (this.zoom < 5) {
                        this.zoom += 0.1;
                        this.refresh(this.zoom);
                    }
                }
            }, false);
        }
        else {
            Logger.writeDebug("DielineImage: Converting Method is not supported.");

            this.setState({
                hasErrors: true
            });

            if (this.props.onInit) {
                this.props.onInit(this);
            }
        }


    }

    refresh(zoom) {
        return new Promise((resolve, reject) => {
            this.zoom = zoom && zoom > 0 ? zoom : 1;
            this.loadAsync(this.offerKey, this.request)
                .then(layers => {
                    this.layers = layers;
                    this.draw(this.layers, this.zoom);
                    resolve(layers);
                })
                .catch(ex => {
                    Logger.writeError("DielineImage.refresh", ex);
                    this.layers = null;
                    this.draw(this.layers, this.zoom);
                    reject(ex);
                });
        });
    }

    draw(layers, zoom) {
        Logger.writeDebug("DielineImage.draw");
        var ctxt = this.getGraphicsContext();
        if (ctxt) {
            ctxt.clearCanvas();
            if (layers && layers.length > 0) {
                layers.map((layer, idx) => {
                    if (layer.selected) {
                        layer.draw(ctxt, zoom);
                    }
                });

                this.setState({
                    hasErrors: false
                });

            }
            else {

                this.setState({
                    hasErrors: true
                });

            }
        }
    }

    getGraphicsContext() {
        var canvas = document.getElementById(this.id + 'canvas');
        if (canvas) {
            var ctx = canvas.getContext("2d");
            return new GraphicsContext(this.id, ctx, canvas);
        }
        return null;
    }

    onMouseEvent(mouse, context, canvas) {

        this.setState({
            mouseMoving: true
        });

        DelayedAction.RunAsync('onMouseEvent', 500)
            .then(() => {
                this.setState({
                    mouseMoving: false
                });
            });

        if (mouse.mode == 'down') {
            this.dragOrigin = {
                x: mouse.x,
                y: mouse.y
            };
        }

        if (mouse.mode == 'move' && this.dragOrigin) {

            var zoom = this.zoom > 1 ? this.zoom : 1;
            context.clearRect(-50, -50, canvas.width * zoom + 200, canvas.height * zoom + 200);

            var x = (mouse.x - this.dragOrigin.x) / (this.zoom * 10);
            var y = (mouse.y - this.dragOrigin.y) / (this.zoom * 10);

            this.origin.x = x;
            this.origin.y = y

            context.translate(
                this.origin.x,
                this.origin.y
            );

            this.draw(this.layers, this.zoom);
        }

        if (mouse.mode == 'up' && this.dragOrigin) {
            this.dragOrigin = null;
        }
    }

    onMouseWheel(event, context, canvas) {

        if (event.ctrlKey === false){
            event.preventDefault();

            var scrollTop = window.pageYOffset + event.deltaY || document.documentElement.scrollTop; 
            var scrollLeft = window.pageXOffset  || document.documentElement.scrollLeft;
            
                // if any scroll is attempted, set this to the previous value 
                
            window.scrollTo({
                top: scrollTop,
                left: scrollLeft,
                behavior: 'smooth'
            }); 
            
            return;
        }

        var zoom = this.zoom > 1 ? this.zoom : 1;

        this.resizeCanvas();        

        var mousex = event.clientX - canvas.offsetLeft;
        var mousey = event.clientY - canvas.offsetTop;
        var wheel = event.wheelDelta / 120;//n or -n
        var zoom = 1 + wheel / 20;

        var newZoom = this.zoom * zoom;
        if (newZoom > 0.75 && newZoom < 5) {

            context.scale(zoom, zoom);

            this.zoom = newZoom;

            context.translate(
                -(mousex / this.zoom + this.origin.x - mousex / (this.zoom * zoom)),
                -(mousey / this.zoom + this.origin.y - mousey / (this.zoom * zoom))
            );

            this.origin = {
                x: (mousex / this.zoom + this.origin.x - mousex / (this.zoom * zoom)),
                y: (mousey / this.zoom + this.origin.y - mousey / (this.zoom * zoom))
            }
        }
        this.draw(this.layers, this.zoom);
    }

    resizeCanvas() {        
        var container = document.getElementById(this.id + 'dielineImage');
        var canvas = document.getElementById(this.id + 'canvas');

        if (canvas && container) {
            canvas.width = container.clientWidth;
            canvas.height = container.clientHeight;

            var ctx = canvas.getContext("2d");
            ctx.scale(1, 1);
            ctx.clearRect(-50, -50, canvas.width + 100, canvas.height + 100);
        }        
    }

    loadAsync(offerKey, request) {
        return new Promise((resolve, reject) => {
            this.getMetaDataAsync(offerKey, request)
                .then(data => {
                    this.setState({
                        size: data.size,
                        errorCodes: data.errorCodes
                    });

                    
                    if (data.errorCodes?.length > 0) {
                        this.props.dielineContainsErrors(true, data.errorCodes);
                    }
                    else {
                        this.props.dielineContainsErrors(false);
                    }

                    if (this.props.onLoaded) {
                        this.props.onLoaded(data);
                    }

                    this.resizeCanvas();
                    resolve(data.layers);
                })
                .catch(ex => {

                    if (this.props.onLoaded) {
                        this.props.onLoaded();
                    }

                    reject(ex);
                });
        });

    }

    getMetaDataAsync(offerKey, request) {
        this.request = request;

        var settings = {
            loader: false,
            mode: "cors",                                               // no-cors, cors, *same-origin        
            cache: 'default',                                           // *default, no-cache, reload, force-cache, only-if-cached                
            credentials: "include",                                     // include, same-origin, *omit
            redirect: "follow",                                         // manual, *follow, error
            referrer: "no-referrer",                                    // no-referrer, *client  
            headers: {
                "Content-Type": "application/json; charset=utf-8",
                "Accept-Language": "*"
            }
        };

        this.setState({
            loading: true
        });

        return new Promise((resolve, reject) => {
            var dataAccess = new ApiDataAccess('api/order', settings);

            console.log('Dieline Meta Data', request);
            dataAccess.post(`/${offerKey}/dieline/meta`, request)
                .then(data => {

                    Logger.writeDebug("DielineMetaData:", data);

                    data.layers.sort((a, b) => (a.name > b.name) ? 1 : -1);
                    var layers = [];
                    var idx = 0;
                    data.layers.forEach(l => {
                        var layer = new Layer(idx, l);
                        layers.push(layer);
                        idx++;
                    });

                    var response = {
                        errorCodes: data.errorCodes,
                        layers: layers,
                        size: data.size
                    }
                    this.setState({
                        loading: false
                    });

                    if (request.ignoreErrorCodes && data.errorCodes) {
                        request.ignoreErrorCodes.forEach(item => {
                            var idx = data.errorCodes.findIndex(e => e == item);
                            if (idx > -1) {
                                data.errorCodes.splice(idx, 1);
                            }
                        });
                    }

                    resolve(response);
                })
                .catch(ex => {
                    Logger.writeError("DielineImage.getMetaDataAsync", ex);
                    this.setState({
                        loading: false
                    });
                    reject(ex);
                });
        });
    }

    setLocationFeatures(request) {

        if (request.zipperId) {
            this.request.zipperId = request.zipperId;
            if (request.zipperOffset) {
                this.request.zipperOffset = parseFloat(request.zipperOffset);
            }
        }

        if (request.hangHoleId) {
            this.request.hangHoleId = request.hangHoleId;
            if (request.hangHoleOffset) {
                this.request.hangHoleOffset = parseFloat(request.hangHoleOffset);
            }
        }

        if (request.tearNotchId) {
            this.request.tearNotchId = request.tearNotchId;
            if (request.tearNotchOffset) {
                this.request.tearLineOffset = parseFloat(request.tearNotchOffset);
            }
        }

        if (request.valveId) {
            this.request.valveId = request.valveId;
            if (request.valveOffset) {
                this.request.valveOffset = parseFloat(request.valveOffset);
            }
        }

        if (request.qrCodeTypeId) {
            this.request.qrTypeCodeId = request.qrCodeTypeId;
        }

        if (request.sealWidthId) {
            this.request.sideSealWidthId = request.sealWidthId;
        }

        if (request.doubleCutId) {
            this.request.doubleCutId = request.doubleCutId;
        }

        if (request.roundedCornerId) {
            this.request.roundedCornerId = request.roundedCornerId;
        }

        if (request.gussetTypeId) {
            this.request.gussetTypeId = request.gussetTypeId;
        }


        this.request.ignoreErrorCodes = request.ignoreErrorCodes;

        this.refresh(this.zoom);
    }

    setTearNotch(tearNotchId, offset) {
        this.request.tearNotchId = tearNotchId;
        this.request.tearLineOffset = offset ? parseFloat(offset) : this.request.tearLineOffset;
        this.refresh(this.zoom);
    }

    setHangHole(hangHoleId, offset) {
        this.request.hangHoleId = hangHoleId;
        this.request.hangholeOffset = offset ? parseFloat(offset) : this.request.hangholeOffset;
        this.refresh(this.zoom);
    }

    setZipper(zipperId, offset) {
        this.request.zipperId = zipperId;
        this.request.zipperOffset = offset ? parseFloat(offset) : this.request.zipperOffset;
        this.refresh(this.zoom);
    }

    setValve(valveId, offset) {
        this.request.valveId = valveId;
        this.request.valveOffset = offset ? parseFloat(offset) : this.request.valveOffset;
        this.refresh(this.zoom);
    }

    setBottomFill(bottomFillId) {
        this.request.bottomFillId = bottomFillId;
        this.refresh(this.zoom);
    }

    setSideSealWidth(sideSealWidthId) {
        this.request.sideSealWidthId = sideSealWidthId;
        this.refresh(this.zoom);
    }

    setRoundedCorner(roundedCornerId) {
        this.request.roundedCornerId = roundedCornerId;
        this.refresh(this.zoom);
    }

    setGussetType(gussetTypeId) {
        this.request.gussetTypeId = gussetTypeId;
        this.refresh(this.zoom);
    }

    setDoubleCut(doubleCutId) {
        this.request.doubleCutId = doubleCutId;
        this.refresh(this.zoom);
    }

    setQrCodeType(qrCodeTypeId) {
        this.request.qrTypeCodeId = qrCodeTypeId;
        this.refresh(this.zoom);
    }

    onLayerSelected(layer) {
        layer.selected = !layer.selected;
        this.draw(this.layers, this.zoom);
        this.forceUpdate();
    }

    onZoomInClick() {
        this.zoom = this.zoom < 5 ? this.zoom += 0.25 : this.zoom;
        this.draw(this.layers, this.zoom);
    }

    onZoomOutClick() {
        this.zoom = this.zoom > 1 ? this.zoom -= 0.25 : this.zoom;
        this.draw(this.layers, this.zoom);
    }

    onFitClick() {
        this.fit();
    }

    fit() {
        var container = document.getElementById(this.id + 'dielineImage');
        var canvas = document.getElementById(this.id + 'canvas');

        if (canvas && container) {
            canvas.width = container.clientWidth;
            canvas.height = container.clientHeight * 1.2;

            if (canvas) {
                var ctx = canvas.getContext("2d");
                if (ctx) {
                    ctx.scale(1, 1);
                    ctx.clearRect(-50, -50, canvas.width + 100, canvas.height + 100);
                }
            }

            this.zoom = container.clientWidth / container.clientHeight; 
            this.draw(this.layers, this.zoom);
        }
    }

    moveCanvas(zoom, x, y) {
        var canvas = document.getElementById(this.id + 'canvas');
        if (canvas) {
            var ctx = canvas.getContext("2d");
            if (ctx) {
                ctx.scale(1, 1);

                this.origin = {
                    x: x,
                    y: y
                };

                ctx.translate(
                    this.origin.x,
                    this.origin.y
                );

                ctx.scale(zoom, zoom);
            }
        }

    }

    render() {
        return (
            <div>
                {this.state.hasErrors
                    ? <div className='alert alert-danger'>Dieline visualizing is currently not available.</div>
                    : <div id={this.id + 'dielineImage'} className='dielineImage'>
                        {this.state.loading &&
                            <div className='loading'>
                                <img className='loader' src={'/images/ajax-loader.gif'} />
                            </div>
                        }

                        <div id='actionMenu' className='menu left'>
                            {this.layers && this.layers.map((l, idx) => {
                                return (
                                    <div className='menuItem' key={'l' + idx}>
                                        <label className='controlContainer checkbox color-grey'><input type="checkbox" className='control checkbox' checked={l.selected} onChange={() => { this.onLayerSelected(l) }} /> <div className='payload'><Translation id='ed24f91c-3425-4336-a2a7-397c46eede70' sub={idx} defaultMessage={l.layer.name} category='Dieline' /></div></label>
                                    </div>
                                )
                            })}
                        </div>                            

                        <canvas id={this.id + 'canvas'} />

                        <div id='zoomMenu' className='menu right'>
                            <div className='btn-round' onClick={this.onZoomInClick}><Plus className='icon'/></div>
                            <div className='btn-round' onClick={this.onZoomOutClick}><Minus className='icon'/></div>
                            <div className='btn-round' onClick={this.onFitClick}><LifeBuoy className='icon'/></div>
                        </div>

                    </div>
                }
            </div>
        )
    }
}


export class Layer {

    constructor(idx, layer) {
        this.idx = idx;
        this.layer = layer;
        this.selected = true;
    }

    getIndex() {
        return this.idx;
    }

    draw(context, zoom) {
        this.layer.objects.forEach(obj => {
            context.draw(obj, zoom);
        });
    }
}


export class GraphicsContext {

    constructor(id, ctx, canvas) {
        this.id = id;
        this.ctx = ctx;
        this.canvas = canvas;
        this.zoom = 1;
    }

    getCanvasContext() {
        return this.ctx;
    }

    clearCanvas() {
        var zoom = this.zoom > 1 ? this.zoom : 1;
        var c = document.getElementById(this.id + 'canvas');
        var w = c.width * 2;
        var h = c.height * 2;
        this.ctx.scale(1, 1);
        this.ctx.clearRect(-50, -50, w + 100, h + 100);
        this.ctx.scale(zoom, zoom);
    }

    draw(obj, zoom) {
        this.zoom = zoom;
        switch (obj.type) {
            case 'Arc':
                this.drawArc(obj, zoom);
                break;
            case 'Ellipse':
                this.drawEllipse(obj, zoom);
                break;
            case 'Line':
                this.drawLine(obj, zoom);
                break;
            case 'Picture':
                this.drawPicture(obj, zoom);
                break;
            case 'Polygon':
                this.drawPolygon(obj, zoom);
                break;
            case 'Rectangle':
                this.drawRectangle(obj, zoom);
                break;
            case 'Text':
                this.drawText(obj, zoom);
                break;
        }

    }


    drawArc(obj, zoom) {
        var p1 = obj.points[0];
        var p2 = obj.points[1];

        var rx = ((p2.x - p1.x) / 2) * zoom;
        var ry = ((p2.y - p1.y) / 2) * zoom;

        var cx = p1.x * zoom + rx;
        var cy = p1.y * zoom + ry;

        var yOffset = 0;
        var xOffset = 0;

        this.ctx.beginPath();
        this.ctx.globalAlpha = 1;
        this.ctx.lineWidth = obj.pen.width;
        this.ctx.strokeStyle = obj.pen.color;
        if (obj.pen.dashStyle == 'Solid') {
            this.ctx.setLineDash([]);
        }
        else {
            this.ctx.setLineDash([2, 2]);
        }

        if (obj.sweepAngle > 0) {
            var sa = (Math.PI / 180) * obj.startAngle;
            var sw = (Math.PI / 180) * obj.sweepAngle + sa;
            this.ctx.arc(cx + xOffset, cy + yOffset, Math.abs(rx), sa, sw, false);
        }
        else {
            var sa = (Math.PI / 180) * obj.startAngle;
            var sw = (Math.PI / 180) * obj.sweepAngle + sa;
            this.ctx.arc(cx + xOffset, cy + yOffset, Math.abs(rx), sa, sw, true);

        }
        this.ctx.stroke();
    }

    drawEllipse(obj, zoom) {
        var p1 = obj.points[0];
        var p2 = obj.points[1];

        var rx = ((p2.x - p1.x) / 2) * zoom;
        var ry = ((p2.y - p1.y) / 2) * zoom;
        var cx = p1.x * zoom + rx;
        var cy = p1.y * zoom + ry;

        this.ctx.beginPath();
        this.ctx.globalAlpha = 1;
        this.ctx.lineWidth = obj.pen.width;
        this.ctx.strokeStyle = obj.pen.color;
        if (obj.pen.dashStyle == 'Solid') {
            this.ctx.setLineDash([]);
        }
        else {
            this.ctx.setLineDash([2, 2]);
        }

        this.ctx.arc(cx, cy, rx, 0, 2 * Math.PI);
        this.ctx.stroke();
    }

    drawLine(obj, zoom) {
        var p1 = obj.points[0];
        var p2 = obj.points[1];

        this.ctx.beginPath();
        this.ctx.globalAlpha = 1;
        this.ctx.lineWidth = obj.pen.width;
        this.ctx.strokeStyle = obj.pen.color;
        this.ctx.moveTo(p1.x * zoom, p1.y * zoom);
        if (obj.pen.dashStyle == 'Solid') {
            this.ctx.setLineDash([]);
        }
        else {
            this.ctx.setLineDash([2, 2]);
        }

        this.ctx.lineTo(p2.x * zoom, p2.y * zoom);
        this.ctx.stroke();
    }

    drawPicture(obj, zoom) {
    }

    drawPolygon(obj, zoom) {
        var p1 = obj.points[0];

        this.ctx.beginPath();
        this.ctx.globalAlpha = 1;
        this.ctx.lineWidth = obj.pen.width;
        this.ctx.strokeStyle = obj.pen.color;
        if (obj.pen.dashStyle == 'Solid') {
            this.ctx.setLineDash([]);
        }
        else {
            this.ctx.setLineDash([2, 2]);
        }

        this.ctx.moveTo(p1.x * zoom, p1.y * zoom);
        obj.points.forEach(p => {
            this.ctx.lineTo(p.x * zoom, p.y * zoom);
        });

        this.ctx.closePath();

        if (obj.brush.color !== '#FFFFFF') {
            this.ctx.globalAlpha = obj.brush.opacity;
            this.ctx.fillStyle = obj.brush.color;
            this.ctx.fill();
        }
        else {
            this.ctx.globalAlpha = 1;
        }

        this.ctx.stroke();
    }

    drawRectangle(obj, zoom) {
        var p1 = obj.points[0];
        var p2 = obj.points[1];

        this.ctx.beginPath();
        this.ctx.globalAlpha = 1;
        this.ctx.lineWidth = obj.pen.width;
        this.ctx.strokeStyle = obj.pen.color;
        if (obj.pen.dashStyle == 'Solid') {
            this.ctx.setLineDash([]);
        }
        else {
            this.ctx.setLineDash([2, 2]);
        }

        if (obj.brush.color !== '#FFFFFF') {
            this.ctx.globalAlpha = obj.brush.opacity;
            this.ctx.fillStyle = obj.brush.color;
            this.ctx.fillRect(p1.x * zoom, p1.y * zoom, (p2.x - p1.x) * zoom, (p2.y - p1.y) * zoom);
        }
        else {
            this.ctx.globalAlpha = 1;
            this.ctx.rect(p1.x * zoom, p1.y * zoom, (p2.x - p1.x) * zoom, (p2.y - p1.y) * zoom);
        }
        this.ctx.stroke();
    }


    drawText(obj, zoom) {
        var p1 = obj.points[0];

        this.ctx.beginPath();
        this.ctx.globalAlpha = 1;

        this.ctx.save();

        this.ctx.fillStyle = obj.brush.color;
        this.ctx.font = `${obj.fontSize * zoom}px Arial`;

        if (obj.angle > 0) {
            this.ctx.translate((p1.x - obj.fontSize) * zoom, p1.y * zoom);
            this.ctx.rotate((Math.PI / 180) * obj.angle);
            this.ctx.fillText(obj.text, obj.fontSize * zoom * -1, 0);

        }
        else {
            this.ctx.translate(0, 0);
            this.ctx.rotate(0);
            this.ctx.fillText(obj.text, p1.x * zoom, (p1.y - obj.fontSize) * zoom);
        }

        this.ctx.restore();
    }
}

export class ErrorCodes {
    static FromResponse(err) {
        var errorCodes = [];
        if (err && err.length > 0) {            
            err.forEach(ec => {
                var parts = ec.split("-");

                switch (parts[0]) {
                    case "OP": // Outside
                        errorCodes.push(new ErrorCode(ErrorCode.Type.Outside, ErrorCode.getFeatureFromCode(parts[1])));
                        break;
                    case "ZF": // Conflict
                        errorCodes.push(new ErrorCode(ErrorCode.Type.Conflict, ErrorCode.getFeatureFromCode(parts[1]), ErrorCode.getFeatureFromCode(parts[2])));
                        break;
                    case "FO": // Feature Offset is invalid
                        errorCodes.push(new ErrorCode(ErrorCode.Type.OffsetInvalid, ErrorCode.getFeatureFromCode(parts[1])));
                        break;
                    case "FS": // Feature Sequence is invalid
                        errorCodes.push(new ErrorCode(ErrorCode.Type.SequenceInvalid, ErrorCode.getFeatureFromCode(parts[1]), ErrorCode.getFeatureFromCode(parts[2])));
                        break;


                }
            });
            return errorCodes;
        }
    }
}

export class ErrorCode {
    static Type = {
        Outside: 0,
        Conflict: 1,
        OffsetInvalid: 2,
        SequenceInvalid: 3
    }

    static Feature = {
        Zipper: 0,
        HangHole: 1,
        TearNotch: 2,
        Valve: 3
    }
   
    constructor(type, feature1, feature2) {
        this.type = type;
        this.feature1 = feature1;
        this.feature2 = feature2;
        this.source = this.getFeatureTypeName(feature1);
        this.errorMessage = this.getErrorMessage();
    }

    getErrorMessage() {
        switch (this.type) {
            case ErrorCode.Type.Outside:
                return `Location.${this.getFeatureTypeName(this.feature1)}`.toLowerCase();
                break;
            case ErrorCode.Type.Conflict:
                return `Conflict.${this.getFeatureTypeName(this.feature1)}.${this.getFeatureTypeName(this.feature2)}`.toLowerCase();
                break;
            case ErrorCode.Type.OffsetInvalid:
                return `Offset.${this.getFeatureTypeName(this.feature1)}`.toLowerCase();
                break;
            case ErrorCode.Type.SequenceInvalid:
                return `Sequence.${this.getFeatureTypeName(this.feature1)}.${this.getFeatureTypeName(this.feature2)}`.toLowerCase();
                break;
        }
    }

    getFeatureTypeName(feature) {
        switch (feature) {
            case ErrorCode.Feature.Zipper:
                return "zipper";
            case ErrorCode.Feature.HangHole:
                return "hangHole";
            case ErrorCode.Feature.TearNotch:
                return "tearnotch";
            case ErrorCode.Feature.Valve:
                return "valve";
        }
    }


    getFeatureName(feature) {
        switch (feature) {
            case ErrorCode.Feature.Zipper:
                return "Zipper";
            case ErrorCode.Feature.HangHole:
                return "Hang Hole";
            case ErrorCode.Feature.TearNotch:
                return "Tear Notch";
            case ErrorCode.Feature.Valve:
                return "Valve";
        }
    }

    static getFeatureFromCode(code) {
        switch (code.toUpperCase()) {
            case "ZP":
                return ErrorCode.Feature.Zipper;
            case "HH":
                return ErrorCode.Feature.HangHole;
            case "TN":
                return ErrorCode.Feature.TearNotch;
            case "VL":
                return ErrorCode.Feature.Valve;
        }
    }
}