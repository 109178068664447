import React, { Component, useState } from "react";
import { useEffect } from "react";
import { CustomDropdown } from "../../core/CustomDropdown";
import { HookCustomDropdown } from "../../core/HookCustomDropdown";
import { Translation } from "../../core/Translation";
import { Controller } from "react-hook-form";
import Select from "react-select";

import "./UnwindDirectionDropdown.css";



export const UnwindDirectionDropdown = ({ orderPlacementApi, errors, offerKey, control }) => {

    const [unwindDirections, setUnwindDirections] = useState([]);
    const [defaultItem, setDefaultItem] = useState(null);
    const [loaded, setLoaded] = useState(false);
    useEffect(() => {

        const loadDirections = async () => {
            var data = await orderPlacementApi.getUnwindDirections(offerKey);

            if (data) {
                let items = data.response;

                let defaultItem = items.find((e) => e.isDefault === true);

                let selectItems = items.map(x => {
                    return {
                        value: x.id,
                        label: x.value
                    }
                })

                console.log("unwind stuff", items, defaultItem);

                setDefaultItem(defaultItem);

                setUnwindDirections(selectItems);
                console.log(items, defaultItem);

                setLoaded(true);

            }
        };
        loadDirections();
    }, []);

    const formatOptionLabel = ({ value, label, customAbbreviation }) => (
        <div className="list-item">
            <div className="image">
                <img alt="" src={"/images/features/unwind_" + value + ".png"}/>
            </div>
            <span className="text">{label}</span>
        </div>
      );
    
      const brandColor = '#00b3e8';
    
      const getControlStyles = (styles, state) => {
        return {
           
        }
    }

    return (
        loaded && (
             <div className="unwindirections-dropdown">
                <Controller
                    control={control}
                    name="rollstockUnwindInstruction"
                    rules={{required: "Please select a value"}}
                    render={({ field }) => (
                            <Select
                                {...field}
                                styles={{
                                    option: (styles, state) =>({
                                        ...styles,
                                        backgroundColor: state.isSelected ? "#00b3e8": "",
                                        "&:hover": {
                                          backgroundColor: "#00b3e8"
                                        }
                                    }),
                                    control: (styles, state) => ({
                                        ...styles,
                                        boxShadow: state.isFocused ? 0 : 0,
                                        borderStyle: 'solid',
                                        borderWidth: state.isFocused ? '2px' : '2px',
                                        borderColor: errors && errors.rollstockUnwindInstruction ? '#bb1128' : state.isFocused ? brandColor : '#eee',
                                        '&:hover': {
                                            borderColor: state.isFocused ? brandColor : '#eee',
                                        }
                                    })
                                }}
                                placeholder="Please select an unwind direction"
                                options={unwindDirections}
                                formatOptionLabel={formatOptionLabel}
                                onChange={e => {
                                    console.log("chng", e);
                                    field.onChange(e);
                                }}
                            />

                        )}
                />
                {errors && errors.rollstockUnwindInstruction ? <span className="error-message">{errors.rollstockUnwindInstruction.message}</span> : ""}
            </div>
        )
    );
};
