import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter, createBrowserRouter, createRoutesFromElements, Route, RouterProvider } from "react-router-dom";
import App, { AppContextProvider, GoogleAnalyticsDecoratedApp } from "./App";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import { PublicClientApplication, EventType } from "@azure/msal-browser";
import { msalConfig, loginRequest, protectedResources, b2cPolicies } from "./authConfig";
import ReactGA from "react-ga4";
import TagManager from "react-gtm-module";
import { DebugUtilities } from "./debug";
import AppRoutes from "./AppRoutes";
import { HomePage } from "./pages/Home";
import { Layout } from "./components/Layout";

const baseUrl = document.getElementsByTagName("base")[0].getAttribute("href");
const rootElement = document.getElementById("root");
const root = createRoot(rootElement);
ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_KEY, {
    testMode: process.env.NODE_ENV !== "production" ? true : false,
    gaOptions: {},
});
const tagManagerArgs = {
    gtmId: process.env.REACT_APP_GOOGLE_TAG_MANAGER_KEY,
};
TagManager.initialize(tagManagerArgs);

/*
    Be careful when moving these configuration assignments around
*/

b2cPolicies.authorities.signUpSignIn.authority = process.env.REACT_APP_AZURE_AD_B2C_SIGNUPSIGNIN_AUTHORITY;
b2cPolicies.authorities.forgotPassword.authority = process.env.REACT_APP_AZURE_AD_FORGOT_PASSWORD_AUTHORITY;
b2cPolicies.authorities.editProfile.authority = process.env.REACT_APP_AZURE_AD_EDIT_PROFILE_AUTHORITY;
b2cPolicies.authorityDomain = process.env.REACT_APP_AZURE_AD_B2C_AUTHORITY_DOMAIN;

msalConfig.auth.clientId = process.env.REACT_APP_AZURE_AD_B2C_CLIENT_ID;
msalConfig.auth.authority = b2cPolicies.authorities.signUpSignIn.authority;
msalConfig.auth.knownAuthorities = [b2cPolicies.authorityDomain];
msalConfig.auth.navigateToLoginRequestUrl = false;

var loginRequestScopes = process.env.REACT_APP_AZURE_AD_B2C_LOGIN_REQUEST_SCOPES;
var protectedResourcesScopes = process.env.REACT_APP_AZURE_AD_B2C_PROTECTED_RESOURCE_SCOPES;

loginRequest.scopes = loginRequestScopes
    .split(",")
    .filter(Boolean)
    .map((y) => y.trim());
protectedResources.shopApp.scopes = protectedResourcesScopes
    .split(",")
    .filter(Boolean)
    .map((y) => y.trim());

if (process.env.NODE_ENV !== "production") {
    var debug = new DebugUtilities();
    debug.printDebug(b2cPolicies, msalConfig, loginRequest, protectedResources);
}
else {
    console.log("%c ## Shop Build Version " + process.env.REACT_APP_SHOP_BUILD_VERSION + " ##", "background: #333; color: #bada55");
}
export const msalInstance = new PublicClientApplication(msalConfig);

msalInstance.enableAccountStorageEvents();
msalInstance.addEventCallback((event) => {

    if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
        console.log("Login success (root)");
        const account = event.payload.account;
        msalInstance.setActiveAccount(account);
    }
});

root.render(
    <BrowserRouter basename={baseUrl}>
        <App msalInstance={msalInstance} />
    </BrowserRouter>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();
