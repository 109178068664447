import React, { useEffect, useState, useContext } from "react";
import "./Register.css";
import { SecureApiDataAccess } from "../../infrastructure/SecureApiDataAccess";
import { useMsal, useAccount, useIsAuthenticated, AuthenticatedTemplate } from "@azure/msal-react";
import { loginRequest, protectedResources } from "../../authConfig";
import { RegistrationForm } from "./RegistrationForm";
import { useNavigate } from "react-router-dom";
import { Page } from "../../core/Page";
import { useCheckoutContext } from "../../infrastructure/CheckoutContext";
import { useUserContext } from '../../infrastructure/UserContext';
import { PendingRegistration } from "./PendingRegistration";
import { CapabilityName } from '../../infrastructure/Constants';
import { CapabilitiesContext } from '../../infrastructure/Contexts';
import { useTracking } from "react-tracking";
import { useCookies } from 'react-cookie';

export const Register = () => {
    const { instance, accounts, inProgress } = useMsal();
    const capabilitiesContext = useContext(CapabilitiesContext);

    const loader = capabilitiesContext.getCapability(CapabilityName.Loader);

    const account = useAccount(accounts[0] || {});
    const userDataAccess = new SecureApiDataAccess("/api/user");
    const isAuthenticated = useIsAuthenticated();
    const navigate = useNavigate();
    const { createUrlFromContext, product } = useCheckoutContext();
    const [submitError, setSubmitError] = useState([]);
    const [pendingRegistration, setPendingRegistration] = useState(false);
    const { userRegion } = useUserContext();
    const { trackEvent } = useTracking({ page: "RegisterPage" });
    const [cookies, setCookie] = useCookies(['visitorId']);

    const onSubmit = async (data, e) => {
        try {
            loader.show();

            const response = await instance.acquireTokenSilent({
                scopes: [...protectedResources.shopApp.scopes],
                ...loginRequest,
                account: account,
            });

            if (!response.accessToken) {
                throw new Error("Could not retrieve access token.");
            }
            console.log("onSubmit", data, product);
            var registerResponse = await userDataAccess.postAsJson(
                "/register",
                {
                    companyName: data.companyName,
                    firstName: data.firstName,
                    lastName: data.lastName,
                    emailAddress: account.username,
                    phoneNumber: data.phoneNumber,
                    identityProviderAccountId: account.idTokenClaims.oid,
                    taxArea: data.taxArea,
                    taxCountry: data.taxLocale,
                    industry: product.industryName,
                    industryCategory: product.industryCategoryName,
                },
                response.accessToken,
                {
                    throwOnNonSuccess: false
                });

            if (registerResponse?.hasSuccess) {
                if (registerResponse.data.registrationStatusId === "COMPL") {

                    trackEvent({
                        event: 'sign_up',
                        method: account.environment,
                        userId: cookies.visitorId
                    });

                    navigate(createUrlFromContext(userRegion));
                }
                else {
                    setPendingRegistration(true);
                }
            } else {
                if (registerResponse.statusCode === 400) {
                    if (registerResponse.data.errors) {
                        if (registerResponse.data.errors.validation) {
                            let validationErrors = [];
                            registerResponse.data.errors.validation.forEach(x => validationErrors.push(x));
                            setSubmitError(validationErrors);
                        }
                    }
                }
                console.log("No, it didn't work.");
            }
            loader.hide();
        }
        catch (error) {
            loader.hide();
        }
    };

    const onError = (errors, e) => {
        console.log(errors);
    };

    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        const init = async () => {
            if (!account) {
                console.log("register - account not set yet");
                return;
            }


            if (!isAuthenticated){
                navigate("/", {
                    replace: true
                });
            }
            console.log("register - account set");

            const response = await instance.acquireTokenSilent({
                scopes: [...protectedResources.shopApp.scopes],
                ...loginRequest,
                account: account,
            });

            console.log("useEffect - acquire token response", response);
            if (!response.accessToken) {
                throw new Error("Could not retrieve access token.");
            }

            var user = await userDataAccess.get(
                `/search?emailAddress=${encodeURIComponent(
                    account.username
                )}&identityProviderAccountId=${encodeURIComponent(account.idTokenClaims.oid)}`,
                response.accessToken,
                {
                    throwOnNonSuccess: false
                }
            );

            if (user.hasSuccess) {
                console.log("A user was found", user);
                if (user.data.registrationStatusId === "COMPL") {
                    //redirect to checkout
                }
                else if (user.data.registrationStatusId === "PENDG") {
                    console.log("user registration pending")
                    setPendingRegistration(true);
                }
            } else {

                console.log("Didn't find a user.", user.statusCode);
            }

            setLoaded(true);
        };
        init();
    }, [account]);

    return (
        loaded && (
            <Page>
                <AuthenticatedTemplate>
                    {pendingRegistration ? <PendingRegistration /> : <RegistrationForm onSubmit={onSubmit} onError={onError} submitError={submitError} />}
                </AuthenticatedTemplate>
            </Page>
        )
    );
};
