import React, { useContext, useState, useEffect } from "react";
import { useNavigate, useLocation } from 'react-router-dom';
import { useForm, get } from "react-hook-form";
import { Translation } from "../../core/Translation";
import { MyAccountPageHeaderOptions } from '../../core/MyAccountPageHeaderOptions'
import { Language } from "../../infrastructure/Languages";
import { SecureApiDataAccess } from '../../infrastructure/SecureApiDataAccess';
import OrderPlacementApi from '../../infrastructure/OrderPlacementApi';
import { CountriesWithSubSelection } from "../../infrastructure/Constants";
import { CapabilitiesContext } from "../../infrastructure/Contexts";
import { CapabilityName } from "../../infrastructure/Constants";
import { HookEditMyAccountAddress } from '../../pages/account/HookEditMyAccountAddress';


export const EditShippingAddress = () => {

    const {
        register,
        unregister,
        reset,
        setValue,
        getValues,
        formState: { errors, isValid },
    } = useForm({
        mode: "onBlur",
        shouldFocusError: true,
        defaultValues: {
        }
    });

    const navigate = useNavigate();
    const location = useLocation();
    const salesforceAccountId = location.state.salesforceAccountId;
    const address = location.state.address;
    const orderPlacementApi = new OrderPlacementApi("/");
    const language = useContext(Language);
    const capabilitiesContext = useContext(CapabilitiesContext);
    const loader = capabilitiesContext.getCapability(CapabilityName.Loader);
    const [countries, setCountries] = useState(null);
    //const [appointmentRequired, setAppointmentRequired] = useState(false);
    //const [liftgateNeeded, setLiftgateNeeded] = useState(false);
    //const [blindShipment, setBlindShipment] = useState(false);
    const [loaded, setLoaded] = useState(false);
    const [isDefaultAddress, setIsDefaultAddress] = useState(address.isDefault);
    const dataAccess = new SecureApiDataAccess('/api/user');


    const additionalFields = [
        {
            name: "name",
            type: "text",
            placeholder: "Delivery Contact Name",
            required: true,
            maxLength: 50,
            title: <Translation id="91ce24b8-736a-4bac-9b50-1cec9a24f3b9" defaultMessage="Delivery Contact Name" category='Label' />
        },
        {
            name: "phone",
            type: "text",
            placeholder: "Delivery Phone Number",
            required: true,
            maxLength: 20,
            title: <Translation id="91ce24b8-736a-4bac-9b50-1cec9a24f3b3" defaultMessage="Delivery Phone Number" category='Label' />
        },
        /*
        {
            name: "appointmentRequired",
            type: "checkbox",
            placeholder: "Appointment Required",
            required: false,
            onChange: (e) => setAppointmentRequired(e.target.checked),
            title: <Translation id='892c1fe3-2a2b-4c13-8444-c7e6c3d123e5' defaultMessage='Shipping Instructions' category='Label' />,
            description: <Translation id='91ce24b8-736a-4bac-9b50-1cec9a24f3b4' defaultMessage='Appointment Required' category='Label' />
        },
        {
            name: "liftgateNeeded",
            type: "checkbox",
            placeholder: "Liftgate Needed",
            required: false,
            onChange: (e) => setLiftgateNeeded(e.target.checked),
            description: <Translation id='91ce24b8-736a-4bac-9b50-1cec9a24f3b5' defaultMessage='Liftgate Needed' category='Label' />
        },
        {
            name: "blindShipment",
            type: "checkbox",
            placeholder: "Blind Shipment",
            required: false,
            onChange: (e) => setBlindShipment(e.target.checked),
            description: <Translation id='91ce24b8-736a-4bac-9b50-1cec9a24f3c2' defaultMessage='Blind Shipment' category='Label' />
        },
        */
        {
            name: "specialInstructions",
            type: "text",
            placeholder: "Additional Instructions",
            required: false,
            maxLength: 200,
            title: <Translation id="91ce24b8-736a-4bac-9b50-1cec9a24f3b7" defaultMessage="Additional Instructions" category='Label' />
        }
    ];

    useEffect(() => {
        if (loaded) {
            return;
        }
        const init = async () => {
            loader.show();
            console.group("%c Hook Edit Shipping Address init", "background: #333; color: #bada55");
            await loadValues();
            var response = await orderPlacementApi.getCountries();
            setCountries(response.response);
            setLoaded(true);
            loader.hide();
        }
        init();
    }, []);

    const loadValues = async () => {
        setValue("shippingAddress.name", address.name);
        setValue("shippingAddress.phone", address.phone);
        setValue("shippingAddress.streetAddress1", address.streetAddress1);
        setValue("shippingAddress.streetAddress2", address.streetAddress2);
        setValue("shippingAddress.city", address.city);
        setValue("shippingAddress.postalCode", address.postalCode);
        setValue("shippingAddress.state", address.state);
        setValue("shippingAddress.country", address.country);
        setValue("shippingAddress.specialInstructions", address.specialInstructions);
        setValue("shippingAddress.salesforceLocationContactId", address.salesforceLocationContactId);
        setValue("shippingAddress.salesforceLocationId", address.salesforceLocationId);
    }

    const onSubmit = async () => {
        var request = {
            isDefaultAddress: isDefaultAddress,
            accountId: salesforceAccountId,
            name: getValues("shippingAddress.name"),
            phone: getValues("shippingAddress.phone"),
            streetAddress1: getValues("shippingAddress.streetAddress1"),
            streetAddress2: getValues("shippingAddress.streetAddress2"),
            city: getValues("shippingAddress.city"),
            postalCode: getValues("shippingAddress.postalCode"),
            state: getValues("shippingAddress.state"),
            country: getValues("shippingAddress.country"),
            specialInstructions: getValues("shippingAddress.specialInstructions"),
            salesforceLocationId: getValues("shippingAddress.salesforceLocationId"),
            email: getValues("shippingAddress.email"),
            salesforceLocationContactId: getValues("shippingAddress.salesforceLocationContactId")
        };
        try {
            loader.show();
            var response = await dataAccess.postAsJson('/address/shipping', request);
            if (response.hasSuccess)
                navigate('/myaddresses');
        }
        catch (ex) {
            console.log(ex.message);
        }
        finally
        {
            loader.hide();
        }
    }

    return loaded && (
        <div>
            <MyAccountPageHeaderOptions selectedHeaderNumber={2} />
            <div className="edit-address-title">
                <h2>{language.translateLabel('5e6b9c37-a215-4552-99d5-2cafc9ca8209', 'Edit Shipping Address')}</h2>
            </div>
            <div className="address-form">
                <input
                    data-testid={"add-shipping-address-is-default-address"}
                    type="checkbox"
                    className="form-checkbox default-address-checkbox"
                    disabled={address.isDefault}
                    defaultChecked={address.isDefault}
                    onChange={(e) => setIsDefaultAddress(e.target.checked)}
                />
                <Translation id='1f88365b-9c07-4aad-92d4-4a5477fd5fca' defaultMessage='Make this my default address' category='Label' />
                <HookEditMyAccountAddress
                    unregister={unregister}
                    getErrorMessage={get}
                    setValue={setValue}
                    getValues={getValues}
                    register={register}
                    countriesForStateValidation={CountriesWithSubSelection}
                    errors={errors}
                    countries={countries}
                    id="shippingAddress"
                    addressType="shippingAddress"
                    orderPlacementApi={orderPlacementApi}
                    reset={reset}
                    requireEmail={false}
                    requireCompanyName={false}
                    additionalFields={additionalFields}
                />
            </div>
            <button className='btn btn-secondary pull-right' disabled={!isValid} onClick={() => { onSubmit() }}>{language.translateLabel('b5e8af97-25c9-413c-89a6-eb090846613b', 'Save', 'Button')}</button>
        </div>
    );
}