import React from "react";

export const RadioButton = (props) => {

    const getStyle = () => {
        let result = 'control radiobutton';
        return result;
    }

    return (
        <label className='controlContainer radiobutton'>
            <input type="radio" className={getStyle()} name={props.name} value={props.value} onChange={props.onChange} defaultChecked={props.checked === true} /> <div className='payload'>{props.children}</div></label>
    )
}