import React, { useState, useEffect, useRef } from "react";
import { Card } from '../../core/Card';
import { Popup } from '../../core/Popup';
import { Translation } from "../../core/Translation";
import { useNavigate } from "react-router-dom";
import { SecureApiDataAccess } from "../../infrastructure/SecureApiDataAccess";
import { useMsal, useAccount } from "@azure/msal-react";
import { loginRequest, protectedResources } from "../../authConfig";
import { SelectableSkuLineItem } from "../../components/reorder/SelectableSkuLineItem";

import "./OrderedProducts.css";


export const OrderedProducts = ({ }) => {

    const popup = useRef(null);
    const navigate = useNavigate();

    const { instance, accounts } = useMsal();
    const account = useAccount(accounts[0] || {});

    const [products, setProducts] = useState(null);
    const [selectedProductId, setSelectedProductId] = useState(null);
    const [salesforceAccountId, setSalesforceAccountId] = useState();

    useEffect(() => {
        const init = async () => {

            const response = await instance.acquireTokenSilent({
                scopes: [...protectedResources.shopApp.scopes],
                ...loginRequest,
                account: account,
            });

            var userDataAccess = new SecureApiDataAccess("/api/user");
            var user = await userDataAccess.get(
                `/search?emailAddress=${encodeURIComponent(
                    account.username
                )}&identityProviderAccountId=${encodeURIComponent(account.idTokenClaims.oid)}`,
                response.accessToken,
                {
                    throwOnNonSuccess: false
                })

            setSalesforceAccountId(user.data.salesforceAccountId);
            await loadConfigs(user.data.salesforceAccountId);
        }

        if (!!account)
            init();

    }, []);

    const loadConfigs = async (sfAccountId) => {
        var secureDataAccess = new SecureApiDataAccess('/api/reorder');
        var response = await secureDataAccess.get(`user/${sfAccountId}`);
        var data = response.data;

        console.log("DATA", data);
        if (!data || data.length == 0)
            popup.current.show();

        var items = [];
        data.forEach((config) => {
            var productSkus = config.skus.map(function (sku) {
                return new ProductSku(sku.approvedSkuId, sku.description, sku.artwork, sku.thumbnail, sku.printFile);
            });
            var productFeatures = [];
            config.additionalParameters.forEach((parameter) => {
                var parameterNameFormatted = getFormattedParameterName(parameter);
                var parameterValue = !!parameter.domainDescription ? parameter.domainDescription : parameter.dynamicsValue;
                productFeatures.push(new ProductFeature(parameterNameFormatted, parameterValue, parameter.dependentPropertyDynamicsValue));
            });
            items.push(new Product(productSkus, config.convertingMethod, config.dimensions, config.material, config.eyemark, config.latest, productFeatures));
        });
        setProducts(items);
    }

    const getFormattedParameterName = (parameter, key) => {
        var name = !!parameter.featureName ? parameter.featureName : parameter.name;
        var formattedName = (name[0].toUpperCase() + name.slice(1)).replace(/([A-Z]+)/g, " $1").trim();
        return formattedName;
    }

    const onReorderClick = (idx, product) => {
        console.log(idx, product);
        setSelectedProductId(idx);
    }

    const onSubmit = (selectedSkuList) => {
        navigate("/myreorders/reorder", {
            state:
            {
                salesforceAccountId: salesforceAccountId,
                emailAddress: account.username,
                selectedSkus: selectedSkuList
            }
        });
    }

    return (
        <Card className='orderedProducts' title='My Products' subtitle='Your products eligible for reorder are listed below.' transparent={true}>
            {products &&
                <div>
                    {products.map((product, idx) => {
                        return (
                            <OrderedProductLine
                                key={`product${idx}`}
                                idx={idx}
                                product={product}
                                expanded={selectedProductId == idx}
                                onClick={(idx, product) => { onReorderClick(idx, product) }}
                                onSubmit={(selectedSkus) => onSubmit(selectedSkus)}
                                salesforceAccountId={salesforceAccountId}
                            />
                        )
                    })}
                    <Popup closeOnClickAway={true} ref={popup}>
                        <div className='contentSection'>
                            <Translation id='ea42f995-b53f-4d0f-af22-f9d47991e90f' defaultMessage='No reorderable items found the logged user' category='Label' />
                        </div>
                    </Popup>
                </div>
            }
        </Card>
    )
}


export const OrderedProductLine = ({ idx, product, expanded, onClick, onSubmit }) => {

    const onReorderClick = () => {
        if (onClick) {
            onClick(idx, product);
        }
    }

    return (
        <Card className='orderedProductLine' title={product.convertingMethod} subtitle={`You ordered this product last on ${new Date(product.lastOrderDate).toLocaleDateString(0)}`} >

            <div className='row'>
                <div className='cell caption'>Dimensions:</div>
                <div className='cell content'>{product.formattedDimensions}</div>
            </div>

            <div className='row'>
                <div className='cell caption'>Material:</div>
                <div className='cell content'>{product.material}</div>
            </div>

            <div className='row'>
                <div className='cell caption'>Eyemark:</div>
                <div className='cell content'>{product.eyemark}</div>
            </div>

            {product.features.map((feature, idx) => {
                return (
                    <div className='row' key={`feature${idx}`}>
                        <div className='cell caption'>{feature.type}:</div>
                        <div className='cell feature'>{feature.name}</div>
                        <div className='cell offset'>{feature.offset ? `Offset: ${feature.offset} in` : ''}</div>
                    </div>
                )
            })}

            {expanded
                ? <ProductSkuList product={product} onSubmit={(selectedSkus) => onSubmit(selectedSkus)} />
                : <div className='pull-right button'>
                    <button className='btn btn-secondary-outline' onClick={() => { onReorderClick(); }}>Reorder</button>
                    <p>{product.skus.length} Skus available</p>
                </div>
            }
        </Card>
    )
}


export const ProductSkuList = ({ product, onSubmit }) => {

    const [selectedSkus, setSelectedSkus] = React.useState(product.skus.length == 1 ? [product.skus[0].approvedSkuId] : []);
    const [valid, setValid] = React.useState(product.skus.length == 1 ? true : false);

    const onSkuSelectionChanged = (sku, selected) => {
        var items = selectedSkus;
        var idx = items.findIndex(e => e == sku.approvedSkuId);
        if (selected) {
            if (idx == -1)
                items.push(sku.approvedSkuId);
        }
        else {
            if (idx > -1)
                items.splice(idx, 1);
        }

        setSelectedSkus(items);
        setValid(items.length > 0);
    }

    const submitSelectedSkus = async () => {
        onSubmit(selectedSkus);
    }

    return (
        <div>
            <p>Please select the items you want to order from the list below:</p>
            {product.skus.map((sku, idx) => {
                return (
                    <SelectableSkuLineItem key={`sku${idx}`} sku={sku} selected={product.skus.length == 1} onClick={(sku, selected) => { onSkuSelectionChanged(sku, selected) }} />
                )
            })}

            <button disabled={!valid} className='btn btn-secondary pull-right' onClick={() => { submitSelectedSkus(); }}>Continue</button>
        </div>
    )
}

export class Product {

    constructor(skus, convertingMethod, formattedDimensions, material, eyemark, lastOrderDate, features) {
        this.skus = skus;
        this.convertingMethod = convertingMethod;
        this.formattedDimensions = formattedDimensions;
        this.material = material;
        this.eyemark = eyemark;
        this.lastOrderDate = lastOrderDate;
        this.features = features;
    }
}

export class ProductFeature {

    constructor(type, name, offset) {
        this.type = type;
        this.name = name;
        this.offset = offset;
    }
}

export class ProductSku {
    constructor(approvedSkuId, description, artwork, thumbnail, printFile) {
        this.approvedSkuId = approvedSkuId;
        this.description = description;
        this.artwork = artwork;
        this.thumbnail = thumbnail;
        this.printFile = printFile;
    }
}