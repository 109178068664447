import React, { useState } from "react";
import { ImageViewer } from "./ImageViewer";
import { BackSplash } from "../core/BackSplash";

import "./ClickableImage.css";

export const ClickableImage = ({ src }) => {

    const [showBigImage, setShowBigImage] = useState(false);

    return (
        <div className="clickable-image" onClick={() => setShowBigImage(!showBigImage)}>
            <ImageViewer className="small" src={src} src2='/images/sku.png' />
            {
                showBigImage &&
                <div>
                    <BackSplash />
                    <ImageViewer className="big" src={src} src2='/images/sku.png' />
                </div>
            }
        </div>
    )
}