import React, { useContext } from "react";
import { Language } from "../../infrastructure/Languages";
import { Card } from "../../core/Card";
import { Alert } from "../../core/Alert";
import { SectionTitle } from "../../core/SectionTitle";
import { DielineHelper } from "./DielineHelper";
import { ErrorCode } from "./DielineImage";
import { FeatureLocationType } from "../../core/offer/Constants";
import { Row } from "../../core/Row";
import { Column } from "../../core/Column";
import { SectionLabel } from "../../core/SectionLabel";
import { OffsetEditor } from "./OffsetEditor";

import './FeaturesDisplay.css';

export const FeaturesDisplay = ({
    zipper,
    zipperLocation,
    tearNotch,
    tearNotchLocation,
    hangHole,
    hangHoleLocation,
    valve,
    valveLocation,
    offsetReadonly,
    featureDependencies,
    applyFeatureLocation,
    dielineErrors,
    googleAnalyticsContext,
    hideDescription }) => {

    const language = useContext(Language);
    const createGoogleAnalyticsContext = googleAnalyticsContext ? googleAnalyticsContext.CreateContext : () => { };
    var dielineErrorList = dielineErrors ? dielineErrors : [];

    return (
        <Card
            title={language.translateLabel("a6c19a28-3460-4ce4-b500-364507f7a7d8", "Features")}
            subtitle={
                hideDescription ? null : language.translateLabel(
                    "5f2c2e83-7e65-48e5-aa03-daaa9086cf47",
                    "Please validate the selected features below and the measurements"
                )}>

            {
                dielineErrorList && dielineErrorList.length > 0 && (
                    <Alert type="error">
                        {language.translateLabel(
                            "e9d7909a-655b-4ce6-8fa3-c1c6a6232f6d",
                            "The Dieline is not valid and cannot be manufactured. Please see the notifications below and correct the errors by adjusting the measurements."
                        )}
                    </Alert>
                )}
            <Row>
                <Column>
                    <SectionTitle cms="zipper" image="af959a05-b263-4695-8878-68a348b8585d" gaContext={createGoogleAnalyticsContext("Zipper")}>
                        {
                            language.translateLabel(
                                "68e22377-f7f9-4796-ac80-23e42ae8bee1",
                                "Zipper")
                        }
                    </SectionTitle>
                    <SectionLabel description={zipper}>
                        {
                            zipperLocation &&
                            !DielineHelper.hasFeatureDependency(FeatureLocationType.Zipper, featureDependencies) && (
                                offsetReadonly ?
                                    zipperLocation.value
                                    :
                                    <OffsetEditor
                                        disabled={offsetReadonly}
                                        title={language.translateLabel(
                                            "68e22377-f7f9-4796-ac80-23e42ae8bee1",
                                            "Zipper"
                                        )}
                                        offset={zipperLocation}
                                        errorMessage={DielineHelper.getFeatureError(ErrorCode.Feature.Zipper, dielineErrorList)}
                                        hasError={DielineHelper.hasFeatureError(ErrorCode.Feature.Zipper, dielineErrorList)}
                                        onChanged={(value, uom) => {
                                            applyFeatureLocation(FeatureLocationType.Zipper, value);
                                        }}
                                    />
                            )}
                    </SectionLabel>
                </Column>

                <Column>
                    <SectionTitle cms="tear-notch" image="e41d1414-c1bd-4302-a410-b40a041d3c76" gaContext={createGoogleAnalyticsContext("Tear Notch")}>
                        {
                            language.translateLabel(
                                "ab0dd63d-79c9-4b5f-bff1-8c6accf6b023",
                                "Tear Notch"
                            )}
                    </SectionTitle>
                    <SectionLabel description={tearNotch}>
                        {
                            tearNotchLocation &&
                            !DielineHelper.hasFeatureDependency(FeatureLocationType.TearNotch, featureDependencies) && (
                                offsetReadonly ?
                                    tearNotchLocation.value
                                    :
                                    <OffsetEditor
                                        disabled={offsetReadonly}
                                        title={language.translateLabel(
                                            "ab0dd63d-79c9-4b5f-bff1-8c6accf6b023",
                                            "Tear Notch"
                                        )}
                                        offset={tearNotchLocation}
                                        errorMessage={DielineHelper.getFeatureError(ErrorCode.Feature.TearNotch, dielineErrorList)}
                                        hasError={DielineHelper.hasFeatureError(ErrorCode.Feature.TearNotch, dielineErrorList)}
                                        onChanged={(value, uom) => {
                                            applyFeatureLocation(FeatureLocationType.TearNotch, value);
                                        }}
                                    />
                            )}
                    </SectionLabel>
                </Column>
            </Row>
            <Row>
                <Column>
                    <SectionTitle cms="hang-hole" image="13306d0c-0ca7-4813-9397-9aca00719ee0" gaContext={createGoogleAnalyticsContext("Hang Hole")}>
                        {
                            language.translateLabel(
                                "81702163-18c0-4de5-a792-6e6c98abdea8",
                                "Hang Hole")
                        }
                    </SectionTitle>
                    <SectionLabel description={hangHole}>
                        {
                            hangHoleLocation &&
                            !DielineHelper.hasFeatureDependency(FeatureLocationType.HangHole, featureDependencies) && (
                                offsetReadonly ?
                                    hangHoleLocation.value :
                                    <OffsetEditor
                                        disabled={offsetReadonly}
                                        title={language.translateLabel(
                                            "81702163-18c0-4de5-a792-6e6c98abdea8",
                                            "Hang Hole"
                                        )}
                                        offset={hangHoleLocation}
                                        errorMessage={DielineHelper.getFeatureError(ErrorCode.Feature.HangHole, dielineErrorList)}
                                        hasError={DielineHelper.hasFeatureError(ErrorCode.Feature.HangHole, dielineErrorList)}
                                        onChanged={(value, uom) => {
                                            applyFeatureLocation(FeatureLocationType.HangHole, value);
                                        }}
                                    />
                            )}
                    </SectionLabel>
                </Column>
                <Column>
                    <SectionTitle cms="valve" image="9da0c23f-6709-4d55-bb64-f1f09ee63284" gaContext={createGoogleAnalyticsContext("Valve")}>
                        {
                            language.translateLabel(
                                "47e24a00-6d3e-4141-b3c0-d95d425dee81",
                                "Valve")
                        }
                    </SectionTitle>
                    <SectionLabel description={valve}>
                        {
                            valveLocation &&
                            !DielineHelper.hasFeatureDependency(FeatureLocationType.Valve, featureDependencies) && (
                                offsetReadonly ?
                                    valveLocation.value
                                    :
                                    <OffsetEditor
                                        disabled={offsetReadonly}
                                        title={language.translateLabel(
                                            "47e24a00-6d3e-4141-b3c0-d95d425dee81",
                                            "Valve"
                                        )}
                                        offset={valveLocation}
                                        errorMessage={DielineHelper.getFeatureError(ErrorCode.Feature.Valve, dielineErrorList)}
                                        hasError={DielineHelper.hasFeatureError(ErrorCode.Feature.Valve, dielineErrorList)}
                                        onChanged={(value, uom) => {
                                            applyFeatureLocation(FeatureLocationType.Valve, value);
                                        }}
                                    />
                            )}
                    </SectionLabel>
                </Column>
            </Row>
        </Card>
    );
};