import React, { useState, useContext } from "react";
import { Regex } from "../../core/offer/Regex";
import { Language } from '../../infrastructure/Languages';

export const Textbox = (props) => {

    const displayName = Textbox.name;
    // const language = useContext(Language);

    const [value, setValue] = useState(props.value ? props.value.toString() : '');
    const [valid, setValid] = useState(value);
    const [focused, setFocused] = useState(false);
    const regex = props.regex ? new RegExp(props.regex) : null;

    const onChange = (evt) => {
        let changedValue = evt.target.value;
        //this.value = evt.target.value;

        if (props.requireValidInput === true) {

            let valid = isValueValid(changedValue);

            if (valid || changedValue.length === 0) {
                setValid(true);

                notifyListeners(changedValue);
            }
        }
        else {
            let valid = isValueValid(changedValue);
            //setValue(changedValue);
            setValid(valid);

            notifyListeners(changedValue);
        }
        setValue(changedValue);
    }

    const notifyListeners = (value) => {
        let valid = isValueValid(value);

        if (props.onChange) {
            props.onChange(value, valid);
        }
    }

    const isValueValid = (value) => {
        if (props.required === true) {
            if (!value || value.length === 0) {
                return false;
            }
        }

        if (regex) {
            let matchesRegex = regex.test(value);            
            return props.invertRegexMatchCondition ? !matchesRegex : matchesRegex;
        }
        return true;
    }

    const getStyle = () => {
        let result = 'control textbox';
        if (!isValueValid(value)) {
            result += ' error';
        }
        else {
            if (value) {
                result += ' valid';
            }
        }
        return result;
    }

    const onBlur = () => {
        setFocused(false);
        if (props.onBlur) {
            props.onBlur(value);
        }
    }

    return (
        <input className={focused ? 'control textbox' : getStyle()
}
         type={props.type ? props.type : 'text'}
         autoComplete='none'
         value={props.value}
         onChange={onChange}
         maxLength={parseInt(props.maxLength) > 0 ? parseInt(props.maxLength) : 255}
            // placeholder={props.placeholder ? props.placeholder : language.translateLabel('b4f11a24-e367-4e20-8249-192c52e7160e', 'Input text here', 'Label')}
         required={props.required === true ? true : false}
            onFocus={() => setFocused(true)}
            onBlur={() => onBlur()}
        />
    );
}