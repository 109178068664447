import React, { useState, useContext } from 'react';
import { Upload } from 'react-feather';
import "./UploadLink.css";
import { Translation } from '../../core/Translation';
import { Language } from '../../infrastructure/Languages';

export const UploadLink = props => {

    const language = useContext(Language);
    const [fileName, setFileName] = useState("");
    const [progress, setProgress] = useState(0);
    const [errorOnUpload, setErrorOnUpload] = useState(false);

    const [label, setLabel] = useState(props.existingFileName ? props.existingFileName : props.defaultLabel);
    const [uploading, setUploading] = useState(false);
    const [uploadErrorText, setUploadErrorText] = useState(null);

    const onClickUpload = () => {
        let element = document.getElementById("upload_" + props.id);
        element.click();

        if (props.onClick) {
            props.onClick();
        }
    }

    const onHandleUpload = (e) => {
        e.preventDefault();

        var fileToUpload = e.target.files[0];

        if (fileToUpload.size > 104857600) {
            setErrorOnUpload(true);
            notifyUserOfUploadError(language.translateLabel('358defa3-d58c-4bd0-91a2-e424daf1c4b1', 'File cannot exceed 100MB and must be in PDF format to upload.', 'Label'));
            notifyArtworkResourceUploadFailed(props.Id);
            return;
        }

        let file = fileToUpload;
        let fileName = fileToUpload.name;

        let formData = new FormData();

        formData.append("formFile", file);
        formData.append("fileName", fileName)

        try {
            setErrorOnUpload(false);
            setUploading(true);

            let xhr = new XMLHttpRequest();

            xhr.upload.addEventListener("progress", e => {
                if (e.lengthComputable) {
                    var percentComplete = e.loaded / e.total * 100;
                    setProgress(percentComplete);
                  }
            });

            xhr.onerror = () => {
                notifyUserOfUploadError();
                notifyArtworkResourceUploadFailed(props.Id);
            }
            xhr.onload = () => {
                console.log("upload finished", xhr.status);
                if (!(xhr.status >= 200 && xhr.status <= 299)) {
                    try {
                        var msgText = JSON.parse(xhr.response);
                        notifyUserOfUploadError(msgText.message);
                        notifyArtworkResourceUploadFailed(props.id);
                    }
                    catch {
                        notifyUserOfUploadError();
                        notifyArtworkResourceUploadFailed(props.id);
                    }
                }
                else {
                    setLabel(fileToUpload.name);
                    if (props.onUploadComplete) {
                        props.onUploadComplete(props.id, fileToUpload, xhr.response);
                    }
                }
                setUploading(false);
            };
            xhr.open("POST", props.url);

            xhr.send(formData);
        }
        catch (ex) {
            console.log(ex);
            setUploading(false);
            setErrorOnUpload(true);
            notifyArtworkResourceUploadFailed(props.Id);
        }
    }

    const notifyArtworkResourceUploadFailed = (artworkResourceId) => {
        if (props.onArtworkResourceUploadFailed) {
            props.onArtworkResourceUploadFailed(artworkResourceId);
        }
    }

    const notifyUserOfUploadError = (message) => {
        if (!message) {
            message = language.translateLabel('03e2c5d7-1fa3-48ca-b871-47a1a30746f2', 'Unknown error uploading file. Please try again later', 'Label');
        }
        setUploadErrorText(message);
        setLabel(props.defaultLabel);
        setErrorOnUpload(true);
    }

    const renderData = () => {

        if (!uploading){
            return (
            <div>
                <div className='upload iconLink'>
                        <Upload className='icon' onClick={onClickUpload} />
                    <label htmlFor="upload" onClick={onClickUpload}>{label}</label>
                    <div className='fileupload'>
                        <input type="file" id={"upload_" + props.id} accept=".pdf,.ai" onChange={onHandleUpload} disabled={props.disabled} />
                    </div>
                </div>
                    {errorOnUpload && <span className="text error">{uploadErrorText}</span>}
            </div>
            )
        }
        return <div>
            <label style={{ "padding": "5px" }} htmlFor={"progress_" + props.id}>{Math.round(progress)}%</label>
            <progress id={"progress_" + props.id} value={progress} max="100" />
        </div>
    }

    return (
        renderData()
    );
}