import React, { useState } from 'react';
import { ChevronLeft, ChevronRight } from 'react-feather';
import { useTracking } from "react-tracking";


export const Carousel = ({ children, gaContext }) => {

    const [index, setIndex] = useState(0);
    const [orientation, setOrientation] = useState(null);
    const childs = children && children.length > 0 ? children : [children];
    const { trackEvent } = useTracking({ page: "Carousel" });

    const onPreviousClick = () => {
        if (gaContext) {
            trackEvent({
                event: 'ordering_tool_interactions',
                eventProps: {
                    'step': 'order_shopping_1',        // order_shopping_1
                    'product_category': gaContext.industryName,        // e.g. Food & Beverage
                    'product_sub_category': gaContext.industryCategoryName, // E.g. Dry Mix
                    'selection': gaContext.convertingMethodName,        // e.g. 2 Side Seal Pouch
                    'element': 'carousel_back', // e.g. choose_this_style, information, photo_scroll, back
                },
                userId: gaContext.userId
            });
        }
        if (childs && childs.length > 1) {
            var idx = index;
            if (idx > 0) {
                idx = idx - 1;
            }
            else {
                idx = children.length - 1;
            }
            setOrientation('previous');
            setIndex(idx);
        }
    }

    const onNextClick = () => {
        if (gaContext) {
            trackEvent({
                event: 'ordering_tool_interactions',
                eventProps: {
                    'step': 'order_shopping_1',        // order_shopping_1
                    'product_category': gaContext.industryName,        // e.g. Food & Beverage
                    'product_sub_category': gaContext.industryCategoryName, // E.g. Dry Mix
                    'selection': gaContext.convertingMethodName,        // e.g. 2 Side Seal Pouch
                    'element': 'carousel_next', // e.g. choose_this_style, information, photo_scroll, back
                },
                userId: gaContext.userId
            });
        }
        if (childs && childs.length > 1) {
            var idx = index;
            if (idx < children.length - 1) {
                idx = idx + 1;
            }
            else {
                idx = 0;
            }
            setOrientation('next');
            setIndex(idx);
        }
    }

    const getAnimationStyle = () => {
        var result = '';

        if (orientation == 'next') {
            result = 'slide-in-left';
        }

        if (orientation == 'previous') {
            result = 'slide-in-right';
        }

        return result;
    }

    return (
        <div className='carousel'>
            <div className='nav' onClick={onPreviousClick}>
                {childs && childs.length > 1 &&
                    <ChevronLeft className='icon' />
                }
            </div>
            <div className='window'>
                <div className='item-container' onClick={onNextClick}>
                    {childs.map((child, i) => {
                        return (
                            <div key={`child${i}`} className={index == i ? `carousel-item visibility-visible ${getAnimationStyle()}` : 'carousel-item visibility-hidden'}>{child}</div>
                        )
                    })}
                </div>
            </div>
            <div className='nav' onClick={onNextClick}>
                {childs && childs.length > 1 &&
                    <ChevronRight className='icon' />
                }
            </div>
        </div>
    )
}
