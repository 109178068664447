import React from "react";
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from '@azure/msal-react';
import { useNavigate } from 'react-router-dom';

import { Translation } from "../../core/Translation";
import { RadioButton } from "../../core/RadioButton";
import { SectionTitle } from "../../core/SectionTitle";
import { loginRequest } from "../../authConfig";

export const ArtworkWaive = ({ onSelection }) => {

    const { instance } = useMsal();
    const navigate = useNavigate();

    const onLoginClick = async () => {
        console.log("login to", document.location.href);
        instance.loginPopup({
            scopes: loginRequest.scopes,
            redirectUri: "/register/account",
            state: null
        }).then((loginResponse) => {
            if (loginResponse) {
                navigate(loginResponse.state, {
                    replace: true,
                });
            }
        }).catch((error) => console.log("login redirect error", error));
    }

    return (
        <div className="artwork-waive">
            <SectionTitle cms="artwork-waive">
                <Translation id='481ede0d-b33d-48ae-be18-11f8c07fda19' defaultMessage='Reorders require final artwork approval' category='Label' />
            </SectionTitle>
            <div className="artwork-waive-content">
                <UnauthenticatedTemplate>
                    <Translation id='122e67e3-bee2-4723-9d67-d55b55363abd' defaultMessage='You can choose to waive your final artwork approval to get into the production schedule faster but you need to sign in with an authenticated account to waive this final artwork approval' category='Label' />
                    <a onClick={(e) => onLoginClick()}>
                        <Translation
                            id='153c1c53-d93e-4456-b0f9-c4cca98b67bb'
                            defaultMessage='Register or sign in to waive final artwork approval'
                            category='Label' />
                    </a>
                </UnauthenticatedTemplate>
                <AuthenticatedTemplate>
                    <SectionTitle required>
                        <Translation id='546d4bcd-1abe-4686-8fcd-eda4a7b79870' defaultMessage='Do you want to waive your final artwork approval?' category='Label' />
                    </SectionTitle>
                    <div>
                        <RadioButton name="ArtworkApprovalWaived" value="No" onChange={() => { onSelection(false) }}>
                            <Translation
                                id="0b0fa336-f0f1-4c5c-aea1-236e9ff25aa4"
                                defaultMessage="No"
                                category="Label"
                            />
                        </RadioButton>
                        <RadioButton name="ArtworkApprovalWaived" value="Yes" onChange={() => { onSelection(true) }}>
                            <Translation
                                id="bc6dccd1-ec9a-42df-ac0b-71a6841cd1df"
                                defaultMessage="Yes"
                                category="Label"
                            />
                        </RadioButton>
                    </div>
                </AuthenticatedTemplate>
            </div>
        </div>
    );
};