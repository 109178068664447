import { useEffect, useContext } from "react";
import { React, useState, useRef } from "react";
import { MsalAuthenticationTemplate, AuthenticatedTemplate, UnauthenticatedTemplate, useMsalAuthentication, useMsal, useAccount } from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";
import { RegisterSignInPopup } from "../../components/offer/RegisterSignInPopup";
import { Popup } from "../../core/Popup";
import { loginRequest, protectedResources } from "../../authConfig";
import { useNavigate } from "react-router-dom";
import { SecureApiDataAccess } from "../../infrastructure/SecureApiDataAccess";

export const Login = () => {
    const [loaded, setLoaded] = useState(true);
    const [salesForceAccountInUserTable, setSalesforceAccountInUserTable] = useState(false);
    const { instance, accounts } = useMsal();
    const account = useAccount(accounts[0] || {});
    const popup = useRef(null);
    const userDataAccess = new SecureApiDataAccess("/api/user");


    useEffect(() => {
        const init = async () => {
            var user = await getUser();
            if (user.hasSuccess === true) {
                setLoaded(true);
            }
            else {

            }
        }
        init();
    }, [account]);


    useEffect(() => {
        if (loaded && popup.current != null) {
            popup.current.show();
        }
    }, [loaded])


    const getUser = async () => {
        const response = await instance.acquireTokenSilent({
            scopes: [...protectedResources.shopApp.scopes],
            ...loginRequest,
            account: account,
        });


        if (!response.accessToken) {
            throw new Error("Could not retrieve access token.");
        }
        var user = await userDataAccess.get(
            `/search?emailAddress=${encodeURIComponent(
                account.username
            )}&identityProviderAccountId=${encodeURIComponent(account.idTokenClaims.oid)}`,
            response.accessToken,
            {
                throwOnNonSuccess: false
            }
        );
    }

    const createRedirectUri = () => {
        var uri = new URLSearchParams("");
        uri.append("to", encodeURIComponent(window.location.href));
        return "/register/account?" + uri.toString();
    };

    const redirectUri = createRedirectUri();



    return (
        loaded && (
            <div>
                {process.env.REACT_APP_SHOW_REGISTRATION_POPUP && (salesForceAccountInUserTable === false || (salesForceAccountInUserTable === true && !account)) && (
                    <MsalAuthenticationTemplate interactionType={InteractionType.Redirect} >
                    </MsalAuthenticationTemplate>
                )}
            </div>
        ))
}