import React from "react";
import { Translation } from "../../core/Translation";

export const Layout = (props) => {

    return (
        <div>
            <div className='container'>
                {props.offerData &&
                    <div>
                        <h3>
                            {props.offerData.offer.salesForceAccountName}<br />
                            {props.offerData.offer.offer.convertingMethod.value} <Translation id='32590f3a-e55a-4523-9e0b-d69c570f4a85' defaultMessage='for' category='Label' /> {props.offerData.offer.offer.product.value}
                        </h3>
                        <p><Translation id='3731831e-913c-4507-8067-d546a7d50d14' defaultMessage='Order #' category='Label' /> {props.offerData.offer.offer.salesForceOpportunity.referenceNumber}</p>
                    </div>
                }
                {props.children}
            </div>
        </div>
        );
}
