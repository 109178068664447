import { React, useContext, useState } from "react";
import { loginRequest, protectedResources } from "../authConfig";
import { useMsal, useAccount, useIsAuthenticated, UnauthenticatedTemplate, AuthenticatedTemplate } from "@azure/msal-react";
import { useEffect } from "react";
import { SecureApiDataAccess } from "../infrastructure/SecureApiDataAccess";
import { useCheckoutContext } from "../infrastructure/CheckoutContext";
import { useUserContext } from "../infrastructure/UserContext";
import { useNavigate } from 'react-router-dom';
import { Page } from "../core/Page";
import { Language } from '../infrastructure/Languages';
import { useTracking } from "react-tracking";
import { useCookies } from 'react-cookie';

export const SignIn = () => {
    const { instance, accounts, inProgress } = useMsal();
    const language = useContext(Language);
    const account = useAccount(accounts[0] || {});
    const isAuthenticated = useIsAuthenticated();
    const userDataAccess = new SecureApiDataAccess("/api/user");
    const navigate = useNavigate();
    const { trackEvent } = useTracking({ page: "SignInPage" });
    const [cookies, setCookie] = useCookies(['visitorId']);
    const { createUrlFromContext } = useCheckoutContext();
    const { userRegion } = useUserContext();

    useEffect(() => {

        const init = async () => {

            console.log("SignIn useEffect, SignIn", account, isAuthenticated);
            if (!isAuthenticated) {
                console.log("SignIn You are not authenticated.")
                return;
            }

            let route = "/";

            const response = await instance.acquireTokenSilent({
                scopes: [...protectedResources.shopApp.scopes],
                ...loginRequest,
                account: account,
            });
            console.log("useEffect - acquire token response", response);
            if (!response.accessToken) {
                throw new Error("Could not retrieve access token.");
            }

            var userResponse = await userDataAccess.get(
                `/search?emailAddress=${encodeURIComponent(
                    account.username
                )}&identityProviderAccountId=${encodeURIComponent(account.idTokenClaims.oid)}`,
                response.accessToken,
                {
                    throwOnNonSuccess: false,
                }
            );

            if (userResponse.statusCode === 404) {
                route = "/register";
            } else {
                console.log("Sign In - A user was found", userResponse);
                if (userResponse.data && userResponse.data.registrationStatusId === "COMPL") {
                    route = createUrlFromContext(userRegion);

                    trackEvent({
                        event: 'login',
                        method: account.environment,
                        userId: cookies.visitorId
                    });

                    console.log("Sign In - Redirecting to checkout");
                } else {
                    //Registration not finished
                    console.log("Sign In - Registration not finished");
                    route = "/register";
                }
            }

            navigate(route);
        };
        init();
    }, [account]);

    return (
        <Page>
            <AuthenticatedTemplate>
                <div>
                    <h2>{language.translateLabel('16999f8e-e0bf-41a9-b81f-2550b832be83', 'Account', 'Label')}</h2>
                    <p>{language.translateLabel('be9cdd66-c889-47d8-879c-00f0cdc2d04a', 'Signing you in, please wait...', 'Label')}</p>
                </div>
            </AuthenticatedTemplate>
            <UnauthenticatedTemplate>
                <div></div>
            </UnauthenticatedTemplate>
        </Page>
    );
};
