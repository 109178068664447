import React from "react";
import "./LoadingSpinner.css";

export const LoadingSpinner = ({text}) => {
    return (
        <>
            <div className="backSplash" />
            <div className="loader-screen"/>
            <div className="spinner-circle" />  
            {text &&
                <div className="spinner-text">{text}</div>
            }            
        </>
  );
};
