import React from "react";
import { MessageSquare } from "react-feather";

export const MessageLink = ({ type, onClick, disabled, children }) => {

    const getClassName = () => {
        let result = "iconLink";

        switch (type) {
            case "warn":
                result += " warn";
                break;
            case "error":
                result += " error";
                break;
        }

        if (disabled)
            result += " disabled";

        return result;
    };

    return (
        <div className={getClassName()}>
            {
                disabled ?
                    <span>{children}</span> :
                    <span>
                        <MessageSquare className="icon" onClick={onClick} />
                        <span onClick={onClick}>{children}</span>
                    </span>
            }
        </div>
    );
};