import { useState } from "react";
import { useController } from "react-hook-form";

export const RegistrationSelectionDropdown = (props) => {
    const { field, fieldState } = useController(props);

    const onValueChanged = (e) => {
        if (props.onValueChanged) {
            props.onValueChanged(e);
        }
    };

    return (
        <div>
            <span>{props.label} <span className="registration-validation-error">*</span></span>
            <select className="control dropdown" {...field} onChange={(e) => onValueChanged(e)} value={props.value}>
                <option value={""}>Please Select...</option>
                {props.data &&
                    props.data.map((item, i) => {
                        return (
                            <option key={i} value={item}>
                                {item}
                            </option>
                        );
                    })}
            </select>
            {fieldState.error && <span className="registration-validation-error">{fieldState.error.message}</span>}
        </div>
    );
};
