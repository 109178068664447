import React, { Component } from 'react';


export class Capability extends Component {

    constructor(props) {
        super(props);

        if (!this.props.capabilityRegistry) {
            throw new Error('Capability Registry is required!');
        }

        if (!this.props.id) {
            throw new Error('Capability Id is required!');
        }
    }

    componentDidMount() {
        this.props.capabilityRegistry.register(this);
    }


    render() {
        return (
            <div></div>
        )
    }
}

export class CapabilityRegistry {

    constructor() {
        this.components = {};
    }

    register(component) {
        this.components[component.props.id] = component;
    }

    getCapability(id) {
        return this.components[id];
    }
}


export class Capabilities extends Component {

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div>
                {this.props.children}
            </div>
        )
    }
}




export class CultureManager {

   
    constructor(culture, language) {

        if (!culture && window.document.location.pathname != '/') {
            window.document.location = '/';
        }

        this.culture = culture ? culture : null;        
        this.language = language && language != 'undefined' ? language : culture;
    }

    getCulture() {
        return this.culture;
    }

    getLanguage() {
        return this.language;
    }

}
