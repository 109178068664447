import React from "react";

export const NumberInput = (props) => {

    return (
        <div className={props.inputstyle ? props.inputstyle : "text-action-container-textbox"}>
            <input
                data-testid={"form-input-" + props.name}
                type="number"
                className="control textbox"
                autoComplete="none"
                placeholder={props.placeholder}
                value={props.value}
                max={props.max}
                min={props.min}
                onChange={(e) => {
                    if (props.onChange) {
                        props.onChange(e.target.value);
                    }
                }}
            />
        </div>
    );
};