import React, { useContext} from 'react';
import { Page } from '../core/Page';
import { useCheckoutContext } from '../infrastructure/CheckoutContext';
import { useUserContext } from '../infrastructure/UserContext';
import { Language } from '../infrastructure/Languages';

export const SignedOut = () => {

    const language = useContext(Language);
    const { reset } = useCheckoutContext();  
    const { userRegion } = useUserContext();  

    const onSignOutClick = () => {
        reset();
        document.location = `/${userRegion}`;
    }

    return (
        <Page>
            <h1>{language.translateLabel('99192b05-1fe0-400f-a207-292dd741895a', 'Signed Out', 'Label')}</h1>
            <p>{language.translateLabel('ca7c6496-b398-4dba-8d71-381eef471bfb', 'You are now signed out. Please click the button below to return to the home page.', 'Label')}</p>

            <button className='btn btn-primary' onClick={() => { onSignOutClick() }}>{language.translateLabel('55f90459-5de8-4bc9-8f64-273e5a622cbf', 'Home Page', 'Button')}</button>
        </Page>
        )
}